import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Enum } from '@sl/utils';
import keyMirror from 'key-mirror'
import { RequestSubject } from '../../services/NetClient'

export const DebugLogStatus = keyMirror({
  play: '',
  stopped: ''
});

export const DebugLogTag = Enum.build(['SystemSource', 'UserSource', 'OPC UA', 'ModBus']);

const initialState = {
  clients: [],
  debugLog: {
    status: DebugLogStatus.play,
    tagFilter: [...DebugLogTag.names],
    messages: [],
    maxMessageCount: 1000,
    nextMessageID: 0
  },
  license: {
    isFetching: false,
    fetchError: '',
    contactPerson: '',
    organization: '',
    email: '',
    licenseType: 'Free',
    maxNetClients: 1,
    maxChannelCount: 0
  },
  serverStatus: {
    isFetching: false,
    fetchError: '',
    channelCount: 0,
    serverStartTime: null,
    version: ''
  },
}

export const fetchServerStatus = createAsyncThunk(
  'controlPanel/fetchServerStatus',
  async ({ client }) => {
    const response = await client.get(RequestSubject.ServerStatus);
    return response.data;
  }
)

export const fetchLicense = createAsyncThunk(
  'controlPanel/fetchLicense',
  async ({ client }) => {
    const response = await client.get(RequestSubject.License, { format: 'web' });
    return response.data;
  }
)

export const subscribeClients = createAsyncThunk(
  'controlPanel/subscribeClients',
  async ({ client }) => {
    await client.post(RequestSubject.ClientsSubscriptions);
  }
)

export const unsubscribeClients = createAsyncThunk(
  'controlPanel/unsubscribeClients',
  async ({ client }) => {
    await client.delete(RequestSubject.ClientsSubscriptions);
  }
)

export const disconnectClient = createAsyncThunk(
  'controlPanel/disconnectClient',
  async ({ client, clientID }) => {
    await client.delete(RequestSubject.ConnectedClient, { clientID })
  }
)

export const subscribeDebugMessages = createAsyncThunk(
  'controlPanel/subscribeDebugMessages',
  async ({ client }) => {
    await client.post(RequestSubject.DebugMessageSubscriptions);
  }
)

export const unsubscribeDebugMessages = createAsyncThunk(
  'controlPanel/unsubscribeDebugMessages',
  async ({ client }, thunkAPI) => {
    thunkAPI.dispatch(clearDebugMessages());
    await client.delete(RequestSubject.DebugMessageSubscriptions);
  }
)

export const stopServer = createAsyncThunk(
  'controlPanel/stopServer',
  async ({ client }) => {
    await client.post(RequestSubject.StopServer)
  }
)

export const restartServer = createAsyncThunk(
  'controlPanel/restartServer',
  async({ client }) => {
    await client.post(RequestSubject.RestartServer)
  }
)

export const controlPanelSlice = createSlice({
  name: 'controlPanel',
  initialState,
  reducers: {
    clientsReceived: (state, { payload }) => {
      state.clients = payload;
    },
    clearDebugMessages: (state) => {
      state.debugLog.messages = [];
    },
    debugLogMessagesReceived: (state, { payload }) => {
      const { debugLog } = state;
      if (debugLog.status !== DebugLogStatus.play) {
        return;
      }

      let nextMessageID = debugLog.nextMessageID;
      const maxMessageCount = debugLog.maxMessageCount;

      const gotMessages = payload
        .filter(it => debugLog.tagFilter.includes(it.tag))
        .map(it => ({
          ID: nextMessageID++,
          tag: it.tag,
          time: new Date(it.time * 1000),
          text : it.text
        }));
      let newMessages = [
        ...debugLog.messages,
        ...gotMessages
      ];

      newMessages = newMessages.slice(Math.max(newMessages.length - maxMessageCount, 0))

      debugLog.messages = newMessages;
      debugLog.nextMessageID = nextMessageID;
    },
    debugLogStatusSet: (state, { payload }) => {
      state.debugLog.status = payload;
    },
    debugLogTagFilterSet: (state, { payload }) => {
      state.debugLog.tagFilter = payload;
    }
  },
  extraReducers: {
    [fetchServerStatus.pending]: (state) => {
      state.serverStatus.isFetching = true
      state.serverStatus.fetchError = ''
    },
    [fetchServerStatus.fulfilled]: (state, { payload }) => {
      state.serverStatus.isFetching = false
      state.serverStatus.channelCount = payload.channelCount
      state.serverStatus.serverStartTime = new Date(payload.serverStartTime)
      state.serverStatus.version = payload.version
    },
    [fetchServerStatus.rejected]: (state, { error }) => {
      state.serverStatus.isFetching = false
      state.serverStatus.fetchError = error.message
    },

    [fetchLicense.pending]: (state) => {
      state.license.isFetching = true
      state.license.fetchError = ''
    },
    [fetchLicense.fulfilled]: (state, { payload }) => {
      state.license = {
        ...state.license,
        ...payload,
        isFetching: false
      }
    },
    [fetchLicense.rejected]: (state, { error }) => {
      state.license.isFetching = false
      state.license.fetchError = error.message
    }
  }
})

export const { 
  clientsReceived, debugLogMessagesReceived, clearDebugMessages,
  debugLogStatusSet, debugLogTagFilterSet } = controlPanelSlice.actions;

export default controlPanelSlice.reducer;