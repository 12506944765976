import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ruRU as ruCoreLocale, enUS as enCoreLocale } from '@mui/material/locale';
import { ruRU as ruGridLocale, enUS as enGridLocale } from '@mui/x-data-grid';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider as LocalizationProviderPicker } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns as AdapterDateFnsPicker } from '@mui/x-date-pickers/AdapterDateFns';
import { ruRU as ruPickerLocale, enUS as enPickerLocale } from '@mui/x-date-pickers/locales';
import ruDateFnsLocale from 'date-fns/locale/ru';
import enDateFnsLocale from 'date-fns/locale/en-US';

const theme = createTheme({ 
  palette: { 
    mode: 'dark'
  } 
});

const langCoreLocaleMap = {
  'ru-RU': ruCoreLocale,
  en: enCoreLocale
}

const langGridLocaleMap = {
  'ru-RU': ruGridLocale,
  en: enGridLocale
}

const langPickerLocaleMap = {
  'ru-RU': ruPickerLocale,
  en: enPickerLocale
}

const dateFnsLocaleMap = {
  'ru-RU': ruDateFnsLocale,
  en: enDateFnsLocale
}

const ThemeSource = ({ children }) => {
  const { t } = useTranslation()
  const language = t('lang')

  const locale = dateFnsLocaleMap[language]

  const themeWithLocale = useMemo(
    () => createTheme(
        theme,
        langCoreLocaleMap[language],
        langGridLocaleMap[language],
        langPickerLocaleMap[language]
      ), [language]
  )

  return (
    <ThemeProvider theme={themeWithLocale}>
      <LocalizationProvider 
        dateAdapter={AdapterDateFns}
        locale={locale}
      >
        <LocalizationProviderPicker 
          dateAdapter={AdapterDateFnsPicker}
          adapterLocale={locale}
        >
          {children}
        </LocalizationProviderPicker> 
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default ThemeSource;