import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import { accept, close } from './ConfirmDialog.slice'

export const ConfirmDialog = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const open = useSelector(state => state.dialog.confirm.open)
  const text = useSelector(state => state.dialog.confirm.text)
  
  const handleOk = () => {
    dispatch(accept())
  }

  const handleClose = () => {
    dispatch(close())
  }

  return (
    <Dialog maxWidth="sm" open={open} onClose={handleClose}>
      <DialogTitle>{t('dialog.confirm.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOk}>{t('dialog.button.ok')}</Button>
        <Button autoFocus onClick={handleClose}>
          {t('dialog.button.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog