import Polyglot from 'node-polyglot'
import { GVLArgumentControl, GVLControl, GVLElement } from './elements'
import { controlsRtti } from './rtti'
import { ValueType } from './prop-types'
import { PointF } from './types'
import { GVLArgument } from './window'

function findPropertyByName(properties, name) {
  const nameUpper = name.toUpperCase()

  for (let key in properties) {
    if (nameUpper === key.toUpperCase()) {
      return properties[key]
    }
  }

  return null
}

function hexAlphaColorToRGBA(value) {
  let alpha

  if (value.length === 8) {
    const a = parseInt('0x' + value.substring(0, 2))
    alpha = a / 255
    value = value.substring(2)
  } else {
    alpha = 1
  }

  const r = parseInt('0x' + value.substring(0, 2))
  const g = parseInt('0x' + value.substring(2, 4))
  const b = parseInt('0x' + value.substring(4, 6))
  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

var polyglot = new Polyglot()
polyglot.extend({
  "XmlStore.ReadObjectPropertyOnlyForObjectProperty": "ReadObjectProperty может быть применен только к свойству объекту",
  "XmlStore.RootNonWindow": "Корень документа должен быть Window(окном)",
  "XmlStore.TargetHasNoProperty": "%{component} не содержит свойство \"%{propertyName}\"",
  "XmlStore.UnknownElement": "Неизвестный элемент \"%{elementName}\""
})

class Store {

  constructor() {
    this._errors = []
    this._elementRefs = []
    this._readWindow = null
    this.argumentService = null
  }

  fromXml(text, window) {
    this._elementRefs = []

    const parser = new DOMParser()
    const document = parser.parseFromString(text, 'application/xml')

    const root = document.documentElement

    if (root.nodeName === 'parsererror') {
      throw new Error('Met parser error with ' + root.childNodes[0])
    }

    if (root.nodeName !== 'Window') {
      throw new Error(polyglot.t('XmlStore.RootNonWindow'))
    }

    this._readWindow = window
    window.loading = true
    try {
      this._readElement(root, 'Window', window)
      this._resolveElementRefs(window)
    }  catch (e) {
      console.error(e)
    } finally {
      this._elementRefs = []
      this._readWindow = null
      window.loading = false
    }
  }

  _readElement(xmlElement, gvlElementClassName, gvlElement) {
    const type = controlsRtti[gvlElement.constructor.name]

    if (!type) {
      this._errors.push(`Не найдено описание класса ${gvlElement.constructor.name}`)
      return
    }

    const typeProperties = type.properties
    let error
    let metaclass
    let childControl
    let childGvlElement
    
    this._readAttributes(gvlElement, typeProperties, xmlElement.attributes)

    for (let child of xmlElement.children) {
      let tagName = child.tagName
      let tagNameParts = tagName.split('.')

      if (tagNameParts.length === 2) {
        if (tagNameParts[0] === gvlElementClassName) {
          let property = findPropertyByName(typeProperties, tagNameParts[1])

          if (property) {
            this._readObjectProperty(child, property, gvlElement)
          } else {
            error = polyglot.t('XmlStore.TargetHasNoProperty', { component: tagNameParts[0], propertyName: tagNameParts[1] })
            this._errors.push(error)
          }
        }
      } else {
        const childType = this._findElementTypeByTagName(tagName)

        if (childType) {
          metaclass = childType.metaclass

          if (metaclass.prototype instanceof GVLControl) {
            if (gvlElement instanceof GVLControl) {
              childControl = new metaclass()
              gvlElement.addControl(childControl)

              if (metaclass.prototype instanceof GVLArgumentControl) {
                childControl.argumentService = this.argumentService
              }

              this._readElement(child, tagName, childControl)
            }
            
          } else if (metaclass.prototype instanceof GVLElement) {
            childGvlElement = new metaclass()
            gvlElement.addElement(childGvlElement)
            this._readElement(child, tagName, childGvlElement)
          }
        } else {
          error = polyglot.t('XmlStore.UnknownElement', { elementName: tagName })
          this._errors.push(error)
        }
      }
    }
  }

  _readAttributes(instance, typeProperties, xmlAttributes) {
    var aNumber
    var rgbaColor
    var aValue
    var aPoint
    var metaclass

    for (let attribute of xmlAttributes) {
      var attributeName = attribute.name
      var attributeValue = attribute.value

      var property = findPropertyByName(typeProperties, attributeName)

      if (!property) {
        continue
      }

      switch (property.type) {
        case ValueType.Single:
          aNumber = parseFloat(attributeValue)
          instance[attributeName] = aNumber
          break
        case ValueType.AlphaColor:
          if (attributeValue.startsWith('#')) {
            attributeValue = attributeValue.substring(1)
          }

          rgbaColor = hexAlphaColorToRGBA(attributeValue)
          instance[attributeName] = rgbaColor
          break

        case ValueType.Integer:
          aNumber = parseInt(attributeValue)
          instance[attributeName] = aNumber
          break

        case ValueType.Boolean:
          if (attributeValue === 'true') {
            instance[attributeName] = true
          } else if (attributeValue === 'false') {
            instance[attributeName] = false
          } else {
            this._errors.push(`Unacceptable boolean value for ${instance.constructor.name}`)
          }
          break

        case ValueType.Enum:
          aValue = parseEnumValue(property.enumType, attributeValue)

          if (aValue) {
            instance[attributeName] = aValue
          } else {
            this._errors.push(`There's no ${attributeValue} in the ${property.enumType.name} enum type`)
          }

          break

        case ValueType.EnumSet:
          aValue = this._parseEnumSet(property.enumType, attributeValue)
          instance[attributeName] = aValue
          break

        case ValueType.PointF:
          aPoint = new PointF()
          parsePointFromString(aPoint, attributeValue)
          instance[attributeName] = aPoint

          break

        case ValueType.String:
          instance[attributeName] = '' + attributeValue
          break

        case ValueType.Instance:
          attributeValue = attributeValue.trim()
          metaclass = property.metaclass

          if (attributeValue !== '' && metaclass) {
            if (property.metaclass === GVLArgument) {
              const argumentID = +attributeValue
              const argument = this._readWindow.findArgumentByID(argumentID)
              instance[attributeName] = argument
            } else if (property.metaclass === GVLElement || metaclass.prototype instanceof GVLElement) {
              this._elementRefs.push({
                propertyName: attributeName, 
                container: instance, 
                name: attributeValue
              })
            }
          }
          break
        default:
      }

    }
  }
  _readObjectProperty(xmlElement, property, gvlElement) {
    if (property.type !== ValueType.Instance) {
      this._errors.push(polyglot.t('XmlStore.ReadObjectPropertyOnlyForObjectProperty'))
    }

    let read = false

    const { metaclass } = property

    if (metaclass) {
      if (metaclass.prototype instanceof GVLElement) {
        throw new Error('ReadObjectProperty for GVLElement not implemented')
      }
    }

    if (!read) {
      let valueInstance = gvlElement[property.name]

      if (valueInstance) {
        if (Array.isArray(valueInstance)) {
          let itemClass = property.collectionItemClass

          if (itemClass) {
            let newCollection = []
            this._readCollectionItems(xmlElement.children, newCollection, itemClass)
            gvlElement[property.name] = newCollection
          }
        } else {
          this._readValueObject(valueInstance, xmlElement)
        }
      }
    }
  }

  _readCollectionItems(elementChildren, collection, itemClass) {
    for (let child of elementChildren) {
      var item = new itemClass()
      this._readValueObject(item, child)
      collection.push(item)
    }
  }

  _readValueObject(valueObject, xmlElement) {
    const type = controlsRtti[valueObject.constructor.name]
    if (!type) {
      this._errors.push(`Не найдено описание класса ${valueObject.constructor.name}`)
      return
    }

    const attributes = xmlElement.attributes
    this._readAttributes(valueObject, type.properties, attributes)

    for (let child of xmlElement.children) {
      var { tagName } = child
      var property = findPropertyByName(type.properties, tagName)

      if (property && property.type === ValueType.Instance) {
        this._readObjectProperty(child, property, valueObject)
      }
    }
  }

  _findElementTypeByTagName(tagName) {
    let className = `GVL${tagName}`
    return controlsRtti[className]
  }

  _parseEnumSet(enumType, value) {
    const stringEnums = value.split(',').map(num => num.trim())

    const result = []

    for (let enumStr of stringEnums) {
      if (enumStr === '') {
        continue
      }

      const enumValue = parseEnumValue(enumType, enumStr)

      if (enumValue) {
        result.push(enumValue)
      } else {
        this._errors.push(`There's no ${enumStr} in the ${enumType.name} enum type. Ignored.`)
      }
    }

    return result
  }

  get errors() {
    return this._errors
  }

  _resolveElementRefs(window) {
    for (let elementRef of this._elementRefs) {
      var element = window.findByName(elementRef.name)

      if (element) {
        elementRef.container[elementRef.propertyName] = element
      }
    }
  }
}

function parsePointFromString(point, value) {
  const nums = value.split(',').map(num => +(num.trim()))

  if (nums.length === 2) {
    point.x = nums[0]
    point.y = nums[1]
  }
}

function parseEnumValue(enumType, value) {
  const valueUpper = value.toUpperCase()

  for (let ev of Object.keys(enumType)) {
    if (ev.toUpperCase() === valueUpper) {
      return enumType[ev]
    }
  }

  return null
}

export default Store