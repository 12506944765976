import { GVLControl } from "../../GVL/elements"
import { RectF, PointF, polarToCartesian, SvgPath2D, ValueObject } from '../../GVL/types'
import { Colors } from "../../std/utils"
import { SVG } from '@svgdotjs/svg.js'
import { floatEquals } from '../../std/math'
import { EventorOn } from 'std/eventor'
import Metrics from '../../std/metrics'
import { formatNumber } from 'utils/common'

export const svgNS = 'http://www.w3.org/2000/svg'

/**
 * Описывает объект шрифта, аналогичный Delphi System.Classes.TFont
 */
 export class SLFont {
  constructor() {
    this._bold = false;
    this._color = '#FFFFFF';
    this._italic = false;
    this._name = 'Arial';
    this._underline = false;
    this._strikeOut = false;
    this._size = 10;
  }

  dispose() {
    this.onChange = null;
  }

  copy(value) {
    this.bold = value._bold;
    this.color = value._color;
    this.italic = value._italic;
    this.name = value._name;
    this.underline = value._underline;
    this.strikeOut = value._strikeOut;
    this.size = value._size;
  }

  _doChange() {
    this.onChange && this.onChange();
  }

  get bold() { return this._bold; }

  set bold(value) {
    if (this._bold !== value) {
      this._bold = !!value;
      this._doChange();
    }
  }

  get color() {
    return this._color;
  }

  set color(value) {
    if (this._color !== value) {
      this._color = value;
      this._doChange();
    }
  }

  get italic() {
    return this._italic;
  }

  set italic(value) {
    if (this._italic !== value) {
      this._italic = !!value;
      this._doChange();
    }
  }

  get name() {
    return this._name;
  }

  set name(value) {
    if (this._name !== value) {
      this._name = value;
      this._doChange();
    }
  }

  get size() {
    return this._size;
  }

  set size(value) {
    const newValue = +value;
    if (this._size !== newValue) {
      this._size = newValue;
      this._doChange();
    }
  }

  get strikeOut() {
    return this._strikeOut;
  }

  set strikeOut(value) {
    if (this._strikeOut !== value) {
      this._strikeOut = !!value;
      this._doChange();
    }
  }

  get underline() {
    return this._underline;
  }

  set underline(value) {
    if (this._underline !== value) {
      this._underline = !!value;
      this._doChange();
    }
  }

  toCSSValue() {
    let value = '';
    value += this._bold? 'bold ': '';
    value += this._italic? 'italic ': '';
    value += `${this._size}pt ${this._name}`;

    return value;
  }

  setToDOMStyle(style) {
    style.font = this.toCSSValue();
    style.color = this._color;

    if (this._underline || this._strikeOut) {  
      style.textDecoration = this.toCSSTextDecoration();
    } else {
      style.textDecoration = null;
    }
  }

  toCSSTextDecoration() {
    const decorations = [];

    if (this._underline) {
      decorations.push('underline');
    }

    if (this._strikeOut) {
      decorations.push('line-through');
    }

    return decorations.join(' ');
  }

  toCSSPropDeclaration() {
    const fontText = this.toCSSValue();
    let decl = `color: ${this._color}; font: ${fontText};`;

    if (this._underline || this._strikeOut) {
      const decorationText = this.toCSSTextDecoration();
      decl += `text-decoration: ${decorationText};`
    }

    return decl;
  }
}

export class SLTextBlock extends GVLControl {
  constructor() {
    super();

    this._text = 'Текст';
    this._font = new SLFont();
    this._font.family = 'Segoe UI';
    this._font.size = 16;
    this._font.onChange = this._fontChange.bind(this);
    this._vertAlign = 'top';
    this._horzAlign = 'left';
    this._wrapping = false;

    this.width = 100;
    this.height = 50;
  }

  dispose() {
    this._font.dispose();
    super.dispose();
  }

  get font() { return this._font; }

  get horzAlign() { return this._horzAlign }
  set horzAlign(value) { this._setProp('horzAlign', value) }

  get text() { return this._text }
  set text(value) { this._setProp('text', value) }

  get vertAlign() { return this._vertAlign }
  set vertAlign(value) { this._setProp('vertAlign', value) }

  get wrapping() { return this._wrapping }
  set wrapping(value) { this._setProp('wrapping', !!value) }

  _fontChange() {
    this.requestUpdate();
  }

  _propertyChanged(name) {
    super._propertyChanged(name);

    switch (name) {
      case 'width':
        this._domRoot.style.width = `${this._width}px`;
        break;
      case 'height':
        this._domRoot.style.height = `${this._height}px`;
        break;
    }
  }

  initDomRoot() {
    const domRoot = document.createElement('div');
    domRoot.classList.add('gvl-control', 'gvl-text');

    this._domRoot = domRoot;
  }

  render() {
    const block = this._domRoot;
    block.textContent = this._text;

    const { style } = block;

    style.color = this._color;
    
    const font = this._font;
    style.font = font.toCSSValue();
    style.color = Colors.addAlpha(font.color, this.alpha);
    
    if (font.underline || font.strikeOut) {  
      style.textDecoration = font.toCSSTextDecoration();
    } else {
      style.textDecoration = null;
    }

    // Для горизонтального выравнивания 
    // без переноса строк используется flexbox
    // с переносом text-align
    
    switch(this._horzAlign) {
      case 'left':
        style.textAlign = 'left';
        style.justifyContent = 'flex-start';
        break;
      case 'center':
        style.textAlign = 'center';
        style.justifyContent = 'center';
        break;
      case 'right':
        style.textAlign = 'right';
        style.justifyContent = 'flex-end';
        break;
    }

    switch(this._vertAlign) {
      case 'top':
        style.alignItems = 'flex-start';
        break;
      case 'center':
        style.alignItems = 'center';
        break;
      case 'bottom':
        style.alignItems = 'flex-end';
        break;
    }

    style.whiteSpace = this._wrapping? 'normal': 'nowrap';
    style.wordBreak = 'break-word';
  }
}

export class SLTextLine extends SLTextBlock {
  constructor() {
    super();

    this._ellipseWords = false;
    this._font = new SLFont();
    this._font.family = 'Segoe UI';
    this._font.size = 16;
    this._font.onChange = this._fontChange.bind(this);
    this._horzAlign = 'center';
    this._text = 'Текст';
    this._vertAlign = 'top';

    this.width = 100;
    this.height = 50;
  }

  dispose() {
    this._font.dispose();
    super.dispose();
  }

  get font() { return this._font; }

  get ellipseWords() { return this._ellipseWords }
  set ellipseWords(value) { this._setProp('ellipseWords', value) }

  get horzAlign() { return this._horzAlign }
  set horzAlign(value) { this._setProp('horzAlign', value) }

  get text() { return this._text }
  set text(value) { this._setProp('text', value) }

  get vertAlign() { return this._vertAlign }
  set vertAlign(value) { this._setProp('vertAlign', value) }

  _fontChange() {
    this.requestUpdate();
  }

  _propertyChanged(name) {
    super._propertyChanged(name);

    switch (name) {
      case 'width':
        this._domRoot.style.width = `${this._width}px`;
        break;
      case 'height':
        this._domRoot.style.height = `${this._height}px`;
        break;
    }
  }

  initDomRoot() {
    const domRoot = document.createElement('div');
    domRoot.classList.add('gvl-control', 'gvl-text');

    this._domRoot = domRoot;

    this._line = document.createElement('div');
    domRoot.append(this._line);
  }

  render() {
    const block = this._domRoot;
    const line = this._line;
    line.textContent = this._text;

    const { style: lineStyle } = line;

    lineStyle.color = this._color;
    
    const font = this._font;
    lineStyle.font = font.toCSSValue();
    lineStyle.color = Colors.addAlpha(font.color, this.alpha);
    lineStyle.whiteSpace = 'nowrap';
    lineStyle.textOverflow = 'ellipsis';
    lineStyle.overflow = 'hidden';
    
    if (font.underline || font.strikeOut) {  
      lineStyle.textDecoration = font.toCSSTextDecoration();
    } else {
      lineStyle.textDecoration = null;
    }

    switch(this._horzAlign) {
      case 'left':
        block.style.justifyContent = 'flex-start';
        break;
      case 'center':
        block.style.justifyContent = 'center';
        break;
      case 'right':
        block.style.justifyContent = 'flex-end';
        break;
    }
    
    switch(this._vertAlign) {
      case 'top':
        block.style.alignItems = 'flex-start';
        break;
      case 'center':
        block.style.alignItems = 'center';
        break;
      case 'bottom':
        block.style.alignItems = 'flex-end';
        break;
    }
  }
}

export class SLImageView extends GVLControl {
  constructor() {
    super();

    this._keepDomRootByClient = true;
    this._pictureAngle = 0;
    this._src = '';
    this._stretch = 'fill'; // none, fill, uniform
  }

  get pictureAngle() { return this._pictureAngle }
  set pictureAngle(value) { this._setProp('pictureAngle', value) }

  get src() { return this._src }
  set src(value) { this._setProp('src', value) }

  initDomRoot() {
    this._domRoot = document.createElement('div');
    this._domRoot.className = 'gvl-control';

    this._img = document.createElement('img');
    this._domRoot.append(this._img);
  }

  render() {
    const root = this._domRoot;
    const img = this._img;
    const w = this._width;
    const h = this._height;

    const { style } = img;

    if (this._src !== '') {
      img.src = this._src;
      root.style.visibility = null;
      style.transformOrigin = 'top left';

      switch (this._pictureAngle) {
        case 90:
          img.width = h;
          img.height = w;
          style.transform = `translateX(${w}px) rotate(90deg)`;
          break;
        case 180:
          img.width = w;
          img.height = h;
          style.transform = `translate(${w}px, ${h}px) rotate(180deg)`;
          break;
        case 270:
          img.width = h;
          img.height = w;
          style.transform = `translateY(${h}px) rotate(270deg)`;
          break;
        default:
          img.width = w;
          img.height = h;
          style.transform = null;
      }
    } else {
      root.style.visibility = 'hidden';
    }
  }
}

export class SLFrameBox extends GVLControl {
  constructor() {
    super();

    this._backgroundColor = '#FFFFFF';
    this._borderRadius = 7;
    this._color = '#000000';
    this._frame = '3d_1';
    this._size = 1;
    this._bounds = new RectF();
    this._keepDomRootByClient = true;
  }

  get backgroundColor() { return this._backgroundColor }
  set backgroundColor(value) { this._setProp('backgroundColor', value) }

  get borderRadius() { return this._borderRadius }
  set borderRadius(value) { this._setProp('borderRadius', value) }

  get color() { return this._color }
  set color(value) { this._setProp('color', value) }

  get size() { return this._size }
  set size(value) { this._setProp('size', value) }

  get frame() { return this._frame }
  set frame(value) { this._setProp('frame', value) }

  initDomRoot() {
    this._domRoot = document.createElementNS(svgNS, 'svg');
    const {style} = this._domRoot;
    style.position = 'absolute';
    style.top = 0;
    style.left = 0;

    this.$svg = SVG(this._domRoot);
  }

  render() {
    let b = this._bounds;
    let $svg = this.$svg;
    let $g = null;
    let sizeBy2 = this._size / 2;

    $svg.clear();

    b.setBounds(0, 0, this._width, this._height);
    b.inflate(-0.5, -0.5);

    let $rect = $svg.rect(this._width, this._height).fill(this._backgroundColor);

    switch (this._frame) {
      case '3d_1':
        $g = $svg.group().fill('none').stroke({ width: 1 });

        $g.path(`M${b.left},${b.bottom}v${-b.height}h${b.width}`).stroke('white');
        $g.path(`M${b.right},${b.top}v${b.height}h${-b.width}`).stroke('grey');
        break;

      case '3d_2':
        $g = $svg.group().fill('none').stroke({ width: 1 });

        $g.path(`M${b.left},${b.bottom}v${-b.height}h${b.width}`).stroke('grey');
        $g.path(`M${b.right},${b.top}v${b.height}h${-b.width}`).stroke('white');
        break;

      case '3d_3':
        // b: 0.5, 0.5, 299, 199
        $g = $svg.group().fill('none').stroke({ width: 1 });

        $g.rect(b.width - 1, b.height - 1).move(b.left, b.top).stroke('grey');
        $g.path(`M${b.right},0v${b.height+0.5}h-${b.width+1}`).stroke('white');
        break;

      case '3d_4':
        $g = $svg.group().fill('none');

        $g.rect(this._width - 2, this._height - 2).move(1, 1).stroke({ color: 'grey', width: 2 });
        $g.rect(b.width - 1, b.height - 1).move(b.left, b.top).stroke({ color: 'white', width: 1 });
        break;
      case 'rounded':
        b.setBounds(0, 0, this._width, this._height);
        b.inflate(-1, -1);

        $rect.size(b.width, b.height).move(b.left, b.top);
        $rect.rx(this._borderRadius).ry(this._borderRadius);
        $rect.stroke({ color: this._color, width: 2 });
        break;
      case 'solid':
        b.setBounds(0, 0, this._width, this._height);
        b.inflate(-sizeBy2, -sizeBy2);
        $rect.size(b.width, b.height).move(b.left, b.top);
        $rect.stroke({ color: this._color, width: this._size });
        break;
      case '3d_4_4':
        $g = $svg.group().fill('none');

        $g.rect(this._width - 4, this._height - 4).move(2, 2).stroke({ color: 'white', width: 4 });
        $g.rect(this._width - 4, this._height - 4).move(3, 3).stroke({ color: 'grey', width: 2 });
        break;
    }
  }

  _propertyChanged(name) {
    super._propertyChanged(name);

    switch (name) {
      case 'width':
      case 'height':
        this.requestUpdate();
        break;
    }
  }
}

SLFrameBox.frames = ['3d_1', '3d_2', '3d_3', '3d_4', 'none', 'rounded', 'solid'];

export class SLFrame3D extends GVLControl {
  constructor() {
    super();
    this._keepDomRootByClient = true;

    this._backgroundColor = '';
    this._borderWidth = 3;
    this._borderVisible = true;
  }

  get backgroundColor() { return this._backgroundColor }
  set backgroundColor(value) { this._setProp('backgroundColor', value) }

  get borderVisible() { return this._borderVisible }
  set borderVisible(value) { this._setProp('borderVisible', value) }

  initDomRoot() {
    this.defineRoot('div');
  }

  render() {
    const { style } = this._domRoot;

    style.backgroundColor = this._backgroundColor !== ''? this._backgroundColor: null;

    if (this._borderVisible) {
      style.borderTop = `${this._borderWidth}px solid white`;
      style.borderLeft = `${this._borderWidth}px solid white`;
      style.borderRight = `${this._borderWidth}px solid #A0A0A0`;
      style.borderBottom = `${this._borderWidth}px solid #A0A0A0`;
    } else {
      style.borderTop = null;
      style.borderLeft = null;
      style.borderRight = null;
      style.borderBottom = null;
    }
  }
}

export class SLBadQualityCover extends GVLControl {
  constructor() {
    super();
    this.visible = false;
  }

  initDomRoot() {
    let cover = document.createElementNS(svgNS, 'svg');
    cover.classList.add('quality-cover');
    this._domRoot = cover;

    let rect = document.createElementNS(svgNS, 'rect');
    rect.setAttribute('width', '100%');
    rect.setAttribute('height', '100%');
    rect.setAttribute('fill', 'url(#badQualityGrid)');

    let triangle = document.createElementNS(svgNS, 'path');
    triangle.setAttribute('d', 'M0,12L6,0L12,12z');
    triangle.setAttribute('fill', 'yellow');

    cover.appendChild(rect);
    cover.appendChild(triangle);
  }
}

export function createBadQualityCover() {
  let cover = document.createElementNS(svgNS, 'svg');
  cover.classList.add('quality-cover');

  let rect = document.createElementNS(svgNS, 'rect');
  rect.setAttribute('width', '100%');
  rect.setAttribute('height', '100%');
  rect.setAttribute('fill', 'url(#badQualityGrid)');

  let triangle = document.createElementNS(svgNS, 'path');
  triangle.setAttribute('d', 'M0,12L6,0L12,12z');
  triangle.setAttribute('fill', 'yellow');

  cover.appendChild(rect);
  cover.appendChild(triangle);

  return cover;
}

class SLSector extends EventorOn {
  constructor(startValue, endValue, color) {
    super();

    this.color = color;
    this.startValue = Math.min(startValue, endValue);
    this.endValue = Math.max(startValue, endValue);
  }

  dispose() {
    this.onChange = null;
  }

  set(name, value) {
    if (this[name] !== undefined) {
      this[name] = value;
      this.onChange && this.onChange();
    }
  }
}

const GaugeProps = Object.freeze({
  axisWidth: 4,
  arrowWidth: 16,
  tickWidth: 3,
  tickHeight: 8,
  sectorWidth: 40,
  subTickWidth: 1.75,
  subTickHeight: 4
})

export class SLGauge extends GVLControl {
  constructor() {
    super();

    this._arrowFill = 'lightgreen';
    this._arrowAngle = 0;
    this.arrowStroke = 'rgba(0, 0, 0, .75)';
    this._keepDomRootByClient = true;
    this._axisColor = '#000000';
    this._axisWidth = 2;
    this._tickCount = 5;
    this._fractionDigits = 1;
    this._minValue = 0;
    this._maxValue = 100;
    this.rangeAngle = 120;
    this._value = 50;
    this._valueFormatter = Math.round;
    this.font = new SLFont();
    this.font.onChange = () => this.requestUpdate();

    this.center = new PointF();
    this.sectors = [];
    this.ticks = [];

    this._formatFloatValue = this._formatFloatValue.bind(this);
  }

  dispose() {
    this.font.dispose();
    super.dispose();
  }

  get arrowFill() { return this._arrowFill }
  set arrowFill(value) { 
    if (this._arrowFill !== value) {
      this._arrowFill = value;
      this._updateArrowPresentation();
    }
  }

  get axisColor() { return this._axisColor }
  set axisColor(value) { this._setProp('axisColor', value) }
 
  get tickCount() { return this._tickCount }
  set tickCount(value) { this._setProp('tickCount', value) }

  get fractionDigits() { return this._fractionDigits }
  set fractionDigits(value) { this._setProp('fractionDigits', value) }

  get minValue() { return this._minValue }
  set minValue(value) { this._setProp('minValue', value) }

  get maxValue() { return this._maxValue }
  set maxValue(value) { this._setProp('maxValue', value) }

  get value() { return this._value }
  set value(value) { 
    if (this._value !== value) {
      this._value = +value;
      this._updateArrowPresentation();
    }
  }

  addSector(startValue, endValue, color) {
    let sector = new SLSector(startValue, endValue, color);
    this.sectors.push(sector);
    this.requestUpdate();
    return sector;
  }

  initDomRoot() {
    this.$svg = SVG();
    this._domRoot = this.$svg.node;
    this._domRoot.classList.add('gvl-control');
  }

  render() {
    this._calcVertices();

    const {$svg, center} = this;
    $svg.clear();

    let path2d = new SvgPath2D();
    path2d.arcByCenter(center.x, center.y, this._axisRadius, this._axisRadius, this._startAngle, this._sweepAngle);

    let $axis = $svg.path(path2d.toString());
    $axis.fill('none').stroke({
      color: this._axisColor,
      width: GaugeProps.axisWidth
    });

    if (this.ticks.length > 0) {
      this._renderTicks();
    }

    let $sectors = $svg.group();
    $sectors.fill('none').stroke({
      width: this._sectorWidth
    })

    let $path = null;

    path2d.clear();
    path2d.arcByCenter(center.x, center.y, this._radius, this._radius, this._startAngle, this._sweepAngle);
    $path = $sectors.path(path2d.toString());
    $path.stroke(this.sectorWidth);

    for (let sector of this.sectors) {
      path2d.clear();
      path2d.arcByCenter(center.x, center.y, this._radius, this._radius, sector.startAngle, sector.sweepAngle);
      $path = $sectors.path(path2d.toString());
      $path.stroke(sector.color);
    }

    this._renderArrow($svg);
  }

  _renderTicks() {
    const {$svg, font} = this;

    const $ticks = $svg.group();
    $ticks.stroke({ color: this._axisColor, width: GaugeProps.tickWidth });
    
    const $subTicks = $svg.group();
    $subTicks.stroke({ color: this._axisColor, width: GaugeProps.subTickWidth });

    const $labels = $svg.group();
    $labels.font({
      family: font.name,
      size: Metrics.ptToPx(font.size),
      weight: font.bold? 'bold': 'normal'
    });
    $labels.fill(font.color);

    for (let tick of this.ticks) {
      const { startAt, endAt } = tick;

      if (tick.isMain) {
        $ticks.line(startAt.x, startAt.y, endAt.x, endAt.y);
        const {textAt} = tick;

        // Создаем svg элемент напрямую. Вертикальное выравнивание не работает из-за того,
        // что svg.js оборачивает текст в tspan
        let text = document.createElementNS(svgNS, 'text');
        text.setAttribute('x', Math.round(textAt.x));
        text.setAttribute('y', Math.round(textAt.y));
        text.setAttribute('text-anchor', tick.anchor);
        text.setAttribute('alignment-baseline', tick.alignmentBaseline);
        text.textContent = tick.text;
        $labels.node.append(text);
      } else {
        $subTicks.line(startAt.x, startAt.y, endAt.x, endAt.y);
      }
    }
  }

  _renderArrow($svg) {
    const { center } = this;
    let arrowW2 = GaugeProps.arrowWidth / 2;

    let arrowHeight = this._radius  + this._sectorWidth / 2 + this._centerRadius + 3;
    let left = center.x - this._centerRadius - 3;
    let top = center.y - arrowW2;

    this.$arrow = $svg.path(`M${left},${top} l${arrowHeight},${arrowW2} l${-arrowHeight},${arrowW2}z`);    

    const $center = $svg.circle(this._centerRadius * 2);
    $center.move(center.x - this._centerRadius, center.y - this._centerRadius);
    $center.fill('#000000');

    this._updateArrowPresentation();
  }

  _updateArrowPresentation() {
    const { $arrow } = this;

    if ($arrow) {
      const { center } = this;
      const arrowAngle = this._valueToAngle(this._value);
      $arrow.attr('transform', `rotate(${arrowAngle} ${center.x} ${center.y})`);
      $arrow.fill(this.arrowFill);
      $arrow.stroke(this.arrowStroke);
    }
  }

  _calcVertices() {
    const w2 = this._width * 0.5;
    const h2 = this._height * 0.5;
    let valueRange = Math.abs(this._maxValue - this._minValue);
    let anglePerValueInDegrees = this.rangeAngle / valueRange;

    let reminder = (Math.abs(this._maxValue) + Math.abs(this._minValue)) / 10 % 1;
    this._valueFormatter = reminder === 0? Math.round: this._formatFloatValue;

    this.tickCountInUse = this.tickCount * 2;
    this.tickAngleStep = this.rangeAngle / this.tickCountInUse;
    
    this._anglePerValue = anglePerValueInDegrees;
    this._centerRadius = 2 + Math.round(this.width * 0.008);
    
    let a = this.rangeAngle / 2;
    let startAngleInGradus = 270 - a;
    let endAngleInGradus = 270 + a;
    
    this._startAngle = startAngleInGradus;
    this._endAngle = endAngleInGradus;
    this._sweepAngle = this._endAngle - this._startAngle;
    
    let sideLength = this.rangeAngle > 180? this.height: this.width;
    
    let tickMaxHeight = Math.max(GaugeProps.subTickHeight, GaugeProps.tickHeight);
    this._sectorWidth = Math.round(this._width * 0.08);

    const fontDecl = this.font.toCSSValue();
    const labelTexts = this._createTextLabels();
    const labelWidths = labelTexts.map(text => Metrics.computeTextWidth(text, fontDecl));
    const maxLabelWidth = Math.max.apply(null, labelWidths);
   
    this._radius = sideLength / 2 - GaugeProps.axisWidth * 4  - maxLabelWidth - tickMaxHeight - 5; 
    this._axisRadius = this._radius + this._sectorWidth / 2 + GaugeProps.axisWidth / 2;
    
    let bottomOffset = (this.rangeAngle > 180) ? 0: 
         this.height / 2 - GaugeProps.arrowWidth / 2 - SLGauge.FORCE_BOTTOM_OFFSET;

    this.center = { x: w2, y: h2 + bottomOffset };

    this._calcTicks();

    for (let sector of this.sectors) {
      sector.startAngle = this._valueToAngle(sector.startValue);
      sector.endAngle = this._valueToAngle(sector.endValue);
      sector.sweepAngle = sector.endAngle - sector.startAngle;
    }
  }

  _valueToAngle(value) {
    let rangedValue = Math.max(Math.min(this._maxValue, value), this._minValue);
    let axisOffsetValue = rangedValue - this._minValue;
    
    return this._startAngle + axisOffsetValue * this._anglePerValue;
  }

  _calcTicks() {
    this.ticks = [];

    let center = this.center;
    let renderTickCount = this.tickCountInUse + 1;
    let angle = this._startAngle;
    let tickMaxHeight = Math.max(GaugeProps.tickHeight, GaugeProps.subTickHeight);
    let labelRadius = this._axisRadius + GaugeProps.axisWidth + tickMaxHeight;

    for (let i = 0; i < renderTickCount; i++) {
      let isMain = i % 2 === 0;
      let tickHeight = isMain? GaugeProps.tickHeight: GaugeProps.subTickHeight;
      let tickEnd = this._axisRadius + GaugeProps.axisWidth / 2 + tickHeight;

      let startAt = polarToCartesian(center.x, center.y, this._axisRadius, this._axisRadius, angle);
      let endAt = polarToCartesian(center.x, center.y, tickEnd, tickEnd, angle);

      if (isMain) {
        let absoluteValue = this._angleToValue(angle);
        let text = this._valueFormatter(absoluteValue);

        let textAt = polarToCartesian(center.x, center.y, labelRadius, labelRadius, angle);
        let anchor = SLGaugeMethods.mapAngleToAnchor(angle);
        let alignmentBaseline = SLGaugeMethods.mapAngleToBaseline(angle);

        this.ticks.push({
          angle, anchor, alignmentBaseline, startAt, endAt, isMain, textAt, text
        });
      } else {
        this.ticks.push({
          angle, startAt, endAt, isMain
        });
      }

      angle += this.tickAngleStep;
    }
  }

  _createTextLabels() {
    let labels = [];
    let angle = this._startAngle;
    let renderTickCount = this.tickCountInUse + 1;

    for (let i = 0; i < renderTickCount; i += 2) {
      let absoluteValue = this._angleToValue(angle);
      let text = this._valueFormatter(absoluteValue);
      labels.push(text);

      angle += this.tickAngleStep * 2;
    }

    return labels;
  }

  _angleToValue(angle) {
    let axisOffsetValue = (angle - this._startAngle) / this._anglePerValue;
    let rangedValue = axisOffsetValue + this._minValue;
    
    return rangedValue;
  }

  _formatFloatValue(value) {
    return formatNumber(value, this.fractionDigits);
  }
}

SLGauge.FORCE_BOTTOM_OFFSET = 5;

class SLGaugeMethods {
  static mapAngleToAnchor(angle) {
    let alignSensivity = 15;
    let isAlmostTopLabel = angle > 270 - alignSensivity && angle < 270 + alignSensivity;
    let isAlmostBottomLabel = angle > 90 - alignSensivity && angle < 90 + alignSensivity;
  
    if (isAlmostTopLabel || isAlmostBottomLabel) {
      return 'middle';
    }
  
    return angle > 90 && angle < 270 ? 'end': 'start';
  }

  static mapAngleToBaseline(angle) {
    return angle > 0 && angle < 180? 'hanging': 'baseline';
  }
}

const SLLinearGaugeProps = Object.freeze({
  labelTickOffset: 4
});

export class SLLinearGauge extends GVLControl {

  constructor() {
    super();

    this._arrowFill = 'lightgreen';
    this._arrowStroke = 'rgba(0, 0, 0, .75)';
    this._arrowMainSize = 14; // width in horizontal direction
    this._arrowCrossSize = 20; // height in horizontal direction
    this._axisColor = '#000000';
    this._axisWidth = 2;

    this._bounds = new RectF();
    this._direction = 'horizontal';
    this.font = new SLFont();
    this.font.onChange = () => this.requestUpdate();
    this._isHorizontal = false;
    this._keepDomRootByClient = true;
    this._minValue = 0;
    this._maxValue = 100;

    this._sectorColor = 'green';
    this._sectorHeight = 17;
    this._sectorVisible = false;
    this._subtickWidth = this._axisWidth / 2;
    this._subtickHeight = this._sectorHeight / 2;
    this._tickCount = 5;
    this._tickWidth = this._axisWidth;
    this._tickHeight = 23;
    this._value = 0;
    this._valueFormatter = Math.round;

    this.sectors = [];
    this.ticks = [];

    this._formatFloatValue = this._formatFloatValue.bind(this);
  }

  dispose() {
    this.font.dispose();
    super.dispose();
  }

  get arrowFill() { return this._arrowFill }
  set arrowFill(value) { 
    if (this._arrowFill !== value) {
      this._arrowFill = value;
      this._updateArrowPresentation();
    }
  }

  get axisColor() { return this._axisColor }
  set axisColor(value) { this._setProp('axisColor', value) }

  get direction() { return this._direction }
  set direction(value) { 
    this._setProp('direction', value);
    this._isHorizontal = this._direction === 'horizontal';
  }
 
  get tickCount() { return this._tickCount }
  set tickCount(value) { this._setProp('tickCount', value) }

  get minValue() { return this._minValue }
  set minValue(value) { this._setProp('minValue', value) }

  get maxValue() { return this._maxValue }
  set maxValue(value) { this._setProp('maxValue', value) }

  get sectorColor() { return this._sectorColor }
  set sectorColor(value) { this._setProp('sectorColor', value) }

  get sectorVisible() { return this._sectorVisible }
  set sectorVisible(value) { this._setProp('sectorVisible', value) }

  get value() { return this._value }
  set value(value) { 
    if (this._value !== value) {
      this._value = +value;
      this._updateArrowPresentation();
    }
  }

  addSector(startValue, endValue, color) {
    let sector = new SLSector(startValue, endValue, color);
    this.requestUpdate();
    this.sectors.push(sector);
    return sector;
  }

  initDomRoot() {
    this.$svg = SVG();
    const { node } = this.$svg;
    this._domRoot = node;
    node.classList.add('gvl-control');
  }

  render() {
    this.$svg.clear();

    this._calcVertices();
    
    if (this._isHorizontal) {
      this._renderHorz();
    } else {
      this._renderVert();
    }
  }

  _renderVert() {
    // Стоит учесть, что шкала начинается снизу и растет вверх
    const { _width: w, _height: h, $svg, font } = this;
    const padding = 4;
    const horzPadding = padding;
    const vertPadding = padding + this._maxLabelHeight / 2;

    const b = this._bounds;
    b.setBounds(0, 0, w, h);
    b.inflate(-horzPadding, -vertPadding);

    this._axisSize = b.height;
    
    const oppositeSide = this._tickHeight + this._axisWidth / 2 + SLLinearGaugeProps.labelTickOffset + this._maxLabelWidth;

    const cx = w / 2;
    const left = Math.round(cx - oppositeSide / 2);

    const axisWidthBy2 = this._axisWidth / 2;
    const axisX = left + axisWidthBy2;
    const axisY = b.top + axisWidthBy2;

    if (this._sectorVisible) {
      const $sectors = $svg.group();

      let $sector = $sectors.rect(this._sectorHeight, b.height);
      $sector.move(axisX, axisY);
      $sector.fill(this._sectorColor);

      for (let sector of this.sectors) {
        let startY = (1 - sector.endPosition) * b.height;
        let endY = (1 - sector.startPosition) * b.height;
        let sectorHeight = Math.abs(endY - startY);

        $sector = $sectors.rect(this._sectorHeight, sectorHeight);
        $sector.move(axisX, axisY + startY);
        $sector.fill(sector.color);
      }
    }

    const $ticks = $svg.group();
    $ticks.stroke({ color: this._axisColor, width: this._axisWidth });

    $ticks.line(axisX, b.top, axisX, b.bottom);

    const $subticks = $svg.group();
    $subticks.stroke({ color: this._axisColor, width: this._subtickWidth });

    const $labels = $svg.group();
    $labels.font({
      family: font.name,
      size: Metrics.ptToPx(font.size),
      weight: font.bold? 'bold': 'normal'
    });
    $labels.fill(font.color);

    const tickStep = b.height / (this._tickCount * 2);

    let tickPosition = b.bottom;

    for (let tick of this.ticks) {
      let y = Math.round(tickPosition);
      let startX = left;

      if (tick.major) {
        let endX = startX + this._tickHeight;
        $ticks.line(startX, y, endX, y);
        
        let textX = endX + SLLinearGaugeProps.labelTickOffset;
        let textY = y;

        let text = document.createElementNS(svgNS, 'text');
        text.setAttribute('x', Math.round(textX));
        text.setAttribute('y', Math.round(textY));
        text.setAttribute('text-anchor', 'start');
        text.setAttribute('alignment-baseline', 'middle');
        text.textContent = tick.text;
        $labels.node.append(text);
      } else {
        y += 0.5;
        let endX = startX + this._subtickHeight;
        $subticks.line(startX, y, endX, y);
      }

      tickPosition -= tickStep;
    }

    let arrowMainSizeBy2 = this._arrowMainSize / 2;
    let arrowCrossSizeBy2 = this._arrowCrossSize / 2;
    let arrowX = axisX - arrowCrossSizeBy2;
    let arrowY = axisY - arrowMainSizeBy2 + b.height - axisWidthBy2;

    this.$arrow = $svg.path(`M${arrowX},${arrowY} l${this._arrowCrossSize},${arrowMainSizeBy2} l${-this._arrowCrossSize},${arrowMainSizeBy2}z`);
    this.$arrow.stroke(this._arrowStroke);

    this._updateArrowPresentation();
  }

  _renderHorz() {
    const { _width: w, _height: h, $svg, font } = this;
    const padding = 4;
    const horzPadding = padding + this._maxLabelWidth / 2;
    const vertPadding = padding;

    const b = this._bounds;
    b.setBounds(0, 0, w, h);
    b.inflate(-horzPadding, -vertPadding);

    this._axisSize = b.width;
    
    const oppositeSide = this._tickHeight + this._axisWidth / 2 + SLLinearGaugeProps.labelTickOffset + this._maxLabelHeight;

    const cy = this._height / 2;
    const top = Math.round(cy - oppositeSide / 2);

    const axisWidthBy2 = this._axisWidth / 2;
    const axisX = b.left + axisWidthBy2;
    const axisY = top + axisWidthBy2;

    if (this._sectorVisible) {
      const $sectors = $svg.group();

      let $sector = $sectors.rect(b.width, this._sectorHeight);
      $sector.move(axisX, top);
      $sector.fill(this._sectorColor);

      for (let sector of this.sectors) {
        let startX = sector.startPosition * b.width;
        let endX = sector.endPosition * b.width;
        let sectorWidth = Math.abs(endX - startX);

        $sector = $sectors.rect(sectorWidth, this._sectorHeight);
        $sector.move(axisX + startX, top);
        $sector.fill(sector.color);
      }
    }

    const $ticks = $svg.group();
    $ticks.stroke({ color: this._axisColor, width: this._axisWidth });

    $ticks.line(b.left, axisY, b.right, axisY);

    const $subticks = $svg.group();
    $subticks.stroke({ color: this._axisColor, width: this._subtickWidth });

    const $labels = $svg.group();
    $labels.font({
      family: font.name,
      size: Metrics.ptToPx(font.size),
      weight: font.bold? 'bold': 'normal'
    });
    $labels.fill(font.color);

    const tickStep = b.width / (this._tickCount * 2);

    let tickPosition = b.left + axisWidthBy2;

    for (let tick of this.ticks) {
      let x = Math.round(tickPosition);
      let startY = top;

      if (tick.major) {
        let endY = startY + this._tickHeight;
        $ticks.line(x, startY, x, endY);
        
        let textX = x;
        let textY = endY + SLLinearGaugeProps.labelTickOffset;

        let text = document.createElementNS(svgNS, 'text');
        text.setAttribute('x', Math.round(textX));
        text.setAttribute('y', Math.round(textY));
        text.setAttribute('text-anchor', 'middle');
        text.setAttribute('alignment-baseline', 'hanging');
        text.textContent = tick.text;
        $labels.node.append(text);
      } else {
        x += 0.5;
        let endY = startY + this._subtickHeight;
        $subticks.line(x, startY, x, endY);
      }

      tickPosition += tickStep;
    }

    let arrowWidthBy2 = this._arrowMainSize / 2;
    let arrowCrossSizeBy2 = this._arrowCrossSize / 2;
    let arrowX = axisX - arrowWidthBy2;
    let arrowY = axisY - arrowCrossSizeBy2;

    this.$arrow = $svg.path(`M${arrowX},${arrowY} l${arrowWidthBy2},${this._arrowCrossSize} l${arrowWidthBy2},${-this._arrowCrossSize}z`);
    this.$arrow.stroke(this._arrowStroke);
    this.$arrow.fill(this._arrowFill);

    this._updateArrowPresentation();
  }

  _calcVertices() {
    for (let sector of this.sectors) {
      sector.startPosition = this._valueToPosition(sector.startValue);
      sector.endPosition = this._valueToPosition(sector.endValue);
    }

    let reminder = (Math.abs(this._maxValue) + Math.abs(this._minValue)) / 10 % 1;
    this._valueFormatter = reminder === 0? Math.round: this._formatFloatValue;

    this.ticks = [];

    const allTickCount = this._tickCount * 2;
    const tickCountInUse = allTickCount + 1;
    for (let i = 0; i < tickCountInUse; i++) {

      const position = i / allTickCount;
      const value = this._positionToValue(position);

      const major = i % 2 === 0;
      const text = major? this._valueFormatter(value): '';
      const tick = { major, text };

      this.ticks.push(tick);
    }

    const fontDecl = this.font.toCSSValue();
    const labelWidths = this.ticks.map(tick => Metrics.computeTextWidth(tick.text, fontDecl));
    this._maxLabelWidth = Math.max.apply(null, labelWidths);

    this._maxLabelHeight = this.font.size;
  }

  _updateArrowPresentation() {
    if (this.$arrow) {
      this.$arrow.fill(this._arrowFill);

      let arrowPosition = this._valueToPosition(this._value);
      let offset = arrowPosition * this._axisSize;
  
      if (this._isHorizontal) {
        this.$arrow.attr('transform', `translate(${offset} 0)`);
      } else {
        this.$arrow.attr('transform', `translate(0 ${-offset})`);
      }
    }
  }

  _valueToPosition(value) {
    let rangedValue = Math.max(Math.min(this.maxValue, value), this.minValue);
    let axisOffsetValue = rangedValue - this.minValue;
    let rangeLength = Math.abs(this.maxValue - this.minValue);
    
    return !floatEquals(rangeLength, 0)? axisOffsetValue / rangeLength: 0;
  }

  _positionToValue(position) {
    let rangeLength = Math.abs(this.maxValue - this.minValue);
    return this.minValue + rangeLength * position;
  }

  _formatFloatValue(value) {
    return formatNumber(value, 1);
  }
}

export class SLSegmentGauge extends GVLControl {

  constructor() {
    super();

    this._bounds = new RectF();
    this._direction = 'horizontal';
    this._isHorizontal = false;
    this._keepDomRootByClient = true;
    this._minValue = 0;
    this._maxValue = 100;
    this._value = 0;

    this.sectors = [];
    this.segments = [];
  }

  dispose() {
    super.dispose();
  }

  get direction() { return this._direction }
  set direction(value) { 
    this._setProp('direction', value);
    this._isHorizontal = this._direction === 'horizontal';
  }

  get minValue() { return this._minValue }
  set minValue(value) { this._setProp('minValue', value) }

  get maxValue() { return this._maxValue }
  set maxValue(value) { this._setProp('maxValue', value) }

  get value() { return this._value }
  set value(value) { 
    if (this._value !== value) {
      this._value = +value;
      this._updateValuePresentation();
    }
  }

  addSector(startValue, endValue, color) {
    let sector = new SLSector(startValue, endValue, color);
    this.requestUpdate();
    this.sectors.push(sector);
    return sector;
  }

  initDomRoot() {
    this.$svg = SVG();
    const { node } = this.$svg;
    this._domRoot = node;
    node.classList.add('gvl-control');
  }

  render() {
    this.$svg.clear();

    if (this._isHorizontal) {
      this._renderHorz();
    } else {
      this._renderVert();
    }
  }

  _renderHorz() {
    const { $svg } = this;
    const { segmentSize, segmentMainSpace } = SLSegmentGauge;

    const segments = [];
    this.segments = segments;

    for (let x = 0; x < this._width; x += segmentMainSpace) {
      let segmentRight = x + SLSegmentGauge.segmentSize;

      if (segmentRight < this._width) {
        const $segment = $svg.rect(segmentSize, this._height);
        $segment.move(x, 0);

        const value = this._positionToValue(this._width, x);

        segments.push({
          $segment,
          value
        });
      }
    }

    this._updateValuePresentation();
  }

  _renderVert() {
    const { $svg } = this;
    const { segmentSize, segmentMainSpace } = SLSegmentGauge;

    const segments = [];
    this.segments = segments;

    for (let y = this._height - SLSegmentGauge.segmentSize; y > 0; y -= segmentMainSpace) {
      let segmentBottom = y + SLSegmentGauge.segmentSize;

      if (y > 0) {
        const $segment = $svg.rect(this._width, segmentSize);
        $segment.move(0, y);

        const value = this._positionToValue(this._height, this._height - segmentBottom);

        segments.push({
          $segment,
          value
        });
      }
    }

    this._updateValuePresentation();
  }

  _updateValuePresentation() {
    const mainAxisSize = this._isHorizontal? this._width: this._height;
    let pos = this._valueToPosition(mainAxisSize, this._value);
    let i = 0;

    for (let segment of this.segments) {
      const { $segment, value } = segment;
      const { node } = $segment;

      if (i <= pos && pos > 1) {
        node.setAttribute('visibility', 'visible');

        let fill = this._getSegmentFill(value);
        $segment.fill(fill);
      } else {
        node.setAttribute('visibility', 'hidden');
      }

      i += SLSegmentGauge.segmentMainSpace;
    }
  }

  _getSegmentFill(value) {
    if (this.segmentFillSource) {
      return this.segmentFillSource(value);
    }

    return 'green';
  }

  _valueToPosition(mainAxisSize, value) {
    if (value > this._maxValue) {
      value = this._maxValue;
    }

    if (value < this._minValue) {
      value = this._minValue;
    }

    const scaleLength = this._maxValue - this._minValue;
    const scaleStartValue = value - this._minValue;
    const a = mainAxisSize / scaleLength;
    return Math.round(a * scaleStartValue);
  }

  _positionToValue(mainAxisSize, position) {
    const a = position / mainAxisSize;
    const scaleLength = this._maxValue - this._minValue;
    return this._minValue + a * scaleLength;
  }
}

SLSegmentGauge.segmentSize = 8;
SLSegmentGauge.segmentMainSpace = 10;

export class SLInput extends GVLControl {
  constructor() {
    super();

    this.font = new SLFont();
    this.font.onChange = () => this.requestUpdate();
    this._keepDomRootByClient = true;
    this.min = 0;
    this.max = 100;
  }

  dispose() {
    const { $input } = this;
    $input.removeEventListener('input', this._inputChanged);
    $input.removeEventListener('focus', this._inputFocus);
    $input.removeEventListener('focusout', this._inputFocusout);
    $input.removeEventListener('keypress', this._inputKeypress);

    this.font.dispose();
    super.dispose();
  }

  get isActive() {
    return document.activeElement === this._domRoot;
  }

  get value() {
    return this._domRoot.value;
  }

  set value(value) {
    this._domRoot.value = value;
    this.requestUpdate();
  }

  blur() {
    this._domRoot.blur();
  }

  initDomRoot() {
    this._inputChanged = this._inputChanged.bind(this);
    this._inputKeypress = this._inputKeypress.bind(this);
    this._inputFocus = this._inputFocus.bind(this);
    this._inputFocusout = this._inputFocusout.bind(this);

    let $input = this.defineRoot('input');
    $input.type = 'number';
    $input.classList.add('sl-input');
    $input.value = 0;
    $input.addEventListener('input', this._inputChanged);
    $input.addEventListener('focus', this._inputFocus);
    $input.addEventListener('focusout', this._inputFocusout);
    $input.addEventListener('keypress', this._inputKeypress);
    this.$input = $input;
  }

  render() {
    this.font.setToDOMStyle(this.$input.style);

    const value = this.value;
    const valueInRange = value > this.min && value < this.max; 

    this.$input.style.color = valueInRange? null: 'red'
  }

  _inputChanged() {
    this.fireEvent('input');
    this.requestUpdate();
  }

  _inputFocus() {
    this.fireEvent('focus');
  }

  _inputFocusout() {
    this.fireEvent('focusout');
  }

  _inputKeypress(e) {
    let code = e.which? e.which: e.keyCode;
    this.fireEvent('keypress', code);
  }
}

export class ButtonState extends ValueObject {
  constructor() {
    super();
    this._backgroundColor = 'white';
  }

  get backgroundColor() {
    return this._backgroundColor;
  }

  set backgroundColor(value) {
    this._setProp('backgroundColor', value);
  }
}

export class ComboBox extends GVLControl {
  constructor() {
    super();

    this._keepDomRootByClient = true;
    this._backgroundColor = '#FFFFFF';
    this._buttonColor = '#6495ED';
    this._chevronColor = '#F2F2F2';
    this._hoverButtonColor = '#737373';
    this._hoverChevronColor = '#F2F2F2';
    this._pressedButtonColor = '#6E6E6E';
    this._pressedChevronColor = '#F2F2F2';
    this._itemIndex = -1;
    this._items = [];
    this._strokeColor = '#000000';
    this._strokeRadius = 0;
    this._strokeWidth = 1;

    this.font = new SLFont();
  }

  dispose() {
    const { font, $select } = this;
    $select.removeEventListener('change', this._selectChange);
    font.dispose();

    super.dispose();
  }

  get backgroundColor() { return this._backgroundColor }
  set backgroundColor(value) { this._setProp('backgroundColor', value) }

  get buttonColor() { return this._buttonColor }
  set buttonColor(value) { this._setProp('buttonColor', value) }

  get chevronColor() { return this._chevronColor }
  set chevronColor(value) { this._setProp('chevronColor', value) }

  get hoverButtonColor() { return this._hoverButtonColor }
  set hoverButtonColor(value) { this._setProp('hoverButtonColor', value) }

  get hoverChevronColor() { return this._hoverChevronColor }
  set hoverChevronColor(value) { this._setProp('hoverChevronColor', value) }

  get itemIndex() { return this._itemIndex }
  set itemIndex(value) {
    if (value >= -1 && value <= this._items.length - 1) {
      if (this._itemIndex !== value) {
        this._itemIndex = value;

        if (this.$select) {
          this.$select.selectedIndex = this._itemIndex;
        }
      }
    }
  }

  get items() { return this._items }
  set items(value) {
    if (typeof value === 'object' && Array.isArray(value)) {
      this._items = Array.from(value);
      this._updateSelectContent();
    }
  }

  get pressedButtonColor() { return this._pressedButtonColor }
  set pressedButtonColor(value) { this._setProp('pressedButtonColor', value) }

  get pressedChevronColor() { return this._pressedChevronColor }
  set pressedChevronColor(value) { this._setProp('pressedChevronColor', value) }

  get strokeColor() { return this._strokeColor }
  set strokeColor(value) { this._setProp('strokeColor', value) }

  get strokeRadius() { return this._strokeRadius }
  set strokeRadius(value) { this._setProp('strokeRadius', value) }

  get strokeWidth() { return this._strokeWidth }
  set strokeWidth(value) { this._setProp('strokeWidth', value) }

  initDomRoot() {
    let $root = this.$root = this.defineRoot('div');
    this.$shadow = $root.attachShadow({ mode: 'closed' });

    this.$style = document.createElement('style');
    this.$select = document.createElement('select');

    this._selectChange = this._selectChange.bind(this);
    this.$select.addEventListener('change', this._selectChange);

    this.$shadow.append(this.$style, this.$select);
  }

  render() {
    const { style } = this.$select;
    style.width = `${this._width}px`;
    style.height = `${this._height}px`;

    this._updateStyleContent();
  }

  _updateStyleContent() {
    const w = 30;
    const h = this._height;
    const r = this._strokeRadius;
    const paddingLeft = 6 + this._strokeWidth;
    const chevronHeight = 5;
    const chevronY = h / 2 - chevronHeight / 2;

    const fontDecl = this.font.toCSSPropDeclaration();

    const svg = `
    <svg height='${h}' viewBox='0 0 ${w} ${h}' width='${w}' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0' y='0' width='${w}' height='${h}' fill='${this._buttonColor}' rx='${r}' ry='${r}' ></rect>
      <path d='M10 ${chevronY}l5 5 5-5z' fill='${this._chevronColor}'/>
    </svg>`;

    const hoverSvg = `
    <svg height='${h}' viewBox='0 0 ${w} ${h}' width='${w}' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0' y='0' width='${w}' height='${h}' fill='${this._hoverButtonColor}' rx='${r}' ry='${r}' ></rect>
      <path d='M10 ${chevronY}l5 5 5-5z' fill='${this._hoverChevronColor}'/>
    </svg>`;

    const pressSvg = `
    <svg height='${h}' viewBox='0 0 ${w} ${h}' width='${w}' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0' y='0' width='${w}' height='${h}' fill='${this._pressedButtonColor}' rx='${r}' ry='${r}' ></rect>
      <path d='M10 ${chevronY}l5 5 5-5z' fill='${this._pressedChevronColor}'/>
    </svg>`;

    this.$style.textContent = `
    select {
      border: 1px solid ${this._strokeColor};
      border-radius: ${this._strokeRadius}px;
      padding: 2px 30px 2px ${paddingLeft}px;
      height: ${h}px;
      box-sizing: border-box;
      text-overflow: ellipsis;
      ${fontDecl}

      outline: none;
      appearance: none;
      background: ${this._backgroundColor};
      background-image: url("data:image/svg+xml;utf8,${encodeURIComponent(svg)}");
      background-repeat: no-repeat;
      background-position-x: 100%;
      background-position-y: 50%;
    }

    select:hover {
      background-image: url("data:image/svg+xml;utf8,${encodeURIComponent(hoverSvg)}");
    }

    select:active {
      background-image: url("data:image/svg+xml;utf8,${encodeURIComponent(pressSvg)}");
    }
    `;
  }

  _updateSelectContent() {
    const $select = this.$select;

    $select.textContent = '';

    let fragment = document.createDocumentFragment();

    for (let i = 0; i < this._items.length; i++) {
      let item = this._items[i];

      let $option = document.createElement('option');
      $option.textContent = item;
      $option.value = i;
      fragment.append($option);
    }

    $select.append(fragment);
    this._itemIndex = $select.selectedIndex;
  }

  _selectChange() {
    // Событие вызывается если изменение вызвано пользователем
    this._itemIndex = this.$select.selectedIndex;

    this.fireEvent('change');
  }
}