import { floatEquals } from 'std/math'

export class PointF {
  constructor(x = 0, y = 0) {
    this.x = x
    this.y = y
  }

  addXY(x, y) {
    this.x += x
    this.y += y
  }

  angle(point) {
    return Math.atan2(this.y - point.y, this.x - point.x)
  }

  distance(other) {
    return Math.sqrt(Math.pow(this.x - other.x, 2) + Math.pow(this.y - other.y, 2))
  }

  divide(value) {
    this.x /= value
    this.y /= value
  }

  from(point) {
    this.x = point.x
    this.y = point.y
  }

  equalsTo(point, epsilon = 0.0001) {
    return floatEquals(this.x, point.x, epsilon)
        && floatEquals(this.y, point.y, epsilon)
  }

  toString() {
    return `${this.x}, ${this.y}`
  }

  setXY(x, y) {
    this.x = x
    this.y = y
  }

  truncate() {
    this.x = Math.trunc(this.x)
    this.y = Math.trunc(this.y)
  }
}

export class RectF {
  constructor() {
    this.left = 0
    this.top = 0
    this.width = 0
    this.height = 0
  }

  inflate(dx, dy) {
    this.left-=dx
    this.top-=dy
    this.width += dx * 2
    this.height += dy * 2
  }

  offsetXY(dx, dy) {
    this.left += dx
    this.top += dy
  }

  setBounds(left, top, width, height) {
    this.left = left
    this.top = top
    this.width = width
    this.height = height
  }

  fitInto(target, horzAlign, vertAlign) {
    let result = new RectF()

    let targetW = target.width
    let targetH = target.height
    const sourceW = this.width
    const sourceH = this.height

    if (sourceW < targetW && sourceH < targetH) {
      result.width = sourceW
      result.height = sourceH
    } else if (GVLMath.sameValue(sourceW, 0) && GVLMath.sameValue(sourceH, 0)) {
      return result
    } else {
      if (sourceW > sourceH) {
        if (sourceW < targetW) {
          targetW = sourceW
        }

        result.width = targetW
        result.height = targetW * sourceH / sourceW
        
        if (result.height > targetH) {
          result.height = targetH
          result.width = targetH * sourceW / sourceH
        }
      } else if (sourceW < sourceH) {
        if (sourceH < targetH) {
          targetH = sourceH
        }

        result.height = targetH
        result.width = targetH * sourceW / sourceH

        if (result.width > targetW) {
          result.width = targetW
          result.height = targetW * sourceH / sourceW
        }
      } else {
        result.width = targetW < targetH? targetW: targetH
        result.height = result.width
      }
    }

    let dx = 0
    switch (horzAlign) {
      case 'center':
        dx = (target.width - result.width) / 2 + target.left
        break

      case 'right':
        dx = target.right - result.width
        break
    }

    let dy = 0
    switch (vertAlign) {
      case 'center':
        dy = (target.height - result.height) / 2 + target.top
        break

      case 'bottom':
        dy = target.bottom - result.height
        break
    }

    result.left += dx
    result.top += dy
    return result
  }

  get bottom() {
    return this.top + this.height
  }

  get centerX() {
    return this.left + this.width / 2
  }

  get centerY() {
    return this.top + this.height / 2
  }

  get right() {
    return this.left + this.width
  }
}

export class SvgPath2D {
  constructor() {
    this._parts = []
  }

  clear() {
    this._parts = []
  }

  close() {
    this._parts.push({
      command: 'z'
    })
  }

  arc(rect, startAngle, sweepAngle) {
    let centerX = rect.centerX
    let centerY = rect.centerY
    let radiusX = rect.width / 2
    let radiusY = rect.height / 2

    this.arcByCenter(centerX, centerY, radiusX, radiusY, startAngle, sweepAngle)
  }

  arcByCenter(centerX, centerY, radiusX, radiusY, startAngle, sweepAngle) {
    let endAngle = startAngle + sweepAngle
    let startPoint = polarToCartesian(centerX, centerY, radiusX, radiusY, startAngle)
    let endPoint = polarToCartesian(centerX, centerY, radiusX, radiusY, endAngle)

    let largeArcFlag = Math.abs(sweepAngle) <= 180 ? '0' : '1'
    let sweepFlag = sweepAngle > 0? '1': '0'

    this._parts.push({
      command: 'M',
      x: startPoint.x,
      y: startPoint.y
    })

    this._parts.push({
      command: 'A',
      radiusX, radiusY,
      largeArcFlag, sweepFlag,
      x: endPoint.x,
      y: endPoint.y
    })
  }

  move(x, y) {
    this._parts.push({
      command: 'M',
      x, y
    })
  }

  line(x, y) {
    this._parts.push({
      command: 'L',
      x, y
    })
  }

  toString() {
    let d = ''

    for (let part of this._parts) {
      switch (part.command) {
        case 'A':
          d += `A ${part.radiusX} ${part.radiusY} 0 ${part.largeArcFlag} ${part.sweepFlag} ${part.x} ${part.y}`
          break
        case 'z':
        case 'Z':
          d += 'z'
          break
        default:
          d += `${part.command} ${part.x} ${part.y}`
      }
    }

    return d
  }
}

export function polarToCartesian(centerX, centerY, radiusX, radiusY, angleInDegrees) {
  var angleInRadians = angleInDegrees * Math.PI / 180.0

  return {
    x: centerX + (radiusX * Math.cos(angleInRadians)),
    y: centerY + (radiusY * Math.sin(angleInRadians))
  }
}

export class Radians {
  static toDegrees(value) {
    return value * 180 / Math.PI
  }

  static fromDegrees(value) {
    return value * Math.PI / 180
  }
}

export class GVLMath {
  static roundTo(n, d) {
    d = d === undefined? d: 2
    d = Math.pow(10, d)
    var t = d
    var e = n * d
    var r = Math.round(e)
    return r / t
  }

  static inRange(value, min, max) {
    return value >= min && value <= max
  }

  static sameValue(a, b, epsilon) {
    return Math.abs(a - b) < epsilon
  }
}

export const Quality = Object.freeze({
  Bad: 0x0,
  Good: 0xC0
})

export const HorzAlign = Object.freeze({
  Left: {name: 'Left'},
  Center: {name: 'Center'},
  Right: {name: 'Right'},
  name: 'HorzAlign'
})

export const VertAlign = Object.freeze({
  Top: {name: 'Top'},
  Center: {name: 'Center'},
  Bottom: {name: 'Bottom'},
  name: 'VertAlign'
})

export const ViewportStretch = Object.freeze({
  None: {name: 'None'},
  Scale: {name: 'Scale'},
  Fill: {name: 'Fill'},
  name: 'ViewportStretch'
})

export class ValueObject {
  constructor() {
    this.onChange = null
  }

  dispose() {
    this.onChange = null
  }

  _doChange() {
    this.onChange && this.onChange(this)
  }

  _setProp(name, value) {
    const fieldName = `_${name}`
    if (this[fieldName] !== value) {
      this[fieldName] = value
      this.onChange && this.onChange(name)
    }
  }
}

export const svgNS = 'http://www.w3.org/2000/svg'