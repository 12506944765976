import i18n from '../i18n'
import { ArgumentType } from 'GVL/window'

export const isDev = process.env?.NODE_ENV === 'development'

export const NUMERICAL_TYPES = ['int', 'uint', 'float']
export const NIGH_NUMERICAL_TYPES = NUMERICAL_TYPES.concat(['boolean'])

export const Quality = Object.freeze({
  Bad: 0x0,
  Good: 0xC0
})

export const SaveStrategy = {
  ByTimer: 0,
  ByChange: 1
}

export const NoTagID = -1

const stringIsEmpty = str => str.length === 0 || !str.trim()

export const tr = (key, options) =>  i18n.t(key, options)
export const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms))

export function formatChannelValue(channel, value) {
  let formattedValue = value == null? "": value

  if (channel.type === 'boolean') {
    formattedValue = (value > 0) ? tr('tag-on'): tr('tag-off'); 
    
  } else if (channel.type === 'float') {
    let variableFormat = channel.hasOwnProperty('variableFormat')? Math.max(channel.variableFormat, 0): 2
    
    if (value === null || value === undefined || typeof value !== 'number') {
      value = 0
    }

    formattedValue = value.toLocaleString(tr('lang'), {
      minimumFractionDigits: variableFormat,
      maximumFractionDigits: variableFormat
    })
  }  

  return formattedValue.toString()
}

export function formatValueAsSummary(channel, value) {
  let formattedValue = formatChannelValue(channel, value)
  let { scale } = channel
  let units = scale?.units
  const hasUnits = units !== undefined && units !== null

  if (channel.type !== 'boolean' && channel.type !== 'string' && hasUnits && !stringIsEmpty(units.toString())) {
    formattedValue += ` ${units}`
  }

  return formattedValue
}

export const formatValue = (value, options) => {
  const { fractionDigits, type } = options
  
  let lValue = value != null
    ? `${value}`
    : ''

  switch (type) {
    case 'boolean':
      lValue = value > 0
        ? tr('tag-on')
        : tr('tag-off')
      break
    
    case 'float':
    case 'Number':
    case 'number':
      let digits = fractionDigits != null
        ? Math.max(options.fractionDigits, 0)
        : 2
      
      if (value === null || value === undefined || typeof value !== 'number') {
        value = 0
      }

      lValue = value.toLocaleString(tr('lang'), {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits
      })
      break
  } 

  return lValue.toString()
}

export const formatNumber = (value, fractionDigits) => {
  if (typeof value !== 'number') {
    return `${value}`
  }

  return value.toLocaleString(tr('lang'), {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  })
}

export function normalizeChannelValue(type, value) {
  if (NUMERICAL_TYPES.indexOf(type) >= 0) {
    return parseFloat(value)
  } else if (type === 'boolean') {
    return +value
  }

  return value
}

export const unpackTypedValue = (type, value) => {
  switch (type) {
    case ArgumentType.String:
    case 'string':
      return `${value}`

    case ArgumentType.Number:
    case 'float':
    case 'int':
    case 'uint':
    case 'number': {
      const n = `${value}`.replace(',', '.')
      return parseFloat(n)
    }

    case ArgumentType.Boolean:
    case 'boolean':
      return value === '1'? true: false

    default:
      return value   
  }
}

export function stringFirstToLowerCase(value) {
  if (value.length > 0) {
    return value[0].toLowerCase() + value.substring(1)
  }
  
  return ''
}