import { GVLControl } from "../../GVL/elements"
import { tr } from '../../utils/common'

export class MnemoControl extends GVLControl {}

export class DeprecatedStub extends MnemoControl {

  initDomRoot() {
    const $root = this.defineRoot('div')
    $root.classList.add('sl-deprecated')
    $root.textContent = tr('screen-component-deprecated')
  }
}