import axios from 'axios'
import { setupCache } from 'axios-cache-interceptor'
import client from './client'
 
const cacheAxios = setupCache(axios.create(), {
  ttl: 1000 * 10
})

export const signIn = (username, password) => {
  return axios.post('/api/login', { username, password }, {
    withCredentials: true
  })
}

export const getPermissions = () => {
  return axios.get('/api/users/me', { withCredentials: true })
}

export const signOut = () => {
  return axios.delete('/api/login', { }, {
    withCredentials: true
  })
}

export const refreshToken = () => {
  return axios.get("/auth/refresh_token", {
    withCredentials: true
  })
}

export const getMnemoList = () => {
  return axios.get('/api/mnemos', {
    withCredentials: true
  })
}

export const getMnemoContent = (qualifiedName) => {
  let p = qualifiedName
  p = p.replaceAll('.', '/')

  return cacheAxios.get(encodeURI(`/mnemos/${p}/index.smx`), {
    withCredentials: true
  })
}

export const getFigureContent = async (uri) => {
  const url = `/figures/${uri}/index.slf`
  const { data } = await cacheAxios.get(url)
  return data
}

export const getAudioURL = uri => {
  return '/audio/' + encodeURIComponent(uri)
}

export const getEndpoints = () => 
  axios.get('/api/endpoints', {
    withCredentials: true
  })

export const getReservation = () =>
  axios.get('/api/reservation', {
    withCredentials: true
  })

export const getServerInfo = async () => axios.get(`/api/server/info`)

export const isAvailable = async (url) => {
  try {
    const response = await axios.get(url + '/api/server/info')
    return Math.trunc(response.status / 100) === 2
  } catch (exception) {
    return false
  }
}

export const validateUser = async () => {
  try {
    const res = await axios.get('/auth/validate', {
      withCredentials: true
    })
    return res.data
  } catch (e) {
    return
  }
}

export const getProjectTreeNodes = () => {
  return axios.get("/api/project-tree")
}

export const writeChannelValueAsync = (channelID, value) => {
  const { connID } = client

  return axios.patch(`/api/channels/${channelID}`, { value }, {
    headers: {
      'X-ConnID': connID,
    }
  })
}

export const getAutorunMnemos = () => {
  return axios.get('/api/autorun-mnemos')
}