export function compareFloat(left, right, epsilon = 0.00001) {
  const dx = left - right
  let result
  
  if (dx < 0) {
    result = -1
  } else if (dx > 0) {
    result = 1
  } else {
    result = floatEquals(left, right, epsilon)? 0: 1
  }

  return result
}

const PiBy180 = Math.PI / 180

export function floatEquals(left, right, epsilon = 0.00001) {
  return Math.abs(left - right) < epsilon
}

export function degreesToRadians(degrees) {
  return degrees * PiBy180
}

export function roundTo(value, digits = 2) {
  let t = Math.pow(10, digits)
  let r = Math.round(value * t)
  return r / t
}

export function transformVectors(vectors, matrix) {
  let output = new Array(vectors.length)
  
  for (let i = 0; i < vectors.length; i++) {
    var v = vectors[i]
    output[i] = {
      x: matrix[0] * v.x + matrix[2] * v.y + matrix[4],
      y: matrix[1] * v.x + matrix[3] * v.y + matrix[5]
    }
  }

  return output
}

export function isInRange(start, end, value, epsilon) {
  return compareFloat(value, start, epsilon) >= 0 
      && compareFloat(value, end, epsilon) <= 0 
}

export class NumberRange {
  constructor() {
    this.start = 0
    this.end = 10
  }

  get length() {
    return Math.abs(this.end - this.start)
  }
}

export class DateRange {
  constructor(start, end) {
    this.start = start !== undefined? start: new Date()
    this.end = end !== undefined? end: new Date()
  }

  clone() {
    return new DateRange(this.start, this.end)
  }

  equals(value) {
    let leftStartTime = this.start.getTime()
    let rightStartTime = value.start.getTime()
    let leftEndTime = this.end.getTime()
    let rightEndTime = value.end.getTime()

    return leftStartTime === rightStartTime && leftEndTime === rightEndTime
  }
}