import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import ErrorIcon from '@mui/icons-material/Error'
import { red } from '@mui/material/colors'
import { ReactMnemoContainer } from 'components/ReactMnemoContainer'
import AlarmEmitter from 'features/live/AlarmEmitter'
import { Stage } from 'features/splashPage/splashSlice'
import { closeMnemo, openMain, MnemoStatus, selectMnemoByName, selectMnemoHandleByName, closeDialogs } from './mnemoPageSlice'
import WindowHost from './Window'

export const MnemoPage = () => {

  const dispatch = useDispatch()
  const params = useParams()
  const splashStage = useSelector(state => state.splash.stage)
  const isPassedSplash = splashStage !== Stage.EventCategories

  const mnemoName = params.name

  const mnemo = useSelector(state => selectMnemoByName(state, mnemoName))
  const handle = selectMnemoHandleByName(mnemoName)

  useEffect(() => {
    if (!isPassedSplash) {
      return
    }

    dispatch(openMain({ mnemoName }))

    return () => {
      dispatch(closeMnemo(mnemoName))
      dispatch(closeDialogs())
    }
  }, [dispatch, isPassedSplash, mnemoName])

  let content = null
  if (!!mnemo) {
    switch (mnemo.status) {
      case MnemoStatus.idle:
        content =
          <Box sx={{ overflow: 'auto', flex: 1 }}>
            <ReactMnemoContainer mnemo={handle} />
          </Box>
        break

      case MnemoStatus.fetching:
        content = 
          <Box 
            sx={{ 
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1
            }}
            mt={2}
          >
            <CircularProgress />
          </Box>
        break

      case MnemoStatus.error:
        content = 
          <Box p={1}>
            <ErrorIcon sx={{ color: red[500] }} />
            <Typography variant='subtitle2'>{mnemo.statusText}</Typography>
          </Box>
        break
    }
  }


  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {/* Убрал пока заголовок */}
      {/* <Typography sx={{ flexBasis: 'content', pl: 1 }} gutterBottom>
        {t('pages.mnemo.title', { name: mnemo? ` - ${mnemo.viewName}`: '' })}
      </Typography> */}
      {content}
      <AlarmEmitter />
      <WindowHost />
    </Box>
  )
}