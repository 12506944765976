import ReactDOM from 'react-dom'
import { ValueObject } from 'GVL/types'
import { GVLControl, GVLFont } from 'GVL/elements'
import { Table } from './EventTable'
import { mapDTOToEvents } from 'utils/events'
import { getActualEvents, getActualEventsWithNoAckToday } from './api'
import { renewEventChanges } from 'actions/mnemo'
import { Provider } from 'react-redux'

let _store

export const injectStore = (store) => {
  _store = store
}


// const createFakeRows = (count) => {
//   return new Array(count).fill(null)
//     .map((_, idx) => ({
//       id: idx,
//       ID: idx,
//       acked: false,
//       ackComment: '',
//       ackTime: null,
//       channel: null,
//       endTime: new Date(),
//       priority: 0,
//       startTime: new Date(),
//       message: `Hello world ${idx}`,
//       text: "Hello world!",
//       textOwn: false,
//       type: EventType.normal,
//       value: ''
//     }))
// }

export class GVLOperationEventTable extends GVLControl {

  constructor() {
    super()

    this._backgroundColor = '#8D8D8D';
    this._borderColor = '#C5C5C5';
    this._gridLineColor = '#C5C5C5';
    this.font = new GVLFont()
    this.header = new GVLOperationEventTable_Header()
    this.header.onChange = () => this.requestUpdate()
    this._actualEventIDs = []
    this._events = []//createFakeRows(50000)
    this._mandatoryAck = false
    this.rowHeight = 0
    this.eventBus = null

    this._keepDomRootByClient = true
  }

  dispose() {
    if (this._domRoot) {
      ReactDOM.unmountComponentAtNode(this._domRoot)
    }

    this.eventBus?.off('eventChanges', this.handleEventChanges)
    
    this.font.dispose()
    this.header.dispose()
    super.dispose()
  }

  get backgroundColor() { return this._backgroundColor }
  set backgroundColor(value) { this._setProp('backgroundColor', value) }

  get borderColor() { return this._borderColor }
  set borderColor(value) { this._setProp('borderColor', value) }

  get gridLineColor() { return this._gridLineColor }
  set gridLineColor(value) { this._setProp('gridLineColor', value) }

  get mandatoryAck() { return this._mandatoryAck }
  set mandatoryAck(value) { this._setProp('mandatoryAck', value) }

  initDomRoot() {
    this.defineRoot('div')
  }

  render() {
    this._domRoot.style.backgroundColor = this._backgroundColor

    const headerProps = {
      backgroundColor: this.header.backgroundColor,
      height: this.header.height,
      textColor: this.header.textColor,
      textFont: this.header.textFont
    }

    ReactDOM.render(
      <Provider store={_store}>
        <Table 
          borderColor={this.borderColor}
          gridLineColor={this.gridLineColor}
          headerProps={headerProps}
          height={this._height}
          font={this.font}
          rowHeight={this.rowHeight}

          actualEventIDs={this._actualEventIDs}
          events={this._events}
          mandatoryAck={this.mandatoryAck}
        />
      </Provider>,
      this._domRoot)
  }

  _loadingChanged() {
    super._loadingChanged()

    if (this.loading) {
      return
    }

    this._fetchEvents()
  }

  async _fetchEvents() {

    const func = this.mandatoryAck
      ? getActualEventsWithNoAckToday
      : getActualEvents

    const res = await func()
    const dtos = res.data

    const rootState = _store.getState()
    const events = mapDTOToEvents(dtos, rootState)

    this._events = events
    this.requestUpdate()

    this.eventBus?.on('eventChanges', this.handleEventChanges)

    renewEventChanges()
    // TODO handle errors
    console.debug(events)
  }

  handleEventChanges = (message) => {
    const { actualEventIDs, newEvents: newDTOEvents, changes } = message

    const rootState = _store.getState()
    const newEvents = mapDTOToEvents(newDTOEvents, rootState)

    this._actualEventIDs = actualEventIDs
    let actualIDSet = new Set(this._actualEventIDs)

    if (newEvents.length > 0) {
      this._events = [...this._events, ...newEvents]
    }

    for (let change of changes) {
      let event = this._events.find(it => it.ID === change.eventID)
      if (!event) {
        continue
      }

      if (change.acked != null) {
        event.acked = !!change.acked
        event.ackTime = new Date(change.ackTime)
        event.ackComment = change.ackComment
      }

      if (change.endTime != null) {
        event.endTime = new Date(change.endTime)
      }
    }

    if (this.mandatoryAck) {
      this._events = this._events.filter(it => !it.acked || actualIDSet.has(it.ID))
    } else {
      this._events = this._events.filter(it => actualIDSet.has(it.ID))
    }

    this.requestUpdate()
  }
}

export class GVLOperationEventTable_Header extends ValueObject {
  constructor() {
    super()
    this._backgroundColor = '#8D8D8D'
    this._textColor = '#000000'
    this.height = 0
    this.textFont = new GVLFont()
    this.textFont.family = 'Segoe UI'
    this.textFont.size = 14
    this.textFont.onChange = () => this._doChange()
  }

  get backgroundColor() { return this._backgroundColor }
  set backgroundColor(value) { this._setProp('backgroundColor', value) }

  get textColor() { return this._textColor }
  set textColor(value) { this._setProp('textColor', value) }

  dispose() {
    this.textFont.dispose()
    super.dispose()
  }
}