import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import './i18n'
import './index.css'
import 'mnemo/component'
import App from './App'

import reportWebVitals from './reportWebVitals'
import ServiceContext, { initMessageHandler } from './contexts/ServiceContext'
import DepsContext from './contexts/DepsContext'
import client from './services/client'
import store from './redux/store'
import SideStore from './utils/sideStore'
import { injectStore } from './services/resource'
import { injectStore as tableInjectStore } from 'GVL/EventTable/GVLTable'
import { injectStore as figureView_injectStore } from 'GVL/figureView'
import { injectStore as mnemoContainer_injectStore } from 'mnemo/controls/container'
import { injectStore as buttonInjectStore } from 'GVL/buttons'
import { injectStore as actInjectStore, injectNetClient as mnemo_injectNetClient } from 'actions/mnemo'
import { startUpConnection } from './reducers/clientSlice'
import { startRefreshToken } from 'reducers/authSlice'
import { restoreFields } from 'features/chart/chartSlice'
import eventBus from 'services/eventBus'

injectStore(store)
tableInjectStore(store)
actInjectStore(store)
buttonInjectStore(store)
figureView_injectStore(store)
mnemoContainer_injectStore(store)

SideStore.channels.store = store

mnemo_injectNetClient(client)

initMessageHandler(client, store, eventBus)

const mnemoDeps = {
  client, eventBus
}

store.dispatch(startUpConnection(client))
store.dispatch(startRefreshToken())
store.dispatch(restoreFields())

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ServiceContext.Provider value={client}>
        <DepsContext.Provider value={mnemoDeps}>
          <App />
        </DepsContext.Provider>
      </ServiceContext.Provider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
