class Buffer {
  constructor() {
    if (arguments.length >= 1) {
      const firstArg = arguments[0];

      if (typeof firstArg === 'object' && firstArg instanceof ArrayBuffer) {
        this.data = firstArg;
        this.capacity = this.data.byteLength;
        this.size = this.capacity;
      }
    } else {
      this.capacity = 65536;
      this.data = new ArrayBuffer(this.capacity);
      this.size = 0;
    }
    
    this.dataView = new DataView(this.data, 0);
    
    this.position = 0;

    this.textEncoder = new TextEncoder();
    this.textDecoder = new TextDecoder('utf8', { fatal: true, ignoreBOM: false });
  }

  rewind() {
    this.position = 0;
  }

  readBoolean() {
    let result = this.dataView.getUint8(this.position);
    this.position += Uint8Array.BYTES_PER_ELEMENT;

    return result === 0xFF;
  }

  readByte() {
    let result = this.dataView.getUint8(this.position);
    this.position += Uint8Array.BYTES_PER_ELEMENT;

    return result;
  }

  readFloat64() {
    let result = this.dataView.getFloat64(this.position, true);
    this.position += Float64Array.BYTES_PER_ELEMENT;

    return result;
  }

  readInt32() {
    let result = this.dataView.getInt32(this.position, true);
    this.position += Int32Array.BYTES_PER_ELEMENT;

    return result;
  }

  readInteger() {
    let result = this.dataView.getBigInt64(this.position, true);
    this.position += BigInt64Array.BYTES_PER_ELEMENT;

    if (result >= Number.MIN_SAFE_INTEGER && result <= Number.MAX_SAFE_INTEGER) {
      const clamped = BigInt.asIntN(54, result);
      return Number(clamped);
    } else {
      throw Error('Buffer.readInteger: failed to read integer that exceed the safe integer range');
    }
  }

  readString() {
    let length = this.readInt32();

    if (length === 0) {
      return '';
    }

    let array = new Uint8Array(this.data, this.position, length);

    let result = this.textDecoder.decode(array);
    this.position += length;

    return result;
  }

  readBytes(byteCount) {
    this._ensureHasBytes(byteCount);

    let array = new Uint8Array(byteCount);
    array.set(new Uint8Array(this.data, this.position, byteCount));

    this._moveNext(byteCount);
    return array;
  }

  writeBoolean(value) {
    this._ensureNext(Uint8Array.BYTES_PER_ELEMENT);
    this.dataView.setUint8(this.position, !!value? 0xFF: 0x0);
    this._moveNext(Uint8Array.BYTES_PER_ELEMENT);
  }

  writeByte(value) {
    this._ensureNext(Uint8Array.BYTES_PER_ELEMENT);
    this.dataView.setUint8(this.position, value);
    this._moveNext(Uint8Array.BYTES_PER_ELEMENT);
  }

  writeTypedArray(value) {
    const byteCount = value.BYTES_PER_ELEMENT * value.length;
    this._ensureNext(byteCount);

    let dataArray = new Uint8Array(this.data, this.position);
    dataArray.set(value);
    
    this._moveNext(byteCount);
  }

  writeInt32(value) {
    this._ensureNext(Int32Array.BYTES_PER_ELEMENT);

    this.dataView.setInt32(this.position, value, true);
    this._moveNext(Int32Array.BYTES_PER_ELEMENT);
  }

  writeInteger(value) {
    this._ensureNext(BigInt64Array.BYTES_PER_ELEMENT);

    const bigIntValue = BigInt(value);
    this.dataView.setBigInt64(this.position, bigIntValue, true);
    this._moveNext(BigInt64Array.BYTES_PER_ELEMENT);
  }

  writeFloat64(value) {
    this._ensureNext(Float64Array.BYTES_PER_ELEMENT);
    this.dataView.setFloat64(this.position, value, true);
    this._moveNext(Float64Array.BYTES_PER_ELEMENT);
  }

  writeString(str) {
    let bytes = this.textEncoder.encode(str);
    
    const byteCount = bytes.byteLength;
    this.writeInt32(byteCount);

    if (byteCount > 0) {
      this._ensureNext(byteCount);

      let arr = new Uint8Array(this.data, this.position, byteCount);
      arr.set(bytes, 0);
      this._moveNext(byteCount);
    }
  }

  toFixedArray() {
    return new Uint8Array(this.data, 0, this.size);
  }

  _ensureNext(byteCount) {
    while (this.position + byteCount > this.capacity) {
      this._setCapacity(Math.trunc(this.capacity * 1.5));
    }
  }

  _setCapacity(value) {
    this.capacity = value;

    let array = new Uint8Array(this.capacity);
    array.set(new Uint8Array(this.data));

    this.data = array.buffer;
    this.dataView = new DataView(this.data, 0);

    if (this.size > this.capacity) {
      this.size = this.capacity;
    }

    if (this.position > this.size) {
      this.position = this.size;
    }
  }

  _moveNext(count) {
    this.position += count;
    
    if (this.size < this.position) {
      this.size = this.position;
    }
  }

  _ensureHasBytes(byteCount) {
    if (this.size - this.position - 1 < byteCount) {
      throw new Error(`Buffer does not have ${byteCount} bytes to read`);
    }
  }
}

module.exports = Buffer;