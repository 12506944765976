
const ThreeByFour = 3 / 4

let metricCanvas = null
let metricCtx = null
let _scrollBarWidth = null

function getMetrixCtx() {
  if (!metricCtx) {
    metricCanvas = document.createElement('canvas')
    metricCtx = metricCanvas.getContext('2d')
  }

  return metricCtx
}

class Metrics {
  static pxToPt(px) {
    return px * ThreeByFour
  }

  static ptToPx(pt) {
    return pt / ThreeByFour
  }

  static computeScrollBarWidth() {
    if (!!_scrollBarWidth) {
      return _scrollBarWidth
    }

    var scrollbox = document.createElement('div')
    scrollbox.style.overflow = 'scroll'
    document.body.appendChild(scrollbox)

    _scrollBarWidth = scrollbox.offsetWidth - scrollbox.clientWidth

    document.body.removeChild(scrollbox)

    return _scrollBarWidth
  }

  static computeTextWidth(text, font) {
    let metricCtx = getMetrixCtx()

    metricCtx.font = font
    const textMetrics = metricCtx.measureText(text)

    return textMetrics.width
  }

  static computeTextHeight(text, font) {
    let span = document.createElement('span')
    span.textContent = 'Hg'
    span.style.font = font

    let block = document.createElement('div')
    block.style.display = 'inline-block'
    block.style.width = '1px'
    block.style.height = '0px'

    let div = document.createElement('div')
    div.append(span, block)

    document.body.append(div)
    try {
      block.style.verticalAlign = 'bottom'
      return block.offsetTop - span.offsetTop
    } finally {
      document.body.removeChild(div)
    }
  }
}

export default Metrics