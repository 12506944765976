
class EnumBase {
  constructor(names, valueCount) {
    this.valueCount = valueCount;
    this.names = names;
  }

  valueOf(number) {
    let name = this.names[number];
    return this[name];
  }
}

class Enum {
  static build(names) {
    return names
      .map(name => ({ name, id: 0 }))
      .reduce((prevValue, value) => {
        prevValue[value.name] = value;
        value.id = prevValue.valueCount++;
        return prevValue; 
      }, new EnumBase(names, 0));
  }
}

module.exports = Enum;