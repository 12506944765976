function storageAvailable(type) {
  try {
    var storage = window[type],
      x = '__storage_test__'
    storage.setItem(x, x)
    storage.removeItem(x)
    return true
  } catch(e) {
    return false
  }
}

var _scrollBarWidth

export function getScrollBarWidth() {
  if (_scrollBarWidth === undefined) {
    // Создадим невидимый контейнер
    const outer = document.createElement('div')
    outer.style.visibility = 'hidden'
    outer.style.overflow = 'scroll' // Принудительно отобразим scrollbar
    outer.style.msOverflowStyle = 'scrollbar' // Нужно для WinJS приложений
    document.body.appendChild(outer)

    // Создадим вложенный элемент и поместим его в контейнер
    const inner = document.createElement('div')
    outer.appendChild(inner)

    // Посчитаем разницу между полной шириной и шириной дочернего элемента
    _scrollBarWidth = outer.offsetWidth - inner.offsetWidth

    // Удалим временный элемент outer из тега body
    outer.parentNode.removeChild(outer)
  }

  return _scrollBarWidth
}

export function hasAnyDOMParent(checkElement, parentElement) {
  let element = checkElement
  
  while (element) {
    if (element === parentElement) {
      return true
    }
    
    element = element.parentNode
  }
  
  return false
}

export class Colors {
  static addAlpha(a24bitColor, alpha) {
    let result = a24bitColor

    if (alpha < 255 && a24bitColor.startsWith('#')) {
      let r = parseInt(a24bitColor.substr(1, 2), 16)
      let g = parseInt(a24bitColor.substr(3, 2), 16)
      let b = parseInt(a24bitColor.substr(5, 2), 16)

      result = `rgba(${r}, ${g}, ${b}, ${alpha / 255})`
    }

    return result
  }
}

export class Color {
  constructor(str) {

    if (typeof str === 'string') {
      if (str.startsWith('rgba(') && str.endsWith(')')) {
        str = str.substring('rgba('.length, str.length - 1)

        const elems = str.split(',').map(it => it.trim())

        this.r = parseInt(elems[0])
        this.g = parseInt(elems[1])
        this.b = parseInt(elems[2])
        this.a = Math.round(parseFloat(elems[3]) * 0xff)
      } else if (str.startsWith('#')) {
        if (str.length === 9) {
          this.a = parseInt(str.substring(1, 3), 16)
          this.r = parseInt(str.substring(3, 5), 16)
          this.g = parseInt(str.substring(5, 7), 16)
          this.b = parseInt(str.substring(7, 9), 16)
        } else if (str.length === 7) {
          this.a = 0xff
          this.r = parseInt(str.substring(1, 3), 16)
          this.g = parseInt(str.substring(3, 5), 16)
          this.b = parseInt(str.substring(5, 7), 16)
        } else {
          throw new Error(`cannot parse ${str} as a color`)
        }
      } else {
        throw new Error(`cannot parse ${str} as a color`)
      }
    } else if (typeof str === 'number') {
      this.b = str & 0xFF
      this.g = (str >> 8) & 0xFF
      this.r = (str >> 16) & 0xFF
      this.a = (str >> 24) & 0xFF
    } else {
      throw new Error(`cannot parse ${str} as a color`)
    }
  }

  changeHSL(dH, dS, dL) {
    let { h, s, l } = this._getHSL()

    h += dH

    if (h < 0) {
      h++
    } else if (h > 1) {
      h--
    }

    s += dS
    if (s < 0) {
      s = 0
    } else if (s > 1) {
      s = 1
    }

    l += dL
    if (l < 0) {
      l = 0
    } else if (l > 1) {
      l = 1
    }

    this._setHSL(h, s, l)
  }

  _getHSL() {
    let r = this.r / 0xff
    let g = this.g / 0xff
    let b = this.b / 0xff

    let mx = Math.max(r, g, b)
    let mn = Math.min(r, g, b)

    let h = (mx + mn) / 2
    let l = h
    let s = h

    if (mx === mn) {
      s = 0
      h = 0
    } else {
      let d = mx - mn

      if (l > 0.5) {
        s = d / (2 - mx - mn)
      } else {
        s = d / (mx + mn)
      }

      if (mx === r) {
        h = (g - b) / d
      } else if (mx === g) {
        h = (b - r) / d + 2
      } else {
        h = (r - g) / d + 4
      }

      h /= 6

      if (h < 0) {
        h += 1
      }
    }

    return { h, s, l }
  }

  _clip(value) {
    if (value < 0) {
      return 0
    } else if (value > 1) {
      return 1
    }

    return value
  }

  _hue2rgb(p, q, t) {
    if (t < 0) {
      t++
    }

    if (t > 1) {
      t--
    }

    if (t < 1 / 6) {
      return p + (q - p) * 6 * t
    }

    if (t < 1 / 2) {
      return q
    }

    if (t < 2 / 3) {
      return p + (q - p) * (2 / 3 - t) * 6
    }

    return this._clip(p)
  }

  _setHSL(h, s, l) {
    let r = 0
    let g = 0
    let b = 0

    if (s === 0) {
      l = this._clip(l)
      r = l
      g = l
      b = l
    } else {
      let q = l < 0.5
        ? l * (1 + s)
        : l + s - l * s

      let p = 2 * l - q
      r = this._hue2rgb(p, q, h + 1/3)
      g = this._hue2rgb(p, q, h)
      b = this._hue2rgb(p, q, h - 1/3)
    }

    this.r = Math.round(r * 0xff)
    this.g = Math.round(g * 0xff)
    this.b = Math.round(b * 0xff)
  }

  toString() {
    if (this.a === 0xff) {
      return `#${toHexByte(this.r)}${toHexByte(this.g)}${toHexByte(this.b)}`
    }

    return `rgba(${this.r}, ${this.g}, ${this.b}, ${this.a / 0xff})`
  }
}

function toHexByte(value) {
  let result = value.toString(16)
  if (result.length === 1) {
    result = '0' + result
  }

  return result
}

export const SessionStorageAvailable = storageAvailable('sessionStorage');