import { GVLControl } from './elements'
import DOMPurify from 'dompurify'

export class GVLSVGBox extends GVLControl {
  constructor() {
    super()
    this._keepDomRootByClient = true
    this._data = ''
  }

  get data() { return this._data }
  set data(value) { this._setProp('data', value) }

  initDomRoot() {
    this.defineRoot('div')
  }

  render() {
    const clean = DOMPurify.sanitize(this._data, {
      USE_PROFILES: {
        svg: true,
      },
      FORBID_TAGS: ['a']
    })
    this._domRoot.innerHTML = clean
  }
}

export default GVLSVGBox