import { PointF } from "./types";

export class SVGUtils {
  static setVisible(svgElement, value) {
    if (!!value) {
      svgElement.removeAttribute('visibility')
    } else {
      svgElement.setAttribute('visibility', 'hidden')
    }
  }

  static clearChildren(element) {
    element.textContent = ''
  }

  static getElementsMaxSize(svgElements) {
    let size = {
      width: 0,
      height: 0
    }

    for (let element of svgElements) {
      var bbox = element.getBBox()

      if (bbox.width > size.width) {
        size.width = bbox.width
      }

      if (bbox.height > size.height) {
        size.height = bbox.height
      }
    }

    return size
  }
}

export function interpolatePoint(a, b, size) {
  const angle = a.angle(b)

  const result = new PointF()
  result.from(a)
  result.addXY(size * Math.cos(angle), size * Math.sin(angle))
  
  return result
}

export function stringFirstToLowerCase(value) {
  if (value.length > 0) {
    return value[0].toLowerCase() + value.substring(1)
  }
  
  return ''
}