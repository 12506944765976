import { createSlice } from '@reduxjs/toolkit'
import { Button } from './types'

const initialState = {
  open: false,
  resolve: null,
  text: '',
}

const slice = createSlice({
  name: 'confirmDialog',
  initialState,
  reducers: {
    accept: (state) => {
      const { resolve } = state
      
      state.open = false
      state.resolve = null

      if (resolve != null) {
        resolve(Button.Ok)
      }
    },
    close: (state) => {
      const { resolve } = state

      state.open = false
      state.resolve = null

      if (resolve != null) {
        resolve(Button.Cancel)
      }
    },
    show: (state, { payload }) => {
      const { text, resolve } = payload
      state.open = true
      state.resolve = resolve
      state.text = text
    }
  }
})

export const { accept, close, show } = slice.actions

export default slice.reducer