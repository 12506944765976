import { GVLControl } from 'GVL/elements'
import { SVG } from '@svgdotjs/svg.js'

class Pipe extends GVLControl {
  constructor() {
    super()

    this._keepDomRootByClient = true
    this._primaryColor = '#878A88'
    this._secondaryColor = '#FFFFFF'
  }

  get primaryColor() { return this._primaryColor }
  set primaryColor(value) { this._setProp('primaryColor', value) }

  get secondaryColor() { return this._secondaryColor }
  set secondaryColor(value) { this._setProp('secondaryColor', value) }

  initDomRoot() {
    this._domRoot = document.createElement('div')
    this._domRoot.className = 'gvl-control'

    this.$svg = SVG().addTo(this._domRoot)
    this.$svg.attr('class', 'd-block')
  }

  _propertyChanged(name) {
    super._propertyChanged(name)

    if (!this.$svg) {
      return
    }

    switch (name) {
      case 'width':
      case 'height':
        this.$svg.size(this._width, this._height)
        break
    }
  }
}

class StraitPipe extends Pipe {

  constructor() {
    super()
    this.isHorizontal = true
  }

  render() {
    let { $svg } = this
    $svg.clear()

    const $gradient = createGradient($svg, this._primaryColor, this._secondaryColor, this.isHorizontal)

    let $rect = $svg.rect(this._width, this._height)
    $rect.fill($gradient)
  }
}

export class HorzPipe extends StraitPipe {}
export class VertPipe extends StraitPipe {
  constructor() {
    super()
    this.isHorizontal = false
  }
}

export class CornerPipe extends Pipe {
  constructor() {
    super()

    this.kind = 'bottomRight'
  }

  render() {
    const {$svg} = this
    $svg.clear()

    const w = this._width
    const h = this._height

    const $gradient = this._createRadialGradient()

    switch (this.kind) {
      case 'bottomRight':
        {
          const $e = $svg.ellipse(2*w, 2*h)
          $e.fill($gradient)
        }
        break

      case 'bottomLeft':
        {
          const $e = $svg.ellipse(2*w, 2*h)
          $e.move(-w, 0)
          $e.fill($gradient)
        }
        break

      case 'topLeft':
        {
          const $e = $svg.ellipse(2*w, 2*h)
          $e.move(-w, -h)
          $e.fill($gradient)
        }
        break

      case 'topRight':
        {
          const $e = $svg.ellipse(2*w, 2*h)
          $e.move(0, -h)
          $e.fill($gradient)
        }
        break
    }
  }

  _createRadialGradient() {
    return this.$svg.gradient('radial', gradient => {
      gradient.stop(0, this._primaryColor)
      gradient.stop(0.5, this._secondaryColor)
      gradient.stop(1, this._primaryColor)
    })
  }
}

const createGradient = ($svg, primaryColor, secondaryColor, isVertical) => {
  const $gradient = $svg.gradient('linear', gradient => {
    gradient.stop(0, primaryColor)
    gradient.stop(0.5, secondaryColor)
    gradient.stop(1, primaryColor)

    if (isVertical) {
      gradient.from(0, 0).to(0, 1)
    }
  })

  return $gradient
}

export class TeePipe extends Pipe {
  constructor() {
    super()

    this.kind = 'top'
  }

  render() {
    const {$svg} = this
    $svg.clear()

    const $horzGradient = createGradient($svg, this._primaryColor, this._secondaryColor, false)
    const $vertGradient = createGradient($svg, this._primaryColor, this._secondaryColor, true)

    const w = this._width
    const h = this._height
    const w2 = w/2
    const h2 = h/2

    switch (this.kind) {
      case 'top': 
        $svg.rect(w, h).move(0, 0).fill($vertGradient)
        $svg.path(`M0,0 l${w2},${h2} l${w2},${-h2} z`).fill($horzGradient)
        break

      case 'right':
        $svg.rect(w, h).move(0, 0).fill($horzGradient)
        $svg.path(`M${w},0 l${-w2},${h2} l${w2},${h2}z`).fill($vertGradient)
        break

      case 'bottom':
        $svg.rect(w, h).move(0, 0).fill($vertGradient)
        $svg.path(`M0,${h} l${w2},${-h2} l${w2},${h2} z`).fill($horzGradient)
        break

      case 'left':
        $svg.rect(w, h).move(0, 0).fill($horzGradient)
        $svg.path(`M0,0 l${w2},${h2} l${-w2},${h2} z`).fill($vertGradient)
        break
    }
  }
}

export class CrossPipe extends Pipe {

  render() {
    const {$svg} = this
    $svg.clear()

    const $horzGradient = createGradient($svg, this._primaryColor, this._secondaryColor, false)
    const $vertGradient = createGradient($svg, this._primaryColor, this._secondaryColor, true)

    const w = this._width
    const h = this._height

    $svg.rect(w, h).move(0, 0).fill($horzGradient)
    $svg.path(`M0,0 l0,${h} l${w},${-h} l0,${h}z`).fill($vertGradient)
  }
}