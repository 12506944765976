import AcknowledgeDialog from 'GVL/EventTable/AckDialog'
import ConfirmDialog from 'features/dialogs/ConfirmDialog'

const Dialogs = () => {
  return (
    <>
      <AcknowledgeDialog />
      <ConfirmDialog />
    </>
  )
}

export default Dialogs;