
import { GVLControl } from 'GVL/elements'
import { SVG } from '@svgdotjs/svg.js'

export class RightArrowCap extends GVLControl {
  constructor() {
    super()

    this._keepDomRootByClient = true
    this._color = '#ffffff'
  }

  get color() { return this._color }
  set color(value) { this._setProp('color', value) }

  initDomRoot() {
    this.$svg = SVG()
    this.$svg.addClass('gvl-control')
    this._domRoot = this.$svg.node
  }

  render() {
    const { $svg } = this
    $svg.clear()

    const w = this.width
    const w2 = 0.5*w
    const data = 
      `0 0 `
    + `${w} ${w2} `
    + `0 ${w}`

    const $polygon = $svg.polygon(data)
    $polygon.fill(this.color)
  }
}

export class CircleCap extends GVLControl {
  constructor() {
    super()
    this._keepDomRootByClient = true
    this._color = '#ffffff'
  }

  get color() { return this._color }
  set color(value) { this._setProp('color', value) }

  initDomRoot() {
    this.defineRoot('div')
  }

  render() {
    const w = this._width
    const w2 = w/2
    const { style } = this._domRoot
    style.borderRadius = `${w2}px`
    style.backgroundColor = this._color
  }
}