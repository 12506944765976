import React from 'react'

const DepsContext = React.createContext(null)

export const useMnemoDeps = () => {
  const service = React.useContext(DepsContext)

  if (!service) {
    throw new Error("useMnemoDeps must be inside a Provider with a value")
  }

  return service
}

export default DepsContext