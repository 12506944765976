import Draggable from 'react-draggable'
import { useTheme, useMediaQuery, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { useDispatch, useSelector } from 'react-redux'
import { MnemoStatus, closeMnemo, selectMnemoByName, selectMnemoHandleByName } from './mnemoPageSlice'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { ReactMnemoContainer } from 'components/ReactMnemoContainer'
import CircularProgress from '@mui/material/CircularProgress'
import ErrorIcon from '@mui/icons-material/Error'
import { red } from '@mui/material/colors'

const WindowHost = () => {
  const windows = useSelector(state => state.mnemoPage.windows)

  return (
    <>
      {windows.map(it => (
        <MnemoWindow 
          key={it.windowId}
          title={it.title}
          defaultPosition={it.defaultPosition}
          mnemoName={it.mnemoName}
        />
      ))}
    </>
  )
}

const MnemoWindow = ({ defaultPosition, title, mnemoName }) => {
  const dispatch = useDispatch()
  const mnemo = useSelector(state => selectMnemoByName(state, mnemoName))
  const handle = selectMnemoHandleByName(mnemoName)

  const isIdle = mnemo.status === MnemoStatus.idle && Boolean(handle)

  let w = isIdle? handle.width: 300
  const h = isIdle? handle.height: 80

  let content = null

  switch (mnemo.status) {
    case MnemoStatus.idle:
      content = <ReactMnemoContainer mnemo={handle} />
      break

    case MnemoStatus.fetching:
      content = 
        <Box sx={{ 
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1
        }} mt={2}>
          <CircularProgress />
        </Box>
      break

    case MnemoStatus.error:
      w = 400
      content = 
        <Box p={1}>
          <ErrorIcon sx={{ color: red[500] }} />
          <Typography variant='subtitle2' sx={{ whiteSpace: 'pre-line' }}>{mnemo.statusText}</Typography>
        </Box>
      break
  }
  
  return (
    <Window 
      title={title}
      defaultPosition={defaultPosition}
      width={w}
      height={h}
      onClose={() => {
        dispatch(closeMnemo(mnemoName))
      }}
    >
      {content}
    </Window>
  )
}

const Window = ({ children, defaultPosition, title, width = 300, height = 300, onClose }) => {
  const nodeRef = useRef(null)
  const [position, setPosition] = useState(defaultPosition)
  const windowSize = useWindowSize()
  
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))
  const headerHeight = matches? 40: 30

  const handleDrag = (_, data) => {
    setPosition({
      x: data.x,
      y: data.y,
    })
  }

  const outerWidth = width
  const outerHeight = height + headerHeight

  useLayoutEffect(() => {
    const newPos = fixPosition(position, outerWidth, outerHeight, windowSize)

    if (newPos.x !== position.x || newPos.y !== position.y) {
      setPosition(newPos)
    }

  }, [position, outerWidth, outerHeight, windowSize])

  return (
    <Draggable
      nodeRef={nodeRef}
      position={position}
      handle='.title'
      onDrag={handleDrag}
    >
      <Box
        bgcolor='#404040'
        display='flex'
        flexDirection='column'
        boxShadow='2px 2px 2px rgba(0,0,0,0.5)'
        position='absolute'
        top={0}
        left={0}
        ref={nodeRef}
        width={outerWidth}
        minHeight={outerHeight}
      >
        <Box
          className='title'
          bgcolor='#303030'
          display='flex'
          sx={{
            flex: `${headerHeight}px 0 0`
          }}
        >
          <div style={{ 
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '.25rem',
            fontSize: '1rem',
            userSelect: 'none'
          }}>{title}</div>
          <WindowButton
            size={headerHeight}
            onClick={onClose}
          />
        </Box>
        <div style={{
          flex: 1
        }}>
          {children}
        </div>
      </Box>
    </Draggable>
  )
}

const WindowButton = ({ size, onClick }) => {
  return (
    <button 
      style={{
        color: '#c9c9c9',
        background: 'transparent',
        border: 'none',
        flex: `${size}px 0 0`,
      }}
      onClick={onClick}
      onTouchStart={onClick}
    >X</button>
  )
}

const fixPosition = (position, outerWidth, outerHeight, windowSize) => {
  const right = position.x + outerWidth
  const bottom = position.y + outerHeight

  const mostWidth = Math.min(windowSize.width, window.screen.width)
  const mostHeight = Math.min(windowSize.height, window.screen.height)

  const dx = right - mostWidth
  const dy = bottom - mostHeight

  if (dx > 0 || dy > 0 || position.x < 0 || position.y < 0) {
    const newPosition = {...position}

    if (dx > 0) {
      newPosition.x -= dx
    }

    if (dy > 0) {
      newPosition.y -= dy
    }

    if (newPosition.x < 0) {
      newPosition.x = 0
    }

    if (newPosition.y < 0) {
      newPosition.y = 0
    }

    return newPosition
  }

  return position
}

const useWindowSize = () => {

  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    const listener = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', listener)

    return () => {
      window.removeEventListener('resize', listener)
    }
  }, [])

  return size
}

export default WindowHost