import { GVLCustomWindow } from 'GVL/window'
import { getScrollBarWidth } from '../std/utils'
import { GVLFigureView } from 'GVL/figureView'
import LineChart from './controls/lineChart'
import GVLTabView from './controls/TabView/gvlTabView'

class ComponentBlinker {
  
  constructor() {
    this._listenerSet = new Set()
    this._state = true

    setInterval(this._blinkAll, 500)
  }

  _blinkAll = () => {
    this._state = !this._state

    for (let listener of this._listenerSet) {
      listener.blink(this._state)
    }
  }

  addListener(element) {
    this._listenerSet.add(element)
  }

  removeListener(component) {
    this._listenerSet.delete(component)
  }
}

const blinker = new ComponentBlinker()

export class Mnemo extends GVLCustomWindow {

  constructor() {
    super()

    // this.fullScreen = true
    this._backgroundImage = ''
    this._backgroundColor = '#FFFFFF'
    this._componentId = 'mnemo'
    this.name = ''
    this.filePath = ''
    this._viewportStretch = 'none'
    this.tabsDisplay = false
  }

  get backgroundColor() { return this._backgroundColor }
  set backgroundColor(value) { this._setProp('backgroundColor', value); }

  get backgroundImage() { return this._backgroundImage }
  set backgroundImage(value) { this._setProp('backgroundImage', value); }

  get viewportStretch() { return this._viewportStretch }
  set viewportStretch(value) { this._setProp('viewportStretch', value) }

  dispose() {
    this.fireEvent('dispose', this);

    for (let control of this._controls) {
      if (control.blink) {
        blinker.removeListener(control)
      }
    }

    const { parentNode } = this._domRoot

    if (parentNode) {
      parentNode.removeChild(this._domRoot)
    }

    super.dispose()
  }

  addControl(control) {
    super.addControl(control);

    if (control.blink) {
      blinker.addListener(control);
    }
  }

  attach(value) {
    super.attach(value)

    const parent = this._domRoot.parentNode
    if (!parent) {
      return
    }

    if (this.viewportStretch === 'scale') {
      this.fitParentToScrollBox()
    }
  }

  initDomRoot() {
    const root = this.defineRoot('div')
    root.classList.add('gvl-container')
  }

  render() {
    const { style } = this._domRoot

    if (this._backgroundImage !== '') {
      style.background = `url(${this._backgroundImage}) no-repeat ${this._backgroundColor}`;
      style.backgroundSize = '100% 100%'
    } else {
      style.background = this._backgroundColor
    }

    // if (this.viewportStretch === 'scale') {
    //   this.fitParentToScrollBox()
    // }
  }

  close() {
    this.fireEvent('close')
    this.dispose()
  }

  fitParentToScrollBox() {
    const parent = this._domRoot.parentNode
    if (!parent)
      return

    const w = this._width
    const h = this._height
    const pW = parent.offsetWidth
    const pH = parent.offsetHeight

    const { style } = this._domRoot

    if (w < pW && h < pH) {
      let factor = pW / w

      const newWidth = w * factor
      const newHeight = h * factor

      if (newWidth > 0 && newHeight > pH) {
        const scrollBarWidth = getScrollBarWidth()
        factor = (pW - scrollBarWidth) / w
      }

      style.transform = `scale(${factor})`
    } else {
      style.transform = null
    }
  }

  fitParentBySmallestSide() {
    const { parent } = this
    let factor = 1

    if (this.width >= parent.width) {
      factor = parent.width / this.width
    } else {
      factor = parent.height / this.height
    }

    this._domRoot.style.transform = `scale(${factor})`
  }

  renderControls() {
    if (this._dirty) {
      this._dirty = false
      this.render()
    }

    super.renderControls()
  }

  getUsedTags() {
    const figures = this.controls.filter(it => it instanceof GVLFigureView)
    const lArguments = figures.flatMap(it => it.arguments)

    const tags = lArguments
      .map(it => it.tag)
      .filter(it => it !== -1)

    const mnemoUsed = this._getMnemoUsedTags()

    const distinctTags = [...new Set([...tags, ...mnemoUsed])]

    return distinctTags
  }

  _getMnemoUsedTags() {
    let tags = []
    this.forEachControlCross(it => {
      if (it instanceof GVLFigureView) {
        return false
      }

      if (it instanceof LineChart) {
        for (let series of it.series) {
          if (series.tagID !== undefined && series.tagID !== -1) {
            tags.push(series.tagID)
          }
        }

        return
      }

      if (it.tagID !== undefined && it.tagID !== -1) {
        tags.push(it.tagID)
      }
    })

    return tags
  }

  _loaded() {
    super._loaded()

    if (!this.tabsDisplay || this.parent) {
      // Отображаем вкладки только на корневой мнемосхеме
      return
    }

    const tabView = new GVLTabView()
    this.addControl(tabView)
    this._realignTabView()
  }

  _propertyChanged(name) {
    super._propertyChanged(name)

    let value = ''

    switch (name) {
      case 'width':
        value = `${this._width}px`
        this._domRoot.style.width = value
        this._realignTabView()
        break;
      case 'height':
        value = `${this._height}px`
        this._domRoot.style.height = value
        this._realignTabView()
        break;

      case 'viewportStretch':
        this.requestUpdate()
        break;
      default:
    }
  }

  _realignTabView() {
    const tabView = this.findControl(it => it instanceof GVLTabView)
    if (!tabView) {
      return
    }

    tabView.width = this.width
    tabView.height = 40
  }
}

export default Mnemo;