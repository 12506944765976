import React from 'react';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouteLink } from 'react-router-dom';
import Link from '@mui/material/Link';

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      <Typography variant="h3" >{t('pages.notFound.title')}</Typography>
      <Link component={RouteLink} to={'/'}>{t('pages.notFound.toHomeLink')}</Link>
    </Grid>
    
  )
}

export default NotFound;