
const _h = (name, className = '', styles = {}) => {
  const newNode = document.createElement(name)
  if (className !== '') {
    newNode.className = className
  }

  const st = newNode.style

  for (let propName of Object.keys(styles)) {
    st[propName] = styles[propName]
  }

  return newNode
}

class Base {

  constructor(node) {
    this.node = node
  }

  h(name, className = '', styles = {}) {
    const node = _h(name, className, styles)

    this.node.append(node)
    return new Base(node)
  }

  addClass(...names) {
    this.node.classList.add(...names)
  }

  attr(name, value = undefined) {
    if (arguments.length >= 2) {
      this.node[name] = value
      return
    }

    return this.node[name]
  }

  blur() {
    this.node.blur()
  }

  clear() {
    this.node.textContent = ''
  }

  a(className, styles) { return this.h('a', className, styles) }
  div(className, styles) { return this.h('div', className, styles) }
  i(className, styles) { return this.h('i', className, styles) }
  input(className, styles) { return this.h('input', className, styles) }
  img(className, styles) { return this.h('img', className, styles) }
  nav(className, styles) { return this.h('nav', className, styles) }
  section(className, styles) { return this.h('section', className, styles) }

  on(eventName, listener, options) {
    this.node.addEventListener(eventName, listener, options)
  }

  off(eventName, listener, options) {
    this.node.removeEventListener(eventName, listener, options)
  }

  removeClass(...names) {
    this.node.classList.remove(...names)
  }

  setText(value) {
    this.node.textContent = value
  }

  setStyle(name, value) {
    this.node.style[name] = value
  }

  setStyles(props) {
    const st = this.node.style

    for (let propName of Object.keys(props)) {
      st[propName] = props[propName]
    }
  }

  setVisible(value) {
    this.node.style.display = value? null: 'none'
  }

  toggleClass(name, condition) {
    this.node.classList.toggle(name, condition)
  }
}

class Dom extends Base {
  constructor(rootElementName, className, styles) {
    const element = _h(rootElementName, className, styles)

    super(element)
  }

  addTo(domElement) {
    domElement.append(this.node)
  }
}

const DomCreator = (rootElementName, className = '', styles = {}) => new Dom(rootElementName, className, styles)

module.exports = DomCreator