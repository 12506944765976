import axios from 'axios'
import { currentDay } from 'std/dateHelper'

let _store = null

export const injectStore = store => {
  _store = store
}

const defaultOptions = () => {
  const state = _store.getState()
  const { auth, apis } = state
  const { hostname } = window.location

  let options = {
    baseURL: `https://${hostname}:${apis.resource.port}/`
  }

  if (auth.required && auth.user) {
    options = {
      ...options,
      auth: {
        username: auth.user.name,
        token: auth.user.token,
      }
    }
  }

  return options
}

export const getFileURL = (uri) => {
  const options = defaultOptions()
  return options.baseURL + uri
}

const client = axios.create()
client.interceptors.request.use(config => {
  const state = _store.getState()
  const { auth, apis } = state
  const { hostname } = window.location
  config.baseURL = `https://${hostname}:${apis.resource.port}`

  if (auth.required && auth.user) {
    config.headers = {
      Authorization: `Bearer ${auth.user.token}`
    }
  }

  return config
})

export const getLastEvents = (eventCount) => {
  return client.get(`/api/v1/events/last/${eventCount}`)
}

export const getEventPageByCriterias = ({ date, channelIDs, eventTypes, page, limit }) => {
  const start = new Date(date.getFullYear(), date.getMonth(), date.getDate())
  const end = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999)

  const requestBody = {
    channels: channelIDs
  }

  const kinds = eventTypes.join(',')

  const params = {
    page: page + 1,
    limit,
    start,
    end,
    kinds
  }

  return client.post('/api/v1/events/get', requestBody, { params })
}

export const getEventPageByCriterion2 = ({ date, channelIDs, eventTypes, continueEventID, limit }) => {
  const start = new Date(date.getFullYear(), date.getMonth(), date.getDate())
  const end = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999)

  const requestBody = {
    channels: channelIDs
  }

  const kinds = eventTypes.join(',')

  const params = {
    continueEventID,
    limit,
    start,
    end,
    kinds
  }

  return client.post('/api/v1/events/get2', requestBody, { params })
}

export const getOnlineUsersAtTime = (time) => {
  const params = {
    online_time: time
  }

  return client.get('/api/v1/users', { params })
}

export const getGroupMoments = (channelID, range, groupTimeStep) => {
  const params = {
    start_time: range.startTime,
    end_time: range.endTime,
    group_time_step: groupTimeStep
  }

  return client.get(`/api/v1/channels/${channelID}/numeric-moments`, { params })
}

export const resolveEventIds = (ids) => {
  const params = {
    ids: ids.map(it => `${it}`).join(',')
  }

  return client.get('/api/v1/events/resolveIds', { params })
}

export const resolveEventIdsWithNoAckToday = (ids) => {

  const time = currentDay()

  const params = {
    ids: ids.map(it => `${it}`).join(','),
    includeNoAckSince: time
  }

  return client.get('/api/v1/events/resolveIds', { params })
}