import { useMemo, useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import styles from './EventTable.module.css'
import Metrics from 'std/metrics'
import { FixedSizeList } from 'react-window'
import { useDispatch, useSelector } from 'react-redux'
import { open } from './AckDialog.slice'
import { useTranslation } from 'react-i18next'
import { selectEventCatByID } from 'features/live/liveSlice'
import { EventType } from 'utils/events'

const eventCategoryEqualityFn = (state, oldState) => {
  return state.live.eventCats.items !== oldState.live.eventCats.items
}

export const Table = ({ 
  actualEventIDs,
  events,
  mandatoryAck,

  borderColor,
  gridLineColor,
  font,
  headerProps,
  height,
  rowHeight,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  // Функция eventCategoryEqualityFn задана чтобы предотвратить ререндер когда меняется состояние приложения
  const state = useSelector(state => state, eventCategoryEqualityFn)
  const columns = useMemo(() => composeColumnDefs(t, state, mandatoryAck), [t, state, mandatoryAck])

  const [columnsProps, setColumnProps] = useState({
    actualMark: {
      sorted: false,
    }
  })

  let rows = events.map(it => ({
    ...it,
    isActual: actualEventIDs.includes(it.ID)
  })).reverse()

  if (columnsProps.actualMark?.sorted) {
    rows.sort((l, r) => {
      const ll = l.isActual? 1: 0
      const rr = r.isActual? 1: 0

      let res = rr - ll
      if (res === 0) {
        res = r.ID - l.ID
      }

      return res
    })
  }

  const handleRowClick = ({ row }) => {
    if (row.acked) {
      return
    }
    
    const event = {
      ID: row.ID,
      message: row.message,
    }
    dispatch(open({ event }))
  }

  const handleColumnClick = (_, columnId) => {
    if (columnId !== 'actualMark') {
      return
    }

    const prevActualMark = columnsProps.actualMark ?? {}
    const prevSorted = columnsProps.actualMark?.sorted

    setColumnProps({
      ...columnsProps,
      actualMark: {
        ...prevActualMark,
        sorted: !prevSorted
      }
    })
  }

  return (
    <Grid 
      columns={columns}
      columnsProps={columnsProps}
      rows={rows}

      borderColor={borderColor}
      gridLineColor={gridLineColor}
      font={font}
      height={height}
      headerProps={headerProps}
      onColumnClick={handleColumnClick}
      onRowClick={handleRowClick}
      rowHeight={rowHeight}
    />
  )
}

const cellConsts = (() => {
  const borderSize = 1
  const paddingHorz = 6

  return {
    borderSize,
    paddingHorz,
    innerSpace: borderSize + 2 * paddingHorz
  }
})()

const scrollBarWidth = Metrics.computeScrollBarWidth()
const scrollColumnWidth = Math.max(0, scrollBarWidth - cellConsts.innerSpace)

const Grid = ({
  columns,
  columnsProps,
  rows,

  borderColor,
  font,
  height,
  gridLineColor,
  headerProps,
  onColumnClick,
  onRowClick,
  rowHeight,
}) => {
  const bodyHeight = height - headerProps.height - 2
  const { headerColumns, rowColumns } = useColumns(columns, rows, height, font, headerProps, gridLineColor, rowHeight)

  return (
    <div className={styles.grid} style={{ borderColor }}>
      <div className={styles.header} style={{ backgroundColor: headerProps.backgroundColor }}>
        {headerColumns.map(it => (
          <Column 
            key={it.id}
            columnId={it.columnId}
            sortable={it.sortable}
            sorted={columnsProps[it.columnId]?.sorted}
            title={it.title}
            width={it.width}
            style={it.headerCellStyle}
            onClick={onColumnClick}
          />
        ))}
      </div>
      <FixedSizeList
        height={bodyHeight}
        itemData={{ columns: rowColumns, rows, onRowClick }}
        itemSize={rowHeight}
        itemCount={rows.length}
        overscanCount={5}
      >
        {renderRow}
      </FixedSizeList>

    </div>
  )
}

const useColumns = (columns, rows, height, cellFont, header, gridLineColor, rowHeight) => {
  const cellFontValue = cellFont.toCSSValue()

  const cellHeight = rowHeight
  const usedHeight = cellHeight * rows.length
  const bodyHeight = height - header.height - 2

  const rowColumns = useMemo(() => {
    return columns.map(it => newViewColumn(it, header, cellFontValue, gridLineColor, rowHeight))
  }, [columns, header, cellFontValue, gridLineColor, rowHeight])

  const isStubVisible = scrollBarWidth > 0 && usedHeight > bodyHeight

  const headerColumns = !isStubVisible
    ? rowColumns
    : [...rowColumns, newViewColumn({
        id: -1,
        title: '',
        widthType: 'px',
        width: scrollColumnWidth,
        valueGetter: () => ""
      }, header, cellFontValue, gridLineColor, rowHeight)]

  return { rowColumns, headerColumns }
}

function renderRow({ index, style, data }) {
  const { rows } = data
  const row = rows[index]

  return <Row 
    key={row.id}
    columns={data.columns}
    row={row}
    onClick={data.onRowClick}
    style={style}
  />
}

const Row = ({ row, columns, onClick, style }) => {
  return (
    <section 
      className={styles.row}
      onClick={!!onClick? () => {
        onClick({ row })
      }: null}
      style={style}>
      {columns.map(it => (
        <Cell
          key={it.id}
          column={it}
          row={row}
        />
      ))}
    </section>
  )
}

const Cell = ({ column, row }) => {
  const { cellStyle, render, valueGetter, styleGetter } = column

  let applyStyle = cellStyle

  if (!!styleGetter) {
    const newStyle = styleGetter(row)

    if (!!newStyle && typeof newStyle === 'object') {
      applyStyle = {
        ...applyStyle,
        ...newStyle
      }
    }
  }

  let displayValue = ''
  let title = ''

  if (!!render) {
    displayValue = render({ column, row })
  } else if (!!valueGetter) {
    displayValue = valueGetter({ column, row })
    title = displayValue
  }

  return (
    <div className={styles.cell} style={applyStyle} title={title}>
      {displayValue}
    </div>
  )
}

const Column = (props) => {
  const {
    columnId,
    sorted,
    sortable,
    title,
    style,
    onClick
  } = props

  return (
    <div 
      className={styles.cell}
      style={style}
      onClick={(event) => onClick && onClick(event, columnId)}
    >
      {title}
      {sortable && sorted && <KeyboardArrowDownIcon style={{ 
        margin: '2px 0 0 0',
        width: '18px',
        height: '18px',
      }} />}
    </div>
  )
}

function newViewColumn(column, header, cellFontValue, gridLineColor, rowHeight) {
  const headerFontValue = header.textFont.toCSSValue()
  const { innerSpace } = cellConsts

  let widthProp = null

  switch (column.widthType) {
    case 'content': {
        const titleWidth = Math.ceil(Metrics.computeTextWidth(column.title, headerFontValue))
        const cellWidth = titleWidth + innerSpace

        widthProp = `${cellWidth}px`
      }
      break

    case 'time': {
        const titleWidth = Math.ceil(Metrics.computeTextWidth(column.title, headerFontValue))
        const timeTextWidth = Metrics.computeTextWidth(dateToString(new Date()), cellFontValue)

        const textWidth = Math.max(titleWidth, timeTextWidth)
        const cellWidth = textWidth + innerSpace

        widthProp = `${cellWidth}px`
      }
      break

    case 'px': {
        const cellWidth = column.width + innerSpace
        widthProp = `${cellWidth}px`
      }
      break
  
    default:
      break
  }

  const cellStyle = {
    borderColor: gridLineColor,
    font: cellFontValue,
    minHeight: `${rowHeight}px`,
    maxHeight: `${rowHeight}px`,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: widthProp
  }

  const headerCellStyle = {
    borderColor: gridLineColor,
    font: headerFontValue,
    minHeight: `${header.height}px`,
    maxHeight: `${header.height}px`,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: widthProp
  }

  if (column.widthType === 'any') {
    cellStyle.flex = 1
    headerCellStyle.flex = 1
  }

  let col = {
    ...column,
    cellStyle,
    headerCellStyle
  }
  return col
}

/*
Как получать события по сети для каждого компонента?
*/

const composeColumnDefs = (t, appState, mandatoryAck) => {
  if (mandatoryAck) {
    return composeMandatoryAckColumns(t, appState)
  }

  return [
    {
      id: 0,
      title: t('eventTable.columns.startTime'),
      widthType: 'time',
      valueGetter: ({ row }) => {
        const { startTime } = row
        return dateToString(startTime)
      }
    },
    {
      id: 1,
      title: t('eventTable.columns.message'),
      widthType: 'any',
      valueGetter: ({ row }) => row.message,
      styleGetter: row => resolveEventMessageStyle(appState, row)
    },
    {
      id: 2,
      title: t('eventTable.columns.acked'),
      widthType: 'content',
      valueGetter: ({ row }) => row.acked? t('eventTable.columns.acked'): ''
    }
  ]
}

const composeMandatoryAckColumns = (t, appState) => ([
  {
    id: 3,
    columnId: 'actualMark',
    sortable: true,
    title: '',
    widthType: 'px',
    width: 20,
    render: ({ row }) => {
      if (row.isActual) {
        return <svg viewBox='0 0 16 16' width="14" height="14">
            <circle cx={8} cy={8} r={8} fill="dodgerblue" />
          </svg>
      }

      return ''
    }
  },
  {
    id: 0,
    title: t('eventTable.columns.startTime'),
    widthType: 'time',
    valueGetter: ({ row }) => {
      const { startTime } = row
      return dateToString(startTime)
    }
  },
  {
    id: 1,
    title: t('eventTable.columns.message'),
    widthType: 'any',
    valueGetter: ({ row }) => row.message,
    styleGetter: row => resolveEventMessageStyle(appState, row)
  },
  {
    id: 4,
    title: t('eventTable.columns.endTime'),
    widthType: 'time',
    valueGetter: ({ row }) => {
      const { endTime } = row
      return dateToString(endTime)
    }
  },
  {
    id: 2,
    title: t('eventTable.columns.acked'),
    widthType: 'content',
    valueGetter: ({ row }) => row.acked? t('eventTable.columns.acked'): ''
  }
])

const resolveEventMessageStyle = (appState, event) => {
  const { channel } = event
  if (!channel) {
    return null
  }

  const cat = selectEventCatByID(appState, channel.eventCatID)
  if (!cat) {
    return null
  }

  const { bitwise, optionsByEventTypeMap, type } = cat
  let options = null

  switch (type) {
    case 'bitwise':
      if (event.type === EventType.badQuality) {
        options = optionsByEventTypeMap[event.type.name]
      } else {
        const { onView, offView } = bitwise
        options = event.value === 1
          ? onView
          : offView
      }

      break
    default:
      options = optionsByEventTypeMap[event.type.name]
  }

  if (!options) {
    return null
  }

  const { textStyle } = options
  const style = {
    color: options.textColor,
    backgroundColor: options.backgroundColor,
    fontStyle: textStyle.italic? 'italic': undefined,
    fontWeight: textStyle.bold? 'italic': undefined
  }

  return style
}

const dateToString = (value) => {
  return value
    ? `${value.toLocaleDateString()} ${value.toLocaleTimeString()}`
    : ""
}