import { MnemoControl } from './controls'
import { DOM } from '@sl/utils'
import { getMnemoContent } from 'services/webServer'
import { decodeMnemo } from 'mnemo/component'
import client from 'services/client'
import { RequestSubject } from 'services/NetClient'
import { isDev, tr } from 'utils/common'
import Mnemo from 'mnemo/mnemo'
import FallbackView from 'GVL/fallbackView'
import { GVLMath, ViewportStretch } from 'GVL/types'
import { decodeVariableLine, isTemplateMnemoPath, transpose } from 'mnemo/templates'

let _store

export const injectStore = value => {
  _store = value
}

export default class MnemoContainer extends MnemoControl {
  constructor() {
    super()
    this._keepDomRootByClient = true
    this._mnemoPath = ''

    this.state = {
      status: 'idle',
      statusText: '',
    }

    this.$fallback = new FallbackView()
    this.addControl(this.$fallback)

    this.width = 300
    this.height = 150
    this.variables = []
  }

  dispose() {
    this._closeWindow()
    super.dispose()
  }

  get mnemoPath() { return this._mnemoPath }
  set mnemoPath(value) { this._setProp('mnemoPath', value) }

  get variableLine() { 
    return this.variables.map(it => `${it.name}=${it.value}`).join(';')
  }

  set variableLine(value) {
    this.variables = decodeVariableLine(value)
  }

  initDomRoot() {
    const $root = DOM('section', 'gvl-control')
    this._domRoot = $root.node

    const $con = $root.div('gvl-container')
    const $box = $con.div('gvl-scale-box')

    this.domElements = { $box, $con }
  }

  _closeWindow() {
    const { $box } = this.domElements
    $box?.clear()

    this._window?.dispose()
    this._window = null
  }

  _propertyChanged(name) {
    super._propertyChanged(name)

    const { $con } = this.domElements

    switch (name) {
      case 'width':
      case 'height':
        $con?.setStyles({
          width: `${this._width}px`,
          height: `${this._height}px`
        })
        break
      case 'mnemoPath':
        this._fetchMnemo()
        break
    }
  }

  _getChildAttachElement(value) {
    if (value.typeName === 'Mnemo') {
      return this.domElements.$box.node
    }

    return this._domRoot
  }

  async _fetchMnemo() {
    this._closeWindow()

    const { mnemoPath } = this
    if (mnemoPath === '')
      return

    const ctrlPath = this.collectControlPath()

    const depthLevel = ctrlPath
      .filter(it => it !== this && it.typeName === 'MnemoContainer')
      .length

    if (depthLevel >= 1) {
      this.state.status = 'error'
      this.state.statusText = tr('mnemoContainer.depthLevelExceed')
      this.requestUpdate()
      return
    }

    this.state.status = 'fetching'
    this.requestUpdate()

    try {
      let { data: dto } = await getMnemoContent(mnemoPath)

      const isTemplate = isTemplateMnemoPath(mnemoPath)

      // isDev && console.log(isTemplate? `${mnemoPath} is template`: `${mnemoPath} is simple`);

      if (isTemplate) {
        const appState = _store.getState()
        dto = transpose(dto, this.variables, appState)
      }

      this._closeWindow()
      
      const lMnemo = new Mnemo()
      this._window = lMnemo
      this.addControl(this._window)
      decodeMnemo(lMnemo, dto, mnemoPath)

      const channelIDs = lMnemo.getUsedTags()
      const needSubscribe = channelIDs.length > 0

      if (needSubscribe) {
        await client.post(RequestSubject.SubscribeChannels, { channelIDs })
      }

      lMnemo.on('dispose', () => {
        this._window = null

        if (needSubscribe) {
          client.delete(RequestSubject.SubscribeChannels, { channelIDs })
        }
      })

      this.state.status = 'idle'
      this.requestUpdate()
    } catch (error) {
      const errorText = errorToText(error)

      this.state.status = 'error'
      this.state.statusText = tr('mnemoContainer.fetchError', {
        mnemoPath,
        errorText
      })
      this.requestUpdate()
    }
  }

  render() {
    this._stretchViewport()

    const hasError = this.state.status === 'error'

    const { $fallback } = this

    $fallback.setBounds({ left: 0, top: 0, width: this._width, height: this._height })
    $fallback.props = {
      isPending: !hasError,
      text: this.state.statusText,
    }
    $fallback.visible = this.state.status !== 'idle'
  }

  _stretchViewport() {
    const { $box } = this.domElements

    const window = this._window
    if (!window) {
      $box.setStyle('transform', null)
      return
    }

    switch (window.viewportStretch) {
      case 'scale':
      case ViewportStretch.Scale: {
          let scaleX = GVLMath.sameValue(window.width, 0, 1e-5)
            ? 1
            : this._width / window.width

          let scaleY = GVLMath.sameValue(window.height, 0, 1e-5)
            ? 1
            : this._height / window.height

          $box.setStyle('transform', `scale(${scaleX}, ${scaleY})`)
        }
        break

      case 'fill':
      case ViewportStretch.Fill:
        $box.setStyle('transform', null)
        window.width = this._width
        window.height = this._height
        break

      default:
        $box.setStyle('transform', null)
    }
  }
  
}

const errorToText = (error) => 
  error.response?.status === 404
    ? tr('mnemoContainer.mnemoNotFound')
    : error.toString()
