import { NoTagID, Quality, formatValueAsSummary } from 'utils/common'
import { MnemoControl } from './controls'
import { GVLFigureView } from 'GVL/figureView'
import { GVLTextBlock } from 'GVL/text'
import { GVLRect } from 'GVL/primitives'
import { isInRange } from 'std/math'
import { BrushKind, StrokeStyle, FontStyle } from 'GVL/elements'
import { GVLImage } from 'GVL/image'
import { SLBadQualityCover } from './childControls'

class StateView extends MnemoControl {
  constructor() {
    super()

    this._blinked = false
    this._keepDomRootByClient = true

    this.$figureView = new GVLFigureView()
    this.addControl(this.$figureView)

    this.$image = new GVLImage()
    this.addControl(this.$image)

    this.$rect = new GVLRect()
    this.addControl(this.$rect)

    this.$textBlock = new GVLTextBlock()
    this.addControl(this.$textBlock)

    this.$qualityCover = new SLBadQualityCover()
    this.addControl(this.$qualityCover)

    this.channelSource = null
    this.states = []
    this.tagID = NoTagID
  }

  dispose() {
    this._bindCancel && this._bindCancel()
    this._bindCancel = null
  }

  blink(blinked) {
    this._blinked = blinked
    this.requestUpdate()
  }

  initDomRoot() {
    this.defineRoot('div')
  }

  render() {
    const { $textBlock: tb, $figureView: fw, $rect: rect, $image: img, $qualityCover } = this
    const clientBounds = { left: 0, top: 0, width: this.width, height: this.height }

    let channel = this.channelSource?.getByID(this.tagID)
    $qualityCover.visible = channel && channel.quality !== Quality.Good
    if ($qualityCover.visible) {
      $qualityCover.setBounds(clientBounds)
    }

    const state = channel? this._getState(channel): undefined
    if (!state) {
      fw.uri = ''
      fw.visible = false
      rect.visible = false
      img.visible = false
      tb.visible = false
      
      return
    }

    if (state.blinkEnabled && this._blinked && !$qualityCover.visible) {
      fw.visible = false
      rect.visible = false
      img.visible = false
      tb.visible = false
      
      return
    }

    const { image, text } = state

    img.visible = image.fileName !== ''
    if (img.visible) {
      img.fileName = image.fileName
      img.pictureRotateAngle = image.pictureRotateAngle
      img.horzAlign = image.horzAlign
      img.vertAlign = image.vertAlign
      img.stretch = image.stretch
      img.setBounds(clientBounds)
    }

    fw.uri = state.figureURI
    fw.visible = fw.uri !== ''
    fw.width = this.width
    fw.height = this.height

    rect.visible = true

    if (rect.visible) {
      rect.setBounds(clientBounds)
      const { fill, stroke } = rect
      
      if (state.hasBackground) {
        fill.kind = BrushKind.Solid
        fill.color = state.backgroundColor
      } else {
        fill.kind = BrushKind.None
      }

      stroke.color = state.borderColor
      stroke.width = state.borderWidth
      stroke.style = StrokeStyle.Solid
    }

    tb.visible = text !== ''
    if (tb.visible) {
      tb.color = text.color
      tb.font.family = text.font.family
      tb.font.size = text.font.size
      
      tb.font.style = FontStyle.parseSet(text.font.style)
      tb.horzAlign = text.horzAlign
      tb.vertAlign = text.vertAlign
      tb.wrapping = false
      
      tb.text = this._composeStateText(state, channel)
      tb.width = this.width
      tb.height = this.height
    }
  }

  _composeStateText(state, channel) {
    const { text } = state

    if (!text.showChannelValue || !channel) {
      return text.text
    }

    const viewValue = formatValueAsSummary(channel, channel.value)
    return `${text.text} ${viewValue}`
  }

  _loaded() {
    super._loaded()

    if (this.tagID === NoTagID) {
      return
    }

    this._bindCancel = this.channelSource?.listen(this.tagID, this._changeHandler)
  }

  _changeHandler = () => {
    this.requestUpdate()
  }

  _getState(channel) {
    const { value } = channel

    for (let state of this.states) {
      const { low, high } = state.range
      if (isInRange(low, high, value, 0.00001)) {
        return state
      }
    }
  }
}



export default StateView