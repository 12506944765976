
export const ValueType = Object.freeze({
  AlphaColor: { name: 'AlphaColor' },
  Integer: { name: 'Integer' },
  Single: { name: 'Single' },
  Boolean: { name: 'Boolean' },
  Enum: { name: 'Enum' },
  EnumSet: { name: 'EnumSet' },
  Instance: { name: 'Instance' },
  PointF: { name: 'PointF' },
  String: { name: 'String' }
})