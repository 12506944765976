import { GVLControl, BrushKind } from './elements'
import { svgNS, Quality, GVLMath, ViewportStretch } from './types'
import { EventorOn } from 'std/eventor'
import { SLParser, InstanceSource } from '../std/expression'
import { stringFirstToLowerCase } from './utils'
import { Color } from 'std/utils'
import { decode as decodeUniValue, pack as packUniValue } from '@sl/utils/src/uni-value'
import { ActionProps } from './buttons'
import { mat2d, vec2 } from 'gl-matrix'
import { degreesToRadians } from 'std/math'
import { mat2d as sl_mat2d } from 'utils/gl-matrix'
import { tr } from 'utils/common'

export const ArgumentType = Object.freeze({
  Number: { name: 'Number' },
  String: { name: 'String' },
  Boolean: { name: 'Boolean' },
  Color: { name: 'Color' },
  Action: { name: 'Action' },
  MnemoPath: { name: 'MnemoPath' },
  Tag: { name: 'Tag' },
  FontFamily: { name: 'FontFamily' },
  HorzAlign: { name: 'HorzAlign' },
  VertAlign: { name: 'VertAlign' },
  name: 'ArgumentType'
})

export class GVLArgument extends EventorOn {
  constructor() {
    super()
    this.id = -1
    this.alarmStatus = 1
    this.isBound = false
    this.name = ''
    this.hiAlarm = 0
    this.hiHiAlarm = 0
    this.highScale = 0
    this.loAlarm = 0
    this.loLoAlarm = 0
    this.lowScale = 0
    this.units = ''
    this.variableFormat = 2
    this.value = 0
    this.quality = Quality.Bad
    this.typ = ArgumentType.Number
    this.defaultValue = 'number:null:'

    this._formatOptions = {
      minimumFractionDigits: this.variableFormat,
      maximumFractionDigits: this.variableFormat
    }
  }

  get type() { return this.typ }
  set type(value) { this.typ = value }

  get viewValue() {
    return this.formattedValue()
  }

  formattedValue() {
    if (typeof this.value === 'string') {
      return '' + this.value
    }

    this._formatOptions.minimumFractionDigits = this.variableFormat
    this._formatOptions.maximumFractionDigits = this.variableFormat

    return this.value.toLocaleString(tr('lang'), this._formatOptions)
  }

  notifyChanged() {
    this.fireEvent('change')
  }
}

export class GVLBinding {
  constructor() {
    this.expression = ''
    this._evaluator = null
    this.targets = []
    this.valueFormat = 2

    this._formatOptions = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }

    this._updateTargets = this._updateTargets.bind(this)
  }

  dispose() {
    this.close()
  }

  close() {
    if (!this._evaluator) {
      return
    }

    for (let place of this._evaluator.places) {
      const { instance } = place
      instance.off('change', this._updateTargets)
    }

    this._evaluator = null
  }

  setUpExpression(value) {
    this.close()

    this._evaluator = value

    for (let place of value.places) {
      const { instance } = place

      instance.on('change', this._updateTargets)
    }

    this._updateTargets()
  }

  _updateTargets() {
    if (!this._evaluator.canEvaluate()) {
      return
    }

    const value = this._evaluator.evaluate()

    this._formatOptions.minimumFractionDigits = this.valueFormat
    this._formatOptions.maximumFractionDigits = this.valueFormat

    let target, targetCount = this.targets.length

    for (let i = 0; i < targetCount; i++) {
      target = this.targets[i]

      target.setValue(value, this._formatOptions)
    }
  }
}

export class GVLPropertySelector {
  constructor() {
    this._element = null
    this._propertyName = ''
    this._endPropertyName = ''
    this._endPropertyValueType = 'number'
    this._endInstance = null
  }

  get element() {
    return this._element
  }

  set element(value) {
    if (this._element !== value) {
      this._element = value

      this._endPropertyName = ''
      this._endInstance = null
    }
  }

  get propertyName() {
    return this._propertyName
  }

  set propertyName(value) {
    if (this._propertyName !== value) {
      this._propertyName = ('' + value).trim()
      
      this._endPropertyName = ''
      this._endInstance = null
    }
  }

  setValue(value, formatOptions) {
    if (!this._element || this._propertyName === '') {
      return
    }

    if (this._endPropertyName === '' || !this._endInstance) {
      this._sureProperty()
    }

    if (!this._endInstance) {
      return
    }

    let newValue = value

    switch(this._endPropertyValueType) {
      case 'string':
        if (typeof value === 'number') {
          newValue = value.toLocaleString(undefined, formatOptions)
        } else {
          newValue = '' + value
        }
        break

      case 'boolean':
        newValue = GVLMath.sameValue(value, 1, 1e-5)
        break

      case 'color': {
          let color = new Color(value)
          newValue = color.toString()
        }
        break

      case 'uniValue': 
        newValue = packUniValue(value)
        break
    }

    this._endInstance[this._endPropertyName] = newValue
  }

  _sureProperty() {
    const pathItems = this._propertyName.split('.')
    const endInstance = this._resolveEndContainer(this._element, pathItems)
    let propertyName = pathItems[pathItems.length - 1]
    propertyName = stringFirstToLowerCase(propertyName)

    if (!endInstance) {
      return
    }

    const value = endInstance[propertyName]
    if (value === undefined) {
      return
    }
    
    this._endInstance = endInstance
    this._endPropertyName = propertyName
    let type = 'number'

    if (endInstance instanceof ActionProps && propertyName === 'value') {
      type = 'uniValue'
    } else {
      type = typeof value
      if (isColorValue(value)) {
        type = 'color'
      }
    }

    this._endPropertyValueType = type
  }

  _resolveEndContainer(object, pathItems) {
    let result = object

    for (let i = 0; i < pathItems.length - 1; i++) {
      var item = pathItems[i]
      item = stringFirstToLowerCase(item)

      result = result[item]

      if (!result || (typeof result !== 'object')) {
        result = null
        break
      }
    }

    return result
  }
}

const isColorValue = value => {
  return typeof value === 'string' && (value.startsWith('#') || value.startsWith('rgba('))
}

class ArgumentSource extends InstanceSource {

  constructor() {
    super()
    this._arguments = null
  }

  getByID(value) {
    for (let argument of this._arguments) {
      if (argument.id === value) {
        return argument
      }
    }

    return null
  }

  set(value) {
    this._arguments = value
  }
}

export class ArgumentService {

  writeValueAsync() {
    return Promise.resolve()
  }
}

export class GVLCustomWindow extends GVLControl {

  constructor() {
    super()
    this._renderRequestId = null
  }

  _updateRequested() {
    if (this._renderRequestId) {
      return
    }

    this._renderRequestId = setTimeout(() => {
      this._renderRequestId = null    
      this.renderControls()
    })
  }
}

export class GVLWindow extends GVLCustomWindow {
  constructor () {
    super()

    this._arguments = []
    this._argumentSource = new ArgumentSource()
    this._argumentSource.set(this._arguments)
    this.bindings = []
    this._parser = new SLParser()
    this._componentId = 'Window'
    this._renderRequestId = null
    this._updateRequestId = null

    this.filePath = ''
    this.fill.kind = BrushKind.Solid
    this.fill.color = '#FFFFFF'
    this.viewportStretch = ViewportStretch.Scale
  }

  dispose() {
    for (let binding of this.bindings) {
      binding.dispose()
    }

    super.dispose()
  }

  get arguments() {
    return this._arguments
  }

  set arguments(value) {
    this._arguments = value
    this._argumentSource.set(this._arguments)
  }

  findArgumentByID(value) {
    return this._arguments.find(it => it.id === value)
  }

  applyArgumentDefaultValues() {
    for(let argument of this._arguments) {
      const { valid, value } = decodeUniValue(argument.defaultValue)
      if (!valid) {
        continue
      }
      
      argument.quality = Quality.Good
      argument.value = value
      argument.notifyChanged()
    }
  }

  initBindings() {
    const parser = this._parser

    for (let binding of this.bindings) {
      if (binding.expression === '') {
        continue
      }

      try {
        let expression = parser.compile(binding.expression, this._argumentSource)
        if (parser.errors.length > 0) {
          console.error(parser.errors)
          continue
        }
  
        binding.setUpExpression(expression)
      } catch (e) {
        console.error(e)
      }      
    }
  }

  initDomRoot() {
    const root = this.defineRoot('div')

    this._svg = document.createElementNS(svgNS, 'svg')
    this._svg.classList.add('gvl-control')
    this._rect = document.createElementNS(svgNS, 'rect')
    this._svg.appendChild(this._rect)

    root.appendChild(this._svg)

    this._fill.bindSvg(this._svg, this._rect)
  }

  _fillChange() {
    super._fillChange()
  }

  _propertyChanged(name, oldValue, newValue) {
    super._propertyChanged(name)

    let value = ''

    switch (name) {
      case 'width':
        value = `${this._width}px`
        this._domRoot.style.width = value
        this._svg.setAttribute('width', value)
        this._rect.setAttribute('width', this._width)
        this._sizeChanged(oldValue, this._height)
        break

      case 'height':
        value = `${this._height}px`
        this._domRoot.style.height = value
        this._svg.setAttribute('height', value)
        this._rect.setAttribute('height', this._height)
        this._sizeChanged(this._width, oldValue)
        break
    }
  }

  _sizeChanged(oldWidth, oldHeight) {
    if (this.loading || this.viewportStretch !== ViewportStretch.Fill) {
      return
    }

    this._applyResize(oldWidth, oldHeight)
  }

  _applyResize(oldWidth, oldHeight) {
    if (oldWidth === 0 || oldHeight === 0) {
      return
    }

    const dx = this._width / oldWidth
    const dy = this._height / oldHeight

    // console.log('scale from', { w: oldWidth, h: oldHeight }, 'to', { w: this.width, h: this.height })

    for (let child of this.controls) {
      stretchResizeAroundCenter(child, dx, dy)
    }
  }
}

const stretchResizeAroundCenter = (control, dx, dy) => {
  let m = mat2d.create()

  const angle = degreesToRadians(control.rotateAngle)
  let ratio = vec2.fromValues(dx, dy)

  let topLeft = vec2.fromValues(control.left, control.top)
  let bottomRight = vec2.fromValues(control.left + control.width, control.top + control.height)

  const rx = control.left + control.rotateCenterX * control.width
  const ry = control.top + control.rotateCenterY * control.height

  let rotateCenter = vec2.fromValues(rx, ry)

  m = sl_mat2d.rotateAtPoint(m, angle, rx, ry)

  vec2.transformMat2d(topLeft, topLeft, m)
  vec2.transformMat2d(bottomRight, bottomRight, m)

  vec2.multiply(topLeft, topLeft, ratio)
  vec2.multiply(bottomRight, bottomRight, ratio)
  const newRotateCenter = vec2.multiply(vec2.create(), rotateCenter, ratio)

  m = sl_mat2d.rotateAtPoint(mat2d.create(), -angle, newRotateCenter[0], newRotateCenter[1])

  vec2.transformMat2d(topLeft, topLeft, m)
  vec2.transformMat2d(bottomRight, bottomRight, m)

  const newX = topLeft[0]
  const newY = topLeft[1]

  const newW = bottomRight[0] - topLeft[0]
  const newH = bottomRight[1] - topLeft[1]

  // console.log('control from', { dx, dy }, { x: control.left, y: control.top, w: control.width, h: control.height }, 'to',
    // { x: newX, y: newY, w: newW, h: newH })

  control.left = newX
  control.top = newY
  control.width = newW
  control.height = newH
}