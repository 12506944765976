import structuredClone from '@ungap/structured-clone'
import { NoTagID, isDev, tr } from 'utils/common'
import { selectChannelByID, selectChannelByName } from 'features/live/liveSlice'

export const decodeVariableLine = (line) => {
  return line.split(';')
    .map(it => it.split('='))
    .filter(it => it.length === 2)
    .map(([name, value]) => ({ name, value }))
}

export const isTemplateMnemoPath = (path) => [...path.matchAll(/%([\w\p{L}\s]+)%/gu)].length > 0

export const transpose = (mnemo, variables, appState) => {

  mnemo = structuredClone(mnemo)

  const isPropTagID = (propName) => propName === 'tag' || propName === 'tagID'

  const mapTagID = (srcTagID) => {

    if (srcTagID === NoTagID) {
      return [srcTagID]
    }

    const channel = selectChannelByID(appState, srcTagID)
    if (!channel)
      return [undefined, tr('mnemoTranspose.noTemplateTag', { srcTagID })]

    let name = channel.name

    for (let lv of variables) {
      let literal = `%${lv.name}%`

      if (name.includes(literal)) {
        name = name.replaceAll(literal, lv.value)
      }
    }

    const newChannel = selectChannelByName(appState, name)
    if (!newChannel) {
      return [undefined, tr('mnemoTranspose.noTargetTag', { name })]
    }

    return [newChannel.ID]
  }

  const mapText = (srcText) => {
    let text = srcText

    for (let lv of variables) {
      let literal = `%${lv.name}%`

      if (text.includes(literal)) {
        text = text.replaceAll(literal, lv.value)
      }
    }

    return text
  }

  let errors = []

  const handleObjectProps = (object) => {
    for (const key of Object.keys(object)) {
      const value = object[key]

      const T = typeof value

      switch (T) {
        case 'object':
          if (Array.isArray(value)) {
            handleChildObjects(value)
          } else {
            handleObjectProps(value)
          }
          break

        case 'number':
          {
            if (!isPropTagID(key))
              continue

            const [newID, error] = mapTagID(value)
            if (error) {
              if (!errors.includes(error)) {
                errors.push(error)
              }
            } else {
              object[key] = newID
            }
          }
          break

        case 'string':
          if (value.includes('%')) {
            object[key] = mapText(value)
          }
          break
      }
    }
  }

  function handleChildObjects(children) {
    for (const child of children) {
      if (typeof child !== 'object') {
        continue
      }

      handleObjectProps(child)

      // Пока решим что у объект нет второго уровня, на который необходимо было бы спуститься
    }
  }

  const lbl = 'Transpose mnemo'
  // isDev && console.time(lbl)
  try {
    handleChildObjects(mnemo.children)
  } finally {
    // isDev && console.timeEnd(lbl)
  }

  if (errors.length) {
    throw new Error(errors.join('\n'))
  }

  return mnemo
}