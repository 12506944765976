import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import { useSelector } from 'react-redux'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const SplashView = () => {
  const { t } = useTranslation();
  const stage = useSelector(state => state.splash.stage)
  const error = useSelector(state => state.splash.fetchError)

  if (error !== "") {
    return (
      <Box sx={{
        textAlign: 'center'
      }}>
        <i className="fa fa-exclamation-triangle fa-3x" style={{ color: 'red' }}></i>
        <Typography variant='subtitle1' color="error">{t(`splash.error`)}</Typography>
      </Box>
    )
  }

  return (
    <Box sx={{
      textAlign: 'center'
    }}>
      <CircularProgress />
      <Typography variant='subtitle1'>{t(`SplashStage.${stage}`)}</Typography>
    </Box>
  )
}

export default SplashView