import { createSlice } from '@reduxjs/toolkit'
import { ConnectStatus } from 'services/NetClient'
import { getEndpoints, getServerInfo, validateUser } from 'services/webServer'
import { setPorts } from './apisSlice'
import { setAuthRequired, setUser } from './authSlice'
import { getClientId } from 'std/fingerprint'
import debounce from 'lodash.debounce'
import Cookie from 'js-cookie'

export const startReconnectCountdown = (client) => (dispatch, getState) => {
  let handle = null

  dispatch(startReconnectTime())

  handle = setInterval(() => {
    dispatch(decrementReconnectTime())

    const state = getState()
    const { client: { reconnectTime, kickReason } } = state

    if (kickReason !== '') {
      clearInterval(handle)
    } else if (reconnectTime <= 0) {
      clearInterval(handle)
      client.connect()
    }
  }, 1000)
}

export const startUpConnection = (client) => async (dispatch, getState) => {

  client.setAccessTokenSource(() => {
    const { auth } = getState()
    
    if (auth.user) {
      return auth.user.token
    }

    return null
  })

  client.setClientIdSource(getClientId)

  let endpoints = null
  try {
    const response = await getEndpoints()
    endpoints = response.data
    client.setPort(endpoints.netClientPort)
    dispatch(setPorts(endpoints.resourcePort))
  } catch (e) {
    dispatch(getEndpointsFailed())
    throw e
  }

  const res = await getServerInfo()
  const info = res.data
  dispatch(setAuthRequired(info.authRequired))

  dispatch(considerConnect(client))

  const { auth } = getState()

  if (auth.required) {
    window.addEventListener('storage', debounce(() => {
      const storageToken = localStorage.getItem("token")
      const { auth } = getState()
      const reduxToken = auth.user? auth.user.token: ""

      if (storageToken && storageToken !== reduxToken) {
        dispatch(considerConnect(client))
      } else if (!storageToken) {
        dispatch(setUser(null))
      }
    }, 500))
  }
}

const considerConnect = client => async (dispatch, getState) => {
  const { auth } = getState()

  if (auth.required) {
    const consent = await validateUser()
    if (!consent || consent.status !== 'ok') {
      return
    }

    const { user } = consent
    if (!user) {
      return
    }

    const token = Cookie.get('token')
    const username = user.username

    dispatch(setUser({
      username, token
    }))
  }

  client.connect()
}

const initialState = {
  connectStatus: ConnectStatus.Connecting,
  textError: '',
  kickReason: '',
  wasAnyConnection: false,
  reconnectTime: 5
}

const slice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    clientKicked: (state, action) => {
      state.kickReason = action.payload
    },
    decrementReconnectTime: (state) => {
      state.reconnectTime = Math.max(0, state.reconnectTime - 1)
    },
    getEndpointsFailed: (state) => {
      state.connectStatus = ConnectStatus.Disconnected
      state.textError = 'errors.getEndpointsFailed'
    },
    setConnectStatus: (state, action) => {
      state.connectStatus = action.payload

      if (state.connectStatus === ConnectStatus.Connected) {
        state.wasAnyConnection = true
      }
    },
    startReconnectTime: (state) => {
      state.reconnectTime = initialState.reconnectTime
    }
  }
})

export const { 
  clientKicked, decrementReconnectTime, getEndpointsFailed, setConnectStatus,
  startReconnectTime
} = slice.actions

export default slice.reducer;