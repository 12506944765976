import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  resource: {
    port: 23501
  }
}

const slice = createSlice({
  name: 'apis',
  initialState,
  reducers: {
    setPorts: (state, action) => {
      const port = action.payload
      state.resource.port = port
    }
  }
})

export const { setPorts } = slice.actions

export default slice.reducer