import { GVLControl } from 'GVL/elements'
import { SVG } from '@svgdotjs/svg.js'

export class RuptureCap extends GVLControl {
  constructor() {
    super()

    this._keepDomRootByClient = true
    this._pipeSize = 8
    this.primaryColor = '#ffffff'
    this.secondaryColor = '#ffffff'
  }

  get pipeSize() { return this._pipeSize }
  set pipeSize(value) { this._setProp('pipeSize', value) }

  initDomRoot() {
    this.$svg = SVG()
    this.$svg.addClass('gvl-control')
    this._domRoot = this.$svg.node
  }

  render() {
    const {$svg} = this
    $svg.clear()

    const w = 10
    const secondDepth = 6
    const firstDepth = 8
    const yStep = this._pipeSize / 8

    const data = `M0,0 l${w},0 `
      + `l${-firstDepth},${yStep}`
      + `l${secondDepth},${yStep} l${-secondDepth},${yStep} `
      + `l${secondDepth},${yStep} l${-secondDepth},${yStep} `
      + `l${secondDepth},${yStep} l${-secondDepth},${yStep} `
      + `l${firstDepth},${yStep} `
      + `l${-w},0 `
      + `z`

    const $gradient = $svg.gradient('linear', add => {
      add.stop(0, this.primaryColor)
      add.stop(0.5, this.secondaryColor)
      add.stop(1, this.primaryColor)
      add.from(0, 0).to(0, 1)
    })

    const $path = $svg.path(data)
    $path.fill($gradient)

    const box = $path.bbox()

    this.$svg.size(box.w, box.h)

    this._domRoot.style.width = `${box.w}px`
    this._domRoot.style.height = `${box.h}px`

    this._width = box.w
    this._height = box.h
    this._updateTransform()
  }
}