import { EventorOn } from 'std/eventor'
import { floatEquals } from 'std/math'
import { PointF, svgNS } from './types'
import { ValueType } from './prop-types'

export class GVLElement extends EventorOn {
  constructor() {
    super()
    this._elements = []
    this._owner = null
    this._loading = false
    this.name = ''
  }

  dispose() {
    this._owner = null

    const elementCount = this._elements.length
    for (let i = 0; i < elementCount; i++) {
      var element = this._elements[i]
      element.dispose()
    }

    super.dispose()
  }

  addElement(value) {
    value.owner?.removeElement(value)

    this._elements.push(value)
    value._owner = this
    value._loading = this._loading
    value._ownerChanged()
  }

  findByName(value) {
    let result = null

    for (let i = 0; i < this._elements.length; i++) {
      var element = this._elements[i]

      if (element.name === value) {
        return element
      } else {
        result = element.findByName(value)

        if (result) {
          break
        }
      }
    }

    return result
  }

  findControl(predicate) {
    for (let child of this._controls) {
      if (predicate(child)) {
        return child
      }
    }

    return null
  }

  removeElement(value) {
    if (value.owner !== this) {
      throw new Error('[GVLElement.removeElement] Passed value is not owned by current GVLElement')
    }

    const removeIndex = this._elements.indexOf(value)
    if (removeIndex < 0) {
      return
    }

    this._elements.splice(removeIndex, 1)
    value._owner = null
  }

  get owner() { return this._owner }

  get loading() { return this._loading }
  set loading(value) {
    this._loading = value

    this._loadingChanged()

    if (!this._loading) {
      this._loaded()
    }
    
    for (let childElement of this._elements) {
      childElement.loading = this._loading
    }
  }

  _ownerChanged() {}

  _loadingChanged() {}

  _loaded() {}
}

export const BrushKind = Object.freeze({
  None: {name: 'None'},
  Solid: {name: 'Solid'},
  Gradient: {name: 'Gradient'},
  Image: {name: 'Image'},
  name: 'BrushKind'
})

export class ColorStop {
  constructor(color = '#000000', position = 0) {
    this.color = color
    this.position = position
  }
}

export const GradientKind = Object.freeze({
  Linear: 'Linear',
  Radial: 'Radial',
})

export class Gradient {
  constructor() {
    this.colorStops = []
    this._centerX = 0.5
    this._centerY = 0.5
    this._radius = 0.5
    this._kind = GradientKind.Linear

    this._startAt = new PointF(0.5, 0)
    this._endAt = new PointF(0.5, 1)
  }

  _doChange() {
    this.onChange && this.onChange()
  }

  get centerX() { return this._centerX }
  set centerX(value) { this._centerX = value; this._doChange(); }

  get centerY() { return this._centerY }
  set centerY(value) { this._centerY = value; this._doChange(); }

  get endAt() { return this._endAt }
  set endAt(value) { this._endAt = value; this._doChange(); }

  get kind() { return this._kind }
  set kind(value) { this._kind = value; this._doChange(); }

  get radius() { return this._radius }
  set radius(value) { this._radius = value; this._doChange(); }

  get startAt() { return this._startAt }
  set startAt(value) { this._startAt = value; this._doChange(); }
}

export const BrushImageStretch = Object.freeze({
  None: { name: 'None' },
  Fill: { name: 'Fill' }
})

export class BrushImage {
  constructor() {
    this._fileName = ''
    this._stretch = null
    this.onChange = null
  }

  dispose() {
    this._fileName = ''
    this.onChange = null
  }

  get fileName() { return this._fileName }
  set fileName(value) {
    if (this._fileName !== value) {
      this._fileName = value
      this._doChange()
    }
  }

  get stretch() { return this._stretch }
  set stretch(value) {
    if (this._stretch !== value) {
      this._stretch = value
      this._doChange()
    }
  }

  _doChange() {
    this.onChange && this.onChange()
  }
}


export class FillBrush {
  constructor() {
    this._applyRequest = null
    this.basePathSource = () => ''
    this._color = '#000000'
    this._leveling = false
    this._levelProcent = 0.5
    this.onChange = null
    this.image = new BrushImage()
    this.image.onChange = this._imageChange.bind(this)
    this.gradient = new Gradient()
    this._kind = BrushKind.Solid
  }

  dispose() {
    this.image.dispose()
    this._defs = null
    this._svg = null
    this._svgElement = null

    if (this._applyRequest) {
      clearTimeout(this._applyRequest)
      this._applyRequest = null
    }
    
    this.onChange = null
  }

  apply(controlId, bounds) {
    if (!this._isSvgBound()) {
      return
    }

    if (this._leveling || this._kind === BrushKind.Gradient || this._kind === BrushKind.Image) {
      this._sureCleanDefs()
    }

    if (this._leveling) {
      this._applyLeveling(controlId)
    } else {
      this._svgElement.removeAttribute('clip-path')
    }

    switch (this._kind) {
      case BrushKind.None:
        this._svgElement.setAttribute('fill', 'transparent')
        break

      case BrushKind.Solid:
        this._svgElement.setAttribute('fill', this._color)
        break

      case BrushKind.Gradient:        
        this._createGradient(controlId, bounds)
        this._svgElement.setAttribute('fill', `url(#${controlId}__local-gradient)`)
        break

      case BrushKind.Image:
        this._createImagePattern(controlId)
        this._svgElement.setAttribute('fill', `url(#${controlId}__image-pattern)`)
        break
    }
  }

  _applyLeveling(controlId) {
    const clipRect = document.createElementNS(svgNS, 'rect')
    this._levelClipRect = clipRect

    clipRect.setAttribute('x', 0)
    clipRect.setAttribute('width', 1)
    this._updateLevelInDOM()

    const clipPath = document.createElementNS(svgNS, 'clipPath')
    clipPath.setAttribute('id', `${controlId}__level-clippath`)
    clipPath.setAttribute('clipPathUnits', 'objectBoundingBox')
    clipPath.append(clipRect)

    this._defs.append(clipPath)

    this._svgElement.setAttribute('clip-path', `url(#${controlId}__level-clippath)`)
  }

  _createGradient(controlId, bounds) {
    const { width: w, height: h } = bounds
    const { _defs: defs, gradient } = this

    switch (gradient.kind) {
      case GradientKind.Linear: {
        const element = document.createElementNS(svgNS, 'linearGradient')
        element.setAttribute('id', `${controlId}__local-gradient`)
        element.setAttribute('gradientUnits', 'userSpaceOnUse')
        element.setAttribute('x1', `${gradient.startAt.x*100}%`)
        element.setAttribute('y1', `${gradient.startAt.y*100}%`)
        element.setAttribute('x2', `${gradient.endAt.x*100}%`)
        element.setAttribute('y2', `${gradient.endAt.y*100}%`)

        const colorStops = [...gradient.colorStops]
        colorStops.sort((l, r) => l.position - r.position)

        for (let colorStop of colorStops) {
          const stop = document.createElementNS(svgNS, 'stop')
          stop.setAttribute('offset', `${colorStop.position*100}%`)
          stop.setAttribute('stop-color', colorStop.color)
          
          element.append(stop)
        }

        defs.append(element)
      }

        break

      case GradientKind.Radial: {
        const element = document.createElementNS(svgNS, 'radialGradient')
        element.setAttribute('id', `${controlId}__local-gradient`)
        element.setAttribute('gradientUnits', 'userSpaceOnUse')
        element.setAttribute('cx', `${gradient.centerX*w}`)
        element.setAttribute('cy', `${gradient.centerY*h}`)
        
        const leastSide = Math.min(w, h)
        const r = leastSide * gradient.radius

        const rx = gradient.radius * w
        const ry = gradient.radius * h

        const cx = gradient.centerX*w
        const cy = gradient.centerY*h
        const sx = r !== 0? rx / r : 1
        const sy = r !== 0? ry / r: 1
        element.setAttribute('r', `${r}`)
        element.setAttribute('gradientTransform', `translate(${cx} ${cy}) scale(${sx} ${sy}) translate(${-cx} ${-cy})`)

        const colorStops = [...gradient.colorStops]
        colorStops.sort((l, r) => l.position - r.position)

        for (let colorStop of colorStops) {
          const stop = document.createElementNS(svgNS, 'stop')
          stop.setAttribute('offset', `${colorStop.position*100}%`)
          stop.setAttribute('stop-color', colorStop.color)
          
          element.append(stop)
        }

        defs.append(element)
      }
        break
    }
  }

  _createImagePattern(controlId) {
    const { fileName } = this.image
    if (fileName === '') {
      return
    }

    const image = document.createElementNS(svgNS, 'image')

    if (this.image.stretch === BrushImageStretch.Fill) {
      image.setAttribute('width', '100%')
      image.setAttribute('height', '100%')
      image.setAttribute('preserveAspectRatio', 'none')
    }

    const basePath = this.basePathSource()
    const href = encodeURI(`${basePath}/images/${fileName}`)

    image.setAttribute('href', href)

    const pattern = document.createElementNS(svgNS, 'pattern')
    pattern.setAttribute('id', `${controlId}__image-pattern`)
    pattern.setAttribute('width', 1)
    pattern.setAttribute('height', 1)
    pattern.append(image)

    this._defs.append(pattern)
  }

  _imageChange() {
    if (this._kind === BrushKind.Image) {
      this._doChange()
    }
  }

  _sureCleanDefs() {
    if (!this._defs) {
      this._defs = document.createElementNS(svgNS, 'defs')
      this._svg.insertBefore(this._defs, this._svgElement)
    } else {
      const defs = this._defs
      
      while (defs.firstChild) {
        defs.removeChild(defs.firstChild)
      }
    }
  }

  bindSvg(svg, svgElement) {
    this._svg = svg
    this._svgElement = svgElement
  }

  requestApply(controlId, bounds) {
    if (!!this._applyRequest) {
      return
    }

    this._applyRequest = setTimeout(() => {
      this._applyRequest = null
      this.apply(controlId, bounds)
    })
  }

  _doChange() {
    this.onChange && this.onChange()
  }

  _isSvgBound() {
    return this._svg && this._svgElement
  }

  get color() { return this._color }
  set color(value) {
    if (this._color !== value) {
      this._color = value
      this._doChange()
    }    
  }

  get kind() { return this._kind }
  set kind(value) {
    if (this._kind !== value) {
      this._kind = value
      this._doChange()
    }
  }

  get leveling() { return this._leveling }
  set leveling(value) {
    const newValue = !!value

    if (this._leveling !== newValue) {
      this._leveling = newValue
      this._doChange()
    }
  }

  get levelProcent() { return this._levelProcent }
  set levelProcent(value) {
    if (value >= 0 && value <= 1) {
      if (this._levelProcent !== value) {
        this._levelProcent = value
        this._updateLevelInDOM()
      }
    }
  }

  _updateLevelInDOM() {
    if (!this._levelClipRect) {
      return
    }

    this._levelClipRect.setAttribute('y', 1 - this._levelProcent)
    this._levelClipRect.setAttribute('height', this._levelProcent)
  }
}

export class GVLFont {
  constructor() {
    this._family = 'Segoe UI'
    this._size = 11
    this._style = []
  }

  dispose() {
    this.onChange = null
  }

  _doChange() {
    this.onChange && this.onChange()
  }

  acceptProps({ family, size, style }) {
    this._family = family ?? this.family
    this._size = size ?? this.size
    this._style = style === undefined? []: [...style]
    this._doChange()
  }

  get family() { return this._family }
  set family(value) {
    if (this._family !== value) {
      this._family = value
      this._doChange()
    }
  }

  get size() { return this._size }
  set size(value) {
    const newValue = +value
    if (this._size !== newValue) {
      this._size = newValue
      this._doChange()
    }
  }

  get style() { return this._style }
  set style(value) {
    if (Array.isArray(value)) {
      this._style = value
      this._doChange()
    }
  }

  toCSSValue() {
    let value = ''
    value += this._style.indexOf(FontStyle.fsBold) >= 0? 'bold ': ''
    value += this._style.indexOf(FontStyle.fsItalic) >= 0? 'italic ': ''
    value += `${this._size}px ${this._family}`

    return value
  }
}

export const FontStyle = Object.freeze({
  fsBold: {name: 'fsBold'},
  fsItalic: {name: 'fsItalic'},
  name: 'FontStyle',
  parseSet(source) {
    return source.split(',')
      .map(it => FontStyle[it])
      .filter(it => typeof it === 'object')
  }
})

GVLFont.gvlPropTypes = {
  style: {
    type: ValueType.EnumSet,
    enumType: FontStyle,
  }
}

export const StrokeStyle = Object.freeze({
  Solid: {name: 'Solid'},
  Dash: {name: 'Dash'},
  name: 'StrokeStyle'
})

export class Stroke {
  constructor() {
    this._color = '#000000'
    this._roundCap = false
    this._style = StrokeStyle.Solid
    this._width = 0
    this.onChange = null
  }

  dispose() {
    this.onChange = null
  }

  _doChange() {
    this.onChange && this.onChange()
  }

  applyToSVGElement(rect, element) {
    // const maxWidth = this.getMaxWidthForRect(rect)
    const maxWidth = this._width

    element.setAttribute('stroke', this._color)
    element.setAttribute('stroke-width', maxWidth)

    const dashLength = 10
    const gapLength = 3

    if (this._roundCap) {
      element.setAttribute('stroke-linecap', 'round')
    } else {
      element.removeAttribute('stroke-linecap')
    }

    switch(this._style) {
      case StrokeStyle.Dash:
        element.setAttribute('stroke-dasharray', `${this._width * dashLength} ${this._width * gapLength}`)
        break
      default:
        element.removeAttribute('stroke-dasharray')
    }
  }

  getMaxWidthForRect(rect) {
    const minSideLength = Math.min(rect.width, rect.height)
    return Math.min(minSideLength, this._width)
  }

  get color() { return this._color }
  set color(value) {
    if (this._color !== value) {
      this._color = value
      this._doChange()
    }    
  }

  get roundCap() { return this._roundCap }
  set roundCap(value) {
    if (this._roundCap !== value) {
      this._roundCap = value
      this._doChange()
    }
  }

  get style() { return this._style }
  set style(value) {
    if (this._style !== value) {
      this._style = value
      this._doChange()
    }
  }

  get width() { return this._width }
  set width(value) {
    if (this._width !== value) {
      this._width = value
      this._doChange()
    }
  }
}

const newUniqueId = (prefix) => {
  let map = {}

  return () => {
    let id = map[prefix]?? 1
    let idd = `${prefix}${id++}`
    map[prefix] = id
    return idd
  }
}

const newControlId = newUniqueId('gvl-c-')

export class GVLCollection {}

export class GVLControl extends GVLElement {

  constructor() {
    super()
    this._controls = []
    this._parent = null
    this._keepDomRootByClient = false
    this._left = 0
    this._top = 0
    this._width = 0
    this._height = 0
    this._squareSize = false
    this._visible = true
    this._dirty = true
    this._rotateAngle = 0
    this._rotateCenterX = 0.5
    this._rotateCenterY = 0.5
    this._controlId = newControlId()
    this.minWidth = 0
    this.minHeight = 0

    this._fill = new FillBrush()
    this._fill.kind = BrushKind.None
    this._fill.onChange = this._fillChange.bind(this)
    this._fill.basePathSource = () => {
      const c = this.getWindow(this)
      return !!c? c.filePath: ''
    }

    this._stroke = new Stroke()
    this._stroke.width = 0
    this._stroke.onChange = this._strokeChange.bind(this)

    this.initDomRoot()
    this._updateTransform()
  }

  dispose() {
    this._fill.dispose()
    this._stroke.dispose()
    this._parent = null

    super.dispose()
  }

  addControl(value) {
    if (value.parent) {
      value.parent.removeControl(value)
      value._parent = null
      value._parentChanged()
    }

    this.addElement(value)

    this._controls.push(value)
    value._parent = this
    value._parentChanged()

    const { parentNode } = this._domRoot

    // Если у текущего компонента есть родительский узел в DOM, значит Control.attach вызван ранее
    if (parentNode) {
      const attachDomParent = this._getChildAttachElement(value)
      value.attach(attachDomParent)
    }
  }

  get controls() {
    return this._controls
  }

  get typeName() {
    return this.__proto__.constructor.name
  }

  getDomRoot() {
    return this._domRoot
  }

  defineRoot(tagName) {
    this._domRoot = document.createElement(tagName)
    this._domRoot.className = 'gvl-control'
    return this._domRoot
  }

  attach(value) {
    this._fill.requestApply(this._controlId, this)
    this.requestUpdate()

    if (!this._domRoot) {
      return
    }
    
    value.append(this._domRoot)

    for (let control of this._controls) {
      const attachEl = this._getChildAttachElement(control)
      control.attach(attachEl)
    }
  }

  _getChildAttachElement(value) {
    return this._domRoot
  }

  initDomRoot() {}

  render() {}

  hasControlCrossByType(type) {
    for (let control of this._controls) {
      if (control.__proto__.constructor === type) {
        return true
      }

      if (control.hasControlCrossByType(type)) {
        return true
      }
    }

    return false
  }

  renderControls() {
    const controlCount = this._controls.length
    for (let i = 0; i < controlCount; i++) {
      var control = this._controls[i]

      if (control._dirty) {
        control._dirty = false

        try {
          control.render()
        } catch (e) {
          console.error(e)
        }
      }
      
      control.renderControls()
    }
  }

  _updateRequested() {
    this._parent?._updateRequested()
  }

  _parentChanged() {}

  getWindow() {
    let c = this.parent
    while (!!c && c.typeName !== 'GVLWindow' && c.typeName !== 'Mnemo') {
      c = c.parent
    }

    return c
  }

  /**
   * Метод запроса на обновления текущего контрола. Метод объявляет текущий Control грязным.
   */
  requestUpdate() {
    this._dirty = true
    this._parent?._updateRequested()
  }

  removeControl(value) {
    if (value.parent !== this) {
      throw new Error('[GVLControl.removeControl] Passed value is not owned by current GVLControl')
    }

    const removeIndex = this._controls.indexOf(value)
    if (removeIndex < 0) {
      return
    }

    this._controls.splice(removeIndex, 1)
    value._parent = null
  }

  removeControlChildren() {
    for (let control of this._controls) {
      control._parent = null

      this._elements = this._elements.filter(it => it !== control)
      control._owner = null
      control.dispose()
    }

    this._controls = []
    this._domRoot.textContent = ''
  }

  getBounds() {
    return {
      left: this.left,
      top: this.top,
      width: this.width,
      height: this.height,
    }
  }

  setBounds(rect) {
    this.left = rect.left
    this.top = rect.top
    this.width = rect.width
    this.height = rect.height
  }

  _fillChange() {
    this._fill.requestApply(this._controlId, this)
  }

  _strokeChange() {}

  _setProp(name, value) {
    const fieldName = '_' + name

    if (this[fieldName] === undefined) {
      return
    }

    if (this[fieldName] !== value) {
      this[fieldName] = value
      this._dirty = true
      this._propertyChanged(name)
      this.requestUpdate()
    }
  }

  get fill() { return this._fill }
  get parent() { return this._parent }

  get left() { return this._left }
  set left(value) {
    if (this._left !== value) {
      this._left = value
      this._propertyChanged('left')
      this._leftChanged()
    }
  }

  get rotateAngle() { return this._rotateAngle }
  set rotateAngle(value) {
    if (this._rotateAngle !== value) {
      this._rotateAngle = value
      this._updateTransform()
    }
  }

  get rotateCenterX() { return this._rotateCenterX }
  set rotateCenterX(value) {
    if (this._rotateCenterX !== value) {
      this._rotateCenterX = value
      this._updateTransformOrigin()
    }
  }

  get rotateCenterY() { return this._rotateCenterY }
  set rotateCenterY(value) {
    if (this._rotateCenterY !== value) {
      this._rotateCenterY = value
      this._updateTransformOrigin()
    }
  }

  get height() { return this._height }
  set height(value) {

    if (this.minHeight > 0) {
      value = Math.max(this.minHeight, value)
    }

    if (this._height !== value) {
      const oldValue = this._height
      this._height = value
      this._propertyChanged('height', oldValue, value)

      if (this._squareSize) {
        this.width = value
      }
    }
  }

  get width() { return this._width }
  set width(value) {

    if (this.minWidth > 0) {
      value = Math.max(this.minWidth, value)
    }

    if (this._width !== value) {
      const oldValue = this._width
      this._width = value
      this._propertyChanged('width', oldValue, value)

      if (this._squareSize) {
        this.height = value
      }
    }
  }

  get top() { return this._top }

  set top(value) {
    if (this._top !== value) {
      this._top = value
      this._propertyChanged('top')
      this._topChanged()
    }
  }

  get visible() { return this._visible }
  set visible(value) {
    if (this._visible !== value) {
      this._visible = !!value

      if (!this._domRoot) {
        return
      }

      const { style } = this._domRoot
      style.display = this._visible? null: 'none'
    }
  }

  _propertyChanged(name, oldValue, newValue) {
    if (!this._domRoot) {
      return
    }

    switch (name) {
      case 'width':
        this._fill.requestApply(this._controlId, this)

        if (this._keepDomRootByClient) {
          this._domRoot.style.width = `${this._width}px`
        }
        
        break
      case 'height':
        this._fill.requestApply(this._controlId, this)
      
        if (this._keepDomRootByClient) {
          this._domRoot.style.height = `${this._height}px`
        }
        
        break
    }
  }

  _leftChanged() {
    this._updateTransform()
  }

  _topChanged() {
    this._updateTransform()
  }

  _updateTransformOrigin() {
    const { style } = this._domRoot

    if (this._rotateCenterX !== 0.5 || this._rotateCenterY !== 0.5) {
      style.transformOrigin = `${this._rotateCenterX * 100}% ${this._rotateCenterY * 100}%`
    }    
  }

  _updateTransform() {
    const { style } = this._domRoot
    let text = `translate(${this._left}px, ${this._top}px)`

    if (this._rotateAngle !== 0) {
      text += ` rotate(${this._rotateAngle}deg)`
    }

    style.transform = text
  }

  get bodyOffset() {
    let bodyRect = document.body.getBoundingClientRect()
    let elemRect = this._domRoot.getBoundingClientRect()
    let x = elemRect.left - bodyRect.left
    let y = elemRect.top - bodyRect.top

    return { x, y }
  }

  collectControlPath() {
    let e = this
    let controlElements = []

    while (!!e) {
      controlElements.push(e)
      e = e.parent
    }

    return controlElements.reverse()
  }

  get absoluteParent() {
    let e = this.parent

    while (!!e && !!e.parent) {
      e = e.parent
    }

    return e
  }

  forEachControlCross(consumer) {
    for (let child of this._controls) {
      const res = consumer(child)
      if (res === false) {
        continue
      }
      
      child.forEachControlCross(consumer)
    }
  }

  findParent(predicate) {
    let e = this.parent
    while (e) {
      if (predicate(e)) {
        return e
      }

      e = e.parent
    }

    return
  }
}

GVLControl.gvlPropTypes = {}

export class GVLArgumentControl extends GVLControl {
  constructor() {
    super()

    this._argument = null
    this._argumentChanged = this._argumentChanged.bind(this)
    this.argumentService = null
  }

  dispose() {
    this._unbindArgument()
    super.dispose()
  }

  get argument() { return this._argument }
  set argument(value) {
    if (this._argument !== value) {
      this._unbindArgument()

      this._argument = value
      this._argument?.on('change', this._argumentChanged)
    }
  }

  _unbindArgument() {
    this._argument?.off('change', this._argumentChanged)
    this._argument = null
  }

  _argumentChanged() {}
}

export class GVLGroup extends GVLControl {

  addControl(value) {
    super.addControl(value)
    this._squareSizeValid = false
  }

  removeControl(value) {
    super.removeControl(value)
    this._squareSizeValid = false
  }

  initDomRoot() {
    this.defineRoot('div')
  }

  _propertyChanged(name) {
    super._propertyChanged(name)

    switch (name) {
      case 'width':
        this._domRoot.style.width = `${this._width}px`
        this._sizeChanged()
        break
      case 'height':
        this._domRoot.style.height = `${this._height}px`
        this._sizeChanged()
        break
    }
  }

  _sizeChanged() {
    if (this._squareSizeValid) {
      return
    }

    this._squareSize = this._controls.some(control => control._squareSize || !floatEquals(control.rotateAngle, 0))
    this._squareSizeValid = true
  }
}