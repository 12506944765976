/* eslint no-template-curly-in-string: 0 */

import { isEmpty, replaceAllIgnoreCase } from 'std/string'
import { NIGH_NUMERICAL_TYPES, tr, formatValueAsSummary } from './common'
import { selectChannelByID, selectCommonEventCat, selectEventCatByID } from 'features/live/liveSlice'

const createEventType = (name, viewComponentText, messagePrefix = '') => {
  return {
    name,
    viewComponentText,
    messagePrefix
  }
}

export const EventType = Object.freeze({
  loLoAlarm: createEventType('loLoAlarm', true),
  normal: createEventType('normal', true),
  hiHiAlarm: createEventType('hiHiAlarm', true),
  discreteOn: createEventType('discreteOn', true),
  discreteOff: createEventType('discreteOff', true),
  none: createEventType('none', true),
  userAction: createEventType('userAction', false),
  info: createEventType('info', false, 'eventTypes.info.messagePrefix'),
  warning: createEventType('warning', false, 'eventTypes.warning.messagePrefix'),
  alarm: createEventType('alarm', false, 'eventTypes.alarm.messagePrefix'),
  hiAlarm: createEventType('hiAlarm', true),
  loAlarm: createEventType('loAlarm', true),
  badQuality: createEventType('badQuality', true),

  valueOf: function (name) {
    let result = EventType[name]

    if (!result || typeof result === 'function') {
      throw new Error(`Enum event type not found by ${name} name`)
    }

    return result
  }
})

const resolveEventOptions = (event, state) => {
  const { channel } = event
  const eventType = event.type
  let cat = null

  if (channel) {
    cat = selectEventCatByID(state, channel.eventCatID)
  }

  if (!cat) {
    cat = selectCommonEventCat(state)
  }

  const optionsMap = cat.optionsByEventTypeMap
  const options = optionsMap[eventType.name]
  
  return options
}


const composeEventMessage = (event, state) => {
  const { channel } = event
  const eventType = event.type
  let message = ''

  if (!isEmpty(eventType.messagePrefix)) {
    message += tr(eventType.messagePrefix)
  }

  if (channel && channel.customMessages && channel.customMessages.enabled) {
    const { customMessages } = channel

    if (customMessages.hasOwnProperty(eventType.name)) {
      message += customMessages[eventType.name].trim()
    }
  } else {
    const options = resolveEventOptions(event, state)
    message += event.textOwn
      ? event.text
      : eventType.viewComponentText
        ? options.textFormat
        : event.text
  }

  if (channel) {
    message = replaceAllIgnoreCase(message, '${Channel.Group}', channel.localGroup)
    message = replaceAllIgnoreCase(message, '${Channel.Name}', channel.name)

    if (NIGH_NUMERICAL_TYPES.includes(channel.type)) {
      const floatValue = parseFloat(event.value)
      const formattedValue = formatValueAsSummary(channel, floatValue)

      message = replaceAllIgnoreCase(message, '${Channel.Value}', formattedValue)
    }
  }

  if (!isEmpty(event.userName)) {
    message = replaceAllIgnoreCase(message, '${User.Name}', event.userName)
  }

  return message
}

export const mapDTOToEvents = (dtos, state) => {
  const events = []

  for (let dto of dtos) {
    var event = {
      id: dto.ID,
      ID: dto.ID,
      acked: dto.acked,
      ackComment: dto.ackComment,
      ackTime: new Date(dto.ackTime),
      channel: dto.channelID !== -1? selectChannelByID(state, dto.channelID): null,
      endTime: dto.endTime? new Date(dto.endTime): null,
      priority: dto.priority,
      message: '',
      startTime: new Date(dto.startTime),
      text: dto.text,
      textOwn: dto.textOwn,
      type: EventType.valueOf(dto.type),
      userID: dto.userID,
      userName: dto.userName,
      value: dto.value
    }
    event.message = composeEventMessage(event, state)
    events.push(event)
  }

  return events
}

