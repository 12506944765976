import * as uuid from 'uuid'
import axios from 'axios'
import { Buffer, Enum } from '@sl/utils'
import { EventorOn } from 'std/eventor'
import keyMirror from 'key-mirror'

export const Command = Enum.build([
  'None', 'Kick', 'Hello', 'IdentifyConnection', 'Frame_v3', 'NotifyChannelUpdateReceived',
  'ScheduleChanged', 'RequestSchedules', 'AcceptSchedules', 'SetScheduleRanges', 'SetScheduleEnabled',
  'Request', 'Response', 'CookbookRecipesChanged', 'CookbookRecipeStatesChanged', 'AddRecipe', 'DeleteRecipe',
  'ApplyRecipe', 'SetRecipeStates', 'SetRecipeProperties', 'AcceptMnemos', 'AcceptEventChangeNotification',
  'RenewEventChanges', 'AcceptClientList', 'AcceptDebugMessages', 'Disconnect', 'CloseMnemo'
])

export const ConnectStatus = keyMirror({
  Disconnected: null,
  Connecting: null,
  Connected: null,
})

export const RequestSubject = Enum.build([
  'None', 'Schedules', 'Schedule', 'CookbookAndRecipes', 'Cookbook', 'Mnemos', 'Mnemo',
  'Events', 'NumericMoments', 'MnemoTabSwitch', 'EventOptions', 'MonitorView', 'Channels',
  'EventCategories', 'ActualEvents', 'Report', 'WebClient', 'MnemoChannelSubscriptions',
  'License', 'ChannelsOnly', 'ChannelOnly', 'Ping', 'Scales', 'LastEvents', 'Users',
  'CookbookRecipeID', 'ChannelID', 'LogIn', 'AutoRunMnemo', 'SubscribeChannels',
  'Endpoints', 'ServerStatus', 'ConnectedClient', 'ClientsSubscriptions',
  'DebugMessageSubscriptions', 'Menu', 'StopServer', 'RestartServer', 'DisconnectMeAll',
  'RenewEventChanges'
])

export const KickReason = Enum.build([
  'None', 'ServerMaxClients', 'Manually', 'UserAlreadyInUse'
])

const RequestMethod = Enum.build([
  'GET', 'PATCH', 'POST', 'DELETE'
])

class Request {
  constructor() {
    this.subject = RequestSubject.None
    this.method = RequestMethod.GET
    this.id = null
    this.params = {}
    this.body = null
  }

  encode(buffer) {
    // write uuid as 16 bytes
    let idBytes = this.id? uuid.parse(this.id): new Uint8Array(16)

    buffer.writeTypedArray(idBytes)
    buffer.writeByte(this.method.id)
    buffer.writeByte(this.subject.id)

    let strParams = JSON.stringify(this.params)
    buffer.writeString(strParams)

    let strBody = this.body? JSON.stringify(this.body): ''
    buffer.writeString(strBody)
  }
}

class Response {
  constructor() {
    this.requestId = null
    this.data = null
    this.statusText = 'Pending'
  }

  decode(buffer) {
    let bytes = buffer.readBytes(16)
    this.requestId = uuid.stringify(bytes)

    let strBody = buffer.readString()

    if (strBody !== '') {
      this.data = JSON.parse(strBody)
    } else {
      this.data = null
    }

    let failReason = buffer.readString()
    this.statusText = failReason? failReason: 'OK'
  }

  get ok() {
    return this.statusText === 'OK'
  }
}

class ResponseError extends Error {
  constructor(response, ...args) {
    super(...args)
    this.response = response
  }
}

class Hello {

  connID = ''
  programVersion = ''
  version = 0
  authRequired = false

  decode(buffer) {
    this.programVersion = buffer.readString()
    this.version = buffer.readInteger()
    this.authRequired = buffer.readBoolean()
    this.connID = buffer.readString()
  }

  encode(buffer) {
    throw new Error('Unimplemented SystemInfo.encode')
  }
}

export class IdentificationStruct {

  name = ''
  clientId = ''
  
  decode(buffer) {
    this.name = buffer.readString()
    buffer.readInteger() // app type: Monitor
    buffer.readString() // App key
    this.clientId = buffer.readString()
  }

  encode(buffer) {
    buffer.writeString(this.name)
    buffer.writeInteger(0) // app type: Monitor
    buffer.writeString('') // App key
    buffer.writeString(this.clientId)
  }
}

export class KickBody {
  reason = KickReason.None

  encode(buffer) {
    throw new Error('Unimplemented DisconnectionBody.encode')
  }

  decode(buffer) {
    let reasonId = buffer.readInteger()
    this.reason = KickReason.valueOf(reasonId)

    if (this.reason === undefined) {
      this.reason = KickReason.None
    }
  }
}

export class CloseMnemoBody {
  mnemoName = ''

  encode() {
    throw new Error('Unimplemented CloseMnemoBody.encode')
  }

  decode(buffer) {
    this.mnemoName = buffer.readString()
  }
}

class NetPrefix {
  command = Command.None
  timestamp = new Date()
  size = 0

  decode(buffer) {
    let commandId = buffer.readInteger()
    this.command = Command.valueOf(commandId)

    const timestampStr = buffer.readString()
    this.timestamp = new Date(timestampStr)

    this.size = buffer.readInteger()
  }

  encode(buffer) {
    const timestampStr = this.timestamp.toISOString()

    buffer.writeInteger(this.command.id)
    buffer.writeString(timestampStr)
    buffer.writeInteger(this.size)
  }
}

const packedQualities = [0, 64, 192, 193, 4, 8, 12, 16, 20, 24, 28, 32, 1001]

const decodeSignalByte = (byte) => {
  let packQuality = (byte & 0xF0) >> 4
  let quality = packQuality >= 0 && packQuality < packedQualities.length? packedQualities[packQuality]: 0
  let valueType = (byte & 0xE) >> 1
  let boolValue = !!(byte & 0x1)

  return {
    valueType,
    quality,
    boolValue
  }
}

class Framev3Body {

  constructor() {
    this.updates = []
    this.serverTime = null
  }

  decode(buffer) {
    this.updates = []
    this.serverTime = null

    if (buffer.position < buffer.size) {
      var strTime = buffer.readString()
      this.serverTime = new Date(strTime)
    }

    while (buffer.position < buffer.size) {
      var channelID = buffer.readInteger()
      var rawAlarmStatus = buffer.readInteger()
      var alarmAcked = buffer.readBoolean()
      var bitTags = this.decodeBitTags(buffer)
      var signalByte = buffer.readByte()

      var fields = decodeSignalByte(signalByte)

      var value = null

      switch (fields.valueType) {
        case 0:
          value = buffer.readString()
          break
        case 1:
          value = fields.boolValue
          break
        case 2:
          value = buffer.readFloat64()
          break
        default:
          value = null
      }

      this.updates.push({
        alarmAcked,
        alarmStatus: rawAlarmStatus,
        bitTags,
        channelID,
        quality: fields.quality,
        value
      })
    }
  }

  decodeBitTags(buffer) {
    let tags = []

    const tagLen = buffer.readInteger()
    
    for (let i = 0; i < tagLen; i++) {
      const acked = buffer.readBoolean()
      const status = buffer.readInteger()
      const bit = buffer.readInteger()

      const tag = {
        alarm: {
          acked,
          status
        },
        bit
      }
      tags.push(tag)
    }

    return tags
  }

  encode(buffer) {
    throw new Error('Unimplemented Framev3Body.encode')
  }
}

function decodeScheduleTime(dateStr) {
  const parts = dateStr.split(':')

  if (parts.length === 5) {
    const time = {
      weekday: +parts[0],
      hours: +parts[1],
      minutes: +parts[2],
      seconds: +parts[3],
      milliseconds: +parts[4]
    }

    return time
  } else {
    throw new Error(`decodeScheduleTime: failed for ${dateStr}`)
  }
}

class ScheduleChangedBody {
  constructor() {
    this.scheduleId = ''
    this.enabled = false
    this.ranges = []
  }

  decode(buffer) {
    this.scheduleId = buffer.readString()
    this.enabled = buffer.readBoolean()
    let rangeCount = buffer.readInteger()

    this.ranges = []

    for (let i = 0; i < rangeCount; i++) {
      let strStart = buffer.readString()
      let strEnd = buffer.readString()

      let start = decodeScheduleTime(strStart)
      let end = decodeScheduleTime(strEnd)

      this.ranges.push({ start, end })
    }
  }
}

class CookbookRecipesChangedBody {
  constructor() {
    this.id = ''
    this.appliedID = ''
    this.recipes = []
  }

  decode(buffer) {
    var json = buffer.readString()
    var body = JSON.parse(json)
    this.id = body.id
    this.appliedID = body.appliedID
    this.recipes = body.recipes
  }
}

class CookbookRecipeStatesChangedBody {
  constructor() {
    this.cookbookID = ''
    this.recipeID = ''
    this.states = []
  }

  decode(buffer) {
    var json = buffer.readString()
    var body = JSON.parse(json)

    Object.assign(this, body)
  }
}

class AcceptEventChangeNotificationBody {

  decode(buffer) {
    const bodyContent = buffer.readString()
    const json = JSON.parse(bodyContent)
    Object.assign(this, json)
  }
}

class AcceptClientListBody {
  decode(buffer) {
    const bodyContent = buffer.readString()
    const json = JSON.parse(bodyContent)
    Object.assign(this, json)
  }
}

class AcceptDebugMessagesBody {
  decode(buffer) {
    const bodyContent = buffer.readString()
    const json = JSON.parse(bodyContent)
    Object.assign(this, json)
  }
}

class RequestTimeoutError extends Error {
  constructor(message, request) {
    super(message)
    this.request = request
  }
}

export class NetClient extends EventorOn {
  constructor(hostname, port, secure = false) {
    super()

    this.connID = ''
    this._hostname = hostname
    this._port = port
    this._secure = secure

    this.heartbeat = {
      timeoutId: undefined,
    }

    this._handleOpen = this._handleOpen.bind(this)
    this._handleClose = this._handleClose.bind(this)
    this._handleError = this._handleError.bind(this)
    this._handleMessage = this._handleMessage.bind(this)

    this.requestCtxMap = new Map()
  }

  async connect() {
    if (this.ws) {
      if (this.ws.readyState === WebSocket.CLOSING || this.ws.readyState === WebSocket.CLOSED) {
        this._cleanUp()
      }
    }

    if (!this.ws) {
      const accessToken = this._accessTokenSource
        ? this._accessTokenSource()
        : ''

      const clientId = this._clientIdSource
        ? await this._clientIdSource()
        : ""

      this.connID = ''
      try {
        const response = await axios.post(`https://${this._hostname}:${this._port}/connections`, {
          a: clientId
        }, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        this.connID = response.data.connID
      } catch (e) {
        let reason = e.response? e.response.data: undefined
        this.connID = ''

        this.fireEvent('reject', { reason })
        return
      }
      
      this.ws = new WebSocket(`wss://${this._hostname}:${this._port}/ws/${this.connID}`)
      this.ws.addEventListener('open', this._handleOpen)
      this.ws.addEventListener('close', this._handleClose)
      this.ws.addEventListener('error', this._handleError)
      this.ws.addEventListener('message', this._handleMessage)
      this.fireEvent('connecting')
    }
  }

  disconnect() {
    this.ws?.close()
  }

  notify(command, payload) {
    this._ensureConnection()

    let buffer = new Buffer()

    let prefix = new NetPrefix()
    prefix.command = command

    prefix.encode(buffer)
    payload?.encode(buffer)

    const fixedBytes = buffer.toFixedArray()
    this.ws.send(fixedBytes)

    if (prefix.command !== Command.Request) {
      console.debug(`-> [${prefix.command.name}] `, payload)
    }
  }

  delete(subject, params = {}) {
    return this._sendRequest(RequestMethod.DELETE, subject, params, null)
  }

  get(subject, params = {}) {
    return this._sendRequest(RequestMethod.GET, subject, params, null)
  }

  post(subject, params = {}, body = null) {
    return this._sendRequest(RequestMethod.POST, subject, params, body)
  }

  patch(subject, params = {}, body = null) {
    return this._sendRequest(RequestMethod.PATCH, subject, params, body)
  }

  setAccessTokenSource(source) {
    this._accessTokenSource = source
  }

  setPort(value) {
    if (value !== undefined) {
      this._port = value
    }
  }

  setClientIdSource(source) {
    this._clientIdSource = source
  }

  get isConnected() {
    return this.ws && this.ws.readyState === WebSocket.OPEN
  }

  _ensureConnection() {
    if (!this.isConnected) {
      throw new Error('NetClient is not connected.')
    }
  }

  _sendRequest(method, subject, params, body, timeout = 30000) {
    if (typeof params !== 'object') {
      throw new Error('sendRequest params param must be object')
    }

    let req = new Request()
    req.subject = subject
    req.method = method
    req.params = params
    req.body = body
    req.id = uuid.v4()

    return new Promise((resolve, reject) => {
      this.notify(Command.Request, req)

      req.timeoutId = setTimeout(() => {
        reject(new RequestTimeoutError(`-> ${method.name} ${subject.name} timed out`, {
          subject,
          method,
          params,
        }))
      }, timeout)

      console.debug(`-> ${method.name} ${subject.name}`, 'params: ', params, 'body: ', body)
      this.requestCtxMap.set(req.id, [req, resolve, reject])
    })
  }

  _handleOpen() {
    if (this.heartbeat.timeoutId !== undefined) {
      clearTimeout(this.heartbeat.timeoutId)
      this.heartbeat.timeoutId = undefined
    }
    this.heartbeat.timeoutId = setTimeout(this._doHeartbeat, 15000)

    this.fireEvent('connected')
  }

  _doHeartbeat = async () => {
    this.heartbeat.timeoutId = undefined

    if (!this.isConnected) {
      return
    } 

    try {
      await this._sendRequest(RequestMethod.POST, RequestSubject.Ping, {}, {}, 15000)
    } catch (err) {
      console.error(err)

      this.ws?.close()
      this._cleanUp()
      this.fireEvent('disconnected')
    } finally {
      this.heartbeat.timeoutId = setTimeout(this._doHeartbeat, 15000)
    }
  }

  _handleClose() {
    this._cleanUp()
    this.fireEvent('disconnected')
  }

  _handleError() {
    this._cleanUp()
    this.fireEvent('error')
  }

  _cleanUp() {
    if (this.heartbeat.timeoutId !== undefined) {
      clearTimeout(this.heartbeat.timeoutId)
      this.heartbeat.timeoutId = undefined
    }

    const { ws } = this

    if (ws) {
      ws.removeEventListener('open', this._handleOpen)
      ws.removeEventListener('close', this._handleClose)
      ws.removeEventListener('error', this._handleError)
      ws.removeEventListener('message', this._handleMessage)
      this.ws = null
    }
  }

  async _handleMessage(e) {
    let data = e.data
    let srcBuffer = await data.arrayBuffer()

    let buffer = new Buffer(srcBuffer)

    let prefix = new NetPrefix()
    prefix.decode(buffer)

    let constructor = this._mapBodyToConstructor(prefix)
    let payload = null

    if (constructor) {
      payload = new constructor()
      payload.decode(buffer)
    } else {
      if (buffer.position < buffer.size) {
        payload = `No body constructor for buffer data`
      }
    }

    if (prefix.command === Command.Response) {
      let response = payload
      let entry = this.requestCtxMap.get(response.requestId)
      if (!entry) {
        return
      }

      let [req, onResponse, reject] = entry
      this.requestCtxMap.delete(response.requestId)

      if (req.timeoutId !== undefined) {
        clearTimeout(req.timeoutId)
        req.timeoutId = undefined
      }

      console.debug(`<- ${req.method.name} ${req.subject.name} - ${response.statusText}`, payload.data)

      if (response.statusText === 'OK') {
        onResponse && onResponse(response)
      } else {
        reject && reject(new ResponseError(response, `Net error: ${response.statusText}`))
      }
    } else {
      console.debug(`<- [${prefix.command.name}] `, payload)
    }
    
    this.fireEvent('command', { command: prefix.command, payload })
  }

  _mapBodyToConstructor(prefix) {
    switch (prefix.command) {
      case Command.Hello:
        return Hello
      case Command.Response:
        return Response
      case Command.Frame_v3:
        return Framev3Body
      case Command.ScheduleChanged:
        return ScheduleChangedBody
      case Command.CookbookRecipesChanged:
        return CookbookRecipesChangedBody
      case Command.CookbookRecipeStatesChanged:
        return CookbookRecipeStatesChangedBody
      case Command.AcceptEventChangeNotification:
        return AcceptEventChangeNotificationBody
      case Command.AcceptClientList:
        return AcceptClientListBody
      case Command.AcceptDebugMessages:
        return AcceptDebugMessagesBody
      case Command.Kick:
        return KickBody
      case Command.CloseMnemo:
        return CloseMnemoBody
      default:
        return null
    }
  }
}

export default NetClient