import { useSelector } from 'react-redux'

const AlarmEmitter = () => {
  const soundURL = useSelector(state => state.alarm.soundURL)
  const userClick = useSelector(state => state.alarm.userClick)

  if (soundURL && userClick) {
    return <audio loop={true} autoPlay src={soundURL} preload='auto' />
  }

  return null
}

export default AlarmEmitter
