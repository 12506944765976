import { ValueType } from 'GVL/prop-types'
import { MnemoControl } from './controls'
import { Quality, tr, SaveStrategy, formatNumber } from 'utils/common'
import { ValueObject } from 'GVL/types'
import { FAView } from 'GVL/faView'
import { subSeconds } from 'date-fns'
import { ru, enUS } from 'date-fns/locale'
import { enqueueSnackbar } from 'notistack'
import {
  Chart as ChartJS,
  TimeScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title as TitleChartJS,
  Tooltip,
  Legend as LegendChartJS,
  Colors,
  LineController
} from 'chart.js'
import zoomPlugin from 'chartjs-plugin-zoom'
import 'chartjs-adapter-date-fns'
import { getGroupMoments } from 'services/resource'

const logDebug = (...args) => {
  console.debug('LineChart: ', ...args)
}

const chartArea = {
  id: 'chartArea',
  beforeDraw(chart, args, options) {
    const { ctx, chartArea: { left, top, width, height } } = chart
    ctx.save()

    ctx.globalCompositeOperation = 'destination-over'
    ctx.fillStyle = options.backgroundColor
    ctx.fillRect(left, top, width, height)

    ctx.strokeStyle = options.borderColor
    ctx.lineWidth = options.borderWidth
    ctx.setLineDash(options.borderDash || [])
    ctx.lineDashOffset = options.borderDashOffset
    ctx.strokeRect(left, top, width, height)
    ctx.restore()
  }
}

ChartJS.register(
  Colors,
  CategoryScale,
  chartArea,
  TimeScale,
  LinearScale,
  PointElement,
  LineController,
  LineElement,
  TitleChartJS,
  Tooltip,
  LegendChartJS,
  zoomPlugin
)

class SeriesItem extends ValueObject {
  constructor() {
    super()

    this.color = '#000000'
    this.data = []
    this.tagID = -1
    this.width = 2
    this.yAxisID = ''
  }
}

class LineChart extends MnemoControl {

  constructor() {
    super()

    this._bindings = []
    this._initialized = false

    this.channelSource = null
    this._keepDomRootByClient = true

    this.backgroundColor = '#FFFFFF'
    this.width = 400
    this.height = 200

    this.area = new Area()
    this.area.onChange = this._nestedPropChanged

    this.legend = new Legend()
    this.legend.onChange = this._nestedPropChanged

    this.scaleX = new ScaleX()
    this.scaleX.onChange = this._nestedPropChanged

    this.scalesY = []

    this.series = []

    this.title = new Title()
    this.title.onChange = this._nestedPropChanged

    this.state = {
      fetchId: 0,
      status: 'idle',
    }

    this.$spinner = new FAView()
    this.addControl(this.$spinner)

    document.addEventListener('visibilitychange', this._visibilityChange)
  }

  dispose() {
    this._cancelInit()
    document.removeEventListener('visibilitychange', this._visibilityChange)

    this.area.dispose()
    this.legend.dispose()
    this.scaleX.dispose()
    this.title.dispose()

    for (let item of this.scalesY) {
      item.dispose()
    }

    for (let item of this.series) {
      item.dispose()
    }

    this._nestedPropChanged = null

    super.dispose()
  }

  _nestedPropChanged = () => {
    this.requestUpdate()
  }

  initDomRoot() {
    const $root = this.defineRoot('div')
    this.$canvas = document.createElement('canvas')
    $root.append(this.$canvas)
  }

  render() {

    if (this.$chart) {
      this.$chart.destroy()
      this.$chart = null
    }

    this._domRoot.style.backgroundColor = this.backgroundColor

    const language = tr('lang')

    const grid = {
      display: true,
      color: this.area.gridColor
    }

    logDebug('render')

    const xScaleMax = new Date()
    const xScaleMin = subSeconds(xScaleMax, this.scaleX.span)

    const xScale = {
      adapters: {
        date: {
          locale: language === 'ru-RU'? ru: enUS
        }
      },
      border: {
        color: this.scaleX.color
      },
      display: this.scaleX.visible,
      grid: {
        ...grid,
        tickColor: this.scaleX.tickColor
      },
      min: xScaleMin,
      max: xScaleMax,
      ticks: {
        align: 'end',
        autoSkipPadding: 7,
        color: this.scaleX.tickColor,
        maxRotation: 0,
        major: {
          enabled: true
        }
      },
      time: {
        tooltipFormat: 'HH:mm:ss dd MMMM yyyy',
        displayFormats: {
          'second': 'HH:mm:ss',
          'minute': 'HH:mm',
          'hour': 'HH:00',
          'day': 'dd MMM',
          'week': 'dd MMM',
          'month': 'MMM yyyy',
          'quarter': 'MMM yyyy',
          'year': 'yyyy',
        },
        minUnit: 'second'
      },
      type: 'time',
      visible: this.scaleX.visible
    }

    let yScales = {}

    const newYLabelCallback = (digits) => (value) => {
      return formatNumber(value, digits)
    }

    for (let scaleY of this.scalesY) {
      yScales['y' + scaleY.axisID] = {
        border: {
          color: scaleY.color
        },
        display: scaleY.visible,
        grid: {
          ...grid,
          tickColor: scaleY.tickColor
        },
        position: scaleY.position.name.toLowerCase(),
        min: scaleY.min,
        max: scaleY.max,
        ticks: {
          stepSize: scaleY.tickStepSize,
          color: scaleY.tickColor,
          callback: newYLabelCallback(scaleY.tickFractionDigits)
        },
        title: {
          color: scaleY.title.color,
          display: scaleY.title.display,
          text: scaleY.title.text,
        }
      }
    }

    const datasets = this.series.map(it => {
        const channel = this.channelSource.getByID(it.tagID)
        if (!channel) {
          return null
        }

        const stepped = channel.type === 'boolean' || (channel.saveDB && channel.saveStrategy === SaveStrategy.ByChange)

        return {
          id: channel.ID,
          label: channel.name,
          borderColor: it.color,
          borderWidth: it.width,
          data: [...it.data],
          stepped,
          yAxisID: `y${it.yAxisID}`
        }
      })
      .filter(it => !!it)

    this.$chart = new ChartJS(this.$canvas, {
      type: 'line',
      data: {
        datasets
      },
      options: {
        animation: false,
        elements: {
          point: {
            radius: 0
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: xScale,
          ...yScales
        },
        layout: {
          padding: {
            right: 10,
          }
        },
        plugins: {
          chartArea: {
            borderColor: this.area.borderColor,
            borderWidth: 1,
            borderDashOffset: 0,
            backgroundColor: this.area.backgroundColor
          },
          legend: {
            display: this.legend.visible,
            labels: {
              color: this.legend.textColor
            }
          },
          title: {
            display: this.title.visible,
            color: this.scaleX.tickColor,
            text: this.title.text,
            font: {
              size: 16,
              weight: 'bold'
            }
          }
        }
      }
    })

    this._renderSpinner()
  }

  _renderSpinner() {
    const { $spinner: s } = this
    s.setBounds({ left: 0, top: 0, width: this._width, height: this._height })
    s.iconClass = 'spinner'
    s.spinning = true
    s.size = 32
    s.visible = this.state.status === 'fetching'
  }

  _loadingChanged() {
    super._loadingChanged()

    this._initialize()
  }

  _visibilityChange = () => {
    this._cancelInit()

    if (!document.hidden) {
      this._initialize()
    }
  }

  async _initialize() {
    if (this.loading || this._initialized) {
      return
    }

    this._initialized = true

    if (!this.channelSource) {
      return
    }

    this.state.status = 'fetching'
    this._renderSpinner()

    logDebug('initialization')
    try {
      await this._fetchSeriesContent()
      this.state.status = 'idle'
    } catch (err) {
      this.state.status = 'error'

      console.error(`${err.stack || err}`)
      enqueueSnackbar(tr('mnemo.lineChart.fetchSeriesFailed'), { variant: 'error' })
    } finally {
      logDebug('initialization end')
      this._renderSpinner()
    }

    this._startSampling()
  }

  async _fetchSeriesContent() {
    this.state.fetchId++
    const fetchId = this.state.fetchId
    
    const endTime = new Date()
    const startTime = subSeconds(endTime, this.scaleX.span)

    for (let series of this.series) {
      const channel = this.channelSource.getByID(series.tagID)
      if (!channel) {
        continue
      }

      const res = await getGroupMoments(series.tagID, {
        startTime, endTime
      }, 0)

      if (fetchId !== this.state.fetchId) {
        return
      }

      const dtos = res.data

      let dataset = this.$chart.data.datasets.find(it => it.id === series.tagID)
      if (!dataset) {
        return
      }

      const moments = mapMomentsFromDTO(dtos)

      series.data = moments

      dataset.data.length = 0
      dataset.data = moments      

      logDebug(`[${channel.name}] got moments: `, [...series.data])
    }

    this.$chart.update('none')
  }


  _startSampling() {

    logDebug('sampling tags')

    for (let series of this.series) {
      const channel = this.channelSource.getByID(series.tagID)
      if (!channel) {
        continue
      }

      const handler = createPasser(this, series)

      this._bindings.push({
        tagID: series.tagID,
        handler
      })
    }

    this.timeScaleUpdaterId = setInterval(this._scaleUpdater, this.scaleX.updateInterval)
    this._scaleUpdater()

    logDebug('sampling tags end')
  }

  _cancelInit() {
    if (this.timeScaleUpdaterId) {
      clearInterval(this.timeScaleUpdaterId)
      this.timeScaleUpdaterId = null
    }

    this._bindings = []
    this._initialized = false

    for (let series of this.series) {
      series.data = []
    }

    const { $chart } = this
    if ($chart) {
      const datasets = $chart.data.datasets

      for (let dataset of datasets) {
        dataset.data.length = 0
      }

      $chart.update('none')
    }
  }

  _scaleUpdater = () => {
    const { $chart } = this

    for (let bindOne of this._bindings) {
      const channel = this.channelSource.getByID(bindOne.tagID)
      if (!channel) {
        continue
      }

      bindOne.handler(channel)
    }

    const xScaleMax = new Date()
    const xScaleMin = subSeconds(xScaleMax, this.scaleX.span)

    const x = $chart.options.scales.x
    
    x.min = xScaleMin
    x.max = xScaleMax
    $chart.update('none')
  }
}

const createPasser = (control, series) => {
  return (channel) => {
    if (!channel.anyUpdate) {
      return
    }

    const x = new Date()
    const y = channel.quality === Quality.Good
      ? channel.value
      : null

    const xScaleMax = new Date()
    const mostLessPivotX = subSeconds(xScaleMax, 1.5 * control.scaleX.span)

    series.data.push([x, y])

    let index = calcSliceDataIndex(series.data, mostLessPivotX)
    if (index >= 0)  {
      series.data.splice(0, index + 1)
    }

    const { $chart } = control

    let dataset = $chart.data.datasets.find(it => it.id === channel.ID)
    if (!dataset) {
      return
    }

    dataset.data.push([x, y])

    index = calcSliceDataIndex(dataset.data, mostLessPivotX)
    if (index >= 0)  {
      dataset.data.splice(0, index + 1)
    }

    logDebug(`[${channel.name}] passer called`, x, y)

    $chart.update('none')
  }
}

const calcSliceDataIndex = (points, pivotDate) => {
  const t = pivotDate.getTime()
  let res = -1

  for (let i = 0; i < points.length; i++) {
    const point = points[i]
    const x = point[0].getTime()

    if (x <= t) {
      res = i
    } else {
      break
    }
  }

  return res
}


const mapMomentsFromDTO = (dtos) => {
  const moments = []

  for (let dto of dtos) {
    let x = new Date(dto.t)
    let y = dto.q === Quality.Good
      ? dto.v
      : null

    moments.push([x, y])
  }

  return moments
}

const ScalePosition = Object.freeze({
  Left: { name: 'Left' },
  Right: { name: 'Right' },
  name: 'ScalePosition'
})

class ScaleX extends ValueObject {
  constructor() {
    super()

    this._color = '#000000'
    this._span = 5 * 60
    this._tickColor = '#000000'
    this._updateInterval = 500
    this._visible = true
  }

  get color() { return this._color }
  set color(value) { this._setProp('color', value) }

  get span() { return this._span }
  set span(value) { this._setProp('span', value) }

  get tickColor() { return this._tickColor }
  set tickColor(value) { this._setProp('tickColor', value) }

  get updateInterval() { return this._updateInterval }
  set updateInterval(value) { this._setProp('updateInterval', value) }

  get visible() { return this._visible }
  set visible(value) { this._setProp('visible', value) }
}

class ScaleTitle extends ValueObject {
  constructor() {
    super()

    this.color = '#000000'
    this.display = false
    this.text = ''
  }
}

class ScaleY extends ValueObject {
  constructor() {
    super()

    this.axisID = ''
    this._color = '#000000'
    this._min = 0
    this._max = 100
    this._position = ScalePosition.Left
    this.tickStepSize = 10
    this._tickColor = '#000000'
    this.tickFractionDigits = 0
    this.title = new ScaleTitle()
    this._visible = true
  }

  dispose() {
    this.title.dispose()
    super.dispose()
  }

  get color() { return this._color }
  set color(value) { this._setProp('color', value) }

  get min() { return this._min }
  set min(value) { this._setProp('min', value) }

  get max() { return this._max }
  set max(value) { this._setProp('max', value) }

  get position() { return this._position }
  set position(value) { this._setProp('position', value) }

  get tickColor() { return this._tickColor }
  set tickColor(value) { this._setProp('tickColor', value) }

  get visible() { return this._visible }
  set visible(value) { this._setProp('visible', value) }
}

class Area extends ValueObject {
  
  constructor() {
    super()

    this._backgroundColor = '#FFFFFF'
    this._borderColor = '#000000'
    this._gridColor = '#E0E0E0'
  }

  get backgroundColor() { return this._backgroundColor }
  set backgroundColor(value) { this._setProp('backgroundColor', value) }

  get borderColor() { return this._borderColor }
  set borderColor(value) { this._setProp('borderColor', value) }

  get gridColor() { return this._gridColor }
  set gridColor(value) { this._setProp('gridColor', value) }
}

class Legend extends ValueObject {
  
  constructor() {
    super()

    this._textColor = '#000000'
    this._visible = true
  }

  get textColor() { return this._textColor }
  set textColor(value) { this._setProp('textColor', value) }

  get visible() { return this._visible }
  set visible(value) { this._setProp('visible', value) }
}

class Title extends ValueObject {
  
  constructor() {
    super()

    this._text = 'Title'
    this._visible = true
  }

  get text() { return this._text }
  set text(value) { this._setProp('text', value) }

  get visible() { return this._visible }
  set visible(value) { this._setProp('visible', value) }
}

LineChart.gvlPropTypes = {
  scalesY: {
    type: ValueType.Instance,
    collectionItemClass: ScaleY
  },
  series: {
    type: ValueType.Instance,
    collectionItemClass: SeriesItem
  }
}

ScaleY.gvlPropTypes = {
  position: {
    type: ValueType.Enum,
    enumType: ScalePosition
  }
}

export default LineChart