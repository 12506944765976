import { GVLControl } from './elements'
import { DOM } from '@sl/utils'

export default class FallbackView extends GVLControl {
  constructor() {
    super()

    this._keepDomRootByClient = true

    this._props = {
      isPending: false,
      text: '',
    }
  }

  get props() { return this._props }
  set props(value) { this._setProp('props', value) }

  initDomRoot() {
    const $root = DOM('div', 'gvl-control')
    this._domRoot = $root.node

    this.domElements = { $root }
  }

  render() {
    const { $root } = this.domElements
    const { isPending, text } = this.props

    $root.clear()
    $root.toggleClass('mnemo-spinner', isPending)
    $root.setStyles({
      'white-space': !isPending && text !== ''? 'pre': null,
    })

    if (isPending) {
      $root.i('fa fa-spinner fa-spin', { fontSize: '32px' })
    } else if (text !== '') {
      $root.setText(text)
    }
  }
}