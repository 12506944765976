import { GVLControl } from './elements'
import { RectF } from './types'
import { floatEquals, degreesToRadians } from '../std/math'

export class SLImageBox extends GVLControl {
  constructor() {
    super()

    this._keepDomRootByClient = true
    this._horzAlign = 'center' // left, center, right
    this._pictureRotateAngle = 0
    this._img = null
    this._stretch = 'fill' // none, fill, uniform
    this._vertAlign = 'center' // top, center, bottom
    this._viewport = new BoxViewport()
  }

  get horzAlign() { return this._horzAlign }
  set horzAlign(value) { this._setProp('horzAlign', value) }

  get pictureRotateAngle() { return this._pictureRotateAngle }
  set pictureRotateAngle(value) { this._setProp('pictureRotateAngle', value) }

  get img() { return this._img }
  set img(value) { this._setProp('img', value) }

  get stretch() { return this._stretch }
  set stretch(value) { this._setProp('stretch', value) }

  get vertAlign() { return this._vertAlign }
  set vertAlign(value) { this._setProp('vertAlign', value) }

  initDomRoot() {
    this._domRoot = document.createElement('canvas')
    this._domRoot.className = 'gvl-control'
  }

  render() {
    const canvas = this._domRoot
    const ctx = canvas.getContext('2d')

    ctx.clearRect(0, 0, this.width, this.height)

    if (!this._img) {
      return
    }

    switch (this._stretch) {
      case 'none':
        this._renderNoneStretch(ctx)
        break

      case 'fill':
        this._renderFillStretch(ctx)
        break

      case 'uniform':
        this._renderUniformStretch(ctx)
        break
    }
  }

  _renderNoneStretch(ctx) {
    this._updateViewport_None()
    
    const {x, y, w, h, sX, sY, sW, sH} = this._viewport
    
    if (floatEquals(this._pictureRotateAngle, 0)) {
      ctx.drawImage(this.img, sX, sY, sW, sH, x, y, w, h)
    } else {
      const radAngle = degreesToRadians(this._pictureRotateAngle)

      const dx = x + w / 2
      const dy = y + h / 2

      ctx.save()

      ctx.translate(dx, dy)
      ctx.rotate(radAngle)
      ctx.translate(-dx, -dy)

      ctx.drawImage(this.img, sX, sY, sW, sH, x, y, w, h)

      ctx.restore()
    }
  }

  _updateViewport_None() {
    const img = this._img

    const imgW = img.naturalWidth || img.width
    const imgH = img.naturalHeight || img.height

    const vp = this._viewport
    vp.clear()

    vp.w = imgW
    vp.h = imgH
    vp.sW = imgW
    vp.sH = imgH

    switch (this.horzAlign) {
    case 'center':
      vp.x = this.width / 2 - imgW / 2
      break
    case 'right':
      vp.x = this.width - imgW
      break
    }

    switch (this.vertAlign) {
    case 'center':
      vp.y = this.height / 2 - imgH / 2
      break
    case 'bottom':
      vp.y = this.height - imgH
      break
    }
  }

  _renderFillStretch(ctx) {
    const img = this._img

    let x = 0
    let y = 0
    let w = this._width
    let h = this._height

    if (floatEquals(this._pictureRotateAngle, 0)) {
      ctx.drawImage(img, x, y, w, h)
    } else {
      ctx.save()

      switch (this._pictureRotateAngle) {
        case 90:
          w = this._height
          h = this._width
          ctx.translate(this._width, 0)
          break
        case 180:
          ctx.translate(this._width, this._height)
          break
        case 270:
          w = this._height
          h = this._width
          ctx.translate(0, this._height)
          break
      }

      let radAngle = degreesToRadians(this._pictureRotateAngle)

      ctx.rotate(radAngle)
      ctx.drawImage(this.img, x, y, w, h)
      ctx.restore()
    }
  }

  _renderUniformStretch(ctx) {
    const img = this._img

    const imgW = img.naturalWidth || img.width
    const imgH = img.naturalHeight || img.height

    let clientBounds = new RectF()
    clientBounds.setBounds(0, 0, this._width, this._height)

    let imgBounds = new RectF()

    if (this._pictureRotateAngle === 90 || this._pictureRotateAngle === 270) {
      imgBounds.setBounds(0, 0, imgH, imgW)
    } else {
      imgBounds.setBounds(0, 0, imgW, imgH)
    }
    
    imgBounds = imgBounds.fitInto(clientBounds, this._horzAlign, this._vertAlign)

    let {left: x, top: y, width: w, height: h} = imgBounds

    if (floatEquals(this._pictureRotateAngle, 0)) {
      ctx.drawImage(img, x, y, w, h)
    } else {
      ctx.save()

      switch (this._pictureRotateAngle) {
        case 90:
          ctx.translate(h, 0)
          break
        case 180:
          ctx.translate(h, w)
          break
        case 270:
          ctx.translate(0, w)
          break
      }

      let radAngle = degreesToRadians(this._pictureRotateAngle)

      ctx.translate(x, y)
      ctx.rotate(radAngle)
      
      ctx.drawImage(this.img, 0, 0, w, h)
      ctx.restore()
    }
  }

  _propertyChanged(name) {
    super._propertyChanged(name)

    switch (name) {
      case 'width':
        this._domRoot.width = this._width
        this.requestUpdate()
        break
      case 'height':
        this._domRoot.height = this._height
        this.requestUpdate()
        break
    }
  }
}

class BoxViewport {
  constructor() {
    this.clear()
  }

  clear() {
    this.x = 0
    this.y = 0
    this.w = 0
    this.h = 0
    this.sX = 0
    this.sY = 0
    this.sW = 0
    this.sH = 0
    this.scaleX = 1
    this.scaleY = 1
  }
}