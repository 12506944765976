import Button from '@mui/material/Button'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'
import { TextField, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { acknowledge, close } from './AckDialog.slice'
import { useRef } from 'react'

const AcknowledgeDialog = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const commentRef = useRef()

  const open = useSelector(state => state.dialog.acknowledge.open)
  const event = useSelector(state => state.dialog.acknowledge.event)
  const message = useSelector(state => state.dialog.acknowledge.event?.message)

  const handleYes = () => {
    const comment = commentRef.current?.value
    dispatch(acknowledge({ event, comment }))
  }
  const handleCancel = () => dispatch(close())

  return (
    <Dialog
      maxWidth="sm"
      open={open}
    >
      <DialogTitle>{t('dialog.acknowledge.title')}</DialogTitle>
      <DialogContent>
        <Typography>{t('dialog.acknowledge.prompt')}</Typography>
        <Typography variant='subtitle2'>{message}</Typography>
        <TextField
          autoFocus
          margin="dense"
          fullWidth
          inputRef={commentRef}
          label={t('dialog.acknowledge.comment')}
          type="text"
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleYes}>{t('dialog.button.yes')}</Button>
        <Button autoFocus onClick={handleCancel}>
          {t('dialog.button.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AcknowledgeDialog