import { GVLControl } from './elements'

export class FAView extends GVLControl {
  constructor() {
    super()
    this._keepDomRootByClient = true
    this._color = null
    this._iconClass = 'spinner'
    this._spinning = true
    this._size = null
    this._title = ''
  }

  get color() { return this._color }
  set color(value) { this._setProp('color', value) }

  get iconClass() { return this._iconClass }
  set iconClass(value) { this._setProp('iconClass', value) }

  get spinning() { return this._spinning }
  set spinning(value) { this._setProp('spinning', value) }

  get size() { return this._size }
  set size(value) { this._setProp('size', value) }

  get title() { return this._title }
  set title(value) { this._setProp('title', value) }

  initDomRoot() {
    this.defineRoot('div')
    this._domRoot.classList.add('mnemo-spinner')

    let i = document.createElement('i')
    i.className = 'fa fa-spinner fa-spin fa-2x'
    this.$i = i
    this._domRoot.append(i)
  }

  render() {
    const { $i } = this
    $i.className = `fa fa-${this._iconClass}` + (this._spinning? ' fa-spin': '')
    $i.style.color = this._color? this._color: null
    $i.title = this._title

    this._domRoot.style.fontSize = this._size? `${this._size}px`: null
  }
}