
import { stringFirstToLowerCase } from '../GVL/utils'
import { GVLFigureView } from 'GVL/figureView'
import { PipePlot } from './controls/pipes/plot'
import { LinePlot } from './controls/lines/plot'
import { Image } from './controls/image'
import { DeprecatedStub } from './controls/controls'
import OperationEventTable from 'GVL/EventTable'
import { GVLTextBlock } from 'GVL/text'
import { GVLActionButton } from 'GVL/buttons'
import { ValueType } from 'GVL/prop-types'
import { GVLTable, GVLTableRows, GVLTableRow, GVLTableCell } from 'GVL/Table'
import { GVLScrollBox } from 'GVL/controls'
import LineChart from './controls/lineChart'
import DateTimeView from './controls/dateTimeView'
import StateView from './controls/stateView'
import SideStore from 'utils/sideStore'
import Mnemo from 'mnemo/mnemo'
import eventBus from 'services/eventBus'
import MnemoContainer from './controls/container'
import { isDev } from 'utils/common'

const deprecatedControls = Object.freeze({
})

const mnemoControlClasses = {
  PipePlot,
  LinePlot,
  FigureView: GVLFigureView,
  TextBlock: GVLTextBlock,
  Image,
  OperationEventTable,
  ActionButton: GVLActionButton,
  Table: GVLTable,
  ScrollBox: GVLScrollBox,
  TableRows: GVLTableRows,
  TableRow: GVLTableRow,
  TableCell: GVLTableCell,
  LineChart,
  DateTimeView,
  StateView,
  MnemoContainer
}

function getControlClass(type) {
  if (deprecatedControls[type]) {
    return DeprecatedStub
  }

  return mnemoControlClasses[type]
}

export class MnemoDecoder {
  constructor(deps) {
    this._deps = deps
  }

  run(jMnemo, mnemo) {
    const label = 'Decoding mnemo'
    isDev && console.time(label)
    
    mnemo.loading = true
    try {     
      for (var propertyName in jMnemo) {
        switch (propertyName) {
          case 'children':
            this.decodeControls(jMnemo[propertyName], mnemo)
            break

          case 'name':
            continue
          default:
            mnemo[propertyName] = jMnemo[propertyName]
        }
      }

      mnemo.renderControls()
    } finally {
      mnemo.loading = false
      isDev && console.timeEnd(label)
      isDev && console.log(mnemo.name, mnemo)
    }
  }

  decodeControls(jControls, parent) {
    for (var jControl of jControls) {
      var lClass = getControlClass(jControl.type)

      if (!lClass) {
        console.error(`${jControl.type} control type not found for Mnemo`)
        continue
      }

      var control = new lClass()
      parent.addControl(control)

      this._satisfyControlProps(control)
      this.decodeObjectProperties(jControl, control)

      if (!!jControl.children && jControl.children.length > 0) {
        this.decodeControls(jControl.children, control);
      }
    }
  }

  _satisfyControlProps(control) {
    if (control.channelSource === null) {
      control.channelSource = this._deps.channels
    }

    if (control.eventBus === null) {
      control.eventBus = this._deps.eventBus
    }
  }

  decodeObjectProperties(jControl, target)  {
    for (var propertyName in jControl) {
      var instancePropName = stringFirstToLowerCase(propertyName)
      var value = jControl[propertyName]

      if (!(instancePropName in target)) {
        continue
      }

      const destValue = target[instancePropName]

      const { gvlPropTypes: propTypes } = target.__proto__.constructor
      const propType = propTypes !== undefined? propTypes[instancePropName]: undefined

      if (typeof destValue !== 'object') {
        target[instancePropName] = value
        continue
      }

      if (propType) {
        if (propType.type === ValueType.Enum && propType.enumType) {
          const enumValue = parseEnumValue(propType.enumType, value)
          target[instancePropName] = enumValue
          continue
        }

        if (propType.type === ValueType.EnumSet && propType.enumType && Array.isArray(destValue)) {
          const enumValue = this.decodeEnumSet(propType.enumType, value)
          target[instancePropName] = enumValue
          continue
        }
        
        if (propType.type === ValueType.Instance && propType.collectionItemClass) {
          const items = this.decodeCollectionItems(value, propType.collectionItemClass)
          target[instancePropName] = items
          continue
        }
      }

      if (Array.isArray(destValue)) {
        if (!propType) {
          target[instancePropName] = value
        }
      } else {
        this.decodeObjectProperties(value, destValue)
      }
    }
  }

  decodeEnumSet(enumType, value) {
    const stringEnums = value.split(',').map(num => num.trim())

    const result = []

    for (let enumStr of stringEnums) {
      if (enumStr === '') {
        continue
      }

      const enumValue = parseEnumValue(enumType, enumStr)

      if (enumValue) {
        result.push(enumValue)
      } else {
        console.error(`There's no ${enumStr} in the ${enumType.name} enum type. Ignored.`)
      }
    }

    return result
  }

  decodeCollectionItems(jItems, itemClass) {
    let items = []

    for (let jItem of jItems) {
      var item = new itemClass()
      this.decodeObjectProperties(jItem, item)
      items.push(item)
    }

    return items
  }
}

const parseEnumValue = (enumType, value) => {
  const valueUpper = value.toUpperCase()

  for (let ev of Object.keys(enumType)) {
    if (ev.toUpperCase() === valueUpper) {
      return enumType[ev]
    }
  }

  return null
}

export const decodeMnemo = (mnemoOrNull, dto, mnemoName) => {
  const { channels } = SideStore

  const mnemo = mnemoOrNull ?? (new Mnemo())
  mnemo.filePath = '/mnemos/' + mnemoName.replaceAll('.', '/')
  mnemo.name = mnemoName
  
  const decoder = new MnemoDecoder({ 
    channels, eventBus 
  })
  decoder.run(dto, mnemo)

  return mnemo
}