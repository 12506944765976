import { floatEquals, roundTo } from 'std/math'
import { GVLArgumentControl } from './elements'
import { ValueType } from './prop-types'
import { ValueObject } from './types'
import { DOM } from '@sl/utils'
import { enqueueSnackbar } from 'notistack'
import { confirmDialog } from 'actions/mnemo'
import { formatValue, tr } from 'utils/common'
import { Button } from 'features/dialogs/types'

export const Orientation = Object.freeze({
  Horizontal: 'Horizontal',
  Vertical: 'Vertical',
  name: 'Orientation'
})

const ThumbSideOffset = 4

const isTouchMode = () => "ontouchstart" in document.documentElement


export class GVLSlider extends GVLArgumentControl {
  constructor() {
    super()

    this._keepDomRootByClient = true

    this.argumentService = null

    this.background = new SliderBackground()
    this.background.onChange = this._nestedPropChanged

    this.level = new SliderLevel()
    this.level.onChange = this._nestedPropChanged

    this.thumb = new SliderThumb()
    this.thumb.onChange = this._nestedPropChanged

    this.state = {
      writeTimeoutId: undefined,
      value: 40,
    }

    this._min = 0
    this._max = 100
    this._orientation = Orientation.Vertical
    this.writeConfirm = false
    this.writeConfirmText = ''
    this.writeTimeout = 0
  }

  dispose() {
    this.background.dispose()
    this.level.dispose()
    this.thumb.dispose()
    this._nestedPropChanged = null
    super.dispose()
  }

  _nestedPropChanged = () => {
    this.requestUpdate()
  }

  get min() { return this._min }
  set min(value) { this._setProp('min', value) }

  get max() { return this._max }
  set max(value) { this._setProp('max', value) }

  get orientation() { return this._orientation }
  set orientation(value) { this._setProp('orientation', value) }

  initDomRoot() {
    this._thumbMousedown = this._thumbMousedown.bind(this)
    this._thumbMousemove = this._thumbMousemove.bind(this)
    this._thumbMouseup = this._thumbMouseup.bind(this)

    const $back = DOM('div', 'gvl-control', {
      userSelect: 'none',
    })
    this._domRoot = $back.node

    const $wrap = $back.div()

    const $level = $wrap.div()
    const $thumb = $wrap.div()

    if (isTouchMode()) {
      $thumb.on('touchstart', this._thumbMousedown)
    } else {
      $thumb.on('mousedown', this._thumbMousedown)
    }

    this.domElements = { $back, $level, $thumb, $wrap }
  }

  _argumentChanged() {
    super._argumentChanged()

    if (!this.argument) {
      return
    }

    const newValue = this.argument.value

    if (this.state.writeTimeoutId) {
      clearTimeout(this.state.writeTimeoutId)
      this.state.writeTimeoutId = undefined
    }

    if (this.state.value !== newValue) {
      this.state.value = newValue
      this.requestUpdate()
    }
  }

  _thumbMousedown(event) {
    event.preventDefault()
    this._startDrag(event)
    this.requestUpdate()
  }

  _thumbMousemove(event) {
    this._moveDrag(event)
    this.requestUpdate()
  }

  _thumbMouseup(event) {
    if (isTouchMode()) {
      window.removeEventListener('touchmove', this._thumbMousemove)
      window.removeEventListener('touchend', this._thumbMouseup)
    } else {
      window.removeEventListener('mousemove', this._thumbMousemove)
      window.removeEventListener('mouseup', this._thumbMouseup)
    }
    
    this.requestUpdate()

    const { argument, argumentService: service } = this

    let newValue = argument
      ? roundTo(this.state.value, argument.variableFormat)
      : this.state.value

    const drag = this.state.drag
    if (!drag) {
      return
    }

    this.state.drag = null

    if (!service || !argument) {
      if (argument) {
        this.state.value = argument.value
      }
      
      this.requestUpdate()
      return
    }

    (async () => {
      if (this.writeConfirm) {
        const viewValue = formatValue(newValue, {
          fractionDigits: argument.variableFormat,
          type: argument.type.name,
        })
        const text = this.writeConfirmText !== ''
          ? this.writeConfirmText
          : tr('dialog.confirm.writeAction', { value: viewValue })

        const button = await confirmDialog.show(text)
        if (button !== Button.Ok) {
          this.state.value = argument.value
          this.requestUpdate()
          return
        }
      }

      this.state.writeTimeoutId = setTimeout(() => {
        this.state.value = argument.value
        this.requestUpdate()
      }, this.writeTimeout)

      try {
        await service.writeValueAsync(argument, newValue)
      } catch (err) {
        this.state.value = argument.value
        this.requestUpdate()

        enqueueSnackbar(tr('writeValueAction.error', { text: `${err}` }), {
          variant: 'error',
        })
      }
    })()
  }

  _startDrag(event) {
    const { screenX, screenY } = eventToPosition(event)
    const { $back, $level } = this.domElements

    const zoom = getZoom()

    const backRect = $back.node.getBoundingClientRect()
    const levelRect = $level.node.getBoundingClientRect()

    const levelPadding = this.background.strokeWidth
    const maxWidth = zoom * (backRect.width - 2 * levelPadding)
    const maxHeight = zoom * (backRect.height - 2 * levelPadding)

    if (isTouchMode()) {
      window.addEventListener('touchmove', this._thumbMousemove)
      window.addEventListener('touchend', this._thumbMouseup)
    } else {
      window.addEventListener('mousemove', this._thumbMousemove)
      window.addEventListener('mouseup', this._thumbMouseup)
    }

    this.state = {
      ...this.state,
      drag: {
        ...this.state.drag,
        level: {
          maxWidth,
          maxHeight,
          width: levelRect.width * zoom,
          height: levelRect.height * zoom,
        },
        startX: screenX,
        startY: screenY,
      }
    }
  }

  _moveDrag(event) {
    const { screenX, screenY } = eventToPosition(event)
    const drag = this.state.drag
    if (!drag) {
      return
    }

    let value = 0
    const dx = screenX - drag.startX
    const dy = screenY - drag.startY

    switch (this.orientation) {
      case Orientation.Horizontal: {
        const maxWidth = drag.level.maxWidth

        let newW = drag.level.width + dx
        if (newW < 0) {
          newW = 0
        }

        if (newW > maxWidth) {
          newW = maxWidth
        }

        const newFilled = floatEquals(maxWidth, 0, 0.0001)? 0: newW / maxWidth
        value = this._percentageToValue(newFilled)
        break
      }

      case Orientation.Vertical: {
        const maxHeight = drag.level.maxHeight

        let newH = drag.level.height - dy
        if (newH < 0) {
          newH = 0
        }

        if (newH > maxHeight) {
          newH = maxHeight
        }

        const newFilled = floatEquals(maxHeight, 0, 0.0001)? 0: newH / maxHeight
        value = this._percentageToValue(newFilled)
        break
      }
    
      default:
        break;
    }

    this.state = {
      ...this.state,
      value,
    }
  }

  render() {
    const { $back, $level, $thumb, $wrap } = this.domElements

    let backBackground = this.background.fillColor

    if (this.background.imageFileName !== '') {
      const mnemoPath = this.parent.filePath
      const url = encodeURI(`${mnemoPath}/images/${this.background.imageFileName}`)
      backBackground = `url("${url}")`
    }

    $back.setStyles({
      background: backBackground,
      backgroundSize: '100% 100%',
      border: `${this.background.strokeWidth}px solid ${this.background.strokeColor}`,
    })

    const wrapPadding = this.background.strokeWidth

    $wrap.setStyles({
      position: 'relative',
      width: `${this._width}px`,
      height: `${this._height}px`,
      marginLeft: `${-wrapPadding}px`,
      marginTop: `${-wrapPadding}px`,
    })

    $level.setStyles({
      backgroundColor: this.level.fillColor,
    })

    let thumbBackground = this.thumb.fillColor

    if (this.thumb.imageFileName !== '') {
      const mnemoPath = this.parent.filePath
      const url = encodeURI(`${mnemoPath}/images/${this.thumb.imageFileName}`)
      thumbBackground = `url("${url}")`
    }

    $thumb.setStyles({
      background: thumbBackground,
      backgroundSize: '100% 100%',
      border: `${this.thumb.strokeWidth}px solid ${this.thumb.strokeColor}`,
      position: 'absolute',
    })

    let filled = this._getCurrentFilled()

    switch (this.orientation) {
      case Orientation.Horizontal:
        this._renderHorz({ filled })
        break

      case Orientation.Vertical:
        this._renderVert({ filled })
        break

      default:
        console.error(`GVLSlider: unknown orientation ${this.orientation?.name}`)
    }
  }

  _renderHorz({ filled }) {
    const { $level, $thumb } = this.domElements

    const padding = this.background.strokeWidth
    const level = {
      x: padding,
      y: padding,
      w: this.width - 2 * padding,
      h: this.height - 2 * padding,
    }

    const fillW = Math.round(level.w * filled)
    const fillX = fillW

    $level.setStyles({
      transform: `translate(${level.x}px, ${level.y}px)`,
      width: `${fillW}px`,
      height: `${level.h}px`,
    })

    const thumbBackSize = this._height + ThumbSideOffset * 2
    const thumbW = this.thumb.mainSize

    $thumb.setStyles({
      width: `${thumbW}px`,
      height: `${thumbBackSize}px`,
      left: `${fillX - 0.5 * thumbW}px`,
      top: `${this._height / 2 - thumbBackSize / 2}px`
    })
  }

  _renderVert({ filled }) {
    const { $level, $thumb } = this.domElements

    const padding = this.background.strokeWidth
    const level = {
      x: padding,
      y: padding,
      w: this.width - 2 * padding,
      h: this.height - 2 * padding,
    }

    const fillH = Math.round(level.h * filled)
    const fillY = level.h - fillH

    $level.setStyles({
      transform: `translate(${level.x}px, ${level.y + fillY}px)`,
      width: `${level.w}px`,
      height: `${fillH}px`,
    })

    const thumbBackSize = this._width + ThumbSideOffset * 2
    const thumbH = this.thumb.mainSize

    $thumb.setStyles({
      width: `${thumbBackSize}px`,
      height: `${thumbH}px`,
      left: `${this._width / 2 - thumbBackSize / 2}px`,
      top: `${fillY - 0.5 * thumbH}px`
    })
  }

  _getCurrentFilled() {
    return this._valueToPercentage(this.state.value)
  }

  _valueToPercentage(src) {
    let lMin = Math.min(this.min, this.max)
    let lMax = Math.max(this.min, this.max)

    const rangeLen = Math.abs(lMax - lMin)
    let scaleValue = src - lMin
    
    let filled = floatEquals(rangeLen, 0, 0.0001)
      ? 0
      : scaleValue / rangeLen

    filled = filled > 1? 1: filled
    filled = filled < 0? 0: filled
    return filled
  }

  _percentageToValue(src) {
    let lMin = Math.min(this.min, this.max)
    let lMax = Math.max(this.min, this.max)
    const rangeLen = Math.abs(lMax - lMin)
    let value = rangeLen * src + lMin
    value = value > lMax? lMax: value
    value = value < lMin? lMin: value

    return value
  }

  _propertyChanged(name) {
    super._propertyChanged(name)

    if (!this._domRoot) {
      return
    }

    switch (name) {
      case 'width':
      case 'height':
        this.requestUpdate()
        break
    }
  }
}

GVLSlider.gvlPropTypes = {
  orientation: {
    type: ValueType.Enum,
    enumType: Orientation,
  }
}

const eventToPosition = (event) => {
  const { targetTouches } = event
  let screenX = event.screenX
  let screenY = event.screenY

  if (targetTouches && targetTouches.length > 0) {
    screenX = targetTouches[0].screenX
    screenY = targetTouches[0].screenY
  }

  return { screenX, screenY }
}

const getZoom = () => {
  if (isTouchMode()) {
    const viewport = window.visualViewport
    return viewport
      ? viewport.scale
      : 1
  }

  return window.devicePixelRatio
}

export class SliderBackground extends ValueObject {
  constructor() {
    super()
    this._fillColor = '#777777'
    this._imageFileName = ''
    this._strokeColor = '#000000'
    this._strokeWidth = 2
  }

  get fillColor() { return this._fillColor }
  set fillColor(value) { this._setProp('fillColor', value) }

  get imageFileName() { return this._imageFileName }
  set imageFileName(value) { this._setProp('imageFileName', value) }

  get strokeColor() { return this._strokeColor }
  set strokeColor(value) { this._setProp('strokeColor', value) }

  get strokeWidth() { return this._strokeWidth }
  set strokeWidth(value) { this._setProp('strokeWidth', value) }
}

export class SliderLevel extends ValueObject {
  constructor() {
    super()
    this._fillColor = '#BAFFFF'
  }

  get fillColor() { return this._fillColor }
  set fillColor(value) { this._setProp('fillColor', value) }
}

export class SliderThumb extends ValueObject {
  constructor() {
    super()
    this._fillColor = '#A2F2F2'
    this._imageFileName = ''
    this._mainSize = 10
    this._strokeColor = '#DCE0E0'
    this._strokeWidth = 1
  }

  get fillColor() { return this._fillColor }
  set fillColor(value) { this._setProp('fillColor', value) }

  get imageFileName() { return this._imageFileName }
  set imageFileName(value) { this._setProp('imageFileName', value) }

  get mainSize() { return this._mainSize }
  set mainSize(value) { this._setProp('mainSize', value) }

  get strokeColor() { return this._strokeColor }
  set strokeColor(value) { this._setProp('strokeColor', value) }

  get strokeWidth() { return this._strokeWidth }
  set strokeWidth(value) { this._setProp('strokeWidth', value) }
}