import { MnemoControl } from '../controls'
import { PointF } from 'GVL/types'
import { Color } from 'std/utils'
import { CornerPipe, HorzPipe, VertPipe, TeePipe, CrossPipe } from './pipe'
import collectIntersections, { IntersectionType } from './intersections'
import { GVLRect } from 'GVL/primitives'
import { BrushKind, ColorStop } from 'GVL/elements'
import { RuptureCap } from './caps'

export class PipePlot extends MnemoControl {
  constructor() {
    super()

    this._keepDomRootByClient = true

    this._color = '#F0F0F0'
    this._primaryColor = this._getPrimaryColor()
    this.endCap = 'none'
    this.pipeSize = 20
    this.segments = []
    this.startCap = 'none'
  }

  get color() { return this._color }
  set color(value) { this._setProp('color', value) }

  initDomRoot() {
    this.defineRoot('div')
  }

  render() {
    const parsePointF = (str) => {
      const args = str.split(', ').map(it => parseFloat(it))
      let p = new PointF()
      p.setXY(...args)
      return p
    }

    const segments = this.segments.map(it => {
      let props = new SegmentProps()
      props.isHorizontal = it.orientation === 'horizontal'
      props.direction = it.direction
      props.start = parsePointF(it.start)
      props.end = parsePointF(it.ending)

      return props
    })

    this.removeControlChildren()

    const segmentPipeMap = new Map()

    const pipeSize = this.pipeSize
    const pipeSize2 = pipeSize / 2

    for (let segment of segments) {
      if (segment.isHorizontal) {
        const pipe = new HorzPipe()
        this.addControl(pipe)

        const width = segment.end.x - segment.start.x

        pipe.setBounds({ 
          left: segment.start.x,
          top: segment.start.y - pipeSize2,
          width,
          height: pipeSize
        })

        segmentPipeMap.set(segment, pipe)
        this._setUpPreferences(pipe)
      } else {
        const pipe = new VertPipe()
        this.addControl(pipe)

        const height = segment.end.y - segment.start.y

        pipe.setBounds({
          left: segment.start.x - pipeSize2,
          top: segment.start.y,
          width: pipeSize,
          height
        })

        segmentPipeMap.set(segment, pipe)
        this._setUpPreferences(pipe)
      }
    }

    const intersections = collectIntersections(segments)

    this._considerSpawnCaps(segments)

    for (let inter of intersections) {
      switch (inter.type) {
        case IntersectionType.Corner:
          {
            let corner = new CornerPipe()
            this.addControl(corner)

            corner.kind = inter.cornerKind
            corner.setBounds({
              left: inter.location.x - pipeSize2,
              top: inter.location.y - pipeSize2,
              width: pipeSize,
              height: pipeSize
            })

            this._setUpPreferences(corner)

            for (let segment of inter.segments) {
              const pipe = segmentPipeMap.get(segment)

              pipe && reducePipeSideForCorner(corner, pipe)
            }
          }
          break

        case IntersectionType.Tee:
          {
            let tee = new TeePipe()
            this.addControl(tee)

            tee.kind = inter.teeKind
            tee.setBounds({
              left: inter.location.x - pipeSize2,
              top: inter.location.y - pipeSize2,
              width: pipeSize,
              height: pipeSize
            })
            this._setUpPreferences(tee)
          }
          break

        case IntersectionType.Cross:
          {
            let cross = new CrossPipe()
            this.addControl(cross)
            cross.setBounds({
              left: inter.location.x - pipeSize2,
              top: inter.location.y - pipeSize2,
              width: pipeSize,
              height: pipeSize
            })
            this._setUpPreferences(cross)
          }
          break
      }
    }
  }

  _propertyChanged(name) {
    super._propertyChanged(name)

    switch (name) {
      case 'color':
        this._primaryColor = this._getPrimaryColor()
        break
    }
  }

  _considerSpawnCaps(segments) {
    if (segments.length === 0) {
      return
    }

    const first = segments[0]
    const last = segments[segments.length - 1]

    if (this.startCap !== 'none') {
      switch (first.direction) {
        case 'direct':
          this._spawnCap(this.startCap, first.start, first.isHorizontal? 'left': 'top')
          break

        case 'back':
          this._spawnCap(this.startCap, first.end, first.isHorizontal? 'right': 'bottom')
          break
      }
    }

    if (this.endCap !== 'none') {
      switch (last.direction) {
        case 'direct':
          this._spawnCap(this.endCap, last.end, last.isHorizontal? 'right': 'bottom')
          break

        case 'back':
          this._spawnCap(this.endCap, last.start, last.isHorizontal? 'left': 'top')
          break
      }
    }
  }

  _spawnCap(kind, point, side) {
    let cap = null

    switch (kind) {
      case 'flange':
        cap = createFlange(this, this.pipeSize, this._primaryColor, this.color)
        break

      case 'rupture':
        cap = createRupture(this, this.pipeSize, this._primaryColor, this.color)
        break

      default:
        return
    }

    cap.rotateCenterX = 0
    cap.rotateCenterY = 0.5
    cap.left = point.x
    cap.top = point.y - cap.height / 2    
    cap.rotateAngle = rotationAngleBySideMap[side]
  }

  _setUpPreferences(pipe) {
    pipe.primaryColor = this._primaryColor
    pipe.secondaryColor = this.color
  }

  _getPrimaryColor() {
    let c = new Color(this.color)
    c.changeHSL(0, 0, -0.3)
    return c.toString()
  }
}

const rotationAngleBySideMap = {
  'top': -90,
  'right': 0,
  'bottom': 90,
  'left': 180
}

const createFlange = (parent, pipeSize, primaryColor, secondaryColor) => {
  let r = new GVLRect()
  parent.addControl(r)
  r.stroke.width = 1
  r.stroke.color = '#000000'
  r.fill.kind = BrushKind.Gradient

  const { gradient } = r.fill
  const { colorStops } = gradient

  let stop = new ColorStop(primaryColor, 0)
  colorStops.push(stop)

  stop = new ColorStop(secondaryColor, 0.5)
  colorStops.push(stop)

  stop = new ColorStop(primaryColor, 1)
  colorStops.push(stop)

  r.width = 8
  r.height = pipeSize * 1.5

  gradient.startAt = new PointF(0, 0)
  gradient.endAt = new PointF(0, 1)

  return r
}

const createRupture = (parent, pipeSize, primaryColor, secondaryColor) => {
  const c = new RuptureCap()
  parent.addControl(c)
  c.pipeSize = pipeSize
  c.primaryColor = primaryColor
  c.secondaryColor = secondaryColor
  c.render()
  return c
}

const reducePipeSideForCorner = (corner, pipe) => {
  // нужно узнать с какой стороны происходит пересечение с этой стороны уменьшить трубу

  // проверка сверху
  if (['topLeft', 'topRight'].includes(corner.kind)) {
    if (pipe instanceof VertPipe) {
      pipe.height -= corner.height / 2
      return
    }
  }

  // проверка справа
  if (['topRight', 'bottomRight'].includes(corner.kind)) {
    if (pipe instanceof HorzPipe) {
      pipe.left += corner.width / 2
      pipe.width -= corner.width / 2
      return
    }
  }

  // проверка снизу
  if (['bottomLeft', 'bottomRight'].includes(corner.kind)) {
    if (pipe instanceof VertPipe) {
      pipe.top += corner.height / 2
      pipe.height -= corner.height / 2
      return
    }
  }

  // проверка слева
  if (['topLeft', 'bottomLeft'].includes(corner.kind)) {
    if (pipe instanceof HorzPipe) {
      pipe.width -= corner.width / 2
      return
    }
  }
}

class SegmentProps {
  constructor() {
    this.direction = 'direct'
    this.isHorizontal = true

    this.start = new PointF()
    this.end = new PointF()
  }

  isNormalized() {
    const distance = this.isHorizontal
      ? this.end.x - this.start.x
      : this.end.y - this.start.y 

    return distance >= 0
  }

  normalize() {
    if (this.isNormalized) {
      return
    }

    let temp = this.start
    this.start = this.end
    this.end = temp
  }
}