import { signIn } from '../../services/webServer'
import { setUser } from '../../reducers/authSlice'

const ACTION_LOGIN_PAGE__SET_USERNAME = 'LoginPage/SetUserName'
const ACTION_LOGIN_PAGE__SET_PASSWORD = 'LoginPage/SetPassword'
const ACTION_LOGIN_PAGE__SET_LOGGING_IN = 'LoginPage/SetLoggingIn'
const ACTION_LOGIN_PAGE__SET_ERROR_TEXT = 'LoginPage/SetErrorText'

export const LoginPage_SetUsername = value => ({ type: ACTION_LOGIN_PAGE__SET_USERNAME, payload: value })
export const LoginPage_SetPassword = value => ({ type: ACTION_LOGIN_PAGE__SET_PASSWORD, payload: value })
export const LoginPage_SetLoggingIn = value => ({ type: ACTION_LOGIN_PAGE__SET_LOGGING_IN, payload: value })
export const LoginPage_SetErrorText = value => ({ type: ACTION_LOGIN_PAGE__SET_ERROR_TEXT, payload: value })
export const LoginPage_LogIn = (client, t) => async (dispatch, getState) => {
  const state = getState()
  const { username, password } = state.loginPage
  let body = null

  dispatch(LoginPage_SetErrorText(''))
  dispatch(LoginPage_SetLoggingIn(true))
  try {
    try {
      const res = await signIn(username, password)
      body = res.data
    } catch {
      dispatch(LoginPage_SetErrorText(t('errors.signInFailed')))
      return
    }
    
    const { token } = body
    dispatch(LoginPage_SetUsername(''))
    dispatch(LoginPage_SetPassword(''))
    dispatch(setUser({ username, token }))

    client.connect()
  } catch (error) {
    if (error.response) {
      dispatch(LoginPage_SetErrorText(error.response.statusText))
    } else {
      dispatch(LoginPage_SetErrorText(t('errors.unknown')))
      throw error;
    }
  } finally {
    dispatch(LoginPage_SetLoggingIn(false))
  }
}


const initialState = {
  username: '',
  password: '',
  isLoggingIn: false,
  errorText: ''
};

function loginPageReducer(state = initialState, action) {
  const { payload } = action;

  switch (action.type) {
    case ACTION_LOGIN_PAGE__SET_ERROR_TEXT: {
      return {
        ...state,
        errorText: payload
      }
    }

    case ACTION_LOGIN_PAGE__SET_LOGGING_IN: {
      return {
        ...state,
        isLoggingIn: payload
      }
    }

    case ACTION_LOGIN_PAGE__SET_USERNAME: {
      return {
        ...state,
        username: payload
      }
    }

    case ACTION_LOGIN_PAGE__SET_PASSWORD: {
      return {
        ...state,
        password: payload
      }
    }
    default:
      return state;
  }
}

export default loginPageReducer;