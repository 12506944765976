import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  items: {
    mnemos: { visible: false },
    channels: { visible: false },
    eventLog: { visible: false },
    chartViewer: { visible: false },
    user: { visible: false },
    about: { visible: false },
    controlPanel: { visible: false },
  },
  visible: false
}

const slice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    menuItemsReceived: (state, { payload }) => {
      state.items = payload

      // Если есть хотя бы один видимый пункт в меню, то отображаем его
      state.visible = Object.keys(state.items)
        .map(key => state.items[key].visible)
        .reduce((prev, current) => prev || current, false)
    }
  }
})

export const selectMenuVisible = (menuName) => state => state.menu.items[menuName].visible

export const { menuItemsReceived } = slice.actions
export default slice.reducer