import { combineReducers } from 'redux'
import apis from './apisSlice'
import live from 'features/live/liveSlice'
import alarm from 'features/live/alarmSlice'
import auth from './authSlice'
import client from './clientSlice'
import reservation from './reservationSlice'
import dialog from './dialogSlice'
import menu from '../components/MainMenu/MainMenuSlice'
import loginPage from '../features/loginPage/loginPageSlice'
import eventLogPage from '../features/eventlog/eventLogSlice'
import eventDetails from '../features/eventlog/eventDetailsSlice'
import chartPage from '../features/chart/chartSlice'
import controlPanel from '../features/controlPanelPage/controlPanelSlice'
import splash from '../features/splashPage/splashSlice'
import mnemoPage from 'features/mnemoPage/mnemoPageSlice'

const rootReducer = combineReducers({
  apis,
  alarm,
  auth,
  live,
  client,
  reservation,
  dialog,
  menu,
  mnemoPage,
  loginPage,
  eventLogPage,
  eventDetails,
  chartPage,
  controlPanel,
  splash,
})

export default rootReducer