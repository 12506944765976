import structuredClone from '@ungap/structured-clone'
import { normalizeChannelValue } from 'utils/common'
import { AlarmStatus } from 'utils/runtime'

const CHANNELS_CHANGED = 'Live/ChannelsChanged'
const CHANNELS_NEW = 'Live/ChannelsNew'
const EVENT_CATS_NEW = 'Live/EventCatsNew'
const PROJECT_TREE_NEW = 'Live/ProjectTreeNew'

export const channelsChanged = (channels) => ({ type: CHANNELS_CHANGED, payload: channels })
export const channelsNew = (channels) => ({ type: CHANNELS_NEW, payload: channels })
export const eventCatsNew = (payload) => ({ type: EVENT_CATS_NEW, payload })
export const projectTreeNew = (payload) => ({ type: PROJECT_TREE_NEW, payload })

export const channelsUpdates = (updates) => (dispatch, getState) => {
  const state = getState()
  const idIndex = state.live.channels.idIndex
  const oldChannels = state.live.channels.items

  const channels = oldChannels.slice(0)

  for (const update of updates) {
    const index = idIndex[update.channelID]
    if (index === undefined) {
      continue
    }

    const channel = channels[index]
    if (!channel) {
      continue
    }

    let newAlarmStatus = AlarmStatus.names[update.alarmStatus]

    const value = normalizeChannelValue(channel.type, update.value)
    const quality = update.quality
    const lastUpdatedAt = update.timestamp
    const alarmAcked = !!update.alarmAcked
    const alarmStatus = newAlarmStatus
    let alarmStartTime = channel.alarmStartTime
    const bitTags = update_decodeBitTags(update.bitTags)

    if (channel.alarmStatus !== AlarmStatus.Normal.name) {
      alarmStartTime = new Date()
    }

    channels[index] = {
      ...channel,
      value,
      quality,
      lastUpdatedAt,
      anyUpdate: true,
      alarmAcked,
      alarmStatus,
      alarmStartTime,
      bitTags
    }
  }

  dispatch(channelsChanged(channels))
}

const update_decodeBitTags = tags => {
  return tags.map(tag => ({
    alarm: {
      acked: tag.alarm.acked,
      status: AlarmStatus.names[tag.alarm.status]
    },
    bit: tag.bit,
  }))
}


const initialState = {
  channels: {
    idIndex: {},
    items: []
  },
  eventCats: {
    idIndex: {},
    items: []
  },
  projectTree: {
    root: null
  }
}

function liveReducer(state = initialState, action) {
  switch (action.type) {

    case CHANNELS_CHANGED: {
      const items = action.payload
      return {
        ...state,
        channels: {
          ...state.channels,
          items
        }
      }
    }

    case CHANNELS_NEW: {
      let items = action.payload

      items = items.map(it => ({
        ...it,
        anyUpdate: false,
        alarmStartTime: new Date()
      }))

      const idIndex = createChannelIndexMap(items)

      return {
        ...state,
        channels: {
          ...state.channels,
          idIndex,
          items
        }
      }
    }

    case EVENT_CATS_NEW: {
      const items = structuredClone(action.payload)
      const idIndex = createEventCatIndexMap(items)

      return {
        ...state,
        eventCats: {
          ...state.eventCats,
          idIndex,
          items
        }
      }
    }

    case PROJECT_TREE_NEW: {
      const root = decodeProjectTree(action.payload)

      return {
        ...state,
        projectTree: {
          ...state.projectTree,
          root
        }
      }
    }

    default:
      return state
  }
}

const decodeProjectTree = (srcNodes) => {

  let ctx = {
    id: 0
  }

  return {
    id: `${ctx.id++}`,
    name: '<root>',
    type: 'root',
    children: decodeProjectTreeNodes(ctx, srcNodes)
  }
}

const decodeProjectTreeNodes = (ctx, srcNodes) => {
  return srcNodes.map(it => {
    const res = {
      id: `${ctx.id++}`,
      name: it.name,
      type: it.type,
      passportID: it.passportID,
      children: !!it.children? decodeProjectTreeNodes(ctx, it.children): []
    }

    return res
  })
}

const createChannelIndexMap = (channels) => {
  let map = {}

  let index = 0
  for (let channel of channels) {
    map[channel.ID] = index
    index++
  }

  return map
}

const createEventCatIndexMap = (cats) => {
  let map = {}

  let index = 0
  for (let cat of cats) {
    map[cat.ID] = index
    index++
  }

  return map
}

export const selectChannelByID = (state, channelID) => {
  const { idIndex, items } = state.live.channels
  const index = idIndex[channelID]
  if (index === undefined) {
    return null
  }

  return items[index]
}

export const selectChannelByName = (state, channelName) => {
  const { items } = state.live.channels

  for (let item of items) {
    if (item.name === channelName) {
      return item
    }
  }

  return
}

export const selectEventCatByID = (state, eventCatID) => {
  const { idIndex, items } = state.live.eventCats
  const index = idIndex[eventCatID]

  if (index === undefined) {
    if (eventCatID === 0) {
      return null
    }

    return selectCommonEventCat(state)
  }

  return items[index]
}

export const selectCommonEventCat = (state) => selectEventCatByID(state, 0)

export default liveReducer;