import { createSlice } from '@reduxjs/toolkit'
import { RequestSubject } from 'services/NetClient'
import { channelsNew, eventCatsNew, projectTreeNew } from 'features/live/liveSlice'
import { menuItemsReceived } from 'components/MainMenu/MainMenuSlice'
import { getAutorunMnemos, getProjectTreeNodes, getReservation } from 'services/webServer';
import { optionsChanged } from '../../reducers/reservationSlice'
import keyMirror from 'key-mirror'
import db from '../../services/db'
import { startAlarms } from 'features/live/alarmSlice'
import { fetchPermissions } from 'reducers/authSlice'

export const Stage = keyMirror({
  EventCategories: null,
  Channels: null,
  Menu: null,
  Reservation: null,
  StartMnemo: null,
})

const initialState = {
  isFetching: false,
  fetchError: '',
  stage: Stage.EventCategories,
  visible: false
}

const slice = createSlice({
  name: 'splash',
  initialState,
  reducers: {
    nextStage: (state, { payload }) => {
      state.stage = payload
    },
    startFetch: (state) => {
      state.isFetching = true
      state.fetchError = ''
      state.stage = Stage.EventCategories
      state.visible = true
    },
    stopFetch: (state) => {
      state.isFetching = false
      state.fetchError = ''
      state.visible = false
    },
    rejectFetch: (state, { payload }) => {
      state.isFetching = false
      state.fetchError = payload
    }
  }
})

const getChannels = async (client) => {
  const localETag = localStorage.getItem("tags.etag")

  const res = await client.get(RequestSubject.Channels, { ETag: localETag });
  let { etag, tags } = res.data

  if (localETag !== etag) {
    localStorage.setItem("tags.etag", `${etag}`)

    await db.tags.clear()
    await db.tags.bulkAdd(tags)
  } else {
    tags = await db.tags.toArray()
  }

  return tags
}

export const fetchResources = (client) => async (dispatch) => {
  dispatch(startFetch())
  try {
    let res = await client.get(RequestSubject.EventCategories, { format: 'web' });
    let dtoEventCats = res.data

    dispatch(eventCatsNew(dtoEventCats))
    dispatch(fetchPermissions())
    dispatch(nextStage(Stage.Channels))

    const tags = await getChannels(client)

    dispatch(channelsNew(tags))

    res = await getProjectTreeNodes()
    dispatch(projectTreeNew(res.data))

    dispatch(nextStage(Stage.Menu))

    res = await client.get(RequestSubject.Menu)
    let dtoMenu = res.data

    dispatch(nextStage(Stage.Reservation))
    res = await getReservation();
    let newReservation = res.data

    dispatch(menuItemsReceived(dtoMenu.items))
    dispatch(startAlarms())
    dispatch(optionsChanged(newReservation))

    dispatch(nextStage(Stage.StartMnemo))

    const { data: mnemos } = await getAutorunMnemos()

    dispatch(stopFetch())

    window.top.postMessage({
      cmd: 'applyLayout',
      data: mnemos,
    }, '*')
  } catch (error) {
    console.error(error)
    dispatch(rejectFetch(error.toString()))
  }
}

export const { startFetch, stopFetch, rejectFetch, nextStage } = slice.actions

export default slice.reducer