import { 
  FillBrush, 
  Stroke, BrushKind, Gradient, ColorStop, StrokeStyle, GVLFont, FontStyle,
  GVLGroup, GVLElement, BrushImage, BrushImageStretch, GradientKind
} from './elements'
import { GVLRect, GVLEllipse, GVLArc, GVLPath, GVLPathPoint, LineCap, LineCapEnding, GVLBezierPath } from './primitives'
import { GVLWindow, GVLArgument, GVLBinding, GVLPropertySelector, ArgumentType } from './window'
import { ScaleOrientation, TickSide, GVLScale, GVLArcScale } from './scales'
import { GVLTextBlock } from './text'
import { GVLArgumentEdit } from './edit'
import { GVLFloatAnimation, InterpolationType, GVLBooleanAnimation, GVLColorAnimation } from './animation'
import { HorzAlign, VertAlign, ViewportStretch } from './types'
import { GVLScrollBox } from './controls'
import { GVLTable, GVLTableRows, GVLTableRow, GVLTableCell, 
  GVLColumn, ColumnAlign, ColumnEditable, ColumnValueSource, CellAlign, CellEditable, CellValueSource,
  GVLTableHeader } from './Table'
import { GVLImage } from './image'
import GVLOperationEventTable, { GVLOperationEventTable_Header } from './EventTable'
import { ActionProps, GVLActionButton } from './buttons'
import { ValueType } from './prop-types'
import { GVLSlider, Orientation, SliderBackground, SliderLevel, SliderThumb } from './slider'
import { GVLSVGBox } from './svgBox'

function createProperties(options)  {
  for (let key in options) {
    options[key].name = key
  }

  return options
}

export const controlsRtti = Object.freeze({
  GVLWindow: {
    metaclass: GVLWindow,
    properties: createProperties({
      arguments: {
        type: ValueType.Instance,
        collectionItemClass: GVLArgument
      },
      bindings: {
        type: ValueType.Instance,
        collectionItemClass: GVLBinding
      },
      fill: {
        type: ValueType.Instance,
        metaclass: FillBrush
      },
      left: {
        type: ValueType.Single
      },
      top: {
        type: ValueType.Single
      },
      width: {
        type: ValueType.Single
      },
      height: {
        type: ValueType.Single
      },
      viewportStretch: {
        type: ValueType.Enum,
        enumType: ViewportStretch
      },
      visible: {
        type: ValueType.Boolean
      }
    })
  },
  GVLArgument: {
    metaclass: GVLArgument,
    properties: createProperties({
      id: {
        type: ValueType.Integer
      },
      name: {
        type: ValueType.String
      },
      typ: {
        type: ValueType.Enum,
        enumType: ArgumentType
      },
      defaultValue: {
        type: ValueType.String
      }
    })
  },
  GVLBinding: {
    metaclass: GVLBinding,
    properties: createProperties({
      expression: {
        type: ValueType.String
      },
      targets: {
        type: ValueType.Instance,
        collectionItemClass: GVLPropertySelector
      },
      valueFormat: {
        type: ValueType.Integer
      },
    })
  },
  GVLPropertySelector: {
    metaclass: GVLPropertySelector,
    properties: createProperties({
      element: {
        type: ValueType.Instance,
        metaclass: GVLElement
      },
      propertyName: {
        type: ValueType.String
      }
    })
  },
  FillBrush: {
    metaclass: FillBrush,
    properties: createProperties({
      color: {
        type: ValueType.AlphaColor
      },
      gradient: {
        type: ValueType.Instance
      },
      image: {
        type: ValueType.Instance
      },
      kind: {
        type: ValueType.Enum,
        enumType: BrushKind
      },
      leveling: {
        type: ValueType.Boolean
      },
      levelProcent: {
        type: ValueType.Single
      }
    })
  },

  BrushImage: {
    metaclass: BrushImage,
    properties: createProperties({
      fileName: {
        type: ValueType.String
      },
      stretch: {
        type: ValueType.Enum,
        enumType: BrushImageStretch
      }
    })
  },

  Gradient: {
    metaclass: Gradient,
    properties: createProperties({
      centerX: {
        type: ValueType.Single,
      },
      centerY: {
        type: ValueType.Single,
      },
      colorStops: {
        type: ValueType.Instance,
        collectionItemClass: ColorStop
      },
      endAt: {
        type: ValueType.PointF
      },
      kind: {
        type: ValueType.Enum,
        enumType: GradientKind,
      },
      radius: {
        type: ValueType.Single,
      },
      startAt: {
        type: ValueType.PointF
      }
    })
  },

  ColorStop: {
    metaclass: ColorStop,
    properties: createProperties({
      color: {
        type: ValueType.AlphaColor
      },
      position: {
        type: ValueType.Single
      }
    })
  },
  Stroke: {
    metaclass: Stroke,
    properties: createProperties({
      color: {
        type: ValueType.AlphaColor
      },
      roundCap: {
        type: ValueType.Boolean
      },
      style: {
        type: ValueType.Enum,
        enumType: StrokeStyle
      },
      width: {
        type: ValueType.Single
      }
    })
  },
  GVLRect: {
    metaclass: GVLRect,
    properties: createProperties({
      fill: {
        type: ValueType.Instance,
        metaclass: FillBrush
      },
      left: {
        type: ValueType.Single
      },
      name: {
        type: ValueType.String
      },
      top: {
        type: ValueType.Single
      },
      width: {
        type: ValueType.Single
      },
      height: {
        type: ValueType.Single
      },
      radiusX: {
        type: ValueType.Single
      },
      radiusY: {
        type: ValueType.Single
      },
      rotateAngle: {
        type: ValueType.Single
      },
      rotateCenterX: {
        type: ValueType.Single
      },
      rotateCenterY: {
        type: ValueType.Single
      },
      visible: {
        type: ValueType.Boolean
      },
      stroke: {
        type: ValueType.Instance,
        metaclass: Stroke
      }
    })
  },
  GVLEllipse: {
    metaclass: GVLEllipse,
    properties: createProperties({
      fill: {
        type: ValueType.Instance,
        metaclass: FillBrush
      },
      left: {
        type: ValueType.Single
      },
      name: {
        type: ValueType.String
      },
      top: {
        type: ValueType.Single
      },
      width: {
        type: ValueType.Single
      },
      height: {
        type: ValueType.Single
      },
      rotateAngle: {
        type: ValueType.Single
      },
      rotateCenterX: {
        type: ValueType.Single
      },
      rotateCenterY: {
        type: ValueType.Single
      },
      visible: {
        type: ValueType.Boolean
      },
      stroke: {
        type: ValueType.Instance,
        metaclass: Stroke
      }
    })
  },
  GVLArc: {
    metaclass: GVLArc,
    properties: createProperties({
      connectCenter: {
        type: ValueType.Boolean
      },
      fill: {
        type: ValueType.Instance,
        metaclass: FillBrush
      },
      left: {
        type: ValueType.Single
      },
      name: {
        type: ValueType.String
      },
      top: {
        type: ValueType.Single
      },
      width: {
        type: ValueType.Single
      },
      height: {
        type: ValueType.Single
      },
      rotateAngle: {
        type: ValueType.Single
      },
      rotateCenterX: {
        type: ValueType.Single
      },
      rotateCenterY: {
        type: ValueType.Single
      },
      visible: {
        type: ValueType.Boolean
      },
      startAngle: {
        type: ValueType.Single
      },
      sweepAngle: {
        type: ValueType.Single
      },
      stroke: {
        type: ValueType.Instance,
        metaclass: Stroke
      }
    })
  },
  GVLPath: {
    metaclass: GVLPath,
    properties: createProperties({
      endCap: {
        type: ValueType.Instance,
        metaclass: LineCap
      },
      fill: {
        type: ValueType.Instance,
        metaclass: FillBrush
      },
      left: {
        type: ValueType.Single
      },
      name: {
        type: ValueType.String
      },
      top: {
        type: ValueType.Single
      },
      width: {
        type: ValueType.Single
      },
      height: {
        type: ValueType.Single
      },
      points: {
        type: ValueType.Instance,
        collectionItemClass: GVLPathPoint
      },
      rotateAngle: {
        type: ValueType.Single
      },
      rotateCenterX: {
        type: ValueType.Single
      },
      rotateCenterY: {
        type: ValueType.Single
      },
      visible: {
        type: ValueType.Boolean
      },
      startCap: {
        type: ValueType.Instance,
        metaclass: LineCap
      },
      stroke: {
        type: ValueType.Instance,
        metaclass: Stroke
      },
      viewSize: {
        type: ValueType.PointF
      }
    })
  },
  GVLPathPoint: {
    metaclass: GVLPathPoint,
    properties: createProperties({
      x: {
        type: ValueType.Single
      },
      y: {
        type: ValueType.Single
      }
    })
  },
  LineCap: {
    metaclass: LineCap,
    properties: createProperties({
      ending: {
        type: ValueType.Enum,
        enumType: LineCapEnding
      },
      size: {
        type: ValueType.Integer
      }
    })
  },

  GVLBezierPath: {
    metaclass: GVLBezierPath,
    properties: createProperties({
      fill: {
        type: ValueType.Instance,
        metaclass: FillBrush
      },
      left: {
        type: ValueType.Single
      },
      name: {
        type: ValueType.String
      },
      top: {
        type: ValueType.Single
      },
      width: {
        type: ValueType.Single
      },
      height: {
        type: ValueType.Single
      },
      rotateAngle: {
        type: ValueType.Single
      },
      rotateCenterX: {
        type: ValueType.Single
      },
      rotateCenterY: {
        type: ValueType.Single
      },
      visible: {
        type: ValueType.Boolean
      },
      stroke: {
        type: ValueType.Instance,
        metaclass: Stroke
      },
      data: {
        type: ValueType.String
      }
    })
  },

  GVLFont: {
    metaclass: GVLFont,
    properties: createProperties({
      family: {
        type: ValueType.String
      },
      size: {
        type: ValueType.Single
      },
      style: {
        type: ValueType.EnumSet,
        enumType: FontStyle
      }
    })
  },
  GVLScale: {
    metaclass: GVLScale,
    properties: createProperties({
      digitCommaCount: {
        type: ValueType.Integer
      },
      endValue: {
        type: ValueType.Single
      },
      font: {
        type: ValueType.Instance,
        metaclass: GVLFont
      },
      left: {
        type: ValueType.Single
      },
      name: {
        type: ValueType.String
      },
      top: {
        type: ValueType.Single
      },
      width: {
        type: ValueType.Single
      },
      height: {
        type: ValueType.Single
      },
      lineVisible: {
        type: ValueType.Boolean
      },
      rotateAngle: {
        type: ValueType.Single
      },
      rotateCenterX: {
        type: ValueType.Single
      },
      rotateCenterY: {
        type: ValueType.Single
      },
      orientation: {
        type: ValueType.Enum,
        enumType: ScaleOrientation
      },
      visible: {
        type: ValueType.Boolean
      },
      startValue: {
        type: ValueType.Single
      },
      startAngle: {
        type: ValueType.Single
      },
      stroke: {
        type: ValueType.Instance,
        metaclass: Stroke
      },
      sweepAngle: {
        type: ValueType.Single
      },
      tickCount: {
        type: ValueType.Integer
      },
      tickSide: {
        type: ValueType.Enum,
        enumType: TickSide
      },
      tickVisible: {
        type: ValueType.Boolean
      },
      tickTipVisible: {
        type: ValueType.Boolean
      }
    })
  },
  GVLArcScale: {
    metaclass: GVLArcScale,
    properties: createProperties({
      arcVisible: {
        type: ValueType.Boolean
      },
      digitCommaCount: {
        type: ValueType.Integer
      },
      endValue: {
        type: ValueType.Single
      },
      fill: {
        type: ValueType.Instance,
        metaclass: FillBrush
      },
      font: {
        type: ValueType.Instance,
        metaclass: GVLFont
      },
      left: {
        type: ValueType.Single
      },
      name: {
        type: ValueType.String
      },
      top: {
        type: ValueType.Single
      },
      width: {
        type: ValueType.Single
      },
      height: {
        type: ValueType.Single
      },
      rotateAngle: {
        type: ValueType.Single
      },
      rotateCenterX: {
        type: ValueType.Single
      },
      rotateCenterY: {
        type: ValueType.Single
      },
      visible: {
        type: ValueType.Boolean
      },
      startAngle: {
        type: ValueType.Single
      },
      startValue: {
        type: ValueType.Single
      },
      stroke: {
        type: ValueType.Instance,
        metaclass: Stroke
      },
      sweepAngle: {
        type: ValueType.Single
      },
      tickCount: {
        type: ValueType.Integer
      },
      tickVisible: {
        type: ValueType.Boolean
      },
      tickTipOffset: {
        type: ValueType.Single,
      },
      tickTipVisible: {
        type: ValueType.Boolean
      }
    })
  },
  GVLTextBlock: {
    metaclass: GVLTextBlock,
    properties: createProperties({
      color: {
        type: ValueType.AlphaColor
      },
      font: {
        type: ValueType.Instance,
        metaclass: GVLFont
      },
      horzAlign: {
        type: ValueType.Enum,
        enumType: HorzAlign
      },
      left: {
        type: ValueType.Single
      },
      name: {
        type: ValueType.String
      },
      top: {
        type: ValueType.Single
      },
      width: {
        type: ValueType.Single
      },
      height: {
        type: ValueType.Single
      },
      rotateAngle: {
        type: ValueType.Single
      },
      rotateCenterX: {
        type: ValueType.Single
      },
      rotateCenterY: {
        type: ValueType.Single
      },
      text: {
        type: ValueType.String
      },
      vertAlign: {
        type: ValueType.Enum,
        enumType: VertAlign
      },
      visible: {
        type: ValueType.Boolean
      },
      wrapping: {
        type: ValueType.Boolean
      }
    })
  },
  GVLImage: {
    metaclass: GVLImage,
    properties: createProperties({
      left: {
        type: ValueType.Single
      },
      name: {
        type: ValueType.String
      },
      top: {
        type: ValueType.Single
      },
      width: {
        type: ValueType.Single
      },
      height: {
        type: ValueType.Single
      },
      rotateAngle: {
        type: ValueType.Single
      },
      rotateCenterX: {
        type: ValueType.Single
      },
      rotateCenterY: {
        type: ValueType.Single
      },
      fileName: {
        type: ValueType.String
      },
      horzAlign: {
        type: ValueType.String
      },
      pictureRotateAngle: {
        type: ValueType.Single
      },
      stretch: {
        type: ValueType.String
      },
      vertAlign: {
        type: ValueType.String
      },
      visible: {
        type: ValueType.Boolean
      }
    })
  },
  GVLGroup: {
    metaclass: GVLGroup,
    properties: createProperties({
      left: {
        type: ValueType.Single
      },
      name: {
        type: ValueType.String
      },
      top: {
        type: ValueType.Single
      },
      width: {
        type: ValueType.Single
      },
      height: {
        type: ValueType.Single
      },
      rotateAngle: {
        type: ValueType.Single
      },
      rotateCenterX: {
        type: ValueType.Single
      },
      rotateCenterY: {
        type: ValueType.Single
      },
      visible: {
        type: ValueType.Boolean
      }
    })
  },
  GVLArgumentEdit: {
    metaclass: GVLArgumentEdit,
    properties: createProperties({
      argument: {
        type: ValueType.Instance,
        metaclass: GVLArgument
      },
      backgroundColor: {
        type: ValueType.AlphaColor
      },
      borderColor: {
        type: ValueType.AlphaColor
      },
      borderWidth: {
        type: ValueType.Integer
      },
      color: {
        type: ValueType.AlphaColor
      },
      font: {
        type: ValueType.Instance,
        metaclass: GVLFont
      },
      horzAlign: {
        type: ValueType.Enum,
        enumType: HorzAlign
      },
      left: {
        type: ValueType.Single
      },
      name: {
        type: ValueType.String
      },
      top: {
        type: ValueType.Single
      },
      width: {
        type: ValueType.Single
      },
      height: {
        type: ValueType.Single
      },
      rotateAngle: {
        type: ValueType.Single
      },
      rotateCenterX: {
        type: ValueType.Single
      },
      rotateCenterY: {
        type: ValueType.Single
      },
      text: {
        type: ValueType.String
      },
      visible: {
        type: ValueType.Boolean
      },
      writeConfirm: {
        type: ValueType.Boolean
      },
      writeConfirmText: {
        type: ValueType.String
      }
    })
  },
  GVLFloatAnimation: {
    metaclass: GVLFloatAnimation,
    properties: createProperties({
      autoReverse: {
        type: ValueType.Boolean
      },
      delay: {
        type: ValueType.Single
      },
      duration: {
        type: ValueType.Single
      },
      enabled: {
        type: ValueType.Boolean
      },
      endValue: {
        type: ValueType.Single
      },
      loop: {
        type: ValueType.Boolean
      },
      name: {
        type: ValueType.String
      },
      propertyName: {
        type: ValueType.String
      },
      initialFrameOnStop: {
        type: ValueType.Boolean
      },
      initialFrameOnStopAnimated: {
        type: ValueType.Boolean
      },
      interpolation: {
        type: ValueType.Enum,
        enumType: InterpolationType
      },
      inverse: {
        type: ValueType.Boolean
      },
      startValue: {
        type: ValueType.Single
      },
      startIsCurrent: {
        type: ValueType.Boolean
      }
    })
  },
  GVLBooleanAnimation: {
    metaclass: GVLBooleanAnimation,
    properties: createProperties({
      autoReverse: {
        type: ValueType.Boolean
      },
      delay: {
        type: ValueType.Single
      },
      duration: {
        type: ValueType.Single
      },
      enabled: {
        type: ValueType.Boolean
      },
      loop: {
        type: ValueType.Boolean
      },
      name: {
        type: ValueType.String
      },
      propertyName: {
        type: ValueType.String
      },
      startIsCurrent: {
        type: ValueType.Boolean
      }
    })
  },
  GVLColorAnimation: {
    metaclass: GVLColorAnimation,
    properties: createProperties({
      autoReverse: {
        type: ValueType.Boolean
      },
      delay: {
        type: ValueType.Single
      },
      duration: {
        type: ValueType.Single
      },
      enabled: {
        type: ValueType.Boolean
      },
      endValue: {
        type: ValueType.AlphaColor
      },
      loop: {
        type: ValueType.Boolean
      },
      name: {
        type: ValueType.String
      },
      propertyName: {
        type: ValueType.String
      },
      initialFrameOnStop: {
        type: ValueType.Boolean
      },
      initialFrameOnStopAnimated: {
        type: ValueType.Boolean
      },
      interpolation: {
        type: ValueType.Enum,
        enumType: InterpolationType
      },
      inverse: {
        type: ValueType.Boolean
      },
      startValue: {
        type: ValueType.AlphaColor
      },
      startIsCurrent: {
        type: ValueType.Boolean
      }
    })
  },
  GVLScrollBox: {
    metaclass: GVLScrollBox,
    properties: createProperties({
      left: {
        type: ValueType.Single
      },
      top: {
        type: ValueType.Single
      },
      width: {
        type: ValueType.Single
      },
      height: {
        type: ValueType.Single
      }
    })
  },
  GVLTable: {
    metaclass: GVLTable,
    properties: createProperties({
      alternationColor: {
        type: ValueType.AlphaColor
      },
      backgroundColor: {
        type: ValueType.AlphaColor
      },
      cellFont: {
        type: ValueType.Instance,
        metaclass: GVLFont
      },
      columns: {
        type: ValueType.Instance,
        collectionItemClass: GVLColumn
      },
      header: {
        type: ValueType.Instance,
        metaclass: GVLTableHeader
      },
      gridColor: {
        type: ValueType.AlphaColor
      },
      left: {
        type: ValueType.Single
      },
      name: {
        type: ValueType.String
      },
      top: {
        type: ValueType.Single
      },
      width: {
        type: ValueType.Single
      },
      height: {
        type: ValueType.Single
      },
      rotateAngle: {
        type: ValueType.Single
      },
      rotateCenterX: {
        type: ValueType.Single
      },
      rotateCenterY: {
        type: ValueType.Single
      },
      rowCount: {
        type: ValueType.Integer
      },
      rowHeight: {
        type: ValueType.Integer
      },
      visible: {
        type: ValueType.Boolean
      }
    })
  },
  GVLTableRows: {
    metaclass: GVLTableRows,
    properties: createProperties({})
  },
  GVLTableRow: {
    metaclass: GVLTableRow,
    properties: createProperties({})
  },
  GVLTableCell: {
    metaclass: GVLTableCell,
    properties: createProperties({
      argument: {
        type: ValueType.Instance,
        metaclass: GVLArgument
      },
      backgroundColor: {
        type: ValueType.AlphaColor
      },
      editable: {
        type: ValueType.Enum,
        enumType: CellEditable
      },
      font: {
        type: ValueType.Instance,
        metaclass: GVLFont
      },
      fontSource: {
        type: ValueType.Enum,
        enumType: CellValueSource
      },
      text: {
        type: ValueType.String
      },
      textAlign: {
        type: ValueType.Enum,
        enumType: CellAlign
      },
      textColor: {
        type: ValueType.AlphaColor
      },
      textColorSource: {
        type: ValueType.Enum,
        enumType: CellValueSource
      }
    })
  },
  GVLColumn: {
    metaclass: GVLColumn,
    properties: createProperties({
      caption: {
        type: ValueType.String
      },
      editable: {
        type: ValueType.Enum,
        enumType: ColumnEditable
      },
      font: {
        type: ValueType.Instance,
        metaclass: GVLFont
      },
      fontSource: {
        type: ValueType.Enum,
        enumType: ColumnValueSource
      },
      textAlign: {
        type: ValueType.Enum,
        enumType: ColumnAlign
      },
      textColor: {
        type: ValueType.AlphaColor
      },
      width: {
        type: ValueType.Integer
      }
    })
  },
  GVLTableHeader: {
    metaclass: GVLTableHeader,
    properties: createProperties({
      backgroundColor: {
        type: ValueType.AlphaColor
      },
      font: {
        type: ValueType.Instance,
        metaclass: GVLFont
      },
      horzAlign: {
        type: ValueType.Enum,
        enumType: HorzAlign
      },
      suggestHeight: {
        type: ValueType.Single,
      },
      text: {
        type: ValueType.String
      },
      textColor: {
        type: ValueType.AlphaColor
      },
      visible: {
        type: ValueType.Boolean
      }
    })
  },
  GVLOperationEventTable: {
    metaclass: GVLOperationEventTable,
    properties: createProperties({
      backgroundColor: {
        type: ValueType.AlphaColor
      },
      borderColor: {
        type: ValueType.AlphaColor
      },
      font: {
        type: ValueType.Instance,
        metaclass: GVLFont
      },
      gridLineColor: {
        type: ValueType.AlphaColor
      },
      header: {
        type: ValueType.Instance,
        metaclass: GVLOperationEventTable_Header
      },
      mandatoryAck: {
        type: ValueType.Boolean
      },
      name: {
        type: ValueType.String
      },
      top: {
        type: ValueType.Single
      },
      left: {
        type: ValueType.Single
      },
      width: {
        type: ValueType.Single
      },
      height: {
        type: ValueType.Single
      },
    })
  },
  GVLOperationEventTable_Header: {
    metaclass: GVLOperationEventTable_Header,
    properties: createProperties({
      backgroundColor: {
        type: ValueType.AlphaColor
      },
      height: {
        type: ValueType.Single
      },
      textColor: {
        type: ValueType.AlphaColor
      },
      textFont: {
        type: ValueType.Instance,
        metaclass: GVLFont
      }
    })
  },
  GVLActionButton: {
    metaclass: GVLActionButton,
    properties: createProperties({
      name: {
        type: ValueType.String
      },
      top: {
        type: ValueType.Single
      },
      left: {
        type: ValueType.Single
      },
      width: {
        type: ValueType.Single
      },
      height: {
        type: ValueType.Single
      },
      rotateAngle: {
        type: ValueType.Single
      },
      rotateCenterX: {
        type: ValueType.Single
      },
      rotateCenterY: {
        type: ValueType.Single
      },
      
      action: {
        type: ValueType.String
      },
      actionProps: {
        type: ValueType.Instance,
        metaclass: ActionProps
      },
      argument: {
        type: ValueType.Instance,
        metaclass: GVLArgument
      },
      font: {
        type: ValueType.Instance,
        metaclass: GVLFont
      },
      hoverBackgroundColor: {
        type: ValueType.AlphaColor
      },
      hoverBorderColor: {
        type: ValueType.AlphaColor
      },
      hoverBorderWidth: {
        type: ValueType.Integer
      },
      hoverURI: {
        type: ValueType.String
      },
      hoverText: {
        type: ValueType.String
      },
      hoverTextColor: {
        type: ValueType.AlphaColor
      },
      normalBackgroundColor: {
        type: ValueType.AlphaColor
      },
      normalBorderColor: {
        type: ValueType.AlphaColor
      },
      normalBorderWidth: {
        type: ValueType.Integer
      },
      normalURI: {
        type: ValueType.String
      },
      normalText: {
        type: ValueType.String
      },
      normalTextColor: {
        type: ValueType.AlphaColor
      },
      pressedBackgroundColor: {
        type: ValueType.AlphaColor
      },
      pressedBorderColor: {
        type: ValueType.AlphaColor
      },
      pressedBorderWidth: {
        type: ValueType.Integer
      },
      pressedURI: {
        type: ValueType.String
      },
      pressedText: {
        type: ValueType.String
      },
      pressedTextColor: {
        type: ValueType.AlphaColor
      }
    })
  },
  ActionProps: {
    metaclass: ActionProps,
    properties: createProperties({
      containerName: {
        type: ValueType.String
      },
      tagID: {
        type: ValueType.Integer
      },
      mnemoPath: {
        type: ValueType.String
      },
      value: {
        type: ValueType.String
      },
      variableLine: {
        type: ValueType.String
      },
      windowTitle: {
        type: ValueType.String
      }
    })
  },

  GVLSlider: {
    metaclass: GVLSlider,
    properties: createProperties({
      name: {
        type: ValueType.String
      },
      top: {
        type: ValueType.Single
      },
      left: {
        type: ValueType.Single
      },
      width: {
        type: ValueType.Single
      },
      height: {
        type: ValueType.Single
      },

      argument: {
        type: ValueType.Instance,
        metaclass: GVLArgument
      },
      background: {
        type: ValueType.Instance,
        metaclass: SliderBackground,
      },
      min: {
        type: ValueType.Single,
      },
      max: {
        type: ValueType.Single,
      },
      level: {
        type: ValueType.Instance,
        metaclass: SliderLevel,
      },
      orientation: {
        type: ValueType.Enum,
        enumType: Orientation,
      },
      thumb: {
        type: ValueType.Instance,
        metaclass: SliderThumb,
      },
      writeConfirm: {
        type: ValueType.Boolean,
      },
      writeConfirmText: {
        type: ValueType.String,
      },
      writeTimeout: {
        type: ValueType.Integer,
      }
    }),
  },
  SliderBackground: {
    metaclass: SliderBackground,
    properties: createProperties({
      fillColor: {
        type: ValueType.AlphaColor,
      },
      imageFileName: {
        type: ValueType.String,
      },
      strokeColor: {
        type: ValueType.AlphaColor,
      },
      strokeWidth: {
        type: ValueType.Integer,
      },
    })
  },

  SliderLevel: {
    metaclass: SliderThumb,
    properties: createProperties({
      fillColor: {
        type: ValueType.AlphaColor,
      }
    }),
  },
  
  SliderThumb: {
    metaclass: SliderThumb,
    properties: createProperties({
      fillColor: {
        type: ValueType.AlphaColor,
      },
      imageFileName: {
        type: ValueType.String,
      },
      mainSize: {
        type: ValueType.Integer,
      },
      strokeColor: {
        type: ValueType.AlphaColor,
      },
      strokeWidth: {
        type: ValueType.Integer,
      },
    })
  },

  GVLSVGBox: {
    metaclass: GVLSVGBox,
    properties: createProperties({
      name: {
        type: ValueType.String
      },
      top: {
        type: ValueType.Single
      },
      left: {
        type: ValueType.Single
      },
      width: {
        type: ValueType.Single
      },
      height: {
        type: ValueType.Single
      },
      rotateAngle: {
        type: ValueType.Single
      },
      rotateCenterX: {
        type: ValueType.Single
      },
      rotateCenterY: {
        type: ValueType.Single
      },
      data: {
        type: ValueType.String,
      },
    })
  }
})