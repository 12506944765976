import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { differenceInSeconds } from 'date-fns'
import { currentDay, joinDateTime } from 'std/dateHelper'
import { Quality, SaveStrategy } from 'utils/common'
import { getGroupMoments } from 'services/resource'
import { selectChannelByID } from 'features/live/liveSlice'


export const intervalOptions = [
  'hour', 'hours8', 'currentDay', 'days3',  'days5', 'days7', 'days30', 'days90'
]

const durationFromMinutes = (minutes) => minutes * 60
const durationFromHours = (hours) => durationFromMinutes(hours * 60)

const rangeSourceByHours = hours => (startTime) => {
  const duration = durationFromHours(hours) * 1000
  const endingMs = startTime.getTime() + duration
  return {
    startTime,
    endTime: new Date(endingMs)
  }
}

const rangeSourceByDays = days => rangeSourceByHours(days * 24)

const intervalProps = {
  hour: { rangeSource: rangeSourceByHours(1) },
  hours8: { rangeSource: rangeSourceByHours(8) },
  currentDay: {
    rangeSource: (startTime) => ({
      startTime: new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate()),
      endTime: new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate(), 23, 59, 59, 999)
    }),
  },
  days3: { rangeSource: rangeSourceByDays(3) },
  days5: { rangeSource: rangeSourceByDays(5) },
  days7: { rangeSource: rangeSourceByDays(7) },
  days30: { rangeSource: rangeSourceByDays(30) },
  days90: { rangeSource: rangeSourceByDays(90) },
}

export const ZoomType = Object.freeze({
  OverScale: 'OverScale',
  XY: 'XY',
  Drag: 'Drag',
})

const initialState = {
  criteria: {
    chartAreaWidth: undefined,
    checkedChannels: {},
    startDate: currentDay(),
    startTime: currentDay(),
    interval: intervalOptions[2],
    selectedGroupNode: null,
  },
  colorPicker: {
    passportID: undefined,
  },
  anyFetch: false,
  fetchError: null,
  filterVisible: false,
  isFetching: false,
  fastFetch: {
    requestId: undefined,
  },
  series: [],
  yAxes: {
    y: {
      id: 0,
      min: null,
      max: null,
    }
  },
  xAxis: {
    min: null,
    max: null
  },
  zoomType: ZoomType.OverScale,
}

const slice = createSlice({
  name: 'chartPage',
  initialState,
  reducers: {
    chartAreaWidthSet: (state, { payload: width }) => {
      state.criteria.chartAreaWidth = width
    },
    channelChecked: (state, action) => {
      const passportID = action.payload
      const { checkedChannels: tags, selectedGroupNode } = state.criteria
      if (!selectedGroupNode) {
        return
      }

      if (tags[passportID]) {
        tags[passportID] = undefined
      } else {
        tags[passportID] = {
          color: selectNextColor(state),
          passportID,
          ownScaleY: false,
        }
      }
    },
    colorPicker_open: (state, { payload }) => {
      state.colorPicker = payload
    },
    colorPicker_toggleOwnScaleY: (state) => {
      const { passportID } = state.colorPicker
      const { checkedChannels: tags } = state.criteria
      const tag = tags[passportID]
      if (!tag) {
        return
      }

      tag.ownScaleY = !tag.ownScaleY
    },
    filterVisibleSet: (state, action) => {
      const visible = action.payload
      state.filterVisible = visible
    },
    groupNodeSelected: (state, action) => {
      const node = action.payload

      state.criteria.selectedGroupNode = node
    },
    intervalSet: (state, action) => {
      const interval = action.payload
      state.criteria.interval = interval
    },
    startDateSet: (state, action) => {
      const startDate = action.payload
      state.criteria.startDate = startDate
    },
    startTimeSet: (state, action) => {
      const startTime = action.payload
      state.criteria.startTime = startTime
    },
    viewportSet: (state, { payload }) => {
      const { xAxis, yAxes } = payload
      state.xAxis.min = xAxis.min
      state.xAxis.max = xAxis.max

      Object.keys(yAxes).forEach(yAxisID => {
        const it = yAxes[yAxisID]
        const srcAxis = state.yAxes[yAxisID]
        if (!srcAxis) {
          return
        }

        srcAxis.min = it.min
        srcAxis.max = it.max
      })
    },
    zoomTypeSet: (state, { payload: zoomType }) => {
      state.zoomType = zoomType
    }
  },

  extraReducers: build => {
    build.addCase(fetchChartSeries.pending, (state) => {
      state.fetchError = null
      state.series = []
      state.xAxis = { min: null, max: null }
      state.anyFetch = true
      state.isFetching = true
    })

    build.addCase(fetchChartSeries.fulfilled, (state, action) => {
      const { series, xAxis, yAxes } = action.payload

      state.isFetching = false
      state.series = series
      state.xAxis = {
        ...state.xAxis,
        ...xAxis
      }
      state.yAxes = yAxes
    })

    build.addCase(fetchChartSeries.rejected, state => {
      state.isFetching = false
    })

    build.addCase(fetchFast.pending, (state, { meta }) => {
      state.fastFetch.requestId = meta.requestId
    })

    build.addCase(fetchFast.fulfilled, (state, { meta, payload }) => {
      if (state.fastFetch.requestId !== meta.requestId) {
        return
      }

      const { series } = payload
      state.series = series
        .map(it => {
          const sibling = state.series.find(it2 => it2.id === it.id)

          return sibling
            ? {
              ...sibling,
              data: mergeSeriesItems(sibling.data, it.data),
            }
            : null
        })
        .filter(it => it !== null)
    })
  },
})

const chartSeriesColors = [
  '#4dc9f6',
  '#f67019',
  '#f53794',
  '#537bc4',
  '#acc236',
  '#166a8f',
  '#00a950',
  '#58595b',
  '#8549ba',
  '#ff0000',
  '#0000ff',
  '#008000',
  '#ff00ff',
  '#00ffff',
  '#00ff00',
  '#800000',
  '#808000',
  '#000080',
  '#800080',
  '#008080',
  '#808080',
  '#c00000',
  '#00c000',
  '#c0c000',
]

const selectNextColor = (state) => {
  const tags = state.criteria.checkedChannels

  const usedColors = Object.values(tags)
    .filter(it => it !== undefined && it !== null)
    .map(it => it.color)

  for (let color of chartSeriesColors) {
    const sibling = usedColors.find(it => it === color)
    if (!sibling) {
      return color
    }
  }

  return ''
}

export const fetchChartSeries = createAsyncThunk(
  'chartPage/fetchChartSeries', async (_, { getState, rejectWithValue }) => {
    const state = getState()
    const { chartPage: { criteria } } = state
    const { chartAreaWidth, checkedChannels } = criteria

    const channelIDs = Object.keys(checkedChannels)
      .filter(it => checkedChannels[it])
      .map(it => +it)

    const selectedChannels = channelIDs
      .map(it => selectChannelByID(state, it))
      .filter(it => !!it)

    const mapMomentsFromDTO = (dtos) => {
      const moments = []

      for (let dto of dtos) {
        let x = new Date(dto.t)
        let y = dto.q === Quality.Good? dto.v: null
        moments.push([x, y])
      }

      return moments
    }

    const range = selectCriteriaRange(state)
    const timeDiffSec = differenceInSeconds(range.endTime, range.startTime)

    const timeStep = isValidAreaWidth(chartAreaWidth) !== 0
      ? Math.round(timeDiffSec / chartAreaWidth): 1

    const promises = selectedChannels.map(it => getGroupMoments(it.ID, range, timeStep))
    let responses = []

    try {
      responses = await Promise.all(promises)
    } catch (error) {
      if (!error.response) {
        throw error
      }

      return rejectWithValue(error.response)
    }

    const yMin = Math.min.apply(null, selectedChannels.map(it => it.scale.lowScale))
    const yMax = Math.max.apply(null, selectedChannels.map(it => it.scale.highScale))

    const yAxes = {
      y: {
        id: 0,
        min: yMin,
        max: yMax,
        ticks: {
          fractionDigits: 2,
        }
      }
    }
    const series = []

    const dataArray = responses.map(it => it.data)
    dataArray.forEach((it, index) => {
      const channel = selectedChannels[index]
      const { scale } = channel
      const data = mapMomentsFromDTO(it)

      const checkChannel = checkedChannels[channel.ID] || {}
      const { color, ownScaleY } = checkChannel

      if (ownScaleY) {
        yAxes[`y${channel.ID}`] = {
          id: channel.ID,
          min: scale.lowScale,
          max: scale.highScale,
          ticks: {
            fractionDigits: channel.variableFormat,
          }
        }
      }

      const stepped = channel.type === 'boolean' || channel.saveStrategy === SaveStrategy.ByChange

      const item = {
        id: channel.ID,
        backgroundColor: color,
        borderColor: color,
        label: channel.name,
        data,
        stepped,
        yAxisID: ownScaleY? `y${channel.ID}`: undefined
      }
      series.push(item)
    })

    return {
      series,
      xAxis: { min: range.startTime, max: range.endTime },
      yAxes,
    }
  }
)

export const fetchFastDefault = () => (dispatch, getState) => {
  const state = getState()
  const range = selectCriteriaRange(state)

  dispatch(fetchFast({ range }))
}

export const fetchFast = createAsyncThunk(
  'chartPage/fetchFast', async ({ range }, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI
    const state = getState()
    const { chartPage: { criteria } } = state
    const { chartAreaWidth, checkedChannels } = criteria

    const channelIDs = Object.keys(checkedChannels)
      .filter(it => checkedChannels[it])
      .map(it => +it)

    const selectedChannels = channelIDs
      .map(it => selectChannelByID(state, it))
      .filter(it => !!it)

    const mapMomentsFromDTO = (dtos) => {
      const moments = []

      for (let dto of dtos) {
        let x = new Date(dto.t)
        let y = dto.q === Quality.Good? dto.v: null
        moments.push([x, y])
      }

      return moments
    }

    const timeDiffSec = differenceInSeconds(range.endTime, range.startTime)

    const timeStep = isValidAreaWidth(chartAreaWidth) !== 0
      ? Math.round(timeDiffSec / chartAreaWidth): 1

    const promises = selectedChannels.map(it => getGroupMoments(it.ID, range, timeStep))
    let responses = []

    try {
      responses = await Promise.all(promises)
    } catch (error) {
      if (!error.response) {
        throw error
      }

      return rejectWithValue(error.response)
    }
  
    const dataArray = responses.map(it => it.data)
    const series = dataArray.map((it, index) => {
      const channel = selectedChannels[index]
      const data = mapMomentsFromDTO(it)

      const color = checkedChannels[channel.ID]?.color
      const stepped = channel.type === 'boolean' || channel.saveStrategy === SaveStrategy.ByChange

      return {
        id: channel.ID,
        label: channel.name,
        backgroundColor: color,
        borderColor: color,
        data,
        stepped
      }
    })

    const xMin = range.startTime
    const xMax = range.endTime

    return {
      xAxis: { min: xMin, max: xMax },
      series,
    }
  }
)

const isValidAreaWidth = (width) => {
  return width !== undefined && width !== 0
}

export const mergeSeriesItems = (srcItems, destItems) => {
  if (srcItems.length <= 0) {
    return destItems
  }

  if (destItems.length === 0) {
    return [srcItems[0], srcItems[srcItems.length-1]]
  }

  const decodeTime = (point) => {
    const [dateTimeText] = point
    const dt = new Date(dateTimeText)
    const time = dt.getTime()
    return time
  }

  let newItems = [...destItems]
  const firstSrcTime = decodeTime(srcItems[0])
  const firstNewTime = decodeTime(newItems[0])

  if (firstNewTime > firstSrcTime) {
    newItems.unshift(srcItems[0])
  }

  const lastSrcTime = decodeTime(srcItems[srcItems.length-1])
  const lastNewTime = decodeTime(newItems[newItems.length-1])

  if (lastNewTime < lastSrcTime) {
    newItems.push(srcItems[srcItems.length-1])
  }

  return newItems
}

export const restoreFields = () => (dispatch) => {

  const getStorageTime = (key) => {
    const item = localStorage.getItem(key)
    if (item == null) {
      return null
    }

    const time = +item
    if (isNaN(time)) {
      return null
    }

    return new Date(time)
  }

  let interval = localStorage.getItem('chartPage.interval')

  if (interval !== null) {
    if (intervalOptions.includes(interval)) {
      dispatch(intervalSet(interval))
    }
  }

  let startTime = getStorageTime('chartPage.startTime')

  if (startTime) {
    dispatch(startTimeSet(startTime))
  }

  let zoomType = localStorage.getItem('chartPage.zoomType')

  if (zoomType !== null && zoomType in ZoomType) {
    dispatch(zoomTypeSet(zoomType))
  }

  return null
}

const selectCriteriaRange = (state) => {
  const { criteria } = state.chartPage
  const { rangeSource } = intervalProps[criteria.interval]

  const startDateTime = joinDateTime(criteria.startDate, criteria.startTime)
  const range = rangeSource(startDateTime)
  return range
}

export const selectHasPoints = (state) => {
  const series = state.chartPage.series

  if (series.length === 0) {
    return false
  }

  return series.some(it => it.data.length > 0)
}

export const selectCheckedChannelByID = (state, passportID) => state.chartPage.criteria.checkedChannels[passportID]

export const { 
  chartAreaWidthSet, 
  colorPicker_open, colorPicker_toggleOwnScaleY,
  channelChecked, filterVisibleSet, groupNodeSelected, intervalSet,
  startDateSet, startTimeSet, viewportSet, zoomTypeSet
} = slice.actions

export default slice.reducer