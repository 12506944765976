import { Enum } from '@sl/utils'
import { selectChannelByID } from 'features/live/liveSlice'
import { EventorOn } from 'std/eventor'

export const AlarmStatus = Enum.build(['Normal', 'LoAlarm', 'HiAlarm', 'DiscreteOn', 'DiscreteOff', 'HiHiAlarm', 'LoLoAlarm'])

export class ChannelStorage extends EventorOn {
  constructor() {
    super()
    this.store = null
  }

  getByID(value) {
    if (!this.store) {
      return
    }

    const state = this.store.getState()
    return selectChannelByID(state, value)
  }

  listen(channelID, handler) {
    const channel = this.getByID(channelID)
    if (!channel) {
      return () => {}
    }

    const eventName = `change.${channel.ID}`

    this.on(eventName, handler)
    handler(channel)

    return () => {
      this.off(eventName, handler)
    }
  }

  channelsUpdated(ids) {
    if (!this.store) {
      return
    }
    
    const state = this.store.getState()

    for (let id of ids) {
      const channel = selectChannelByID(state, id)
      if (!channel) {
        continue
      }

      this.fireEvent(`change.${id}`, channel)
    }
  }
}
