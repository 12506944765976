import { createSlice } from '@reduxjs/toolkit'
import { ConnectStatus } from 'services/NetClient'
import * as serverApi from 'services/webServer'
import keyMirror from 'key-mirror'

const initialState = {
  options: {
    enabled: false,
    connectToReserveServerTimeout: 30,
    connectToMainServerTimeout: 30,
    reserveHost: '',
    reserveNetPort: 23500,
    reserveWebPort: 2000,
    mainHost: '',
    mainNetPort: 23500,
    mainWebPort: 2000,
    role: 'main'
  },
  errorNotification: {
    visible: false
  },
  goNotification: {
    visible: false
  },
  timeoutHandle: null
}

export const ServerRole = keyMirror({
  main: null,
  reserve: null
})

const getNewServerURL = (options) => {  
  const { reserveHost, reserveWebPort, mainHost, mainWebPort, role } = options
  const host = role === 'main'? reserveHost: mainHost
  const port = role === 'main'? reserveWebPort: mainWebPort
  return `https://${host}:${port}`
}

export const startCountReservation = () => (dispatch, getState) => {
  const { reservation: { options, timeoutHandle } } = getState()
  const { role, connectToReserveServerTimeout, connectToMainServerTimeout } = options

  if (!options.enabled || timeoutHandle) {
    return
  }
  
  const connectTimeout = role === 'main'? connectToReserveServerTimeout: connectToMainServerTimeout
  let handle = null

  handle = setTimeout(async () => {
    dispatch(timeoutSet(null))
    dispatch(errorNotificationHidden())
    let state = getState()
    let { connectStatus } = state.client

    if (connectStatus === ConnectStatus.Connected) {
      return
    }

    let options1 = state.reservation.options
    const url = getNewServerURL(options1)
    const isAvailable = await serverApi.isAvailable(url)

    if (!isAvailable) {
      dispatch(errorNotificationShown())
      return
    }

    dispatch(goNotificationShown())
  }, connectTimeout * 1000)
  dispatch(timeoutSet(handle))
}

const slice = createSlice({
  name: 'reservation',
  initialState,
  reducers: {
    optionsChanged: (state, { payload }) => {
      state.options = payload
    },
    goNotificationHidden: (state) => {
      state.goNotification.visible = false
    },
    goNotificationShown: (state) => {
      state.goNotification.visible = true
    },
    errorNotificationHidden: (state) => {
      state.errorNotification.visible = false
    },
    errorNotificationShown: (state) => {
      state.errorNotification.visible = true
    },
    changeServer: (state) => {
      const url = getNewServerURL(state.options)
      window.location.href = url
    },
    timeoutSet: (state, { payload }) => {
      state.timeoutHandle = payload
    }
  }
  
})

export const { changeServer, optionsChanged, 
  goNotificationHidden, goNotificationShown,
  errorNotificationHidden, errorNotificationShown,
  timeoutSet
} = slice.actions

export default slice.reducer