import React from 'react'
import { Command, ConnectStatus } from '../services/NetClient'
import { setConnectStatus, startReconnectCountdown, clientKicked } from '../reducers/clientSlice'
import { fetchResources } from 'features/splashPage/splashSlice'
import { channelsUpdates } from 'features/live/liveSlice'
import SideStore from '../utils/sideStore'
import { clientsReceived, debugLogMessagesReceived } from '../features/controlPanelPage/controlPanelSlice'
import { startCountReservation } from 'reducers/reservationSlice'
import { setUser } from 'reducers/authSlice'
import { acceptEventChanges } from 'features/live/alarmSlice'
import { closeMnemo, selectMnemoByName, MnemoKind } from 'features/mnemoPage/mnemoPageSlice'
import history from 'utils/history'

export const ServiceContext = React.createContext(null)

export const useService = () => {
  const service = React.useContext(ServiceContext)

  if (!service) {
    throw new Error("useService must be inside a Provider with a value")
  }

  return service
}

export const initMessageHandler = (client, store, eventBus) => {
  
  const handleMessage = e => {
    const { dispatch } = store

    switch (e.command) {
      case Command.Hello:
        dispatch(fetchResources(client))  
        dispatch(setConnectStatus(ConnectStatus.Connected))
        break

      case Command.Kick: {
        const body = e.payload

        client.disconnect()
        dispatch(clientKicked(body.reason.name))
        break
      }
      case Command.Disconnect:
        dispatch(setUser(null))
        client.disconnect()
        break

      case Command.Frame_v3: {
          client.notify(Command.NotifyChannelUpdateReceived)

          const body = e.payload

          const { channels } = SideStore

          dispatch(channelsUpdates(body.updates))

          const ids = body.updates.map(it => it.channelID)
          channels.channelsUpdated(ids)
        }
        break

      case Command.AcceptEventChangeNotification: 
        eventBus.fireEvent('eventChanges', e.payload)
        dispatch(acceptEventChanges(e.payload))
        break
      case Command.AcceptClientList: {
          const { clients } = e.payload
          dispatch(clientsReceived(clients))
        }
        break
      case Command.AcceptDebugMessages: {
          const { messages } = e.payload
          dispatch(debugLogMessagesReceived(messages))
        }
        break

      case Command.CloseMnemo: {
        const { mnemoName } = e.payload

        const appState = store.getState()
        const mnemo = selectMnemoByName(appState, mnemoName)
        if (!mnemo) {
          return
        }

        if (mnemo.kind === MnemoKind.dialog) {
          dispatch(closeMnemo(mnemoName))
        } else {
          history.push('/app/mnemotable')
        }
      }
        break
      default:
    }
  }

  client.on('command', handleMessage)
  client.on('connecting', () => {
    store.dispatch(setConnectStatus(ConnectStatus.Connecting))
  })
  client.on('disconnected', () => {
    store.dispatch(setConnectStatus(ConnectStatus.Disconnected))
    store.dispatch(startReconnectCountdown(client))
    store.dispatch(startCountReservation())
  })
  client.on('error', () => {
    store.dispatch(setConnectStatus(ConnectStatus.Disconnected))
    store.dispatch(startReconnectCountdown(client))
    store.dispatch(startCountReservation())
  })
  client.on('reject', ({ reason }) => {
    store.dispatch(setConnectStatus(ConnectStatus.Disconnected))
    if (reason) {
      store.dispatch(clientKicked(reason))
    } else {
      store.dispatch(startReconnectCountdown(client))
      store.dispatch(startCountReservation())
    }
  })
}

export default ServiceContext