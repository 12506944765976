import { SVG } from '@svgdotjs/svg.js'
import { DOM } from '@sl/utils'
import { GVLArgumentControl, GVLFont } from './elements'
import { HorzAlign, Quality } from './types'
import { formatValue, tr, unpackTypedValue } from 'utils/common'
import { confirmDialog } from 'actions/mnemo'
import { Button } from 'features/dialogs/types'

export class GVLArgumentEdit extends GVLArgumentControl {
  constructor() {
    super()

    this._backgroundColor = '#D9D9D9'
    this._borderColor = '#000000'
    this._borderWidth = 1
    this._color = '#000000'
    this._font = new GVLFont()
    this._font.family = 'Segoe UI'
    this._font.size = 14
    this._font.onChange = this._fontChange.bind(this)
    this._horzAlign = 'left'

    this.state = {
      status: 'idle',
      statusText: ''
    }

    this.width = 100
    this.height = 30
    this.text = 'Текст'
    this.writeConfirm = false
    this.writeConfirmText = ''
  }

  dispose() {
    const { $input } = this

    $input?.off('change', this._inputChanged)
    $input?.off('keypress', this._inputKeypress)

    this._font.dispose()
    super.dispose()
  }

  get backgroundColor() { return this._backgroundColor }
  set backgroundColor(value) {
    if (this._backgroundColor !== value) {
      this._backgroundColor = `${value}`
    }
  }

  get borderColor() { return this._borderColor }
  set borderColor(value) {
    if (this._borderColor !== value) {
      this._borderColor = `${value}`
    }
  }

  get borderWidth() { return this._borderWidth }
  set borderWidth(value) {
    if (this._borderWidth !== value) {
      this._borderWidth = +value
    }
  }

  get color() { return this._color }
  set color(value) {
    if (this._color !== value) {
      this._color = `${value}`
    }
  }

  get horzAlign() { return this._horzAlign }
  set horzAlign(value) { this._setProp('horzAlign', value) }

  get font() { return this._font }

  get text() { return this._text }
  set text(value) {
    if (this._text !== value) {
      this._text = `${value}`

      const { $input } = this.domElements
      $input?.attr('value', this._text)
    }
  }

  _fontChange() {
    this.requestUpdate()
  }

  _propertyChanged(name) {
    super._propertyChanged(name)

    const { $root } = this.domElements

    switch (name) {
      case 'width':
        $root.setStyle('width', `${this._width}px`)
        this.requestUpdate()
        break
      case 'height':
        $root.setStyle('height', `${this._height}px`)
        this.requestUpdate()
        break
    }
  }

  _argumentChanged() {
    super._argumentChanged()

    if (!this._argument) {
      return
    }

    const { $input } = this.domElements
    if ($input.node === document.activeElement) {
      return
    }

    const value = this._argument.formattedValue()
    $input.attr('value', value)
    this.requestUpdate()
  }

  initDomRoot() {
    this._inputChanged = this._inputChanged.bind(this)
    this._inputBlur = this._inputBlur.bind(this)
    this._inputKeypress = this._inputKeypress.bind(this)

    const $root = DOM('div', 'gvl-control', {
      display: 'flex',
      alignItems: 'center'
    })

    const $input = $root.input()
    $input.on('change', this._inputChanged)
    $input.on('keypress', this._inputKeypress, false)
    $input.on('blur', this._inputBlur)

    const $cover = createBadQualityCover()
    $cover.addTo($root.node)

    const $img = $root.img('absolute h-16px r-1', {
      display: 'none',
    })
    $img.attr('src', '/assets/icons/loading.svg')

    this.domElements = { $root, $input, $cover, $img }
    this._domRoot = $root.node
  }

  render() {
    const { $img, $input, $cover } = this.domElements
    
    $input.setStyles({
      backgroundColor: this._backgroundColor,
      border: `${this._borderWidth}px solid ${this._borderColor}`,
      color: this._color,
      font: this._font.toCSSValue(),
      paddingLeft: '1px',
      textAlign: this._getTextAlign(),
      width: `${this.width}px`,
      height: `${this.height}px`,
      outline: 'none'
    })

    const { status, statusText } = this.state
    $img.setVisible(status !== 'idle')
    $img.attr('title', status === 'error'? statusText: '')

    switch (status) {
      case 'fetching':
        $img.attr('src', '/assets/icons/loading.svg')
        break
      case 'error':
        $img.attr('src', '/assets/icons/triangle-exclamation.svg')
        break
    }

    const { argument } = this

    if (argument && argument.quality !== Quality.Good) {
      $cover.show()
    } else {
      $cover.hide()
    }
  }

  _getTextAlign() {
    let result = 'left'

    const horzAlign = typeof this.horzAlign === 'string'
      ? this.horzAlign.toLowerCase()
      : this.horzAlign

    switch (horzAlign) {
      case HorzAlign.Center:
      case 'center':
        result = 'center'
        break

      case HorzAlign.Right:
      case 'right':
        result = 'right'
        break
    }

    return result
  }

  _inputBlur() {
    if (!this._argument) {
      return
    }

    if (this.state.status !== 'fetching') {
      const { $input } = this.domElements
      const value = this._argument.formattedValue()
      $input.attr('value', value)
    }
  }

  _inputChanged() {
    const { $input } = this.domElements
    this._text = $input.attr('value').trim()
  }
  
  async _inputKeypress({ key }) {
    if (key !== 'Enter') {
      return  
    }

    const { $input } = this.domElements
    setTimeout(() => $input.blur())

    const { argument, argumentService } = this

    if (!argument || !argumentService) {
      return
    }

    this._text = $input.attr('value').trim()
    const value = unpackTypedValue(argument.type, this._text)

    if (this.writeConfirm) {
      const viewValue = formatValue(value, {
        fractionDigits: argument.variableFormat,
        type: argument.type.name,
      })
      const text = this.writeConfirmText !== ''
        ? this.writeConfirmText
        : tr('dialog.confirm.writeAction', { value: viewValue })

      const button = await confirmDialog.show(text)
      if (button !== Button.Ok) {
        return
      }
    }

    setTimeout(() => $input.blur())

    this.state.status = 'fetching'
    this.state.statusText = ''
    this.requestUpdate()

    try {
      await argumentService.writeValueAsync(argument, value)

      this.state.status = 'idle'
      this.state.statusText = ''
      this.requestUpdate()

    } catch (error) {
      this.state.status = 'error'
      this.state.statusText = error.response.data
      this.requestUpdate()
    }  
  }
}

function createBadQualityCover() {
  const $cover = SVG().addClass('quality-cover')
  $cover.rect('100%', '100%').fill('url(#badQualityGrid)')
  $cover.path('M0,12L6,0L12,12z').fill('yellow')

  return $cover
}