import { ValueType } from 'GVL/prop-types'
import { FontStyle, GVLControl, GVLFont } from '../elements'
import { HorzAlign } from '../types'
import { ColumnValueSource, GVLColumn, getCellAlignHorzAlign, getCellAlignVertAlign } from './types'

export class GVLTable extends GVLControl {
  constructor() {
    super()

    this._fontChange = this._fontChange.bind(this)
    this._headerChange = this._headerChange.bind(this)

    this._alternationColor = 'rgba(25, 0, 0, 0)'
    this._backgroundColor = '#8D8D8D'
    this.columns = []
    this._cellFont = new GVLFont()
    this._cellFont.size = 14
    this._cellFont.onChange = this._fontChange
    this._header = new GVLTableHeader()
    this._header.onChange = this._headerChange
    this._gridColor = '#C5C5C5'
    this._rowCount = 10
    this._rowHeight = 28
  }

  dispose() {
    this._cellFont.dispose()
    this._header.dispose()

    for (let column of this.columns) {
      column.dispose()
    }

    super.dispose()
  }

  get alternationColor() { return this._alternationColor }
  set alternationColor(value) {
    if (this._alternationColor !== value) {
      this._alternationColor = '' + value
      this.requestUpdate()
    }
  }

  get backgroundColor() { return this._backgroundColor }
  set backgroundColor(value) {
    if (this._backgroundColor !== value) {
      this._backgroundColor = '' + value
      this.requestUpdate()
    }
  }

  get cellFont() { return this._cellFont }

  get header() { return this._header }

  get gridColor() { return this._gridColor }
  set gridColor(value) {
    if (this._gridColor !== value) {
      this._gridColor = '' + value
      this.requestUpdate()
    }
  }

  get rowCount() { return this._rowCount }
  set rowCount(value) {
    if (this._rowCount !== value) {
      this._rowCount = value
      this.requestUpdate()
    }
  }

  get rowHeight() { return this._rowHeight }
  set rowHeight(value) {
    if (this._rowHeight !== value) {
      this._rowHeight = value
      this.requestUpdate()
    }
  }

  _getChildAttachElement(value) {
    return this.$shadow
  }

  initDomRoot() {
    this._domRoot = this.defineRoot('div')

    this.$shadow = this._domRoot.attachShadow({mode: 'closed'})

    this._localStyle = document.createElement('style')
    this._headerBlock = document.createElement('header')
    
    this.$shadow.append(this._localStyle, this._headerBlock)

    let figuresTemplate = document.querySelector('.svg-patterns')
    if (!figuresTemplate) {
      return
    }

    let svg = figuresTemplate.cloneNode(true)
    this.$shadow.append(svg)
  }

  render() {
    const gridSize = 1
    const tableWidth = this.columns.reduce(
      (prevValue, column) => prevValue + column.width + gridSize, 0
    )
    const cellFontContent = this._cellFont.toCSSValue()

    const headerHeight = this._header.suggestHeight
    
    let styleContent = `
    .gvl-scrollbox {
      overflow: auto;
    }

    @supports selector(::-webkit-scrollbar) {
      .gvl-scrollbox::-webkit-scrollbar {
        width: 7px;
        height: 7px;
      }

      .gvl-scrollbox::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 10rem;
        border: none;
      }
      
      .gvl-scrollbox::-webkit-scrollbar-track-piece:start, 
      .gvl-scrollbox::-webkit-scrollbar-track-piece:end, 
      .gvl-scrollbox::-webkit-scrollbar-corner {
        background: transparent;
      }
    }

    @supports not selector(::-webkit-scrollbar)  {
      .gvl-scrollbox {
        scrollbar-color: rgba(0, 0, 0, 0.6) transparent;
      }
    }

    * {
      box-sizing: border-box;
    }

    :host { 
      display: flex;
      flex-direction: column; 
      user-select: none;
    }

    header {
      padding: 0 3px 4px 3px;
      white-space: nowrap;
      overflow: hidden;
      margin: 0;
      flex: 0 0 ${headerHeight}px;

      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    table {
      border-spacing: 0;
      table-layout: fixed;
      width: ${tableWidth}px;
    }
    table tr {
      height: ${this._rowHeight + gridSize}px;
    }
    table tr:nth-child(odd) {
      background-color: ${this._alternationColor};
    }
    td, th {
      padding: 0;
      overflow: hidden;
      white-space: nowrap;
      border-right: ${gridSize}px solid ${this._gridColor};
      border-bottom: ${gridSize}px solid ${this._gridColor};
      font: ${cellFontContent};
      position: relative;
    }
    
    table td:nth-child(1) {
      border-left: ${gridSize}px solid ${this._gridColor};
    }

    table tr:nth-child(1) td {
      border-top: ${gridSize}px solid ${this._gridColor};
    }

    .quality-cover {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    .spinner {
      height: 16px;
    }

    input {
      width: 100%;
      height: ${this._rowHeight}px;
      border: 1px solid #000000;
      background: #D9D9D9;
      outline: none;
      box-sizing: border-box;
      position: absolute;
    }
    `

    for (let i = 0; i < this.columns.length; i++) {
      let column = this.columns[i]
      let horzTextAlign = getCellAlignHorzAlign(column.textAlign)
      let vertTextAlign = getCellAlignVertAlign(column.textAlign)
      let fontDecl = ''

      if (column.fontSource === ColumnValueSource.Column) {
        fontDecl = 'font: ' + column.font.toCSSValue() + ';'
      }

      const useWidth = i === 0
        ? column.width - gridSize
        : column.width

      styleContent += `
      table td:nth-child(${i+1}) {
        color: ${column.textColor};
        width: ${useWidth}px;
        max-width: ${useWidth}px;
        text-align: ${horzTextAlign};
        vertical-align: ${vertTextAlign};
        ${fontDecl}
      }
      `
    }

    this._localStyle.textContent = styleContent
    this._domRoot.style.backgroundColor = this._backgroundColor

    this._updateHeader()
  }

  _updateHeader() {
    const header = this._header
    const { style } = this._headerBlock

    style.display = header.visible? null: 'none'
    if (!header.visible) {
      return
    }

    style.backgroundColor = header.backgroundColor
    style.color = header.textColor
    style.font = header.font.toCSSValue()
    style.lineHeight = 1
    style.textAlign = header.horzAlign.name.toLocaleLowerCase()
    this._headerBlock.textContent = header.text
  }

  _realign() {
    if (!this.loading) {
      const box = this._scrollbox
      box.width = this._width
      box.height = this._height
    }
  }

  _propertyChanged(name) {
    super._propertyChanged(name)

    switch (name) {
      case 'width':
        this._domRoot.style.width = `${this._width}px`
        this._realign()
        this.requestUpdate()
        break
      case 'height':
        this._domRoot.style.height = `${this._height}px`
        this._realign()
        this.requestUpdate()
        break
    }
  }

  _loadingChanged() {
    if (this.loading) {
      return
    }

    this._scrollbox = this.controls[0]
    this._rows = this._scrollbox.controls[0]
    this._realign()

    for (let row of this._rows.controls) {
      this._satisfyRow(row)
    }
  }

  _satisfyRow(row) {
    for (let i = 0, rowCount = row.controls.length; i < rowCount; i++) {
      let cell = row.controls[i]
      cell.table = this

      if (i < this.columns.length) {
        cell.column = this.columns[i]
      }
    }
  }

  _fontChange() {
    this.requestUpdate()
  }

  _headerChange() {
    this.requestUpdate()
  }
}

GVLTable.gvlPropTypes = {
  columns: {
    type: ValueType.Instance,
    collectionItemClass: GVLColumn
  },
}

export class GVLTableHeader {
  constructor() {

    this._fontChange = this._fontChange.bind(this)
    this.onChange = null

    this._backgroundColor = 'rgba(0, 0, 0, 0)'
    this._font = new GVLFont()
    this._font.size = 20
    this._font.style = [FontStyle.fsBold]
    this._font.onChange = this._fontChange
    this._horzAlign = HorzAlign.Left
    this.suggestHeight = 30
    this._text = ''
    this._textColor = '#CACACA'
    this._visible = true
  }

  dispose() {
    this._font.dispose()
    this.onChange = null
  }

  get backgroundColor() { return this._backgroundColor }
  set backgroundColor(value) {
    if (this._backgroundColor !== value) {
      this._backgroundColor = '' + value
      this._doChange()
    }
  }

  get font() { return this._font }

  get horzAlign() { return this._horzAlign }
  set horzAlign(value) {
    if (this._horzAlign !== value) {
      this._horzAlign = value
      this._doChange()
    }
  }

  get text() { return this._text }
  set text(value) {
    if (this._text !== value) {
      this._text = value
      this._doChange()
    }
  }

  get textColor() { return this._textColor }
  set textColor(value) {
    if (this._textColor !== value) {
      this._textColor = '' + value
      this._doChange()
    }
  }

  get visible() { return this._visible }
  set visible(value) {
    if (this._visible !== value) {
      this._visible = !!value
      this._doChange()
    }
  }

  _fontChange() {
    this._doChange()
  }

  _doChange() {
    if (this.onChange) {
      this.onChange()
    }
  }
}

GVLTableHeader.gvlPropTypes = {
  horzAlign: {
    type: ValueType.Enum,
    enumType: HorzAlign
  }
}

export default GVLTable