import { RectF, svgNS, PointF, Radians } from './types'
import { GVLControl, BrushKind } from './elements'
import { SVGPathData } from 'svg-pathdata'
import { floatEquals } from 'std/math'

export class GVLRect extends GVLControl {

  constructor() {
    super()
    this._model = new RectF()
    this._radiusX = 0
    this._radiusY = 0
    this.width = 100
    this.height = 100
    this.fill.kind = BrushKind.Solid
    this.fill.color = '#AFAFAF'
  }

  initDomRoot() {
    this._domRoot = document.createElementNS(svgNS, 'svg')
    this._domRoot.classList.add('gvl-control')

    this._fillRect = document.createElementNS(svgNS, 'rect')
    this._domRoot.appendChild(this._fillRect)

    this._strokeRect = document.createElementNS(svgNS, 'rect')
    this._strokeRect.setAttribute('fill', 'transparent')
    this._domRoot.appendChild(this._strokeRect)

    this._fill.bindSvg(this._domRoot, this._fillRect)
  }

  _propertyChanged(name) {
    super._propertyChanged(name)

    switch (name) {
      case 'width':
        this._domRoot.style.width = `${this._width}px`
        this._realignElement()
        break
      case 'height':
        this._domRoot.style.height = `${this._height}px`
        this._realignElement()
        break
    }
  }

  _realignElement() {
    const { stroke, _model: model } = this
    const actualStrokeWidth = stroke.getMaxWidthForRect(this)
    const semiStrokeWidth = actualStrokeWidth / 2
    
    model.setBounds(0, 0, this._width, this._height)
    model.inflate(-semiStrokeWidth, -semiStrokeWidth)

    for (let rect of [this._fillRect, this._strokeRect]) {
      rect.setAttribute('x', model.left)
      rect.setAttribute('y', model.top)
      rect.setAttribute('width', model.width)
      rect.setAttribute('height', model.height)
    }
  }

  _strokeChange() {
    super._strokeChange()
    this._stroke.applyToSVGElement(this, this._strokeRect)
    this._realignElement()
  }

  get radiusX() {
    return this._radiusX
  }

  set radiusX(value) {
    if (this._radiusX !== value) {
      this._radiusX = +value
      this._fillRect.setAttribute('rx', this._radiusX)
      this._strokeRect.setAttribute('rx', this._radiusX)
    }
  }

  get radiusY() {
    return this._radiusY
  }

  set radiusY(value) {
    if (this._radiusY !== value) {
      this._radiusY = +value
      this._fillRect.setAttribute('ry', this._radiusY)
      this._strokeRect.setAttribute('ry', this._radiusY)
    }
  }

  get stroke() {
    return this._stroke
  }
}

export class GVLEllipse extends GVLControl {
  constructor() {
    super()

    this._model = new RectF()
    this.width = 100
    this.height = 100
    this.fill.kind = BrushKind.Solid
    this.fill.color = '#AFAFAF'
  }

  initDomRoot() {
    this._domRoot = document.createElementNS(svgNS, 'svg')
    this._domRoot.classList.add('gvl-control')

    this._fillEllipse = document.createElementNS(svgNS, 'ellipse')
    this._domRoot.appendChild(this._fillEllipse)

    this._strokeEllipse = document.createElementNS(svgNS, 'ellipse')
    this._strokeEllipse.setAttribute('fill', 'transparent')
    this._domRoot.appendChild(this._strokeEllipse)

    this._fill.bindSvg(this._domRoot, this._fillEllipse)
  }

  _propertyChanged(name) {
    super._propertyChanged(name)

    switch (name) {
      case 'width':
        this._domRoot.style.width = `${this._width}px`
        this._realignElement()
        break
      case 'height':
        this._domRoot.style.height = `${this._height}px`
        this._realignElement()
        break
    }
  }

  _realignElement() {
    const { stroke, _model: model } = this
    const actualStrokeWidth = stroke.getMaxWidthForRect(this)
    const semiStrokeWidth = actualStrokeWidth / 2
    
    model.setBounds(0, 0, this._width, this._height)
    setRectFToSvgEllipse(model, this._fillEllipse)

    model.inflate(-semiStrokeWidth, -semiStrokeWidth)
    setRectFToSvgEllipse(model, this._strokeEllipse)
  }

  _strokeChange() {
    super._strokeChange()

    this._stroke.applyToSVGElement(this, this._strokeEllipse)
    this._realignElement()
  }

  get stroke() {
    return this._stroke
  }
}

function setRectFToSvgEllipse(rect, ellipse) {
  ellipse.setAttribute('cx', rect.centerX)
  ellipse.setAttribute('cy', rect.centerY)
  ellipse.setAttribute('rx', rect.width / 2)
  ellipse.setAttribute('ry', rect.height / 2)
}

export class GVLArc extends GVLControl {
  constructor() {
    super()
    this._model = new RectF()
    this._connectCenter = false
    this._startAngle = 0
    this._sweepAngle = 180
    this.width = 100
    this.height = 100
    this.stroke.color = '#3C3C3C'
    this.stroke.width = 2
  }

  initDomRoot() {
    this._domRoot = document.createElementNS(svgNS, 'svg')
    this._domRoot.classList.add('gvl-control')

    this._fillPath = document.createElementNS(svgNS, 'path')
    this._domRoot.appendChild(this._fillPath)

    this._strokePath = document.createElementNS(svgNS, 'path')
    this._strokePath.setAttribute('fill', 'transparent')
    this._domRoot.appendChild(this._strokePath)

    this._fill.bindSvg(this._domRoot, this._fillPath)
  }

  _propertyChanged(name) {
    super._propertyChanged(name)

    switch (name) {
      case 'width':
        this._domRoot.style.width = `${this._width}px`
        this._realignElement()
        break
      case 'height':
        this._domRoot.style.height = `${this._height}px`
        this._realignElement()
        break
    }
  }

  _realignElement() {
    const { stroke, _model: model } = this
    const actualStrokeWidth = stroke.getMaxWidthForRect(this)
    const semiStrokeWidth = actualStrokeWidth / 2
    
    model.setBounds(0, 0, this._width, this._height)
    model.inflate(-semiStrokeWidth, -semiStrokeWidth)
    
    const { centerX, centerY } = model
    const radiusX = model.width / 2
    const radiusY = model.height / 2

    if (Math.abs(this._sweepAngle) >= 360) {
      let d = `M ${model.left} ${centerY} `
        + `A ${radiusX} ${radiusY} 0 1 1 ${model.right} ${centerY} `
        + `A ${radiusX} ${radiusY} 0 1 1 ${model.left} ${centerY}`
      
      this._fillPath.setAttribute('d', d)
      this._strokePath.setAttribute('d', d)
      return
    }

    let endAngle = this._startAngle + this._sweepAngle
    let startPoint = polarToCartesian(centerX, centerY, radiusX, radiusY, endAngle)
    let endPoint = polarToCartesian(centerX, centerY, radiusX, radiusY, this._startAngle)

    let largeArcFlag = Math.abs(this._sweepAngle) <= 180 ? '0' : '1'
    let sweepFlag = this._sweepAngle > 0? '0': '1'

    let d = `M ${startPoint.x} ${startPoint.y} ` 
          + `A ${radiusX} ${radiusY} 0 ${largeArcFlag} ${sweepFlag} ${endPoint.x} ${endPoint.y}`

    if (this._connectCenter) {
      d += ` L ${centerX} ${centerY} Z M 0 0 v0.0001 M ${this._width} ${this._height} h -0.0001`
    }

    this._fillPath.setAttribute('d', d)
    this._strokePath.setAttribute('d', d)
  }

  _strokeChange() {
    super._strokeChange()

    this._stroke.applyToSVGElement(this, this._strokePath)
    this._realignElement()
  }

  get stroke() {
    return this._stroke
  }

  get connectCenter() {
    return this._connectCenter
  }

  set connectCenter(value) {
    if (this._connectCenter !== value) {
      this._connectCenter = value
      this._realignElement()
    }
  }

  get startAngle() {
    return this._startAngle
  }

  set startAngle(value) {
    if (this._startAngle !== value) {
      this._startAngle = value
      this._realignElement()
    }
  }

  get sweepAngle() {
    return this._sweepAngle
  }

  set sweepAngle(value) {
    let newValue = +value

    if (this._sweepAngle !== newValue) {
      this._sweepAngle = newValue

      while (this._sweepAngle > 360) {
        this._sweepAngle -= 360
      }
        
      while (this._sweepAngle < -360) {
        this._sweepAngle += 360
      } 

      this._realignElement()
    }
  }
}

function polarToCartesian(centerX, centerY, radiusX, radiusY, angleInDegrees) {
  var angleInRadians = angleInDegrees * Math.PI / 180.0

  return {
    x: centerX + (radiusX * Math.cos(angleInRadians)),
    y: centerY + (radiusY * Math.sin(angleInRadians))
  }
}

export const LineCapEnding = Object.freeze({
  None: {name: 'None'},
  ArrowStroked: {
    name: 'ArrowStroked', 
    pathData: 'M0,0 L15,7.5 L0,15Z', 
    stroked: true,
    filled: false,
    joinCenter: 0
  },
  ArrowFilled: {
    name: 'ArrowFilled', 
    pathData: 'M0,0 L15,7.5 L0,15Z', 
    stroked: false,
    filled: true,
    joinCenter: 0.0295
  },
  CircleStroked: {
    name: 'CircleStroked', 
    pathData: 'M0,8 A 8 8 0 0 1 16 8 A 8 8 0 0 1 0 8 Z', 
    stroked: true,
    filled: false,
    joinCenter: 0
  },
  CircleFilled: {
    name: 'CircleFilled',
    pathData: 'M0,8 A 8 8 0 0 1 16 8 A 8 8 0 0 1 0 8 Z',
    stroked: false,
    filled: true,
    joinCenter: 0.5
  },
  DiamondStroked: {
    name: 'DiamondStroked',
    pathData: 'M8,0 l8,8 l-8,8 l-8,-8 Z',
    stroked: true,
    filled: false,
    joinCenter: 0
  },
  DiamondFilled: {
    name: 'DiamondFilled',
    pathData: 'M8,0 l8,8 l-8,8 l-8,-8 Z',
    stroked: false,
    filled: true,
    joinCenter: 0.5
  },
  name: 'LineCapEnding'
})

const DefaultLineCapSize = 16

export class LineCap {
  constructor() {
    this._ending = LineCapEnding.None
    this._size = DefaultLineCapSize
    this.onChange = null
  }

  dispose() {
    this.onChange = null
  }

  get ending() {
    return this._ending
  }

  set ending(value) {
    if (this._ending !== value) {
      this._ending = value
      this._doChange()
    }
  }

  get size() {
    return this._size
  }

  set size(value) {
    if (this._size !== value) {
      this._size = value
      this._doChange()
    }
  }

  _doChange() {
    this.onChange && this.onChange()
  }
}

class SvgPathMiddle {

  constructor() {
    this.angle = 0
    this.left = 0
    this.rotateAtX = 0
    this.rotateAtY = 0
    this.scale = 1
    this.top = 0
  }

  update() {
    if (this.svgPath) {
      this.svgPath.setAttribute('transform', `translate(${this.left} ${this.top}) rotate(${this.angle} ${this.rotateAtX} ${this.rotateAtY}) scale(${this.scale})`)
    }
  }
}

export class GVLPathPoint {
  constructor() {
    this.x = 0
    this.y = 0
  }
}

export class GVLPath extends GVLControl {
  constructor() {
    super()

    this.minWidth = 1
    this.minHeight = 1

    this._points = []
    this._outputPoints = []

    this._endCapPath = document.createElementNS(svgNS, 'path')
    this._endCap = new LineCap()
    this._endCap.onChange = this._endCapChange.bind(this)
    this._endCapMiddle = new SvgPathMiddle()

    this._startCapPath = document.createElementNS(svgNS, 'path')
    this._startCap = new LineCap()
    this._startCap.onChange = this._startCapChange.bind(this)
    this._startCapMiddle = new SvgPathMiddle()

    this._stroke.color = '#3C3C3C'
    this._stroke.width = 2
  }

  dispose() {
    this._endCap.dispose()
    this._startCap.dispose()
    super.dispose()
  }

  initDomRoot() {
    this._domRoot = document.createElementNS(svgNS, 'svg')
    this._domRoot.setAttribute('overflow', 'visible')
    this._domRoot.classList.add('gvl-control')

    this._fillPath = document.createElementNS(svgNS, 'path')
    this._domRoot.appendChild(this._fillPath)

    this._strokePath = document.createElementNS(svgNS, 'path')
    this._strokePath.setAttribute('fill', 'none')
    this._domRoot.appendChild(this._strokePath)

    this._fill.bindSvg(this._domRoot, this._fillPath)
  }

  _propertyChanged(name) {
    super._propertyChanged(name)

    switch (name) {
      case 'width':
        this._domRoot.style.width = `${this._width}px`
        this._stroke.applyToSVGElement(this, this._strokePath)
        this.requestUpdate()
        break
      case 'height':
        this._domRoot.style.height = `${this._height}px`
        this._stroke.applyToSVGElement(this, this._strokePath)
        this.requestUpdate()
        break
    }
  }

  _alignCapPath(middle, firstPoint, centerPoint) {
    const actualCapWidth = DefaultLineCapSize * middle.scale
    const angle = firstPoint.angle(centerPoint)

    const joinX = firstPoint.x - actualCapWidth * Math.cos(angle)
    const joinY = firstPoint.y - actualCapWidth * Math.sin(angle)

    middle.angle = Radians.toDegrees(angle)
    middle.left = joinX
    middle.top = joinY - actualCapWidth / 2
    middle.rotateAtX = 0
    middle.rotateAtY = 0.5 * actualCapWidth
    middle.update()
  }

  _endCapChange() {
    this._updateEndCap()    
  }

  _startCapChange() {
    this._updateStartCap()
  }

  _updateEndCap() {
    this._updateStrokePath()
    this._updateCap(this._endCap, this._endCapPath, this._endCapMiddle)
    
  }

  _updateStartCap() {
    this._updateStrokePath()
    this._updateCap(this._startCap, this._startCapPath, this._startCapMiddle)
  }

  _updateCap(cap, path, middle) {
    const { parentNode } = path

    if (cap.ending === LineCapEnding.None) {
      if (parentNode) {
        parentNode.removeChild(path)
        middle.svgPath = null
      }
    } else {
      if (!parentNode) {
        path.setAttribute('vector-effect', 'non-scaling-stroke')
        middle.svgPath = path
        this._domRoot.appendChild(path)
      }

      const { ending } = cap

      middle.scale = (cap.size + this._stroke.width) / DefaultLineCapSize
      middle.update()

      path.setAttribute('d', ending.pathData)
      path.setAttribute('stroke', ending.stroked? this._stroke.color: 'none')
      path.setAttribute('stroke-width', ending.stroked? this._stroke.width: 0)
      path.setAttribute('fill', ending.filled? this._stroke.color: 'none')
    }
  }

  get endCap() {
    return this._endCap
  }

  get points() {
    return this._points
  }

  set points(value) {
    if (this._points !== value) {
      this._points = value
      this.requestUpdate()
    }
  }

  get viewSize() {
    return this._viewSize
  }

  set viewSize(value) {
    this._viewSize = value
    this.requestUpdate()
  }

  get startCap() {
    return this._startCap
  }

  render() {
    this._updateOutputPoints()

    this._updateFillPath()
    this._updateStrokePath()
  }

  _updateOutputPoints() {
    // Пока просто перенесу
    if (this._outputPoints.length > 0) {
      this._outputPoints.splice(0, this._outputPoints.length)
    }

    let ratioX = 1
    let ratioY = 1

    if (this._viewSize) {
      ratioX = this._viewSize.x !== 0? this._width / this._viewSize.x: 1
      ratioY = this._viewSize.y !== 0? this._height / this._viewSize.y: 1
    }

    for (let point of this._points) {
      var outputOne = new PointF()
      outputOne.x = point.x * ratioX
      outputOne.y = point.y * ratioY
      this._outputPoints.push(outputOne)
    }

    if (this._points.length >= 2) {
      if (this._startCapPath) {
        const firstPoint = this._outputPoints[0]
        const nextPoint = this._outputPoints[1]

        this._alignCapPath(this._startCapMiddle, firstPoint, nextPoint)
      }

      if (this._endCapPath) {
        const pointCount = this._outputPoints.length
        const lastPoint = this._outputPoints[pointCount - 1]
        const prevLastPoint = this._outputPoints[pointCount - 2]
  
        this._alignCapPath(this._endCapMiddle, lastPoint, prevLastPoint)
      }
    }
  }

  _updateFillPath() {
    let d = ''

    if (this._outputPoints.length >= 2) {
      const firstPoint = this._outputPoints[0]
      const lastPoint = this._outputPoints[this._outputPoints.length - 1]
  
      const firstLastDistance = firstPoint.distance(lastPoint)
      const pathClosed = firstLastDistance < 3
  
      let traverseCount = this._outputPoints.length
      
      if (pathClosed) {
        traverseCount--
      }
  
      d = `M${firstPoint.x} ${firstPoint.y}`
  
      for (let i = 1; i < traverseCount; i++) {
        var point = this._outputPoints[i]
  
        d += ` L${point.x} ${point.y}`
      }
  
      if (pathClosed) {
        d += ` z`
      }      
    }

    this._fillPath.setAttribute('d', d)
  }

  _updateStrokePath() {
    const pointCount = this._outputPoints.length
    let d = ''
    
    if (pointCount >= 2) {
      let firstPoint = this._outputPoints[0]
      const lastPoint = this._outputPoints[this._outputPoints.length - 1]
      const traverseCount = pointCount - 1

       const firstLastDistance = firstPoint.distance(lastPoint)
      
      if (this._startCap.ending !== LineCapEnding.None) {
        firstPoint = this._getStartJoinPoint()
      }

      d = `M${firstPoint.x} ${firstPoint.y}`
  
      for (let i = 1; i < traverseCount; i++) {
        var point = this._outputPoints[i]
  
        d += ` L${point.x} ${point.y}`
      }

      const noStartCap = this._startCap.ending === LineCapEnding.None
      const noEndCap = this._endCap.ending === LineCapEnding.None

      if (noEndCap) {
        if (noStartCap && firstLastDistance <= 0.1) {
          d += ' z'
        } else {
          point = this._outputPoints[traverseCount]
          d += ` L${point.x} ${point.y}`
        }
      } else {
        point = this._getEndJoinPoint()
        d += ` L${point.x} ${point.y}`
      }
    } else {
      d = ''
    }
    
    this._strokePath.setAttribute('d', d)
  }

  _getEndJoinPoint() {
    const lastPoint = this._outputPoints[this._outputPoints.length - 1]
    const prevLastPoint = this._outputPoints[this._outputPoints.length - 2]
    const { ending } = this._endCap

    const endCapWidth = DefaultLineCapSize * this._endCapMiddle.scale
    const joinLength = endCapWidth * (1 - ending.joinCenter)

    const angle = lastPoint.angle(prevLastPoint)
    
    const joinPoint = new PointF()
    joinPoint.from(lastPoint)
    joinPoint.addXY(-joinLength * Math.cos(angle), -joinLength * Math.sin(angle))

    return joinPoint
  }

  _getStartJoinPoint() {
    const firstPoint = this._outputPoints[0]
    const nextPoint = this._outputPoints[1]
    const { ending } = this._startCap

    const capWidth = DefaultLineCapSize * this._startCapMiddle.scale
    const joinLength = capWidth * (1 - ending.joinCenter)

    const angle = firstPoint.angle(nextPoint)
    
    const joinPoint = new PointF()
    joinPoint.from(firstPoint)
    joinPoint.addXY(-joinLength * Math.cos(angle), -joinLength * Math.sin(angle))

    return joinPoint
  }

  _strokeChange() {
    super._strokeChange()

    this._stroke.applyToSVGElement(this, this._strokePath)
    this._updateEndCap()
    this._updateStartCap()
  }

  get stroke() {
    return this._stroke
  }
}

export class GVLBezierPath extends GVLControl {
  constructor() {
    super()
    this._stroke.color = '#3C3C3C'
    this._stroke.width = 2
    this.minWidth = 1
    this.minHeight = 1

    this._data = ''
  }

  get data() { return this._data }
  set data(value) { this._setProp('data', value) }

  get stroke() { return this._stroke }
  
  initDomRoot() {
    const $root = document.createElementNS(svgNS, 'svg')
    this._domRoot = $root
    $root.setAttribute('overflow', 'visible')
    $root.classList.add('gvl-control')

    this.$fillPath = document.createElementNS(svgNS, 'path')
    this.$strokePath = document.createElementNS(svgNS, 'path')
    $root.append(this.$fillPath, this.$strokePath)

    this.fill.bindSvg($root, this.$fillPath)
  }

  render() {
    const { $fillPath, $strokePath } = this

    let pathData = new SVGPathData(this._data)
    const bounds = pathData.getBounds()
    
    const w = Math.abs(bounds.maxX - bounds.minX)
    const h = Math.abs(bounds.maxY - bounds.minY)

    const scaleX = floatEquals(w, 0)? 1: (this._width / w)
    const scaleY = floatEquals(h, 0)? 1: (this._height / h)

    pathData = pathData.toAbs().scale(scaleX, scaleY)
    
    const scaledData = pathData.encode()
    $fillPath.setAttribute('d', scaledData)
    $strokePath.setAttribute('d', scaledData)
    $strokePath.setAttribute('fill', 'none')

    this._stroke.applyToSVGElement(this, $strokePath)
  }

  _propertyChanged(name) {
    super._propertyChanged(name)

    switch (name) {
      case 'width':
        this._domRoot.style.width = `${this._width}px`
        this.requestUpdate()
        break
      case 'height':
        this._domRoot.style.height = `${this._height}px`
        this.requestUpdate()
        break
    }
  }

  _strokeChange() {
    super._strokeChange()
    this.requestUpdate()
  }
}