import history from 'utils/history'
import styles from './TabRow.module.css'
import classNames from 'classnames'

const TabRow = ({ 
  currentMnemoName,
  tabs,
  status,
  statusText,
  viewProps,
  width,
  height
}) => {

  const { backgroundColor } = viewProps

  let content = null

  switch (status) {
    case 'idle':
      content = 
        <div className={styles.content}>
        {tabs.map(it => (
          <Tab
            key={it.mnemoTabId}
            viewProps={viewProps}
            isActive={it.mnemoPath === currentMnemoName}
            title={it.title}
            onClick={() => {
              if (it.mnemoPath !== currentMnemoName) {
                history.push(encodeURI(`/app/mnemo/${it.mnemoPath}`))
              }
            }}
          />
        ))}
        </div>
      break

    case 'fetching':
      content = <div className={styles.spinner}>
        <i className='fa fa-spinner fa-spin fa-2x'></i>
      </div>
      break

    case 'error':
      content = <div className={styles.errorBox}>
        <i className='fa fa-exclamation-triangle fa-2x'></i>
        <p>{statusText}</p>
      </div>
      break
  }

  return (
    <div 
      className={styles.root}
      style={{ 
        background: backgroundColor,
        width: `${width}px`,
        height: `${height}px`,
      }}
    >
      {content}
    </div>
  )
}

const Tab = ({ title, isActive, viewProps, onClick }) => {

  const { 
    activeTabBackgroundColor,
    activeTabFont,
    activeTabHighlightColor,
    activeTabTextColor,
    tabBackgroundColor,
    tabFont,
    tabTextColor,
  } = viewProps

  let obj = {}
  obj[styles.tab] = true
  obj[styles['tab-active']] = isActive

  let style = {
    background: isActive? activeTabBackgroundColor: tabBackgroundColor,
    color: isActive? activeTabTextColor: tabTextColor,
    borderBottom: isActive? `2px solid ${activeTabHighlightColor}`: null,
    ...fontToCssStyle(isActive? activeTabFont: tabFont),
  }

  const divClass = classNames(obj)

  return (
    <div
      className={divClass}
      style={style}
      onClick={onClick}
    >{title}</div>
  )
}

export const fontToCssStyle = (font) => {
  if (!font) {
    return {
      font: null
    }
  }
  const { family, size, bold, italic } = font
  let props = []
  bold && props.push('bold')
  italic && props.push('italic')
  props.push(`${size}px "${family}"`)

  return {
    font: props.join(' ')
  }
}

export default TabRow