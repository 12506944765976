const keyMirror = require('key-mirror')

const Types = keyMirror({
  number: null,
  string: null,
  boolean: null,
  color: null
})

const pack = (value) => {

  const validPart = value !== undefined && value !== null
    ? 'v': 'null'

  const type = typeof value

  let meanPart = `${value}`

  if (type === Types.boolean) {
    meanPart = value? 'true': 'false'
  }

  return `${type}:${validPart}:${meanPart}`
}

const decode = (srcValue) => {

  if (srcValue === undefined || srcValue === null) {
    return {
      type: Types.number,
      valid: false,
      value: null
    }
  }

  const [type, valid, mean] = customSplit(srcValue, ':')
  const value = decodeValue(type, valid, mean)

  return { type, valid: valid === 'v', value }
}

const decodeValue = (type, valid, mean) => {
  if (valid !== 'v') {
    return null
  }

  switch (type) {
    case Types.number:
      return parseFloat(mean)

    case Types.string:
      return `${mean}`

    case Types.boolean:
      return mean === 'true'? true: false

    case Types.color:
      return mean

    default:
      throw new Error(`cannot unpack uni value (type: ${type}, value: ${mean}`)
  }
}

const unpack = (value) => {
  const [type, valid, mean] = customSplit(value, ':')
  return decodeValue(type, valid, mean)
}

const customSplit = (str, separator) => {
  let tokens = []
  let sub = ''
  let subStart = 0
  let subLen = 0
  let len = str.length

  for (let i = 0; i < len; i++) {
    let ch = str[i]
    if (ch === separator && tokens.length < 2) {
      sub = str.substring(subStart, subStart + subLen)
      tokens.push(sub)

      subStart = i+1
      subLen = 0
    } else {
      subLen++
    }
  }

  sub = str.substr(subStart, subLen)
  tokens.push(sub)

  return tokens
}

module.exports = { pack, decode, unpack, Types }