import { GVLControl } from 'GVL/elements'
import { SVG } from '@svgdotjs/svg.js'

class Line extends GVLControl {
  constructor() {
    super()

    this._keepDomRootByClient = true
    this._color = '#FFFFFF'
  }

  get color() { return this._color }
  set color(value) { this._setProp('color', value) }

  initDomRoot() {
    this._domRoot = document.createElement('div')
    this._domRoot.className = 'gvl-control'

    this.$svg = SVG().addTo(this._domRoot)
    this.$svg.attr('class', 'd-block')
  }

  _propertyChanged(name) {
    super._propertyChanged(name)

    if (!this.$svg) {
      return
    }

    switch (name) {
      case 'width':
      case 'height':
        this.$svg.size(this._width, this._height)
        break
    }
  }
}

class StraitLine extends Line {

  constructor() {
    super()
    this.isHorizontal = true
  }

  render() {
    let { $svg } = this
    $svg.clear()

    let $rect = $svg.rect(this._width, this._height)
    $rect.fill(this._color)
  }
}

export class HorzLine extends StraitLine {}
export class VertLine extends StraitLine {
  constructor() {
    super()
    this.isHorizontal = false
  }
}

export class CornerLine extends Line {
  constructor() {
    super()

    this.kind = 'bottomRight'
  }

  render() {
    const {$svg} = this
    $svg.clear()

    const w = this._width
    const h = this._height

    switch (this.kind) {
      case 'bottomRight':
        {
          const $e = $svg.ellipse(2*w, 2*h)
          $e.fill(this._color)
        }
        break

      case 'bottomLeft':
        {
          const $e = $svg.ellipse(2*w, 2*h)
          $e.move(-w, 0)
          $e.fill(this._color)
        }
        break

      case 'topLeft':
        {
          const $e = $svg.ellipse(2*w, 2*h)
          $e.move(-w, -h)
          $e.fill(this._color)
        }
        break

      case 'topRight':
        {
          const $e = $svg.ellipse(2*w, 2*h)
          $e.move(0, -h)
          $e.fill(this._color)
        }
        break
    }
  }
}

export class TeeLine extends Line {
  constructor() {
    super()

    this.kind = 'top'
  }

  render() {
    const {$svg} = this
    $svg.clear()

    const w = this._width
    const h = this._height
    const w2 = w/2
    const h2 = h/2

    switch (this.kind) {
      case 'top': 
        $svg.rect(w, h).move(0, 0).fill(this._color)
        $svg.path(`M0,0 l${w2},${h2} l${w2},${-h2} z`).fill(this._color)
        break

      case 'right':
        $svg.rect(w, h).move(0, 0).fill(this._color)
        $svg.path(`M${w},0 l${-w2},${h2} l${w2},${h2}z`).fill(this._color)
        break

      case 'bottom':
        $svg.rect(w, h).move(0, 0).fill(this._color)
        $svg.path(`M0,${h} l${w2},${-h2} l${w2},${h2} z`).fill(this._color)
        break

      case 'left':
        $svg.rect(w, h).move(0, 0).fill(this._color)
        $svg.path(`M0,0 l${w2},${h2} l${-w2},${h2} z`).fill(this._color)
        break
    }
  }
}

export class CrossLine extends Line {

  render() {
    const {$svg} = this
    $svg.clear()

    const w = this._width
    const h = this._height

    $svg.rect(w, h).move(0, 0).fill(this._color)
    $svg.path(`M0,0 l0,${h} l${w},${-h} l0,${h}z`).fill(this._color)
  }
}