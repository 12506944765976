import { resolveEventIdsWithNoAckToday } from 'services/resource'
import { default as axios } from 'axios'
import { getActualEvents as externalGetActualEvents } from 'api/api'

export const getActualEvents = externalGetActualEvents

export const getActualEventsWithNoAckToday = async () => {
  const res = await axios.get('/api/channels/actualEventIds', {
    withCredentials: true
  })
  const ids = res.data

  return await resolveEventIdsWithNoAckToday(ids)
}

export const ackEvent = async ({ ID, comment }) => {
  return await axios.post(`/api/events/${ID}/acknowledge`, { comment })
}