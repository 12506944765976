
import { ValueType } from 'GVL/prop-types'
import { GVLControl, GVLFont } from '../elements'
import { Quality, svgNS } from '../types'
import { CellEditable, CellAlign, getCellAlignHorzAlign, getCellAlignVertAlign } from './types'
import { formatValueAsSummary, unpackTypedValue } from 'utils/common'
import { writeChannelValueAsync } from 'services/webServer'

export const CellValueSource = Object.freeze({
  Column: { name: 'Column' },
  Cell: { name: 'Cell' },
  name: 'CellValueSource'
})

class GVLTableCell extends GVLControl {
  constructor() {
    super()

    this._fontChange = this._fontChange.bind(this)

    this._backgroundColor = 'rgba(0, 0, 0, 0)'
    this._editable = CellEditable.ColumnLike
    this._font = new GVLFont()
    this._font.size = 14
    this._font.onChange = this._fontChange
    this._fontSource = CellValueSource.Column
    this._text = ''
    this._textAlign = CellAlign.ColumnLike
    this._textColor = '#000000'
    this._textColorSource = CellValueSource.Column
    this.tagID = -1

    this.state = {
      status: 'idle',
      statusText: '',
      valueEditing: false
    }
    
    this.channelSource = null
    this.column = null
    this.table = null
  }

  dispose() {
    this._stopEdit()
    this._unbindTag()
    this.column = null
    this.table = null
    this._font.dispose()
    super.dispose()
  }

  get backgroundColor() { return this._backgroundColor }
  set backgroundColor(value) {
    if (this._backgroundColor !== value) {
      this._backgroundColor = `${value}`
      this.requestUpdate()
    }
  }

  get editable() { return this._editable }
  set editable(value) { this._editable = value }

  get font() { return this._font }

  get fontSource() { return this._fontSource }
  set fontSource(value) {
    if (this._fontSource !== value) {
      this._fontSource = value
      this.requestUpdate()
    }
  }

  get text() { return this._text }
  set text(value) {
    if (this._text !== value) {
      this._text = `${value}`
      this.requestUpdate()
    }
  }

  get textAlign() { return this._textAlign }
  set textAlign(value) {
    if (this._textAlign !== value) {
      this._textAlign = value
      this.requestUpdate()
    }
  }

  get textColor() { return this._textColor }
  set textColor(value) {
    if (this._textColor !== value) {
      this._textColor = `${value}`
      this.requestUpdate()
    }
  }

  get textColorSource() { return this._textColorSource }
  set textColorSource(value) {
    if (this._textColorSource !== value) {
      this._textColorSource = value
      this.requestUpdate()
    }
  }

  initDomRoot() {
    this._tdClick = this._tdClick.bind(this)
    this._inputBlur = this._inputBlur.bind(this)
    this._inputKeypress = this._inputKeypress.bind(this)

    const td = document.createElement('td')
    this._domRoot = td

    this._span = document.createElement('span')
    
    const $status = document.createElement('img')
    $status.className = 'spinner'
    $status.style.display = 'none'

    this.$status = $status

    td.append($status, this._span)
    td.addEventListener('click', this._tdClick)
  }

  render() {
    const td = this._domRoot
    const { style } = td
    this._span.textContent = this._text
    style.backgroundColor = this._backgroundColor
    style.color = this._textColorSource === CellValueSource.Column? null: this._textColor

    if (this._fontSource === CellValueSource.Column) {
      style.font = null
    } else {
      style.font = this._font.toCSSValue()
    }

    this._updateTextAlign()

    const { status, statusText } = this.state
    const { $status } = this
    $status.style.display = status !== 'idle'? null: 'none'
    $status.title = status === 'error'? statusText: ''

    switch (status) {
      case 'fetching':
        $status.src = '/assets/icons/loading.svg'
        
        break
      case 'error':
        $status.src = '/assets/icons/triangle-exclamation.svg'
        break
    }
  }

  _updateTextAlign() {
    const { style } = this._domRoot
    let textAlign = this._textAlign

    if (textAlign === CellAlign.ColumnLike) {
      style.textAlign = null
      style.verticalAlign = null
    } else {
      style.textAlign = getCellAlignHorzAlign(textAlign)
      style.verticalAlign = getCellAlignVertAlign(textAlign)
    }
  }

  _fontChange() {
    if (this._fontSource === CellValueSource.Cell) {
      this.requestUpdate()
    }
  }

  _tdClick() {
    switch (this._editable) {
      case CellEditable.Allowed: 
        this._startEdit()
        break
      case CellEditable.ColumnLike:
        if (this.column && this.column.editable === CellEditable.Allowed) {
          this._startEdit()
        }
        break
    }
  }

  _startEdit() {
    if (this._input || this.state.valueEditing) {
      return
    }

    const i = this._input = document.createElement('input')
    i.value = this._text
    i.addEventListener('keypress', this._inputKeypress)
    i.addEventListener('blur', this._inputBlur)
    this._span.textContent = ''
    this._domRoot.append(i)

    this.state.valueEditing = true

    i.focus()
    i.select()
  }

  _inputKeypress({ key }) {
    if (key !== 'Enter') {
      return
    }

     this._writeTypedValue()
  }

  async _writeTypedValue() {
    if (!this._passer) {
      this.text = this._input.value
      this._stopEdit()
      return
    }

    if (!this.channelSource) {
      return
    }

    const channel = this.channelSource.getByID(this.tagID)
    if (!channel) {
      return
    }

    const value = unpackTypedValue(channel.type, this._input.value)
    this._stopEdit()

    this.state.status = 'fetching'
    this.state.statusText = ''
    this.requestUpdate()

    try {
      await writeChannelValueAsync(this.tagID, value)

      this.state.status = 'idle'
      this.state.statusText = ''
      this.requestUpdate()

    } catch (error) {
      this.state.status = 'error'
      this.state.statusText = error.toString()
      this.requestUpdate()
    }      
  }

  _inputBlur() {
    setTimeout(() => this._stopEdit())
  }

  _stopEdit() {
    const input = this._input
    if (!input) {
      return
    }

    this.state.valueEditing = false

    input.removeEventListener('keypress', this._inputKeypress)
    input.removeEventListener('blur', this._inputBlur)
    this._span.textContent = this._text
    this._domRoot.removeChild(input)
    this._input = null
    this._rewindValue()
  }

  _loadingChanged() {
    super._loadingChanged()

    const { channelSource: source } = this
    if (!source || this.tagID === -1) {
      return
    }

    const channel = source.getByID(this.tagID)
    if (!channel) {
      return
    }

    const eventName = `change.${channel.ID}`
    const handler = this._newUpdateHandler()

    this._passer = {
      eventName,
      handler
    }

    source.on(eventName, handler)

    handler(channel)
  }

  _newUpdateHandler() {
    return (channel) => {
      if (this.state.valueEditing) {
        return
      }

      if (channel.quality === Quality.Good) {
        this.text = formatValueAsSummary(channel, channel.value)

        if (this._cover) {
          this._cover.style.display = 'none'
        }
      } else {
        if (!this._cover) {
          this._cover = createBadQualityCover()
          this._domRoot.append(this._cover)
        }

        this._cover.style.display = null
      }
    }
  }

  _rewindValue() {
    const { channelSource: source, _passer: passer } = this
    if (!source || !passer || this.tagID === -1) {
      return
    }

    const channel = source.getByID(this.tagID)
    if (!channel) {
      return
    }

    passer.handler(channel)
  }

  _unbindTag() {
    const passer = this._passer
    if (!passer) {
      return
    }

    this.channelSource?.off(passer.eventName, passer.handler)
    this._passer = null
  }
}

GVLTableCell.gvlPropTypes = {
  editable: {
    type: ValueType.Enum,
    enumType: CellEditable
  },
  fontSource: {
    type: ValueType.Enum,
    enumType: CellValueSource
  },
  textAlign: {
    type: ValueType.Enum,
    enumType: CellAlign
  }, 
  textColorSource: {
    type: ValueType.Enum,
    enumType: CellValueSource
  }
}

function createBadQualityCover() {
  let cover = document.createElementNS(svgNS, 'svg')
  cover.classList.add('quality-cover')

  let rect = document.createElementNS(svgNS, 'rect')
  rect.setAttribute('width', '100%')
  rect.setAttribute('height', '100%')
  rect.setAttribute('fill', 'url(#badQualityGrid)')

  let triangle = document.createElementNS(svgNS, 'path')
  triangle.setAttribute('d', 'M0,12L6,0L12,12z')
  triangle.setAttribute('fill', 'yellow')

  cover.appendChild(rect)
  cover.appendChild(triangle)

  return cover
}

export default GVLTableCell