import { show as showConfirmDialog } from 'features/dialogs/ConfirmDialog.slice'
import { logout as logoutAction } from 'reducers/authSlice'
import { RequestSubject } from 'services/NetClient'

let _store = null
let _client = null

export const injectStore = store => {
  _store = store
}

export const injectNetClient = client => {
  _client = client
}

export const logout = () => {
  _store.dispatch(logoutAction(_client))
}

export const renewEventChanges = () => {
  _client.post(RequestSubject.RenewEventChanges)
}

export const confirmDialog = {
  show(text) {
    return new Promise(resolve => {
      _store.dispatch(showConfirmDialog({ resolve, text }))
    })
  }
}