import React, { Suspense } from 'react'
import './App.css'
import { useSelector } from 'react-redux'
import { unstable_HistoryRouter as HistoryRouter, Outlet, Navigate, useRoutes } from 'react-router-dom'
import history from './utils/history'
import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress'
import { MainMenu } from './components/MainMenu'
import { MnemoPage } from './features/mnemoPage/MnemoPage'
import { ConnectStatus } from './services/NetClient'
import Dialogs from './components/Dialogs'
import ThemeSource from './components/ThemeSource'

import NotFound from './features/NotFoundPage'
import SplashView from 'features/splashPage/SplashView'
import { SnackbarProvider } from 'notistack'
import AlarmNotifier from 'features/live/AlarmNotifier'

const ConnectPage = React.lazy(() => import('./features/connectPage/ConnectPage'))
const MnemoTablePage = React.lazy(() => import('./features/mnemoTable/MnemoTablePage'))
const TagsPage = React.lazy(() => import('./features/tagsPage/TagsPage'))
const EventLogPage = React.lazy(() => import('./features/eventlog/EventLogForm'))
const ChartPage = React.lazy(() => import('./features/chart/ChartPage'))
const UserPage = React.lazy(() => import('./features/userPage/UserPage'))
const AboutPage = React.lazy(() => import('./features/aboutPage/AboutPage'))
const LoginPage = React.lazy(() => import('./features/loginPage/LoginPage'))
const ControlPanelPage = React.lazy(() => import('./features/controlPanelPage/ControlPanelPage'))

const AppLayout = () => {
  const menuVisible = useSelector(state => state.menu.visible)

  return (
    <>
      {menuVisible
        ? <MainMenu />
        : null
      }
      <main className={menuVisible? 'menu-support': ''}>
        <Outlet />
      </main>
    </>
  )
}

const MainLayout = ({children}) => 
  <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
    style={{ minHeight: '100vh' }}
  >
    {children}
  </Grid>

const PageFetchingSpinner = () => 
  <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
    style={{ minHeight: '100vh' }}
  >
    <CircularProgress />
  </Grid>

const AppRoutes = () => {
  const authRequired = useSelector(state => state.auth.required)
  const user = useSelector(state => state.auth.user)
  const connectStatus = useSelector(state => state.client.connectStatus)
  const splashVisible = useSelector(state => state.splash.visible)

  const isConnected = connectStatus === ConnectStatus.Connected
  const isLoggedIn = !authRequired || (user !== null)

  const routes = [
    {
      path: 'app',
      element: (!isLoggedIn)? <Navigate to="/login" />: (isConnected? <AppLayout />: <Navigate to="/connection" />),
      children: [
        { index: true, element: <Navigate to="/app/mnemotable" /> },
        { path: 'mnemotable', element: <MnemoTablePage /> },
        { path: 'mnemo/:name', element: <MnemoPage /> },
        { path: 'tags', element: <PrivateRoute menuItem='channels'><TagsPage /></PrivateRoute> },
        { path: 'eventlog', element: <PrivateRoute menuItem='eventLog'><EventLogPage /></PrivateRoute> },
        { path: 'chart', element: <PrivateRoute menuItem='chartViewer'><ChartPage /></PrivateRoute> },
        { path: 'user', element: authRequired? <PrivateRoute menuItem='user'><UserPage /></PrivateRoute>: <Navigate to="/" /> },
        { path: 'about', element: <PrivateRoute menuItem='about'><AboutPage /></PrivateRoute> },
        { path: 'control_panel', element: <PrivateRoute menuItem='controlPanel'><ControlPanelPage /></PrivateRoute> },
      ]
    },
    {
      path: '/',
      element: (isLoggedIn && isConnected? <Navigate to="/app/mnemotable" />: <MainLayout />),
      children: [
        { index: true, element: (!isLoggedIn)? <Navigate to="/login" />: <Navigate to="/connection" /> },
        { path: 'login', element: (!isLoggedIn)? <LoginPage />: <Navigate to="/connection" /> },
        { path: 'connection', element: (!isLoggedIn? <Navigate to="/login" />: (isConnected? <Navigate to="/app/mnemotable" />: <ConnectPage />)) },
      ]
    },
    {
      path: '*', element: <NotFound />
    }
  ]

  const routesElements = useRoutes(routes)

  if (!isLoggedIn) {
    return (
      <MainLayout>
        <LoginPage />
      </MainLayout>
    )
  }

  if (!isConnected) {
    return (
      <MainLayout>
        <ConnectPage />
      </MainLayout>
    )
  }

  if (splashVisible) {
    return (
      <MainLayout>
        <SplashView />
      </MainLayout>
    )
  }

  return <>{routesElements}</>
}

const PrivateRoute = ({ menuItem, children }) => {
  const visible = useSelector(state => state.menu.items[menuItem].visible)
  if (!visible) {
    return <Navigate to="/notfound" />
  }

  return children
}

function App() {
  return (
    <HistoryRouter history={history}>
      <SnackbarProvider />
      <Suspense fallback={<PageFetchingSpinner />}>
        <ThemeSource>
          <AppRoutes />
          <AlarmNotifier />
          <Dialogs />
        </ThemeSource>
      </Suspense>
    </HistoryRouter>
  )
}

export default App
