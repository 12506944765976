import FingerprintJS from '@fingerprintjs/fingerprintjs'
import Cookie from 'js-cookie'

const fpPromise = FingerprintJS.load({
  monitoring: false,
})

export const getClientId = async () => {

  let visitorId = Cookie.get('fingerprint')
  if (visitorId !== null && visitorId !== '' && visitorId !== undefined) {
    return visitorId
  }

  const fp = await fpPromise
  const result = await fp.get()

  const { screenFrame, screenResolution, ...components } = result.components

  visitorId = FingerprintJS.hashComponents(components)

  localStorage.setItem('fingerprint', visitorId)

  Cookie.set('fingerprint', visitorId, {
    sameSite: 'None',
    secure: true,
  })

  return visitorId
}