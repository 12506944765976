import { GVLElement } from './elements'
import { Pi2 } from './math'
import { GVLMath } from './types'
import { stringFirstToLowerCase } from './utils'

export const AnimationType = Object.freeze({
  In: { name: 'In' },
  Out: { name: 'Out' },
  InOut: { name: 'InOut' },
  name: 'AnimationType'
})

export const InterpolationType = Object.freeze({
  Linear: { 
    name: 'Linear',
    interpolate(t, B, C, D, type) {
      return C * t / D + B
    }
  },
  Quadratic: { 
    name: 'Quadratic',
    interpolate(t, B, C, D, type) {
      let result = 0

      switch (type) {
        case AnimationType.In:
          t /= D
          result = C * t * t + B
          break
        case AnimationType.Out:
          t /= D
          result = -C * t * (t - 2) + B
          break
        case AnimationType.InOut:
          t /= D / 2

          if (t < 1) {
            result = C / 2 * t * t + B
          } else {
            t -= 1
            result = -C / 2 * (t * (t - 2) - 1) + B
          }
          break
      }

      return result
    }
  },
  Cubic: { 
    name: 'Cubic',
    interpolate(t, B, C, D, type) {
      let result = 0

      switch (type) {
        case AnimationType.In:
          t /= D
          result = C * t * t * t + B
          break
        case AnimationType.Out:
          t = t / D - 1
          result = C * (t * t * t + 1) + B
          break
        case AnimationType.InOut:
          t /= D / 2

          if (t < 1) {
            result = C / 2 * t * t * t + B
          } else {
            t -= 2
            result = C / 2 * (t * t * t + 2) + B
          }
          break
      }

      return result
    }
  },
  Quartic: { 
    name: 'Quartic',
    interpolate(t, B, C, D, type) {
      let result = 0

      switch (type) {
        case AnimationType.In:
          t /= D
          result = C * t * t * t * t + B
          break
        case AnimationType.Out:
          t = t / D - 1
          result = -C * (t * t * t * t - 1) + B
          break
        case AnimationType.InOut:
          t /= D / 2

          if (t < 1) {
            result = C / 2 * t * t * t * t + B
          } else {
            t -= 2
            result = -C / 2 * (t * t * t * t - 2) + B
          }
          break
      }

      return result
    }
  },
  Quintic: { 
    name: 'Quintic',
    interpolate(t, B, C, D, type) {
      let result = 0

      switch (type) {
        case AnimationType.In:
          t /= D
          result = C * t * t * t * t * t + B
          break
        case AnimationType.Out:
          t = t / D - 1
          result = C * (t * t * t * t * t + 1) + B
          break
        case AnimationType.InOut:
          t /= D / 2

          if (t < 1) {
            result = C / 2 * t * t * t * t * t + B
          } else {
            t -= 2
            result = C / 2 * (t * t * t * t * t + 2) + B
          }
          break
      }

      return result
    }
  },
  Sinusoidal: { 
    name: 'Sinusoidal',
    interpolate(t, B, C, D, type) {
      let result = 0

      switch (type) {
        case AnimationType.In:
          result = -C * Math.cos(t / D * (Math.PI / 2)) + C + B
          break
        case AnimationType.Out:
          result = C * Math.sin(t / D * (Math.PI / 2)) + B
          break
        case AnimationType.InOut:
          result = -C / 2 * (Math.cos(Math.PI * t / D) - 1) + B
          break
      }

      return result
    }
  },
  Exponential: { 
    name: 'Exponential',
    interpolate(t, B, C, D, type) {
      let result = 0

      switch (type) {
        case AnimationType.In:
          result = (t === 0)? B: C * Math.pow(2, (10 * (t / D - 1))) + B
          break
        case AnimationType.Out:
          if (t === D) {
            result = B + C
          } else {
            result = C * (-Math.pow(2, (-10 * t / D)) + 1) + B
          }
          break
        case AnimationType.InOut:
          if (t === 0) {
            return B
          }

          if (t === D) {
            return B + C
          }

          t /= D / 2

          if (t < 1) {
            result = C / 2 * Math.pow(2, (10 * (t - 1))) + B
          } else {
            t--
            result = C / 2 * (-Math.pow(2, (-10 * t)) + 2) + B
          }

          break
      }

      return result
    }
  },
  Circular: { 
    name: 'Circular',
    interpolate(t, B, C, D, type) {
      let result = 0

      switch (type) {
        case AnimationType.In:
          t /= D
          result = -C * (Math.sqrt(1 - t * t) - 1) + B
          break
        case AnimationType.Out:
          t = t / D - 1
          result = C * Math.sqrt(1 - t * t) + B
          break
        case AnimationType.InOut:
          t /= D / 2
          
          if (t < 1) {
            result = -C / 2 * (Math.sqrt(1 - t * t) - 1) + B
          } else {
            t -= 2
            result = C / 2 * (Math.sqrt(1 - t * t) + 1) + B
          }

          break
      }

      return result
    }
  },
  Elastic: { 
    name: 'Elastic',
    interpolate(t, B, C, D, type, A = 0, P = 0) {
      let result = 0
      let S

      switch (type) {
        case AnimationType.In:
          if (t === 0) {
            return B
          }

          t /= D

          if (t === 1) {
            return B + C
          }

          if (P === 0) {
            P = D * 0.3
          }

          if (A === 0 || A < Math.abs(C)) {
            A = C
            S = P / 4
          } else {
            S = P / Pi2 * Math.asin(C / A)
          }

          t--
          result = -(A * Math.pow(2, (10 * t)) * Math.sin((t * D - S) * Pi2 / P)) + B
          break

        case AnimationType.Out:
          if (t === 0) {
            return B
          }

          t /= D

          if (t === 1) {
            return B + C
          }

          if (P === 0) {
            P = D * 0.3
          }

          if (A === 0 || A < Math.abs(C)) {
            A = C
            S = P / 4
          } else {
            S = P / Pi2 * Math.asin(C / A)
          }
          result = A * Math.pow(2, (-10 * t)) * Math.sin((t * D - S) * Pi2 / P) + C + B
          break

        case AnimationType.InOut:
          if (t === 0) {
            return B
          }

          t /= D / 2

          if (t === 2) {
            return B + C
          }

          if (P === 0) {
            P = D * 0.3 * 1.5
          }

          if (A === 0 || A < Math.abs(C)) {
            A = C
            S = P / 4
          } else {
            S = P / Pi2 * Math.asin(C / A)
          }
          t--

          if (t < 1) {
            result = -0.5 * (A * Math.pow(2, (10 * t)) * Math.sin((t * D - S) * Pi2 / P)) + B
          } else {
            result = A * Math.pow(2, (-10 * t)) * Math.sin((t * D - S) * Pi2 / P) * 0.5 + C + B
          }
          
          break
      }

      return result
    }
  },
  Back: { 
    name: 'Back',
    interpolate(t, B, C, D, type, S = 0) {
      let result = 0

      if (S === 0) {
        S = 1.70158
      }

      switch (type) {
        case AnimationType.In:
          t /= D
          result = C * t * t * ((S + 1) * t - S) + B
          break
        case AnimationType.Out:
          t = t / D - 1
          result = C * (t * t * ((S + 1) * t + S) + 1) + B
          break
        case AnimationType.InOut:
          t /= D / 2
          S *= 1.525

          if (t < 1) {
            result = C / 2 * (t * t * ((S + 1) * t - S)) + B
          } else {
            t -= 2
            result = C / 2 * (t * t * ((S + 1) * t + S) + 2) + B
          }
          break
      }

      return result
    }
  },
  Bounce: { 
    name: 'Bounce',
    interpolate(t, B, C, D, type) {
      let result = 0

      switch (type) {
        case AnimationType.In:
          result = this._easeIn(t, B, C, D)
          break
        case AnimationType.Out:
          result = this._easeOut(t, B, C, D)
          break
        case AnimationType.InOut:
          if (t < D / 2) {
            result = this._easeIn(t * 2, 0, C, D) * 0.5 + B
          } else {
            result = this._easeOut(t * 2 - D, 0, C, D) * 0.5 + C * 0.5 + B
          }
          break
      }

      return result
    },
    _easeIn(t, B, C, D) {
      return C - this._easeOut(D - t, 0, C, D) + B
    },
    _easeOut(t, B, C, D) {
      let result = 0
      t /= D

      if (t < 1 / 2.75) {
        result = C * (7.5625 * t * t) + B

      } else if (t < 2 / 2.72) {
        t -= 1.5 / 2.75
        result = C * (7.5625 * t * t + 0.75) + B
      } else if (t < 2.5 / 2.75) {
        t -= 2.25 / 2.75
        result = C * (7.5625 * t * t + 0.9375) + B
      } else {
        t -= (2.625 / 2.75)
        result = C * (7.5625 * t * t + 0.984375) + B
      }

      return result
    }
  },
  name: 'InterpolationType'
})

function interpolateSingle(start, stop, t) {
  return start + (stop - start) * t
}


class GVLAnimation extends GVLElement {
  constructor() {
    super()

    this._delayTime = 0
    this._reverseAnim = null
    this._tickCount = 0
    this._savedInverse = false

    this.animationType = AnimationType.In
    this.autoReverse = false
    this.delay = 0
    this.duration = 0.2
    this._enabled = false
    this._running = false
    this.target = null
    this.pause = false
    this.initialFrameOnStop = true
    this.initialFrameOnStopAnimated = false
    this.interpolation = InterpolationType.Linear
    this.inverse = false
    this.loop = false
    this.startIsCurrent = false
    this.stopAtEnd = false
  }

  dispose() {
    this.target = null
    super.dispose()
  }

  _ownerChanged() {
    if (!this.target) {
      this.target = this.owner
    }
  }

  _loadingChanged() {
    if (!this._loading && this._enabled) {
      this.start()
    }
  }

  get enabled() {
    return this._enabled
  }

  set enabled(value) {
    const newValue = !!value

    if (this._enabled !== newValue) {
      this._enabled = newValue

      if (!this._loading) {
        if (this._enabled) {
          this.start()
        } else {
          this.stop()
        }
      }
    }
  }

  get running() {
    return this._running
  }

  get normalizedTime() {
    let result = 0

    if (this.duration > 0 && this._delayTime <= 0) {
      result = this.interpolation.interpolate(this._time, 0, 1, this.duration, this.animationType)
    }

    return result
  }

  start(startTime) {
    if (!this.loop) {
      this._tickCount = 0
    }

    if (!!this._reverseAnim) {
      this._reverseAnim.enabled = false
      this._reverseAnim = null
    }

    if (this.autoReverse) {
      if (this._running) {
        this.inverse = this._savedInverse
      } else {
        this._savedInverse = this.inverse
      }
    }

    if (Math.abs(this.duration) < 0.001) {
      const saveDuration = this.duration
      try {
        this._delayTime = 0
        this.duration = 1

        this._time = this.inverse? 0: this.duration

        if (startTime !== undefined) {
          this._time = startTime
        }

        this._running = true
        this._processAnimation()
        this._running = false
        this._time = 0
        this.fireEvent("finish")
      } finally {
        this.duration = saveDuration
      }
    } else {
      this._delayTime = this.delay
      this._running = true

      if (this._time === undefined) {
        this._time = this.inverse? this.duration: 0
      }

      if (startTime !== undefined) {
        this._time = startTime
      }

      if (this.delay === 0) {
        this._firstFrame()
        this._processAnimation()
      }

      if (!ticker) {
        ticker = new Ticker()
      }

      ticker.add(this)

      if (!ticker.enabled) {
        this.stop()
      } else {
        this._enabled = true
      }
    }
  }

  stop() {
    if (!this._running) {
      this._considerSetInitialFrame()
      return
    }

    if (ticker) {
      ticker.remove(this)
    }

    this._considerSetInitialFrame()
    this._running = false
    this.fireEvent("finish")
  }

  stopAtCurrent() {
    if (!this._running) {
      return
    }

    if (ticker) {
      ticker.remove(this)
    }

    this._time = 0

    this._running = false
    this._enabled = false
    this.fireEvent("finish")
  }

  _considerSetInitialFrame() {
    if (!this.initialFrameOnStop) {
      return
    }

    if (!this.initialFrameOnStopAnimated) {
      this._time = this.stopAtEnd? this.duration: 0
      this._processAnimation()
      return
    }

    const anim = this.clone()

    this._reverseAnim = anim

    const finisher = () => {
      this._time = this.stopAtEnd? this.duration: 0

      anim.off("finish", finisher)
      if (this._reverseAnim === anim) {
        this._reverseAnim = null
      }
    }

    anim.on("finish", finisher)
    anim.autoReverse = false
    anim.delay = 0
    anim.loop = false
    anim.initialFrameOnStop = false
    anim.initialFrameOnStopAnimated = false
    anim.inverse = true
    anim.startIsCurrent = false
    anim.start(this._time)
  }

  clone() {
    const anim = new this.__proto__.constructor()
    anim.animationType = this.animationType
    anim.autoReverse = this.autoReverse
    anim.duration = this.duration
    anim.delay = this.delay
    anim.initialFrameOnStop = this.initialFrameOnStop
    anim.initialFrameOnStopAnimated = this.initialFrameOnStopAnimated
    anim.inverse = this.inverse
    anim.loop = this.loop
    anim.target = this.target
    return anim
  }

  _firstFrame() {

  }

  _processAnimation() {

  }

  processTick(time, deltaTime) {
    if (!this.target || this._loading) {
      return
    }

    if (!this._running || this.pause) {
      return
    }

    if (this.delay > 0 && this._delayTime !== 0) {
      if (this._delayTime > 0) {
        this._delayTime -= deltaTime

        if (this._delayTime <= 0) {
          this._delayTime = 0
          
          if (this._time === undefined) {
            this._time = this.inverse? this.duration: 0
          }
  
          this._firstFrame()
          this._processAnimation()
        }
      }

      return
    }

    this._time += this.inverse? -deltaTime: deltaTime
    
    if (this._time >= this.duration) {
      this._time = this.duration

      if (this.loop) {
        if (this.autoReverse) {
          this.inverse = true
          this._time = this.duration
        } else {
          this._time = 0
        }
      } else if (this.autoReverse && this._tickCount === 0) {
        this._tickCount++
        this.inverse = true
        this._time = this.duration
      } else {
        this._running = false
      }
    } else if (this._time <= 0) {
      this._time = 0

      if (this.loop) {
        if (this.autoReverse) {
          this.inverse = false
          this._time = 0
        } else {
          this._time = this.duration
        }
      } else if (this.autoReverse && this._tickCount === 0) {
        this._tickCount++
        this.inverse = false
        this._time = 0
      } else {
        this._running = false
      }
    }

    this._processAnimation()

    if (!this._running) {
      ticker && ticker.remove(this)

      this.fireEvent("finish")
    }
  }

}

class GVLPropertyAnimation extends GVLAnimation {

  constructor() {
    super()

    this._propertyName = ''
  }

  dispose() {
    if (this.enabled) {
      this.enabled = false
    }

    super.dispose()
  }

  clone() {
    let a = super.clone()
    a.propertyName = this.propertyName
    return a
  }

  start(time) {
    if (this._findProperty()) {
      super.start(time)
    }
  }

  stop() {
    super.stop()
    this._instance = null
  }

  get propertyName() {
    return this._propertyName
  }

  set propertyName(value) {
    if (this._propertyName.toUpperCase() !== value.toUpperCase()) {
      this._instance = null
    }

    this._propertyName = value
  }

  _findProperty() {
    let result = false
    
    if (this.target && this._propertyName !== '') {
      if (!this._instance) {
        this._instance = this.target

        const lexer = new Lexer(this._propertyName, '.', '')

        while (lexer.hasNext()) {
          var persistent = lexer.next()
          persistent = stringFirstToLowerCase(persistent)
          var instance = this._instance[persistent]

          if (instance && typeof instance === 'object') {
            this._instance = instance
          }
        }

        this._instancePropertyName = stringFirstToLowerCase(lexer.text)
        result = this._instancePropertyName in this._instance
      } else {
        result = true
      }
    }

    return result
  }
}

class Lexer {
  constructor(text, separator, stop) {
    this.text = text
    this.separator = separator
    this.stop = stop
    this._breaks = separator + stop
  }

  hasNext() {
    return this.text.indexOf(this.separator) >= 0
  }

  next() {
    let i = 0
    for (; this.text.length; i++) {
      var index = this._breaks.indexOf(this.text[i])

      if (index >= 0) {
        break
      }
    }

    let token = this.text.substring(0, i)
    this.text = this.text.substring(i + 1)
    return token
  }
}

export class GVLFloatAnimation extends GVLPropertyAnimation {
  constructor() {
    super()
    this._to = 100
    this._from = 0
  }

  get endValue() {
    return this._to
  }

  set endValue(value) {
    this._to = value
  }

  get startValue() {
    return this._from
  }

  set startValue(value) {
    this._from = value
  }

  clone() {
    let a = super.clone()
    a.startValue = this.startValue
    a.endValue = this.endValue
    return a
  }

  _firstFrame() {
    if (this.startIsCurrent) {
      const from = this._instance[this._instancePropertyName]

      if (from !== undefined && typeof from === 'number') {
        this._from = from
      }
    }
  }

  _processAnimation() {
    if (this._instancePropertyName in this._instance) {
      this._instance[this._instancePropertyName] = interpolateSingle(this._from, this._to, this.normalizedTime)
    }
  }
}

export class GVLBooleanAnimation extends GVLPropertyAnimation {
  constructor() {
    super()
    this._to = true
    this._from = false
    this._lastValue = false
    this.stopAtEnd = true
  }

  clone() {
    let a = super.clone()
    a.stopAtEnd = this.stopAtEnd
    return a
  }

  stop() {
    if (this.running) {
      this._updateValue(true)
    }

    super.stop()
  }

  _firstFrame() {
    if (this.startIsCurrent && this._instance && this._instancePropertyName) {
      const oldValue = this._instance[this._instancePropertyName]

      if (typeof oldValue === 'boolean') {
        this._from = oldValue
        this._to = !this._from
      }
    }
  }

  _processAnimation() {
    if (this._lastValue) {
      if (GVLMath.sameValue(this.normalizedTime, 0, 0.1)) {
        this._updateValue(false)
      }
    } else {
      if (GVLMath.sameValue(this.normalizedTime, 1, 0.1)) {
        this._updateValue(true)
      }
    }
  }

  _updateValue(value) {
    if (this._instance && this._instancePropertyName) {
      const oldValue = this._instance[this._instancePropertyName]

      if (typeof oldValue === 'boolean') {
        this._instance[this._instancePropertyName] = value
        this._lastValue = value
      }
    }
  }
}

class AlphaColor {
  constructor(r, g, b, a) {
    this.r = r
    this.g = g
    this.b = b
    this.a = a
  }

  fromRGBA(rgba) {
    let success = false

    if (rgba.startsWith('rgba(') && rgba.endsWith(')')) {
      const channelsStr = rgba.substring(5, rgba.length - 1)
      const channels = channelsStr.split(',').map(v => +(v.trim()))

      if (channels.length === 4) {
        this.r = channels[0]
        this.g = channels[1]
        this.b = channels[2]
        this.a = Math.trunc(channels[3] * 255)
        success = true
      }
    } else if (rgba.startsWith('#')) {
      let value = rgba.substring(1)

      if (value.length === 8) {
        const a = parseInt(value.substring(0, 2), 16)
        this.a = a / 255
        value = value.substring(2)
      } else {
        this.a = 255
      }

      this.r = parseInt(value.substring(0, 2), 16)
      this.g = parseInt(value.substring(2, 4), 16)
      this.b = parseInt(value.substring(4, 6), 16)
      success = true
    }

    if (!success) {
      console.error('Failed parsing AlphaColor from rgba')
    }
  }

  takeInterpolation(start, stop, t) {
    this.a = start.a + Math.trunc((stop.a - start.a) * t)
    this.r = start.r + Math.trunc((stop.r - start.r) * t)
    this.g = start.g + Math.trunc((stop.g - start.g) * t)
    this.b = start.b + Math.trunc((stop.b - start.b) * t)
  }

  toRGBA() {
    return `rgba(${this.r}, ${this.g}, ${this.b}, ${this.a / 255})`
  }
}

export class GVLColorAnimation extends GVLPropertyAnimation {
  constructor() {
    super()

    this._currentAlpha = new AlphaColor(255, 255, 255, 255)
    this._to = 'rgba(255, 255, 255, 1)'
    this._toAlpha = new AlphaColor(255, 255, 255, 255)
    this._from = 'rgba(255, 255, 255, 1)'
    this._fromAlpha = new AlphaColor(255, 255, 255, 255)
  }

  get endValue() {
    return this._to
  }

  set endValue(value) {
    this._to = value
    this._toAlpha.fromRGBA(value)
  }

  get startValue() {
    return this._from
  }

  set startValue(value) {
    this._from = value
    this._fromAlpha.fromRGBA(value)
  }

  clone() {
    let a = super.clone()
    a.startValue = this.startValue
    a.endValue = this.endValue
    return a
  }

  _firstFrame() {
    if (this.startIsCurrent) {
      if (this._instance && this._instancePropertyName) {
        const value = this._instance[this._instancePropertyName]

        if (typeof value === 'string') {
          this._from = value
          this._fromAlpha.fromRGBA(value)
        }
      }
    }
  }

  _processAnimation() {
    if (this._instance && this._instancePropertyName) {
      const value = this._instance[this._instancePropertyName]

      if (typeof value === 'string') {
        this._currentAlpha.takeInterpolation(this._fromAlpha, this._toAlpha, this.normalizedTime)
        const newValue = this._currentAlpha.toRGBA()

        this._instance[this._instancePropertyName] = newValue
      }
    }
  }
}

class Ticker {

  constructor() {
    this._animations = []
    this._enabled = false
    this._requestHandle = 0

    this._animateLoop = this._animateLoop.bind(this)
  }

  add(value) {
    this._animations.push(value)
    this.enabled = true
  }

  remove(value) {
    const index = this._animations.indexOf(value)

    if (index >= 0) {
      this._animations.splice(index, 1)

      if (this._animations.length === 0) {
        this.enabled = false
      }
    }
  }

  get enabled() {
    return this._enabled
  }

  set enabled(value) {
    if (this._enabled !== value) {
      this._enabled = !!value

      if (this._enabled) {
        this._time = performance.now() / 1000
        this._requestHandle = requestAnimationFrame(this._animateLoop); 
      } else if (this._requestHandle) {
        cancelAnimationFrame(this._requestHandle)
        this._requestHandle = 0
      }
    }
  }

  _animateLoop(timeMilliseconds) {
    const timeSeconds = timeMilliseconds / 1000
    const deltaTime = timeSeconds - this._time
    this._time = timeSeconds

    if (deltaTime <= 0) {
      this._requestHandle = requestAnimationFrame(this._animateLoop)
      return
    }

    if (this._animations.length > 0) {
      let animationCount = this._animations.length

      for (let i = animationCount - 1; i >= 0; ) {
        var animation = this._animations[i]

        if (animation._running) {
          animation.processTick(timeSeconds, deltaTime)
        }

        i--
        animationCount = this._animations.length

        if (i >= animationCount) {
          i = animationCount - 1
        }
      }

      this._requestHandle = requestAnimationFrame(this._animateLoop)
    } else {
      this.enabled = false
    }
  }
}

var ticker;