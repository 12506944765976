import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOnlineUsersAtTime } from '../../services/resource';

export const witnessDisplayEventTypes = ['loLoAlarm', 'normal', 'hiHiAlarm', 'discreteOn', 'discreteOff', 'hiAlarm', 'loAlarm'];

const initialState = {
  event: null,
  context: {
    witnessIsFetching: false,
    witnessFetchError: ''
  },
  visible: false
}

export const showEventDetails = (event) => async (dispatch, getState) => {
  dispatch(openEventDetails(event));

  const witnessTypeDisplay = witnessDisplayEventTypes.includes(event.type.name);
  let state = getState();

  if (state.auth.required && witnessTypeDisplay) {
    dispatch(fetchDetails())
  }
}

export const fetchDetails = createAsyncThunk(
  'eventDetails/fetchDetails', async (arg, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    let state = getState();
    const event = state.eventDetails.event;

    if (!event) {
      throw new Error('event must be set to eventDetails');
    }

    try {
      const response = await getOnlineUsersAtTime(event.startTime);
      const users = response.data;

      const witnessNames = users.map(user => user.name);
      return witnessNames;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      const { response } = error;
      return rejectWithValue(`(${response.status}) ${response.statusText}`);
    }
  });

const slice = createSlice({
  name: 'eventDetails',
  initialState,
  reducers: {
    openEventDetails: (state, action) => {
      const event = action.payload
      state.event = {
        ...event,
        witnessNames: []
      }
      state.context.witnessIsFetching = false
      state.context.witnessFetchError = ''
      state.visible = true
    },
    closeEventDetails: () => ({
      ...initialState
    })
  },
  extraReducers: {
    [fetchDetails.pending]: (state) => {
      state.context.witnessIsFetching = true;
      state.context.witnessFetchError = '';
    },
    [fetchDetails.fulfilled]: (state, action) => {
      if (state.context.witnessIsFetching) {
        const witnessNames = action.payload;
        state.context.witnessIsFetching = false;
        state.event.witnessNames = witnessNames;
      }
    },
    [fetchDetails.rejected]: (state, { error }) => {
      state.context.witnessIsFetching = false;
      state.context.witnessFetchError = error;
    }
  }
})

export const {
  openEventDetails, closeEventDetails
} = slice.actions;

export default slice.reducer;