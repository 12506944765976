import { createSlice } from '@reduxjs/toolkit'
import { getActualEvents } from 'api/api'
import { selectChannelByID, selectEventCatByID } from './liveSlice'
import { getAudioURL } from 'services/webServer'
import { selectAnyHasEventTable, selectMnemoChannelIDs } from 'features/mnemoPage/mnemoPageSlice'

const initialState = {
  events: [],
  ringChannelName: '',
  muteUnixTime: 0,
  userClick: false,
  soundURL: ''
}

export const startAlarms = () => async (dispatch) => {
  const res = await getActualEvents()
  const dtos = res.data

  const events = decodeSubEventRange(dtos)
  dispatch(setEvents(events))
  dispatch(updateAlarmSound())
}

export const acceptEventChanges = message => (dispatch, getState) => {
  const { actualEventIDs, newEvents: newDTOs, changes } = message

  const rootState = getState()
  const newEvents = decodeSubEventRange(newDTOs)

  let actualIDSet = new Set(actualEventIDs)
  let events = [...rootState.alarm.events]

  if (newEvents.length > 0) {
    events = [...events, ...newEvents]
  }

  events = events.map(it => {
    const change = changes.find(change => change.eventID === it.ID)

    if (change && change.acked != null) {
      return {
        ...it,
        acked: !!change.acked
      }
    }

    return it
  })

  events = events.filter(it => actualIDSet.has(it.ID))
  dispatch(setEvents(events))
  dispatch(updateAlarmSound())
}

export const updateAlarmSound = () => (dispatch, getState) => {
  const rootState = getState()

  let soundURL = ''
  let ringChannelName = ''

  const ringEventAndMore = selectAlarmCauseEvent(rootState)
  if (ringEventAndMore) {
    const { cat, channel } = ringEventAndMore

    ringChannelName = channel.name
    soundURL = mapCategoryToSoundURL(cat)
  }

  const { alarm } = rootState
  if (alarm.ringChannelName !== ringChannelName || alarm.soundURL !== soundURL) {
    dispatch(setProps({
      ringChannelName,
      soundURL
    }))
  }
}

const mapCategoryToSoundURL = cat => {
  let soundURL = ''

  const { sound } = cat.messageChannel

  if (sound.filePath !== '') {
    soundURL = getAudioURL(sound.filePath)
  } else {
    soundURL = getAudioURL('PIC.wav')
  }

  return soundURL
}

const decodeSubEventRange = events => {
  return events
    .filter(it => it.channelID !== -1)
    .map(it => decodeSubEvent(it))
}

const decodeSubEvent = dto => {
  return {
    acked: dto.acked,
    channelID: dto.channelID,
    ID: dto.ID,
    startTime: new Date(dto.startTime),
    type: dto.type
  }
}

const slice = createSlice({
  initialState,
  name: 'alarm',
  reducers: {
    userClickDone: (state) => {
      state.userClick = true
    },
    setEvents: (state, { payload }) => {
      state.events = payload
    },
    setProps: (state, { payload }) => {
      const { ringChannelName, soundURL } = payload
      state.ringChannelName = ringChannelName
      state.soundURL = soundURL
    },
  },
})

const selectAlarmCauseEvent = state => {
  const { events, muteUnixTime } = state.alarm
  let lEvents = events
  
  let lastChannel = null
  let lastCat = null
  let lastEvent = null
  let lastTime = 0

  const hasAnyEventTable = selectAnyHasEventTable(state)

  if (!hasAnyEventTable) {
    const channelIDSet = new Set(selectMnemoChannelIDs(state))

    lEvents = lEvents.filter(it => channelIDSet.has(it.channelID))
  }

  for (let e of lEvents) {
    if (e.acked) {
      continue
    }

    const channel = selectChannelByID(state, e.channelID)
    if (!channel) {
      continue
    }

    const cat = selectEventCatByID(state, channel.eventCatID)
    if (!cat) {
      continue
    }

    const { sound } = cat.messageChannel
    const eventStartUnixTime = e.startTime.getTime()

    if (sound.enabled && eventStartUnixTime > muteUnixTime) {
      if (eventStartUnixTime > lastTime) {
        lastTime = eventStartUnixTime
        lastEvent = e
        lastChannel = channel
        lastCat = cat
      }
    }
  }

  return lastEvent
    ? {
      cat: lastCat,
      channel: lastChannel,
      event: lastEvent,
    }
    : null
}

const { setEvents, setProps } = slice.actions
export const { userClickDone } = slice.actions

export default slice.reducer