import { useState, useEffect } from 'react' 
import { useDispatch, useSelector } from 'react-redux'
import { userClickDone } from 'features/live/alarmSlice'
import { useTranslation } from 'react-i18next'
import { closeSnackbar, enqueueSnackbar } from 'notistack'

const AlarmNotifier = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const soundURL = useSelector(state => state.alarm.soundURL)
  const userClick = useSelector(state => state.alarm.userClick)
  const [snackbarId, setSnackbarId] = useState(null)

  useEffect(() => {
    window.addEventListener('click', () => {
      dispatch(userClickDone())
    }, { once: true })
  }, [dispatch])

  useEffect(() => {
    if (soundURL && !userClick) {
      const barId = enqueueSnackbar(t('alarm.inactiveDueToUserNoInteraction'), {
        variant: 'warning',
        persist: true
      })

      setSnackbarId(barId)
    }
  }, [t, soundURL, userClick])

  useEffect(() => {
    if (userClick && snackbarId !== null) {
      closeSnackbar(snackbarId)
      setSnackbarId(null)
    }
  }, [userClick, snackbarId])

  return null
}

export default AlarmNotifier