import { compareFloat } from 'std/math'

export const IntersectionType = Object.freeze({
  Corner: { name: 'Corner' },
  Tee: { name: 'Tee' },
  Cross: { name: 'Cross' },
  name: 'IntersectionType'
})

const useEpsilon = 0.1

const collect = (segments) => {
  let ctx = {
    results: [],
    metSet: new Set()
  }

  for (let seg of segments) {
    checkPoint(ctx, seg.start, seg, segments, false)
    checkPoint(ctx, seg.end, seg, segments, true)
  }

  return ctx.results
}

const checkPoint = (ctx, point, segment, segments, isEnd) => {
  const { metSet, results } = ctx

  if (metSet.has(point.toString())) {
    return
  }

  const neighbors = findNeighborSegments(point, segment, segments)
  const len = neighbors.length
  let handled = false

  if (len >= 3) {
    const usedSegments = [...neighbors, segment]

    const otherPoints = getOtherPoints(point, usedSegments)
    const hasLeft = anyLeftPoint(point, otherPoints)
    const hasTop = anyTopPoint(point, otherPoints)
    const hasRight = anyRightPoint(point, otherPoints)
    const hasBottom = anyBottomPoint(point, otherPoints)

    if (hasLeft && hasTop && hasRight && hasBottom) {
      const inter = {
        location: point,
        type: IntersectionType.Cross
      }

      results.push(inter)
      metSet.add(point.toString())
      handled = true
    }
  }

  if (!handled && len === 2) {
    const first = segment
    const second = neighbors[0]
    const third = neighbors[1]

    if (first.isHorizontal) {
      if (!second.isHorizontal && !third.isHorizontal) {
        // оба вертикальные
        // слева или справа

        const inter = {
          location: point,
          type: IntersectionType.Tee,
          teeKind: isEnd? 'left': 'right'
        }

        results.push(inter)
        metSet.add(point.toString())
        handled = true
      }
    } else {
      if (second.isHorizontal && third.isHorizontal) {
        const inter = {
          location: point,
          type: IntersectionType.Tee,
          teeKind: isEnd? 'top': 'bottom'
        }

        results.push(inter)
        metSet.add(point.toString())
        handled = true
      }
    }
  }

  if (!handled && len === 1) {
    const first = segment
    const second = neighbors[0]

    if (first.isHorizontal !== second.isHorizontal) {
      const inter = {
        location: point,
        type: IntersectionType.Corner,
        cornerKind: 'topLeft',
        segments: []
      }

      if (first.isHorizontal) {
        // second is vertical

        if (isEnd) {
          inter.cornerKind = second.end.equalsTo(point, useEpsilon)
            ? 'topLeft'
            : 'bottomLeft'
        } else {
          inter.cornerKind = second.end.equalsTo(point, useEpsilon)
            ? 'topRight'
            : 'bottomRight'
        }
      } else {
        // first is vertical

        if (isEnd) {
          inter.cornerKind = second.end.equalsTo(point, useEpsilon)
            ? 'topLeft'
            : 'topRight'
        } else {
          inter.cornerKind = second.end.equalsTo(point, useEpsilon)
            ? 'bottomLeft'
            : 'bottomRight'
        }
      }

      inter.segments = [first, second]
      results.push(inter)
      metSet.add(point.toString())
    }
  }
}

const getOtherPoints = (value, segments) => {
  const results = []

  for (let seg of segments) {
    if (!seg.start.equalsTo(value, useEpsilon)) {
      results.push(seg.start)
    }

    if (!seg.end.equalsTo(value, useEpsilon)) {
      results.push(seg.end)
    }
  }

  return results
}

const findNeighborSegments = (value, pivot, inList) => {
  const outs = []

  for (let segment of inList) {
    if (segment === pivot) {
      continue
    }

    if (segment.start.equalsTo(value, useEpsilon) || segment.end.equalsTo(value, useEpsilon)) {
      outs.push(segment)
    }
  }

  return outs
}

const anyLeftPoint = (value, points) => 
  points.some(it => compareFloat(it.x, value.x, useEpsilon) < 0)

const anyRightPoint = (value, points) => 
  points.some(it => compareFloat(it.x, value.x, useEpsilon) > 0)

const anyTopPoint = (value, points) => 
  points.some(it => compareFloat(it.y, value.y, useEpsilon) < 0)

const anyBottomPoint = (value, points) => 
  points.some(it => compareFloat(it.y, value.y, useEpsilon) > 0)

export default collect

