import { resolveEventIds } from 'services/resource'
import { default as axios } from 'axios'

export const getActualEvents = async () => {
  const res = await axios.get('/api/channels/actualEventIds', {
    withCredentials: true
  })
  const ids = res.data

  return await resolveEventIds(ids)
}