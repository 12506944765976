import { GVLControl } from './elements'

export class GVLScrollBox extends GVLControl {

  initDomRoot() {
    const content = this.defineRoot('div')
    content.classList.add('gvl-scrollbox')
  }

  _propertyChanged(name) {
    super._propertyChanged(name)

    switch (name) {
      case 'width':
        this._domRoot.style.width = `${this._width}px`
        break
      case 'height':
        this._domRoot.style.height = `${this._height}px`
        break
    }
  }
}
