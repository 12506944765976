import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ackEvent } from './api'
import { closeSnackbar, enqueueSnackbar } from 'notistack'
import i18n from 'i18n'

const defaultEvent = {
  ID: -1,
  message: ''
}

const initialState = {
  open: false,
  event: {
    ...defaultEvent
  }
}

export const acknowledge = createAsyncThunk('dialog/acknowledge', async ({ event, comment = '' }) => {
  const waitSnackbarId = enqueueSnackbar(i18n.t('acknowledgeAction.waiting'), {
    variant: 'info',
    persist: true
  })

  try {
    await ackEvent({
      ID: event.ID,
      comment
    })
    closeSnackbar(waitSnackbarId)
    enqueueSnackbar(i18n.t('acknowledgeAction.success'), {
      variant: 'success'
    })
  } catch (error) {
    closeSnackbar(waitSnackbarId)
    enqueueSnackbar(i18n.t('acknowledgeAction.error'), {
      variant: 'error',
    })
    throw error
  }
})

const slice = createSlice({
  initialState,
  name: 'acknowledge',
  reducers: {
    close: (state) => {
      state.open = false
    },
    open: (state, { payload }) => {
      const { event } = payload
      state.open = true
      state.event = {
        ...event
      }
    }
  },
  extraReducers: {
    [acknowledge.pending]: (state) => {
      state.open = false
    },
  }
})

export const { close, open } = slice.actions

export default slice.reducer