import { MnemoControl } from '../controls'
import { PointF } from 'GVL/types'
import { CornerLine, HorzLine, VertLine, TeeLine, CrossLine } from './line'
import collectIntersections, { IntersectionType } from './intersections'
import { Quality } from 'utils/common'
import { CircleCap, RightArrowCap } from './caps'

export class LinePlot extends MnemoControl {
  constructor() {
    super()

    this.channelSource = null

    this._keepDomRootByClient = true

    this._activeColor = '#F10000'
    this._inactiveColor = '#F0F0F0'

    this.endCap = 'none'
    this.lineSize = 8
    this.segments = []
    this.startCap = 'none'
    this.tagID = -1
  }

  dispose() {
    this._changeHandler = null
    this._bindCancel && this._bindCancel()
    this._bindCancel = null

    super.dispose()
  }

  get activeColor() { return this._activeColor }
  set activeColor(value) { this._setProp('activeColor', value) }

  get inactiveColor() { return this._inactiveColor }
  set inactiveColor(value) { this._setProp('inactiveColor', value) }

  initDomRoot() {
    this.defineRoot('div')
  }

  render() {
    const parsePointF = (str) => {
      const args = str.split(', ').map(it => parseFloat(it))
      let p = new PointF()
      p.setXY(...args)
      return p
    }

    const segments = this.segments.map(it => {
      let props = new SegmentProps()
      props.isHorizontal = it.orientation === 'horizontal'
      props.direction = it.direction
      props.start = parsePointF(it.start)
      props.end = parsePointF(it.ending)

      return props
    })

    this.removeControlChildren()

    const segmentLineMap = new Map()

    const lineSize = this.lineSize
    const lineSize2 = lineSize / 2

    for (let segment of segments) {
      if (segment.isHorizontal) {
        const line = new HorzLine()
        this.addControl(line)

        const width = segment.end.x - segment.start.x

        line.setBounds({ 
          left: segment.start.x,
          top: segment.start.y - lineSize2,
          width,
          height: lineSize
        })

        segmentLineMap.set(segment, line)
        this._setUpPreferences(line)
      } else {
        const line = new VertLine()
        this.addControl(line)

        const height = segment.end.y - segment.start.y

        line.setBounds({
          left: segment.start.x - lineSize2,
          top: segment.start.y,
          width: lineSize,
          height
        })

        segmentLineMap.set(segment, line)
        this._setUpPreferences(line)
      }
    }

    const intersections = collectIntersections(segments)

    this._considerSpawnCaps(segments)

    for (let inter of intersections) {
      switch (inter.type) {
        case IntersectionType.Corner:
          {
            let corner = new CornerLine()
            this.addControl(corner)

            corner.kind = inter.cornerKind
            corner.setBounds({
              left: inter.location.x - lineSize2,
              top: inter.location.y - lineSize2,
              width: lineSize,
              height: lineSize
            })

            this._setUpPreferences(corner)

            for (let segment of inter.segments) {
              const line = segmentLineMap.get(segment)

              line && reducePipeSideForCorner(corner, line)
            }
          }
          break

        case IntersectionType.Tee:
          {
            let tee = new TeeLine()
            this.addControl(tee)

            tee.kind = inter.teeKind
            tee.setBounds({
              left: inter.location.x - lineSize2,
              top: inter.location.y - lineSize2,
              width: lineSize,
              height: lineSize
            })
            this._setUpPreferences(tee)
          }
          break

        case IntersectionType.Cross:
          {
            let cross = new CrossLine()
            this.addControl(cross)
            cross.setBounds({
              left: inter.location.x - lineSize2,
              top: inter.location.y - lineSize2,
              width: lineSize,
              height: lineSize
            })
            this._setUpPreferences(cross)
          }
          break
      }
    }

    const channel = this.channelSource.getByID(this.tagID)
    channel && this._changeHandler(channel)
  }

  _propertyChanged(name) {
    super._propertyChanged(name)
  }

  _considerSpawnCaps(segments) {
    if (segments.length === 0) {
      return
    }

    const first = segments[0]
    const last = segments[segments.length - 1]

    if (this.startCap !== 'none') {
      switch (first.direction) {
        case 'direct':
          this._spawnCap(this.startCap, first.start, first.isHorizontal? 'left': 'top')
          break

        case 'back':
          this._spawnCap(this.startCap, first.end, first.isHorizontal? 'right': 'bottom')
          break
      }
    }

    if (this.endCap !== 'none') {
      switch (last.direction) {
        case 'direct':
          this._spawnCap(this.endCap, last.end, last.isHorizontal? 'right': 'bottom')
          break

        case 'back':
          this._spawnCap(this.endCap, last.start, last.isHorizontal? 'left': 'top')
          break
      }
    }
  }

  _spawnCap(kind, point, side) {

    switch (kind) {
      case 'circle':
        return createCap_Circle(this, point, this.lineSize, this._inactiveColor)

      case 'rightArrow':
        return createCap_RightArrow(this, point, this.lineSize, this._inactiveColor, side)

      case 'leftArrow':
        return createCap_LeftArrow(this, point, this.lineSize, this._inactiveColor, side)
    }
    return null
  }

  _setUpPreferences(line) {
    line.color = this._inactiveColor
  }

  _changeHandler = (channel) => {
    const { anyUpdate, value, quality } = channel
    if (!anyUpdate) {
      return
    }

    const qualityGood = quality === Quality.Good
    if (!qualityGood) {
      return
    }

    const fillColor = value > 0
      ? this.activeColor
      : this.inactiveColor

    for (let child of this.controls) {
      child.color = fillColor
    }
  }

  _loadingChanged() {
    super._loadingChanged()

    if (this.loading) {
      return
    }

    this._bindCancel = this.channelSource.listen(this.tagID, this._changeHandler)
  }
}

const createCap_Circle = (parent, point, size, color) => {
  let s = size * 2
  let s2 = s / 2

  let c = new CircleCap()
  parent.addControl(c)
  c.color = color
  c.width = s
  c.height = s
  c.left = point.x - s2
  c.top = point.y - s2

  return c
}

const createCap_LeftArrow = (parent, point, size, color, side) => {
  let s = size * 2
  let s2 = s / 2
  
  let c = new RightArrowCap()
  parent.addControl(c)
  c.rotateCenterX = 0
  c.rotateCenterY = 0.5
  c.rotateAngle = 180
  c.color = color
  c.width = s
  c.height = s
  c.left = point.x
  c.top = point.y - s2

  switch (side) {
    case 'top':
    case 'bottom':
      c.rotateAngle -= 90
      c.top -= 0.5
      break

    case 'left':
    case 'right':
      c.left = point.x + 0.5
      break
  }

  return c
}

const createCap_RightArrow = (parent, point, size, color, side) => {
  let s = size * 2
  let s2 = s / 2
  
  let c = new RightArrowCap()
  parent.addControl(c)
  c.rotateCenterX = 0
  c.rotateCenterY = 0.5
  c.color = color
  c.width = s
  c.height = s
  c.left = point.x
  c.top = point.y - s2

   switch (side) {
    case 'top':
    case 'bottom':
      c.rotateAngle -= 90
      c.top += 0.5
      break

    case 'left':
    case 'right':
      c.left = point.x - 0.5
      break
  }

  return c
}

const reducePipeSideForCorner = (corner, line) => {
  // нужно узнать с какой стороны происходит пересечение с этой стороны уменьшить трубу

  // проверка сверху
  if (['topLeft', 'topRight'].includes(corner.kind)) {
    if (line instanceof VertLine) {
      line.height -= corner.height / 2
      return
    }
  }

  // проверка справа
  if (['topRight', 'bottomRight'].includes(corner.kind)) {
    if (line instanceof HorzLine) {
      line.left += corner.width / 2
      line.width -= corner.width / 2
      return
    }
  }

  // проверка снизу
  if (['bottomLeft', 'bottomRight'].includes(corner.kind)) {
    if (line instanceof VertLine) {
      line.top += corner.height / 2
      line.height -= corner.height / 2
      return
    }
  }

  // проверка слева
  if (['topLeft', 'bottomLeft'].includes(corner.kind)) {
    if (line instanceof HorzLine) {
      line.width -= corner.width / 2
      return
    }
  }
}

class SegmentProps {
  constructor() {
    this.direction = 'direct'
    this.isHorizontal = true

    this.start = new PointF()
    this.end = new PointF()
  }

  isNormalized() {
    const distance = this.isHorizontal
      ? this.end.x - this.start.x
      : this.end.y - this.start.y 

    return distance >= 0
  }

  normalize() {
    if (this.isNormalized) {
      return
    }

    let temp = this.start
    this.start = this.end
    this.end = temp
  }
}