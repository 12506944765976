import { stringFirstToLowerCase } from '../GVL/utils'
import { Parser } from '@sl/expr-eval'

const TokenKind = Object.freeze({
  Variable: { name: 'Variable' },
  Ident: { name: 'Ident' },
  Symbol: { name: 'Symbol' }
})

class Token {
  constructor(kind, value) {
    this.kind = kind
    this.value = value
  }

  from(other) {
    this.kind = other.kind
    this.value = other.value
  }
}

class Lexer {

  constructor() {
    this.errors = []
    this._tokens = null
    this._index = -1
    this._termLength = 0
    this._term = ''
  }

  parse(term) {
    this.errors = []
    let tokens = []
    this._tokens = tokens
    let token

    this._term = term
    this._termLength = term.length

    for (this._index = 0; this._index < this._termLength; this._index++) {
      if (this._index < this._termLength && term[this._index] === '$' && term[this._index + 1] === '{') {
        this._doVariable()

        if (this.errors.length > 0) {
          return tokens
        }
      } else if (term[this._index].match(/[A-Za-z_]/)) {
        this._doIdent()
      } else {
        token = new Token(TokenKind.Symbol, term[this._index])
        tokens.push(token)
      }
    }

    return tokens
  }

  _doVariable() {
    let openTimes = 0
    let nameStartIndex = this._index + 2
    this._index = nameStartIndex

    while (this._index < this._termLength) {
      if (this._term[this._index].match(/[/r/n]/)) {
        break
      }

      if (this._term[this._index] === '{') {
        openTimes++
      } else if (this._term[this._index] === '}') {
        if (openTimes === 0) {
          let value = this._term.substring(nameStartIndex, this._index)

          if (value !== '') {
            let token = new Token(TokenKind.Variable, value)
            this._tokens.push(token)
          } else {
            this.errors.push('Met variable without name')
          }

          return
        } else {
          openTimes--
        }
      }

      this._index++
    }

    this.errors.push('Instance reference unclosed')
  }

  _doIdent() {
    let startIndex = this._index
    let endIndex = this._index
    const regexp = /[A-Za-z0-9_]/

    while (this._index < this._termLength) {
      if (this._term[this._index].match(regexp)) {
        endIndex++
      } else {
        break
      }

      this._index++
    }

    const identLength = endIndex - startIndex

    if (identLength > 0) {
      const value = this._term.substring(startIndex, endIndex)
      let token = new Token(TokenKind.Ident, value)
      this._tokens.push(token)
    }

    this._index--
  }
}

class SLPlace {
  constructor(instance, property, varName) {
    this.instance = instance
    this.property = property
    this.varName = varName
  }
}

export class SLExpression {
  constructor(places, mattExpression) {
    this.places = places
    this._expression = mattExpression
    this._scope = {}
  }

  canEvaluate() {
    return true
  }

  evaluate() {
    if (!this.canEvaluate()) {
      throw new Error('Expression cannot be evaluated')
    }
    
    const placeLength = this.places.length
    for (let i = 0; i < placeLength; i++) {
      var place = this.places[i]

      this._scope[place.varName] = place.instance[place.property]
    }

    const resultValue = this._expression.evaluate(this._scope)

    return resultValue
  }
}

export class SLParser {

  constructor() {
    this.errors = []

    this._parser = new Parser({
      operators: {
        // These default to true, but are included to be explicit
        add: true,
        concatenate: true,
        conditional: true,
        divide: true,
        factorial: true,
        multiply: true,
        power: true,
        remainder: true,
        subtract: true,
    
        // Disable and, or, not, <, ==, !=, etc.
        logical: true,
        comparison: true,
    
        // The in operator is disabled by default in the current version
        'in': true
      }
    })

    this._parser.addEnum('AlarmStatus', ['Normal', 'LoAlarm', 'HiAlarm', 'DiscreteOn', 'DiscreteOff', 'HiHiAlarm', 'LoLoAlarm'])

    this._lexer = new Lexer()
  }

  compile(term, instanceSource) {
    this.errors = []
    
    let tokens = this._lexer.parse(term)

    const emitter = new PlaceEmitter(tokens, instanceSource)
    emitter.emit()

    if (emitter.errors.length > 0) {
      this.errors.push.apply(this.errors, emitter.errors)
      return null
    }
    
    let { places, newTokens } = emitter

    const adaptedTerm = this._composeTerm(newTokens)

    let expression = this._parser.parse(adaptedTerm)

    return new SLExpression(places, expression)
  }

  _composeTerm(tokens) {
    let term = ''

    for (let token of tokens) {
      switch (token.kind) {
        case TokenKind.Variable: 
          term += '${' + token.value + '}'
          break
        default:
          term += token.value
      }
    }

    return term
  }
}

export class InstanceSource {
  getByID(value) {

  }
}

class PlaceEmitter {
  constructor(tokens, instanceSource) {
    this._index = 0
    this._tokens = tokens
    this._instanceSource = instanceSource
    this.newTokens = []
    this.places = []
    this.errors = []
  }

  emit() {
    this.places = []
    this.newTokens = []
    var propertyToken
    var propertyName
    var newToken
    var place

    for (this._index = 0; this._index < this._tokens.length; this._index++) {
      var token = this._tokens[this._index]

      if (token.kind === TokenKind.Variable) {
        propertyToken = this._parsePropertyNameToken()
        propertyName = stringFirstToLowerCase(propertyToken.value)

        let instance = this._instanceSource.getByID(+token.value)

        if (instance) {
          if (instance[propertyName] !== undefined) {
            place = this._addPlace(instance, propertyName)
            newToken = new Token(TokenKind.Symbol, place.varName)
            this.newTokens.push(newToken)
          } else {
            this.errors.push(`Instance have no ${propertyName} property`)
          }
        } else {
          this.errors.push(`There's no instance with id ${token.value}`)
        }
      } else if (token.kind === TokenKind.Symbol) {
        if (token.value !== '/r' && token.value !== '/n') {
          newToken = new Token()
          newToken.from(token)
          this.newTokens.push(newToken)
        }
      } else {
        newToken = new Token()
        newToken.from(token)
        this.newTokens.push(newToken)
      }
    }
  }

  _parsePropertyNameToken() {
    let token = new Token(TokenKind.Ident, 'value')

    const nextTokenCount = this._tokens.length - (this._index + 1)

    if (nextTokenCount >= 1) {
      const nextToken = this._tokens[this._index + 1]

      if (nextToken.kind === TokenKind.Symbol && nextToken.value === '.') {
        this._index++

        if (nextTokenCount >= 2) {
          const next2Token = this._tokens[this._index + 1]

          if (next2Token.kind === TokenKind.Ident) {
            this._index++
            token.from(next2Token)
          } else {
            this.errors.push(`Expected instance property name but met ${next2Token.value}`)
          }
        } else {
          this.errors.push('Expected instance property name in the end')
        }
      }
    }

    return token
  }

  _addPlace(instance, propertyName) {
    let place = this._findPlaceByInstanceAndPropertyName(instance, propertyName)

    if (!place) {
      const instanceChar = String.fromCharCode(65 + this.places.length)
      const varName = `tag_${instanceChar}_${propertyName}`
      place = new SLPlace(instance, propertyName, varName)
      this.places.push(place)
    }

    return place
  }

  _findPlaceByInstanceAndPropertyName(instance, propertyName) {
    for (let place of this.places) {
      if (place.instance === instance && place.property === propertyName) {
        return place
      }
    }

    return null
  }
}