
export class EventorOn {
  constructor() {
    this._events = {}
  }
  
  dispose() {
    this._events = {}
  }
  
  on(eventName, listener) {
    let listeners = this._events[eventName]
    
    if (!listeners) {
      listeners = []
      this._events[eventName] = listeners
    }
    
    listeners.push(listener)
  }
  
  off(eventName, listener) {
    const listeners = this._events[eventName]
    if (!listeners) {
      return
    }
    
    const index = listeners.indexOf(listener)
    
    if (index >= 0) {
      listeners.splice(index, 1)
    }
  }
  
  fireEvent(eventName, ...args) {
    const listeners = this._events[eventName]
    if (!listeners) {
      return
    }
    
    let listener
    
    for (let i = 0; i < listeners.length; i++) {
      listener = listeners[i]
      
      try {
        listener.apply(this, args)
      } catch (e) {
        console.error(e)
      }
    }
  }
}