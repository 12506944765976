import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import MenuIcon from '@mui/icons-material/Menu'
import { useTranslation } from 'react-i18next'

import { ReactComponent as MnemoIcon } from './icons/mnemos.svg'
import { ReactComponent as TagsIcon } from './icons/tags.svg'
import { ReactComponent as ToolsIcon } from './icons/tools.svg'
import { ReactComponent as EventLogIcon } from './icons/eventLog.svg'
import { ReactComponent as ChartIcon } from './icons/chart.svg'
import { ReactComponent as UserIcon } from './icons/user.svg'
import { ReactComponent as AboutIcon } from './icons/about.svg'
import { ReactComponent as ArrowBottomIcon } from './icons/arrowBottom.svg'
import { ReactComponent as ControlPanelIcon } from './icons/controlPanel.svg'
import { selectMenuVisible } from './MainMenuSlice'
import styles from './MainMenu.module.css'

export const MainMenu = () => {
  const [menuOpen, setMenuOpen] = useState(false)

  const appBarClick = () => setMenuOpen(!menuOpen)
  const itemClick = () => setMenuOpen(false)

  return (
    <>
      <Backdrop opened={menuOpen} onClick={itemClick} />
      <Menu opened={menuOpen} itemClick={itemClick} />
      <AppBar onClick={appBarClick} />
    </>
  )
}

const Backdrop = ({ opened, onClick }) => 
  <div className={!opened? `${styles.backdrop} ${styles.dNone}`: styles.backdrop} onClick={onClick}></div>

const Menu = ({ opened, itemClick }) => {
  const { t } = useTranslation()
  const authIsActive = useSelector(state => state.auth.required)
  const mnemosVisible = useSelector(selectMenuVisible('mnemos'))
  const tagsVisible = useSelector(selectMenuVisible('channels'))
  const eventLogVisible = useSelector(selectMenuVisible('eventLog'))
  const chartViewerVisible = useSelector(selectMenuVisible('chartViewer'))
  const userVisible = useSelector(selectMenuVisible('user'))
  const aboutVisible = useSelector(selectMenuVisible('about'))
  const controlPanelVisible = useSelector(selectMenuVisible('controlPanel'))

  return (
    <nav className={opened? `${styles.menu} ${styles.menuOpen}`: styles.menu}>
      <MenuItem name={t('sidebar.items.mnemotable')} toHref="/app/mnemotable" itemClick={itemClick} icon={<MnemoIcon />} visible={mnemosVisible} />
      <MenuItem name={t('sidebar.items.tags')} toHref="/app/tags" itemClick={itemClick} icon={<TagsIcon />} visible={tagsVisible} />
      <MenuItem name={t('sidebar.items.tools')} icon={<ToolsIcon />} visible={eventLogVisible || chartViewerVisible}>
        <MenuItem name={t('sidebar.items.eventLog')} toHref="/app/eventlog" itemClick={itemClick} icon={<EventLogIcon />} visible={eventLogVisible}></MenuItem>
        <MenuItem name={t('sidebar.items.chart')} toHref="/app/chart" itemClick={itemClick} icon={<ChartIcon />} visible={chartViewerVisible}></MenuItem>
      </MenuItem>
      {authIsActive? 
        <MenuItem name={t('sidebar.items.user')} toHref="/app/user" itemClick={itemClick} icon={<UserIcon />} visible={userVisible} />
        : null
      }
      <MenuItem name={t('sidebar.items.about')} toHref="/app/about" itemClick={itemClick} icon={<AboutIcon />} visible={aboutVisible} />
      <MenuItem name={t('sidebar.items.controlPanel')} toHref="/app/control_panel" itemClick={itemClick} icon={<ControlPanelIcon />} visible={controlPanelVisible} />
    </nav>
  )
}

const MenuItem = ({ name, icon, toHref, itemClick, children, visible = true }) => {
  const [expanded, setExpanded] = useState(false)

  const onClick = () => {
    if (React.Children.count(children) > 0) {
      setExpanded(!expanded)
    }
  }

  const content = (
    <>
      <div>{icon}</div>
      <div className={styles.menuItemTitle}>{name}</div>
      {React.Children.count(children) > 0 &&
        (<Chevron />)
      }
    </>
  )

  if (!visible) {
    return null
  }

  return (
    <div className={styles.menuItemGroup}>
      <div className={expanded? `${styles.menuItem} ${styles.menuItemExpanded}`: styles.menuItem} onClick={onClick}>
        {toHref
          ? <Link to={toHref} className={styles.content} onClick={itemClick}>{content}</Link>
          : <div className={styles.content}>{content}</div>
        }
      </div>
    {expanded? children: null}
    </div>
  )
}

const Chevron = () => {
  return <ArrowBottomIcon className={styles.arrowBottom} />
}

const AppBar = ({ onClick }) => {
  return (
    <div className={styles.appBar}>
      <button onClick={onClick}>
        <MenuIcon sx={{ color: '#c9c9c9' }} />
      </button>
    </div>
  )
}
