import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getEventPageByCriterion2 } from '../../services/resource';
import { mapDTOToEvents } from '../../utils/events';

const createEventType = () => ({ enabled: true })

const initialState = {
  criteria: {
    date: new Date(),
    eventTypes: {
      hiHiAlarm: createEventType(),
      hiAlarm: createEventType(),
      normal: createEventType(),
      loAlarm: createEventType(),
      loLoAlarm: createEventType(),
      discreteOn: createEventType(),
      discreteOff: createEventType(),
      warning: createEventType(),
      info: createEventType(),
      alarm: createEventType(),
      userAction: createEventType(),
      badQuality: createEventType(),
    }
  },
  filter: {
    visible: false
  },
  events: [],
  continueEventID: -1,
  needReset: false,
  isFetching: false,
  fetchingError: ''
}

export const fetchEvents = createAsyncThunk('eventLog/fetchEvents', async (payload, thunkAPI) => {
  const { getState } = thunkAPI
  let state = getState()

  const limit = 300
  const { criteria } = state.eventLogPage
  const { date } = criteria
  let { continueEventID } = state.eventLogPage
  
  if (state.eventLogPage.needReset) {
    continueEventID = initialState.continueEventID
  }
  
  const channelIDs = state.live.channels.items.map(it => it.ID)
  const eventTypes = Object.keys(criteria.eventTypes)
    .filter(typeName => criteria.eventTypes[typeName].enabled)

  const response = await getEventPageByCriterion2({ date, channelIDs, eventTypes, continueEventID, limit })
  const dtoEvents = response.data

  state = getState()

  if (state.eventLogPage.isFetching) {
    const events = mapDTOToEvents(dtoEvents, state)
    return events
  }
})

export const considerFetchEvents = () => (dispatch, getState) => {
  const state = getState();

  if (state.eventLogPage.isFetching) { 
    return;
  }

  dispatch(fetchEvents());
}

export const initialFetchEvents = () => (dispatch, getState) => {
  const state = getState()

  if (state.eventLogPage.isFetching) { 
    return
  }

  dispatch(setReset())
  dispatch(fetchEvents())
}

const eventLogSlice = createSlice({
  name: 'eventLog',
  initialState,
  reducers: {
    setCriteriaDate: (state, action) => {
      const date = action.payload
      state.criteria.date = date
      state.needReset = true
    },
    setEnableEventType: (state, action) => {
      const { typeName, enabled } = action.payload

      const eventType = state.criteria.eventTypes[typeName]
      eventType.enabled = enabled
      state.needReset = true
    },
    setFilterVisible: (state, action) => {
      const visible = action.payload

      state.filter.visible = visible
      state.needReset = true
    },
    pageClosed: (state) => {
      state.isFetching = false
      state.events = []
      state.fetchingError = ''
      state.continueEventID = initialState.continueEventID
    },
    setReset: (state) => {
      state.needReset = true
    }
  },
  extraReducers: {
    [fetchEvents.pending]: (state) => {
      state.isFetching = true
      state.fetchingError = ''
    },
    [fetchEvents.fulfilled]: (state, action) => {
      if (state.isFetching) {
        const loadedEvents = action.payload

        state.isFetching = false

        if (state.needReset) {
          state.events = loadedEvents
        } else {
          state.events = state.events.concat(loadedEvents)
        }
        
        const continueEventID = state.events.length > 0
          ? state.events[state.events.length - 1].ID
          : initialState.continueEventID
    
        state.continueEventID = continueEventID
        state.needReset = false
      }
    },
    [fetchEvents.rejected]: (state, { error }) => {
      state.isFetching = false
      state.fetchingError = error.message
    }
  }
})

export const { 
  setCriteriaDate, setEnableEventType, setFilterVisible, pageClosed,
  setReset
 } = eventLogSlice.actions

export default eventLogSlice.reducer;