import ReactDOM from 'react-dom'
import { MnemoControl } from '../controls'
import TabRow, { fontToCssStyle } from './TabRow'
import { getTabView } from './api'
import Metrics from 'std/metrics'

export class TabView extends MnemoControl {
  constructor() {
    super()

    const activeTabFont = {
      family: 'Segoe UI',
      size: 12,
      bold: false,
      italic: false,
    }

    const tabFont = {
      family: 'Segoe UI',
      size: 12,
      bold: false,
      italic: false,
    }

    this._keepDomRootByClient = true
    this._tabs = []
    this.props = {
      status: 'idle',
      viewProps: {
        activeTabFont,
        tabFont,
      },
      tabs: []
    }
    this.activeTabFont = {
      family: 'Segoe UI',
      size: 12,
      bold: false,
      italic: false,
    }
    

    this.width = 200
    this.height = 40
  }

  dispose() {
    if (this._domRoot) {
      ReactDOM.unmountComponentAtNode(this._domRoot)
    }
    super.dispose()
  }

  initDomRoot() {
    this.defineRoot('div')
  }


  _loaded() {
    super._loaded()
    this._fetchTabs()
  }

  async _fetchTabs() {
    this.props.status = 'fetching'
    this.props.tabs = []
    this.requestUpdate()
    try {
      const res = await getTabView()
      const tabRow = res.data

      this.props.viewProps = tabRow.props
      this.props.tabs = tabRow.tabs.map(it => ({
        ...it,
        title: it.mnemoPath.replace('@', ''),
      }))

      this.props.status = 'idle'
      this.requestUpdate()
    } catch (error) {
      const { response } = error
      this.props.status = 'error'
      this.props.statusText = response
        ? `(${response.status}) ${response.statusText}`
        : error.toString()

      this.requestUpdate()
    }
  }

  render() {
    const parent = this.absoluteParent
    const mnemoName = parent? parent.name: ''

    const { activeTabFont, tabFont } = this.props.viewProps
    const { tabs } = this.props

    let cssActiveTabFont = fontToCssStyle(activeTabFont).font
    let cssTabFont = fontToCssStyle(tabFont).font

    const hs = tabs.map(it => Metrics.computeTextHeight(it.title, it.mnemoPath === mnemoName? cssActiveTabFont: cssTabFont))
    const maxHeight = Math.max(40, ...hs)

    this.height = maxHeight
    

    ReactDOM.render(
      <TabRow 
        {...this.props}
        currentMnemoName={mnemoName}
        width={this.width}
        height={this.height}
      />,
      this._domRoot
    )
  }
}

export default TabView