import { FontStyle, GVLControl, GVLFont } from "./elements"
import { HorzAlign, VertAlign } from './types'

export class GVLTextBlock extends GVLControl {
  constructor() {
    super()

    this._color = '#F0F0F0'
    this._text = 'Текст'
    this._font = new GVLFont()
    this._font.family = 'Segoe UI'
    this._font.style = [FontStyle.fsBold]
    this._font.size = 27
    this._font.onChange = this._fontChange.bind(this)

    this._horzAlign = 'center' // HorzAlign.Center
    this._vertAlign = 'center'// VertAlign.Center
    this._wrapping = false

    this.width = 100
    this.height = 40
  }

  dispose() {
    this._font.dispose()
    super.dispose()
  }

  get color() {
    return this._color
  }

  set color(value) {
    if (this._color !== value) {
      this._color = value
      this.requestUpdate()
    }
  }

  get font() {
    return this._font
  }

  set font(value) {
    this.font.acceptProps(value)
  }

  get horzAlign() {
    return this._horzAlign
  }

  set horzAlign(value) {
    if (this._horzAlign !== value) {
      this._horzAlign = value
      this.requestUpdate()
    }
  }

  get text() {
    return this._text
  }

  set text(value) {
    if (this._text !== value) {
      this._text = '' + value
      this.requestUpdate()
    }
  }

  get vertAlign() {
    return this._vertAlign
  }

  set vertAlign(value) {
    if (this._vertAlign !== value) {
      this._vertAlign = value
      this.requestUpdate()
    }
  }

  get wrapping() {
    return this._wrapping
  }

  set wrapping(value) {
    const newValue = !!value

    if (this._wrapping !== newValue) {
      this._wrapping = newValue
      this.requestUpdate()
    }
  }

  _fontChange() {
    this.requestUpdate()
  }

  _propertyChanged(name) {
    super._propertyChanged(name)

    switch (name) {
      case 'width':
        this._domRoot.style.width = `${this._width}px`
        break
      case 'height':
        this._domRoot.style.height = `${this._height}px`
        break
    }
  }

  initDomRoot() {
    const domRoot = document.createElement('div')
    domRoot.classList.add('gvl-control', 'gvl-text')

    this._domRoot = domRoot
  }

  render() {
    const block = this._domRoot
    block.textContent = this._text

    const { style } = block

    style.color = this._color
    
    const font = this._font
    style.font = font.toCSSValue()
    style.lineHeight = `${font.size}px`

    // Для горизонтального выравнивания 
    // без переноса строк используется flex box
    // с переносом text-align

    const horzAlign = typeof this._horzAlign === 'string'
      ? this._horzAlign.toLowerCase()
      : this._horzAlign

    const vertAlign = typeof this._vertAlign === 'string'
      ? this._vertAlign.toLowerCase()
      : this._vertAlign
    
    switch(horzAlign) {
      case HorzAlign.Left:
      case 'left':
        style.textAlign = 'left'
        style.justifyContent = 'flex-start'
        break
      case HorzAlign.Center:
      case 'center':
        style.textAlign = 'center'
        style.justifyContent = 'center'
        break
      case HorzAlign.Right:
      case 'right':
        style.textAlign = 'right'
        style.justifyContent = 'flex-end'
        break
    }

    switch(vertAlign) {
      case VertAlign.Top:
      case 'top':
        style.alignItems = 'flex-start'
        break
      case VertAlign.Center:
      case 'center':
        style.alignItems = 'center'
        break
      case VertAlign.Bottom:
      case 'bottom':
        style.alignItems = 'flex-end'
        break
    }

    style.whiteSpace = this._wrapping? 'pre-wrap': 'nowrap'
  }
}