import keyMirror from 'key-mirror'
import { GVLArgumentControl, GVLFont } from './elements'
import { GVLFigureView } from './figureView'
import { GVLTextBlock } from './text'
import { FAView } from './faView'
import { ValueObject, HorzAlign, VertAlign } from './types'
import { DOM } from '@sl/utils'
import { unpack } from '@sl/utils/src/uni-value'
import history from 'utils/history'
import { writeChannelValueAsync } from 'services/webServer'
import { formatValue, stringFirstToLowerCase, tr } from 'utils/common'
import { closeMnemo, openDialog, selectMnemoByName, MnemoKind } from 'features/mnemoPage/mnemoPageSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import { GVLRect } from './primitives'
import { enqueueSnackbar } from 'notistack'
import { confirmDialog, logout } from 'actions/mnemo'
import { Button } from 'features/dialogs/types'

let _store

export const injectStore = value => {
  _store = value
}

export class ActionProps extends ValueObject {
  constructor() {
    super()
    
    this.containerName = ''
    this.mnemoPath = ''
    this.tagID = -1
    this.value = ''
    this.variableLine = ''
    this.writeConfirm = false
    this.writeConfirmText = ''
  }
}

const getKind = ({ isDown, isHover }) => {
  if (isDown) {
    return 'pressed'
  }

  if (isHover) {
    return 'hover'
  }

  return 'normal'
}

const Action = keyMirror({
  closeClient: null,
  closeMnemo: null,
  openChartPage: null,
  openEventLogPage: null,
  openMnemo: null,
  openMnemoAsDialog: null,
  openMnemoInContainer: null,
  logout: null,
  writeArgument: null,
  writeChannel: null
})

const writeChannel = async (state, update, props) => {
  const { tagID, value: rawValue } = props
  const value = unpack(rawValue)

  state.status = 'fetching'
  state.statusText = ''
  update()

  try {
    await writeChannelValueAsync(tagID, value)
    state.status = 'idle'
    state.statusText = ''
    update()
  } catch (error) {
    state.status = 'error'
    if (error.response) {
      state.statusText = error.response.data
    } else {
      state.statusText = error.toString()
    }

    update()
  }
}

const writeArgument = async (service, state, update, props) => {
  const { argument, value: rawValue } = props
  const value = unpack(rawValue)

  state.status = 'fetching'
  state.statusText = ''
  update()

  try {
    await service.writeValueAsync(argument, value)
    state.status = 'idle'
    state.statusText = ''
    update()
  } catch (error) {
    state.status = 'error'

    if (error.response) {
      state.statusText = error.response.data
    } else {
      state.statusText = error.toString()
    }
    
    update()
  }
}

const isTouchMode = () => "ontouchstart" in document.documentElement

export class GVLActionButton extends GVLArgumentControl {
  constructor() {
    super()
    this.action = Action.openMnemo
    this.actionProps = new ActionProps()
    this.font = new GVLFont()
    this.font.family = 'Segoe UI'
    this.font.size = 27

    this.hoverBackgroundColor = '#9B9B9B'
    this.hoverBorderColor = '#3C3C3C'
    this.hoverBorderWidth = 4
    this.hoverURI = ''
    this.hoverText = ''
    this.hoverTextColor = '#F0F0F0'

    this.normalBackgroundColor = '#AFAFAF'
    this.normalBorderColor = '#3C3C3C'
    this.normalBorderWidth = 4
    this.normalURI = ''
    this.normalText = ''
    this.normalTextColor = '#F0F0F0'

    this.pressedBackgroundColor = '#494949'
    this.pressedBorderColor = '#3C3C3C'
    this.pressedBorderWidth = 4
    this.pressedURI = ''
    this.pressedText = ''
    this.pressedTextColor = '#F0F0F0'

    this.width = 100
    this.height = 30

    this.state = {
      isDown: false,
      isHover: false,
      status: 'idle',
      statusText: ''
    }
  }

  dispose() {
    this.font.dispose()

    const $btn = this._domRoot

    if ($btn) {
      if (isTouchMode()) {
        $btn.removeEventListener('touchstart', this._mousedown)
        window.removeEventListener('touchend', this._mouseup)
      } else {
        $btn.removeEventListener('mouseover', this._mouseover)
        $btn.removeEventListener('mouseleave', this._mouseleave)
        $btn.removeEventListener('mousedown', this._mousedown)
        window.removeEventListener('mouseup', this._mouseup)
      }
    }

    super.dispose()
  }

  get text() { return this.normalText }
  set text(value) {
    this.normalText = value
    this.hoverText = value
    this.pressedText = value
    this.requestUpdate()
  }

  _click() {
    const action = stringFirstToLowerCase(this.action)

    switch (action) {
      case Action.logout:
        logout()
        break

      case Action.closeClient: {
        
        const isContained = window.top !== window
        if (isContained) {
          this._closeClient()
        }
      }
        break

      case Action.closeMnemo: {
        const mnemoHandle = this.findParent(it => it.typeName === 'Mnemo')
        const isRootMnemo = !(mnemoHandle?.parent)
        if (isRootMnemo) {
          const appState = _store.getState()
          const lMnemo = selectMnemoByName(appState, mnemoHandle.name)
          if (!lMnemo)
            return

          if (lMnemo.kind === MnemoKind.dialog) {
            _store.dispatch(closeMnemo(mnemoHandle.name))
          } else {
            history.push('/app/mnemotable')
          }
        } else {
          mnemoHandle?.dispose()
        }
      }
        break

      case Action.openChartPage: {
        const parent = this.absoluteParent
        const appendURL = parent? `?source_mnemo=${encodeURIComponent(parent.name)}`: ''
        history.push(`/app/chart${appendURL}`)
      } 
        break

      case Action.openEventLogPage: {
        const parent = this.absoluteParent
        const appendURL = parent? `?source_mnemo=${encodeURIComponent(parent.name)}`: ''
        history.push(`/app/eventlog${appendURL}`)
      }
        break

      case Action.openMnemo: {
        const { mnemoPath } = this.actionProps
        if (mnemoPath !== '') {
          history.push(encodeURI(`/app/mnemo/${mnemoPath}`))
        }
      }
        break

      case Action.openMnemoAsDialog: {
        const { mnemoPath, variableLine, windowTitle } = this.actionProps
        if (mnemoPath === '') {
          return
        }

        const { x, bottom: y } = this._domRoot.getBoundingClientRect()

        _store.dispatch(openDialog({ 
          defaultPosition: { x, y },
          mnemoName: mnemoPath,
          variableLine,
          windowTitle,
        }))
        .then(unwrapResult)
        .catch(err => err && !err.ignore && console.error(err))
      }
        break

      case Action.openMnemoInContainer: {
        const { containerName, mnemoPath, variableLine } = this.actionProps
        const mnemo = this.findParent(it => it.typeName === 'Mnemo')
        const container = mnemo?.findControl(it => it.name === containerName)
        if (!container) 
          return

        container.variableLine = variableLine
        container.mnemoPath = ''
        container.mnemoPath = mnemoPath
      }
        break

      case Action.writeChannel:
          writeChannel(this.state, this.requestUpdate.bind(this), this.actionProps)
        break

      case Action.writeArgument: {
        const { writeConfirm, writeConfirmText, value: uniValue } = this.actionProps
        const { argument, argumentService, state } = this

        if (!argument || !argumentService) {
          return
        }

        (async () => {
          const value = unpack(uniValue)

          if (writeConfirm) {
            const viewValue = formatValue(value, {
              fractionDigits: argument.variableFormat,
              type: argument.type.name,
            })
            const text = writeConfirmText !== ''
              ? writeConfirmText
              : tr('dialog.confirm.writeAction', { value: viewValue })

            const button = await confirmDialog.show(text)
            if (button !== Button.Ok) {
              return
            }
          }

          writeArgument(argumentService, state, this.requestUpdate.bind(this), { value: uniValue, argument })
        })()
      }
        break
    }
  }

  _closeClient() {
    const { auth } = _store.getState()
    const { closeClient } = auth.permissions

    if (auth.required && !closeClient) {
      enqueueSnackbar(tr('permissions.closeClient'), { variant: 'warning' })
      return
    }

    window.top.postMessage({
      cmd: 'closeClient',
    }, '*')
  }

  _mouseover() {
    this.state.isHover = true
    this.requestUpdate()
  }

  _mouseleave() {
    this.state.isHover = false
    this.requestUpdate()
  }

  _mousedown() {
    this.state.isDown = true
    this.requestUpdate()
  }

  _mouseup() {
    if (!this.state.isDown) {
      return
    }

    this.state.isDown = false
    this.requestUpdate()
  }

  initDomRoot() {
    this._mouseover = this._mouseover.bind(this)
    this._mouseleave = this._mouseleave.bind(this)
    this._mousedown = this._mousedown.bind(this)
    this._mouseup = this._mouseup.bind(this)
    this._click = this._click.bind(this)

    const $btn = DOM('div', 'gvl-control')
    this._domRoot = $btn.node
    $btn.on('click', this._click)

    if (isTouchMode()) {
      $btn.on('touchstart', this._mousedown)
      window.addEventListener('touchend', this._mouseup)
    } else {
      $btn.on('mouseover', this._mouseover)
      $btn.on('mouseleave', this._mouseleave)
      $btn.on('mousedown', this._mousedown)
      window.addEventListener('mouseup', this._mouseup)
    }

    let $bg = new GVLRect()
    this.addControl($bg)
    this.$bg = $bg

    let $normal = new GVLFigureView()
    this.addControl($normal)
    this.$normal = $normal

    let $hover = new GVLFigureView()
    this.addControl($hover)
    this.$hover = $hover

    let $pressed = new GVLFigureView()
    this.addControl($pressed)
    this.$pressed = $pressed

    let $text = new GVLTextBlock()
    $text.horzAlign = HorzAlign.Center
    $text.vertAlign = VertAlign.Center
    this.addControl($text)
    this.$text = $text

    this.$spinner = new FAView()
    this.addControl(this.$spinner)
    this.$spinner.visible = false
  }

  render() {

    const { $normal, $hover, $pressed, $text, $spinner, $bg, state } = this
    const kind = getKind(state)

    $bg.visible = false
    $bg.width = this.width
    $bg.height = this.height
    $bg.radiusX = 7
    $bg.radiusY = 7

    $normal.width = this.width
    $normal.height = this.height
    $normal.uri = this.normalURI
    $normal.visible = kind === 'normal'

    $hover.width = this.width
    $hover.height = this.height
    $hover.uri = this.hoverURI
    $hover.visible = kind === 'hover'

    $pressed.width = this.width
    $pressed.height = this.height
    $pressed.uri = this.pressedURI
    $pressed.visible = kind === 'pressed'

    const hasError = state.status === 'error'

    $spinner.setBounds({ left: 0, top: 0, width: this._width, height: this._height })
    $spinner.iconClass = hasError? 'exclamation-triangle': 'spinner'
    $spinner.spinning = !hasError
    $spinner.title = state.statusText
    $spinner.visible = state.status !== 'idle'

    $text.font = this.font
    $text.width = this.width
    $text.height = this.height
    $text.visible = state.status === 'idle'

    switch (kind) {
      case 'normal':
        $text.color = this.normalTextColor
        $text.text = this.normalText

        $bg.visible = this.normalURI === ''
        if ($bg.visible) {
          $bg.fill.color = this.normalBackgroundColor
          $bg.stroke.color = this.normalBorderColor
          $bg.stroke.width = this.normalBorderWidth
        }
        break

      case 'hover':
        $text.color = this.hoverTextColor

        if (this.hoverText === '') {
          $text.text = this.normalText
        } else {
          $text.text = this.hoverText
        }

        if (this.hoverURI === '') {
          $hover.visible = false
          $bg.visible = this.normalURI === ''

          if ($bg.visible) {
            $bg.fill.color = this.hoverBackgroundColor
            $bg.stroke.color = this.hoverBorderColor
            $bg.stroke.width = this.hoverBorderWidth
          } else {
            $normal.visible = true
          }
        }
        break
        
      case 'pressed':
        $text.color = this.pressedTextColor
        $text.text = this.pressedText

        $bg.visible = this.pressedURI === ''
        if ($bg.visible) {
          $bg.fill.color = this.pressedBackgroundColor
          $bg.stroke.color = this.pressedBorderColor
          $bg.stroke.width = this.pressedBorderWidth
        }
        break
    }
  }

  _propertyChanged(name) {
    super._propertyChanged(name)

    const s = this._domRoot.style

    switch (name) {
      case 'width':
        s.width = `${this._width}px`
        this.requestUpdate()
        break
      case 'height':
        s.height = `${this._height}px`
        this.requestUpdate()
        break
    }
  }
}