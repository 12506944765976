import React, { Component } from 'react';
import { isDev } from 'utils/common'

export class ReactMnemoContainer extends Component {

  static defaultProps = {
    mnemo: null
  }

  constructor(props) {
    super(props);

    this._ref = React.createRef();
  }

  componentDidMount() {
    const mnemo = this.props.mnemo;

    if (mnemo && this._ref.current) {
      mnemo.attach(this._ref.current);
      //isDev && console.log('mnemo attached');
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.mnemo !== prevProps.mnemo) {
      const prevMnemo = prevProps.mnemo;
      const nextMnemo = this.props.mnemo;

      prevMnemo && prevMnemo.dispose();

      if (nextMnemo) {
        nextMnemo.attach(this._ref.current);
        //isDev && console.log('mnemo attached');
      }
    }
  }

  render() {
    return <div ref={this._ref} style={{ height: '100%' }}></div>
  }
}