export function dupeString(string, times) {
  return new Array(times + 1).join(string)
}

function escapeRegExp(str) {
    return str.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1")
}

export function replaceAll(str, search, replacement) {
  let escapedPattern = escapeRegExp(search)
  return str.replace(new RegExp(escapedPattern, 'g'), replacement)
}

export function replaceAllIgnoreCase(str, search, replacement) {
  let escapedPattern = escapeRegExp(search)
  return str.replace(new RegExp(escapedPattern, 'ig'), replacement)
}

export function isEmpty(str) {
  return (str.length === 0 || !str.trim())
}