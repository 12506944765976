import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import keyMirror from 'key-mirror'
import { getMnemoContent } from 'services/webServer'
import { RequestSubject } from 'services/NetClient'
import { updateAlarmSound } from 'features/live/alarmSlice'
import GVLOperationEventTable from 'GVL/EventTable'
import { decodeMnemo } from 'mnemo/component'
import history from 'utils/history'
import client from 'services/client'
import { decodeVariableLine, isTemplateMnemoPath, transpose } from 'mnemo/templates'

/*
  type State
    mnemos Array<MnemoProps>
    windows Array<WindowProps>

  type MnemoProps
    kind MnemoKind
    name string
    status MnemoStatus
    statusText string
    windowId string

  type WindowProps
    defaultPosition Position
    mnemoName string
    title string
    windowId string

  type Position
    x number
    y number
*/

export const MnemoKind = keyMirror({
  dialog: null,
  main: null,
})

export const MnemoStatus = Object.freeze({
  idle: 'idle',
  fetching: 'fetching',
  error: 'error'
})

const initialState = {
  mnemos: [],
  windows: []
}

const acceptMnemo = createAction('mnemoPage/acceptMnemo')

export const openDialog = createAsyncThunk('mnemoPage/openDialog', async ({ defaultPosition, mnemoName, variableLine, windowTitle }, api) => {
  const { dispatch } = api
  const appState = api.getState()
  const { mnemos } = appState.mnemoPage

  const isOpen = mnemos.some(it => it.mnemoName === mnemoName)
  if (isOpen) {
    dispatch(closeMnemo(mnemoName))
  }

  dispatch(openDialogMnemo({ defaultPosition, mnemoName, windowTitle }))

  let { data: dto } = await getMnemoContent(mnemoName)

  if (isTemplateMnemoPath(mnemoName)) {
    const variables = decodeVariableLine(variableLine)
    dto = transpose(dto, variables, appState)
  }
  const handle = decodeMnemo(null, dto, mnemoName)

  const channelIDs = handle.getUsedTags()
  const needSubscribe = channelIDs.length > 0

  handle.on('dispose', () => {
    handleMap[mnemoName] = undefined

    if (needSubscribe) {
      client.delete(RequestSubject.SubscribeChannels, { channelIDs })
    }
  })

  handleMap[mnemoName] = handle

  if (needSubscribe) {
    await client.post(RequestSubject.SubscribeChannels, { channelIDs })
  }

  api.dispatch(acceptMnemo({
    mnemoName,
  }))
  api.dispatch(updateAlarmSound())
})

export const openMain = createAsyncThunk('mnemoPage/openMain', async ({ mnemoName }, api) => {
  const appState = api.getState()
  const { mnemos } = appState.mnemoPage

  const isOpen = mnemos.some(it => it.mnemoName === mnemoName)
  if (isOpen) {
    return api.rejectWithValue()
  }

  api.dispatch(spawnMainMnemo({ mnemoName }))

  const res = await getMnemoContent(mnemoName)
  const dto = res.data
  const handle = decodeMnemo(null, dto, mnemoName)

  console.debug(`mnemo: ${mnemoName}`)
  console.debug(handle)

  const channelIDs = handle.getUsedTags()
  const needSubscribe = channelIDs.length > 0

  handle.on('close', () => history.push('/app/mnemotable'))
  handle.on('dispose', () => {
    handleMap[mnemoName] = undefined

    if (needSubscribe) {
      client.delete(RequestSubject.SubscribeChannels, { channelIDs })
    }
  })

  handleMap[mnemoName] = handle

  if (needSubscribe) {
    await client.post(RequestSubject.SubscribeChannels, { channelIDs })
  }

  api.dispatch(acceptMnemo({
    mnemoName,
  }))
  api.dispatch(updateAlarmSound())
})

let lastWindowId = 0

const handleMap = {}

const getMnemoViewName = (qualifiedName) => {
  return qualifiedName
    .split('.')
    .map(it => it.startsWith('@')
      ? it.substring(1)
      : it
    ).join('.')
}

export const closeDialogs = () => (dispatch, getState) => {
  const appState = getState()
  const { mnemos } = appState.mnemoPage
  const names = mnemos
    .filter(it => it.kind === MnemoKind.dialog)
    .map(it => it.mnemoName)

  for (let name of names) {
    dispatch(closeMnemo(name))
  }
}

const store = createSlice({
  
  name: 'mnemoPage',
  initialState,
  reducers: {
    
    closeMnemo: (state, { payload: mnemoName }) => {
      
      const newWindows = state.windows.filter(it => it.mnemoName !== mnemoName)
      state.windows = newWindows

      const newMnemos = state.mnemos.filter(it => it.mnemoName !== mnemoName)
      state.mnemos = newMnemos

      const handle = handleMap[mnemoName]
      if (!!handle) {
        handle.dispose()
        handleMap[mnemoName] = undefined
      }
    },

    openDialogMnemo: (state, { payload }) => {
      const { defaultPosition, mnemoName, windowTitle } = payload
      const isOpen = state.mnemos.some(it => it.mnemoName === mnemoName)
      if (isOpen) {
        return
      }

      const viewName = getMnemoViewName(mnemoName)

      const windowId = `${++lastWindowId}`
      const window = {
        defaultPosition,
        mnemoName,
        title: windowTitle ?? '',
        windowId
      }

      state.windows = [
        ...state.windows,
        window
      ]

      const mnemo = {
        kind: MnemoKind.dialog,
        mnemoName,
        status: MnemoStatus.fetching,
        statusText: '',
        viewName,
        windowId
      }
      
      state.mnemos = [
        ...state.mnemos,
        mnemo
      ]
    },

    spawnMainMnemo: (state, { payload }) => {
      const { mnemoName } = payload
      const isOpen = state.mnemos.some(it => it.mnemoName === mnemoName)
      if (isOpen) {
        return
      }

      const viewName = getMnemoViewName(mnemoName)

      const mnemo = {
        kind: MnemoKind.main,
        mnemoName,
        viewName,
        status: MnemoStatus.fetching,
        statusText: '',
        windowId: ''
      }
      
      state.mnemos = [
        ...state.mnemos,
        mnemo
      ]
    },
  },
  extraReducers: {
    [acceptMnemo]: (state, { payload }) => {
      const { mnemoName } = payload

      const mnemo = state.mnemos.find(it => it.mnemoName === mnemoName)
      if (!mnemo) {
        return
      }

      mnemo.status = MnemoStatus.idle
    },
    
    [openDialog.rejected]: (state, { error, meta: { arg }, payload }) => {
      const { mnemoName } = arg
      if (payload && payload.ignore) {
        return
      }
      
      const mnemo = state.mnemos.find(it => it.mnemoName === mnemoName)
      if (!mnemo) {
        return
      }

      mnemo.status = MnemoStatus.error
      mnemo.statusText = error.message
    }
  }
})

export const selectAnyHasEventTable = () => {
  const hasEventTable = Object.values(handleMap)
    .some(it => it?.hasControlCrossByType(GVLOperationEventTable))

  return hasEventTable
}

export const selectMnemoChannelIDs = () => {

  const mnemos = Object.values(handleMap)
    .filter(it => Boolean(it))

  const tagSlices = []

  for (let mnemo of mnemos) {
    tagSlices.push(mnemo.getUsedTags())

    mnemo.forEachControlCross(it => {
      if (it.typeName === 'Mnemo') {
        tagSlices.push(it.getUsedTags())
      }
    })
  }

  const lTags = tagSlices.flat(1)
  return lTags
}

export const selectMnemoByName = (state, name) => {
  const { mnemos } = state.mnemoPage

  for (let mnemo of mnemos) {
    if (mnemo.mnemoName === name) {
      return mnemo
    }
  }

  return undefined
}

export const selectMnemoHandleByName = (name) => handleMap[name]

export const {
  closeMnemo,
  openDialogMnemo,
  spawnMainMnemo,
} = store.actions

export default store.reducer