import Axios from 'axios'
import { setupCache } from 'axios-cache-interceptor'

const instance = Axios.create()
const axios = setupCache(instance, {
  ttl: 1000 * 60
})

export const getTabView = () => {
  return axios.get('/api/mnemo-tab-view', {
    withCredentials: true
  })
}