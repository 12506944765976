import { GVLTextBlock } from 'GVL/text'
import { MnemoControl } from './controls'
import { DOM } from '@sl/utils'
import { ValueType } from 'GVL/prop-types'
import { format } from 'date-fns'
import { enUS as enDateFnsLocale, ru as ruDateFnsLocale } from 'date-fns/locale'
import { tr } from 'utils/common'

const DateFormat = Object.freeze({
  D_M: { name: 'D_M', dateFns: 'd.M' },
  DD_MM: { name: 'DD_MM', dateFns: 'dd.MM' },
  D_M_Y: { name: 'D_M_Y', dateFns: 'd.M.y' },
  DD_MM_YYYY: { name: 'DD_MM_YYYY', dateFns: 'dd.MM.yyyy' },
  D_MMM_YYYY: { name: 'D_MMM_YYYY', dateFns: 'dd MMM yyyy' },
  D_MMMM_YYYY: { name: 'D_MMMM_YYYY', dateFns: 'dd MMMM yyyy' },
  name: 'DateFormat',
})

const TimeFormat = Object.freeze({
  H_M: { name: 'H_M', dateFns: 'H:m' },
  HH_MM: { name: 'HH_MM', dateFns: 'HH:mm' },
  H_M_S: { name: 'H_M_S', dateFns: 'H:m:s' },
  H_MM_SS: { name: 'H_MM_SS', dateFns: 'H:mm:ss' },
  HH_MM_SS: { name: 'HH_MM_SS', dateFns: 'HH:mm:ss' },
  name: 'TimeFormat',
})

const dateFnsLocales = { 
  'ru-RU': ruDateFnsLocale,
  en: enDateFnsLocale,
}

class DateTimeView extends MnemoControl {

  constructor() {
    super()

    this._keepDomRootByClient = true

    this.width = 270
    this.height = 40

    this._color = '#000000'
    this._dateFormat = DateFormat.DD_MM_YYYY
    this._showDate = true
    this._showTime = true
    this._timeFormat = TimeFormat.H_MM_SS
  }

  dispose() {
    if (this._intervalId) {
      clearInterval(this._intervalId)
    }

    super.dispose()
  }

  get color() { return this._color }
  set color(value) { this._setProp('color', value) }

  get dateFormat() { return this._dateFormat }
  set dateFormat(value) { this._setProp('dateFormat', value) }

  get showDate() { return this._showDate }
  set showDate(value) { this._setProp('showDate', value) }

  get showTime() { return this._showTime }
  set showTime(value) { this._setProp('showTime', value) }

  get timeFormat() { return this._timeFormat }
  set timeFormat(value) { this._setProp('timeFormat', value) }

  initDomRoot() {
    const $root = DOM('div', 'gvl-control')
    this._domRoot = $root.node

    const $text = new GVLTextBlock()
    this.$text = $text
    this.addControl($text)

    $text.wrapping = false
  }

  render() {
    const { $text } = this

    $text.left = 0
    $text.top = 0
    $text.width = this.width
    $text.height = this.height
    $text.color = this.color
    $text.font.size = 27

    $text.text = this._composeText()
  }

  _loadingChanged() {
    super._loadingChanged()

    if (this.loading) {
      return
    }

    this._intervalId = setInterval(() => this.requestUpdate(), 1000)
  }

  _composeText() {
    const time = new Date()
    const tokens = []

    const locale = dateFnsLocales[tr('lang')]

    if (this.showTime) {
      const timeText = format(time, this._timeFormat.dateFns, { locale })
      tokens.push(timeText)
    }

    if (this.showDate) {
      const dateText = format(time, this._dateFormat.dateFns, { locale })
      tokens.push(dateText)
    }

    return tokens.join(' ')
  }

}

DateTimeView.gvlPropTypes = {
  dateFormat: {
    type: ValueType.Enum,
    enumType: DateFormat
  },
  timeFormat: {
    type: ValueType.Enum,
    enumType: TimeFormat
  },
}

export default DateTimeView