import axios from 'axios'
import { GVLControl } from './elements'
import Store from 'GVL/store'
import { ArgumentType, GVLWindow } from 'GVL/window'
import { GVLMath, Quality, ViewportStretch } from 'GVL/types'
import { FAView } from 'GVL/faView'
import { formatChannelValue, tr } from 'utils/common'
import { AlarmStatus } from 'utils/runtime'
import { DOM } from '@sl/utils'
import { decode as decodeUniValue } from '@sl/utils/src/uni-value'
import { updateAlarmSound } from 'features/live/alarmSlice'
import { getFigureContent } from 'services/webServer'

let _store = null

export const injectStore = (value) => {
  _store = value
}

export class GVLFigureView extends GVLControl {
  constructor() {
    super()

    this._keepDomRootByClient = true

    this.arguments = []
    this.channelSource = null

    this._onWindowClose = () => {}

    this.state = {
      status: 'idle',
      statusText: ''
    }

    this.$spinner = new FAView()
    this.addControl(this.$spinner)

    this._uri = ''
  }

  get uri() { return this._uri }
  set uri(value) { this._setProp('uri', value) }

  dispose() {
    this._closeWindow()

    super.dispose()
  }

  initDomRoot() {
    const $root = DOM('section', 'gvl-control')
    this._domRoot = $root.node

    const $con = $root.div('gvl-container')
    const $box = $con.div('gvl-scale-box')

    this.domElements = { $box, $con }
  }

  _closeWindow() {
    const { $box } = this.domElements
    $box?.clear()

    this._onWindowClose()
    this._onWindowClose = () => {}

    this._window?.dispose()
    this._window = null
  }

  _propertyChanged(name) {
    super._propertyChanged(name)

    const { $con } = this.domElements

    switch (name) {
      case 'width':
      case 'height':
        $con?.setStyles({
          width: `${this._width}px`,
          height: `${this._height}px`
        })
        break
      case 'uri':
        this._fetchFigure()
        break
    }
  }

  _getChildAttachElement(value) {
    if (value.typeName === 'GVLWindow') {
      return this.domElements.$box.node
    }

    return this._domRoot
  }

  async _fetchFigure() {
    this._closeWindow()

    const uri = this.uri

    if (uri === '') {
      return
    }

    const ctrlPath = this.collectControlPath()

    const depthLevel = ctrlPath
      .filter(it => it !== this && it.typeName === 'GVLFigureView')
      .length

    if (depthLevel >= 2) {
      this.state.status = 'error'
      this.state.statusText = tr('figureView.depthLevelExceed')
      this.requestUpdate()
      return
    }

    this.state.status = 'fetching'
    this.requestUpdate()

    try {
      const content = await getFigureContent(uri)

      this._updateWindow(content)

      this.state.status = 'idle'
      this.requestUpdate()
    } catch (error) {
      this.state.status = 'error'
      this.state.statusText = tr('figureView.fetchError', {
        uri,
        errorText: error.toString()
      })
      this.requestUpdate()
    }
  }

  _updateWindow(xmlContent) {
    let store = new Store()
    store.argumentService = createArgumentApi(this.arguments)
    store.pictureService = this.parent.pictures

    let window = new GVLWindow()
    window.filePath = `/figures/${this.uri}`
    store.fromXml(xmlContent, window)

    if (store.errors.length > 0) {
      console.error(store.errors)

      throw new Error(store.errors.join('\n'))
    }

    this._closeWindow()

    this._window = window
    this.addControl(this._window)
    this.render()

    const controlPath = this.collectControlPath()

    const isParentRoot = controlPath
      .filter(it => it !== this)
      .every(it => it.typeName !== 'GVLFigureView')

    if (isParentRoot) {
      this._bindArguments()
    }

    _store.dispatch(updateAlarmSound())
  }

  _loadingChanged() {
    if (this.loading) {
      return
    }
  }

  _bindArguments() {
    const w = this._window

    if (!w) {
      return
    }

    if (this._argumentBound || this.loading) {
      return
    }

    this._argumentBound = true

    w.initBindings()
    w.applyArgumentDefaultValues()
    this.applyLocalArgumentValues()
    this.applyTagBindings()
  }

  applyLocalArgumentValues() {
    const srcArgs = this._window.arguments

    for (let srcArg of srcArgs) {
      let localArg = this.arguments.find(it => it.argID === srcArg.id)
      if (!localArg) {
        continue
      }

      const { valid, value } = decodeUniValue(localArg.value)
      if (!valid) {
        continue
      }

      srcArg.value = value
      srcArg.quality = Quality.Good
      srcArg.notifyChanged()
    }
  }

  applyTagBindings() {
    const w = this._window
    const passers = []
    const channelSource = this.channelSource

    for (let arg of w.arguments) {
      let local = this.arguments.find(it => it.argID === arg.id)
      if (!local) {
        continue
      }

      let channel = channelSource.getByID(local.tag)
      if (!channel) {
        continue
      }

      arg.variableFormat = channel.type === 'float'? channel.variableFormat: 0
      arg.units = channel.scale.units

      const eventName = `change.${channel.ID}`
      const passer = this.createArgumentPasser(arg)

      passers.push({
        eventName, passer
      })
      
      this.channelSource.on(eventName, passer)
      passer(channel)
    }

    this._onWindowClose = () => {
      for (let passer of passers) {
        channelSource.off(passer.eventName, passer.passer)
      }

      this._argumentBound = false
    }
  }

  createArgumentPasser(argument) {
    return (channel) => {
      let { scale } = channel

      const status = AlarmStatus[channel.alarmStatus]
      
      argument.alarmStatus = status.id
      argument.isBound = true
      argument.hiAlarm = scale.hiAlarm
      argument.hiHiAlarm = scale.hiHiAlarm
      argument.loAlarm = scale.loAlarm
      argument.loLoAlarm = scale.loLoAlarm
      argument.highScale = scale.highScale
      argument.lowScale = scale.lowScale

      if (argument.type === ArgumentType.String && ['float', 'boolean'].includes(channel.type)) {
        argument.value = formatChannelValue(channel, channel.value)
      } else {
        argument.value = channel.value
      }
      
      argument.quality = channel.quality
      argument.notifyChanged()
    }
  }

  render() {
    this._stretchViewport()

    const hasError = this.state.status === 'error'

    const { $spinner } = this

    $spinner.setBounds({ left: 0, top: 0, width: this._width, height: this._height })
    $spinner.iconClass = hasError? 'exclamation-triangle': 'spinner'
    $spinner.spinning = !hasError
    $spinner.title = this.state.statusText
    $spinner.visible = this.state.status !== 'idle'
  }

  _stretchViewport() {
    const window = this._window
    if (!window) {
      return
    }

    let scaleX = 1
    let scaleY = 1

    const { $box } = this.domElements

    switch (window.viewportStretch) {
      case ViewportStretch.Scale:
        scaleX = GVLMath.sameValue(window.width, 0, 1e-5)
          ? 1
          : this._width / window.width

        scaleY = GVLMath.sameValue(window.height, 0, 1e-5)
          ? 1
          : this._height / window.height

        $box.setStyle('transform', `scale(${scaleX}, ${scaleY})`)
        break

      case ViewportStretch.Fill:
        $box.setStyle('transform', null)
        window.width = this._width
        window.height = this._height
        break

      default:
        $box.setStyle('transform', null)
    }
  }
}

const createArgumentApi = (viewArguments) => ({
  writeValueAsync(argument, value) {

    const viewArg = viewArguments.find(it => it.argID === argument.id)
    if (!viewArg) {
      throw new Error(`cannot find bound tag for argument ${argument.name}`)
    }

    return axios.patch(`/api/channels/${viewArg.tag}`, {
      value
    })
  }
})