import { createSlice } from '@reduxjs/toolkit'
import { RequestSubject } from 'services/NetClient'
import { getPermissions, refreshToken, signOut } from 'services/webServer'

const defaultPermissions = {
  closeClient: false,
}

const initialState = {
  required: false,
  user: null,
  permissions: defaultPermissions,
}

export const logout = (client) => async (dispatch, getState) => {
  const state = getState()
  if (!state.auth.required) {
    return
  }

  try {
    await signOut()
  } catch (error) {
    console.error(error)
  }

  if (client.isConnected) {
    // Необходимо отключиться в любом случае т.к. подключение связано с конкретным пользователем
    await client.post(RequestSubject.DisconnectMeAll)
  } else {
    dispatch(setUser(null))
  }

  window.top?.postMessage({ cmd: 'logout' }, '*')

  // Выход в HMI
  window.hostGate?.logout()
  // const { hostGate } = window

  // if (hostGate) {
  //   hostGate.logout()
  // }
}

export const fetchPermissions = () => async (dispatch) => {
  const res = await getPermissions()
  const { permissions } = res.data
  dispatch(setPermissions(permissions))
}

export const startRefreshToken = () => async (dispatch, getState) => {
  setInterval(async () => {
    let { auth } = getState()

    if (auth.required && auth.user) {
      try {
        const res = await refreshToken()
        const { token } = res.data
        let { auth } = getState()

        if (auth.required && auth.user) {
          console.debug("token refreshed")
          dispatch(setUser({
            ...auth.user,
            token: token
          }))
        }
      } catch (e) {
        console.error(e)
      }
    }
  }, 20 * 60 * 1000)
}

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearUser: (state) => {
      state.user = null
    },
    setAuthRequired: (state, action) => {
      state.required = !!action.payload
    },
    setUser: (state, action) => {
      const user = action.payload
      state.user = user
      state.permissions = defaultPermissions

      if (user) {
        localStorage.setItem("token", user.token)
        localStorage.setItem("username", user.username)
      } else {
        localStorage.removeItem("token")
        localStorage.removeItem("username")
        localStorage.removeItem("tags.etag")
      }
    },
    setPermissions: (state, { payload }) => {
      state.permissions = payload
    }
  }
})

const { setPermissions } = slice.actions

export const {
  clearUser, setAuthRequired, setUser
} = slice.actions

export default slice.reducer