
export function minutesToMilliseconds(minutes) {
  return minutes * 1000 * 60
}

export function secondsToMilliseconds(seconds) {
  return seconds * 1000
}

export function prependZero(num) {
  let value = '' + num

  if (value.length === 1) {
    return '0' + value
  }

  return value
}

export const currentDay = () => {
  let c = new Date()

  c.setHours(0)
  c.setMinutes(0)
  c.setSeconds(0)
  c.setMilliseconds(0)
  return c
}

export const joinDateTime = (date, time) => {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    time.getHours(),
    time.getMinutes(),
    time.getSeconds(),
    time.getMilliseconds()
  )
}

class DateHelper {
  static getUnixTime(date) {
    let mSecOffset = minutesToMilliseconds(date.getTimezoneOffset())
    let utcUnixTime = date.getTime()

    return utcUnixTime - mSecOffset
  }

  static forDateInput(value) {
    let day = prependZero(value.getDate())
    let month = prependZero(value.getMonth() + 1)
    let year = value.getFullYear()

    return `${year}-${month}-${day}`
  }

  static forTimeInput(value) {
    const hours = prependZero(value.getHours())
    const minutes = prependZero(value.getMinutes())
    const seconds = prependZero(value.getSeconds())

    return `${hours}:${minutes}:${seconds}`
  }

  static decomposeMillseconds(ms) {
    const seconds = ms / 1000
    const minutes = seconds / 60
    const hours = minutes / 60
    const days = Math.trunc(hours / 24)

    return {
      days,
      hours: Math.trunc(hours % 24),
      minutes: Math.trunc(minutes % 60),
      seconds: Math.trunc(seconds % 60)
    }
  }
}

export default DateHelper