import { GVLControl, GVLFont } from './elements'
import { svgNS, PointF, RectF, GVLMath } from './types'
import { SVGUtils, interpolatePoint } from './utils'
import { PiBy4, Pi3By4 } from './math'
import { formatNumber, tr } from 'utils/common'

export const ScaleOrientation = Object.freeze({
  Horizontal: {name: 'Horizontal'},
  Vertical: {name: 'Vertical'},
  name: 'ScaleOrientation'
})

export const TickSide = Object.freeze({
  Initial: {name: 'Initial'},
  Opposite: {name: 'Opposite'},
  name: 'Opposite'
})

const TickHeight = 6
const TickMinorHeight = 3
const TickWidth = 1.5

export class GVLScale extends GVLControl {
  constructor() {
    super()
    this._ticks = []
    this._lineStart = new PointF()
    this._lineEnd = new PointF()

    this._digitCommaCount = 2
    this._endValue = 100
    this._font = new GVLFont()
    this._font.onChange = this._fontChange.bind(this)
    this._orientation = ScaleOrientation.Vertical
    this._lineVisible = false
    this._startValue = 0
    this._tickCount = 7
    this._tickSide = TickSide.Initial
    this._tickVisible = true
    this._tickTipVisible = true

    this._stroke.color = '#ffffff'
    this._stroke.width = 1
    this.width = 50
    this.height = 200
  }

  dispose() {
    this._font.dispose()
    super.dispose()
  }

  get digitCommaCount() {
    return this._digitCommaCount
  }

  set digitCommaCount(value) {
    if (this._digitCommaCount !== value) {
      this._digitCommaCount = value
      this.requestUpdate()
    }
  }

  get endValue() {
    return this._endValue
  }

  set endValue(value) {
    const newValue = +value

    if (this._endValue !== newValue) {
      this._endValue = newValue
      this.requestUpdate()
    }
  }

  get font() {
    return this._font
  }

  get orientation() {
    return this._orientation
  }

  set orientation(value) {
    if (this._orientation !== value) {
      this._orientation = value
      this.requestUpdate()
    }
  }

  get lineVisible() {
    return this._lineVisible
  }

  set lineVisible(value) {
    const newValue = !!value

    if (this._lineVisible !== newValue) {
      this._lineVisible = newValue
      this.requestUpdate()
    }
  }

  get startValue() {
    return this._startValue
  }

  set startValue(value) {
    const newValue = +value

    if (this._startValue !== newValue) {
      this._startValue = newValue
      this.requestUpdate()
    }
  }

  get stroke() {
    return this._stroke
  }

  get tickCount() {
    return this._tickCount
  }

  set tickCount(value) {
    const newValue = +value

    if (this._tickCount !== newValue) {
      this._tickCount = newValue
      this.requestUpdate()
    }
  }

  get tickSide() {
    return this._tickSide
  }

  set tickSide(value) {
    if (this._tickSide !== value) {
      this._tickSide = value
      this.requestUpdate()
    }
  }

  get tickVisible() {
    return this._tickVisible
  }

  set tickVisible(value) {
    const newValue = !!value

    if (this._tickVisible !== newValue) {
      this._tickVisible = newValue
      this.requestUpdate()
    }
  }

  get tickTipVisible() {
    return this._tickTipVisible
  }

  set tickTipVisible(value) {
    const newValue = !!value

    if (this._tickTipVisible !== newValue) {
      this._tickTipVisible = newValue
      this.requestUpdate()
    }
  }

  _fontChange() {
    this.requestUpdate()
  }

  _propertyChanged(name) {
    super._propertyChanged(name)

    switch (name) {
      case 'width':
        this._domRoot.style.width = `${this._width}px`
        this.requestUpdate()
        break
      case 'height':
        this._domRoot.style.height = `${this._height}px`
        this.requestUpdate()
        break
    }
  }

  _strokeChange() {
    super._strokeChange()

    this._line && this._line.setAttribute('stroke', this._stroke.color)
    this._tickPath && this._tickPath.setAttribute('stroke', this._stroke.color)

    for (let tick of this._ticks) {
      var { textElement } = tick
      if (textElement) {
        textElement.setAttribute('fill', this._stroke.color)
      }
    }
  }

  initDomRoot() {
    this._domRoot = document.createElementNS(svgNS, 'svg')
    this._domRoot.setAttribute('overflow', 'visible')
    this._domRoot.classList.add('gvl-control')
  }

  render() {
    for (let tick of this._ticks) {
      this._domRoot.removeChild(tick.textElement)
    }

    this._ticks.splice(0, this._ticks.length)

    const font = this._font

    const mainSize = this._orientation === ScaleOrientation.Horizontal? this._width: this._height
    const positionStep = mainSize / (this._tickCount - 1)
    const valueStep = (this._endValue - this._startValue) / (this._tickCount - 1)
    const formatOptions = { 
      minimumFractionDigits: this._digitCommaCount,
      maximumFractionDigits: this._digitCommaCount
    }

    let d = ''
    let topOffset = 0
    let tipTopOffset = 0
    let tipLeftOffset = 0
    let tipOrigin = new PointF()
    
    if (this._orientation === ScaleOrientation.Horizontal) {
      if (this._tickSide === TickSide.Opposite) {
        topOffset = TickHeight
      } else {
        topOffset = this._height - TickHeight
      }
    }

    for (let i = 0; i < this._tickCount; i++) {
      var tickPosition = Math.round(i * positionStep) + 0.5
      var minorTickPosition = Math.round(tickPosition + positionStep / 2)

      if (this._orientation === ScaleOrientation.Vertical) {
        if (this._tickSide === TickSide.Initial) {
          d += `M 0 ${tickPosition} L ${TickHeight} ${tickPosition} z`

          if (i < this._tickCount - 1) {
            d += `M 0 ${minorTickPosition} L ${TickMinorHeight} ${minorTickPosition} z`
          }
        } else {
          d += `M ${this._width} ${tickPosition} h -${TickHeight} z`

          if (i < this._tickCount - 1) {
            d += `M ${this._width} ${minorTickPosition} h-${TickMinorHeight}z`
          }
        }
      } else {
        if (this._tickSide === TickSide.Initial) {
          d += `M ${tickPosition} ${this._height} v -${TickHeight} z`

          if (i < this._tickCount - 1) {
            d += `M ${minorTickPosition} ${this._height} v -${TickMinorHeight} z`
          }
        } else {
          d += `M ${tickPosition} 0 L ${tickPosition} ${TickHeight} z`

          if (i < this._tickCount - 1) {
            d += `M ${minorTickPosition} 0L ${minorTickPosition} ${TickMinorHeight} z`
          }
        }
      }

      if (this._tickTipVisible) {
        var tickValue = GVLMath.roundTo(this._startValue + i * valueStep, this._digitCommaCount)

        var textElement = document.createElementNS(svgNS, 'text')
        textElement.textContent = tickValue.toLocaleString(tr('lang'), formatOptions)
        textElement.setAttribute('font-family', font.family)
        textElement.setAttribute('font-size', font.size)
        this._domRoot.appendChild(textElement)

        var bbox = textElement.getBBox()

        if (this._orientation === ScaleOrientation.Horizontal) {
          if (this._tickSide === TickSide.Opposite) {
            tipTopOffset = topOffset
          } else {
            tipTopOffset = topOffset - bbox.height
          }
  
          if (i === 0) {
            tipOrigin.setXY(tickPosition - 2, tipTopOffset)
          } else if (i === this._tickCount - 1) {
            tipOrigin.setXY(tickPosition - bbox.width + 2, tipTopOffset)
          } else {
            tipOrigin.setXY(tickPosition - bbox.width / 2, tipTopOffset)
          }
        } else {
          if (this._tickSide === TickSide.Opposite) {
            tipLeftOffset = this._width - bbox.width - TickHeight - 3
          } else {
            tipLeftOffset = TickHeight + 2
          }

         if (i === 0) {
            tipOrigin.setXY(tipLeftOffset, tickPosition - 4)
          } else if (i === this._tickCount - 1) {
            tipOrigin.setXY(tipLeftOffset, tickPosition - bbox.height + 4)
          } else {
            tipOrigin.setXY(tipLeftOffset, tickPosition - bbox.height / 2)
          }
        }
        
        textElement.setAttribute('x', tipOrigin.x)
        textElement.setAttribute('y', tipOrigin.y + bbox.height - 3.5)
        textElement.setAttribute('fill', this._stroke.color)
        
        this._ticks.push({
          textElement
        })
      }
    }

    if (!this._tickPath) {
      this._tickPath = document.createElementNS(svgNS, 'path')
      this._tickPath.setAttribute('stroke-width', TickWidth)
      
      this._domRoot.appendChild(this._tickPath)
    }

    this._tickPath.setAttribute('d', d)
    this._tickPath.setAttribute('stroke', this._stroke.color)

    SVGUtils.setVisible(this._tickPath, this._tickVisible)
    
    if (!this._line) {
      this._line = document.createElementNS(svgNS, 'line')
      this._domRoot.appendChild(this._line)
    }

    const model = new RectF()
    model.setBounds(0, 0, this._width, this._height)
    model.offsetXY(0.5, 0.5)

    if (this._orientation === ScaleOrientation.Vertical) {
      if (this._tickSide === TickSide.Initial) {
        this._lineStart.setXY(model.left, model.top)
        this._lineEnd.setXY(model.left, model.bottom)
      } else {
        this._lineStart.setXY(model.right, model.top)
        this._lineEnd.setXY(model.right, model.bottom)
      }
    } else {
      if (this._tickSide === TickSide.Initial) {
        this._lineStart.setXY(model.left, model.bottom)
        this._lineEnd.setXY(model.right, model.bottom)
      } else {
        this._lineStart.setXY(model.left, model.top)
        this._lineEnd.setXY(model.right, model.top)
      }
    }

    this._line.setAttribute('x1', this._lineStart.x)
    this._line.setAttribute('y1', this._lineStart.y)
    this._line.setAttribute('x2', this._lineEnd.x)
    this._line.setAttribute('y2', this._lineEnd.y)
    this._line.setAttribute('stroke', this._stroke.color)

    SVGUtils.setVisible(this._line, this._lineVisible)
  }
}

export class GVLArcScale extends GVLControl {
  constructor() {
    super()
    
    this._arcRadius = new PointF()
    this._midPoint = new PointF()
    this._ticks = []

    this._arcVisible = true
    this._digitCommaCount = 2
    this._endValue = 100
    this._squareSize = true
    this._startAngle = -180
    this._startValue = 0
    this._sweepAngle = 180
    this._tickCount = 7
    this._tickVisible = true
    this._tickTipOffset = 5
    this._tickTipVisible = true
    this.width = 100
    this.height = 100

    this._font = new GVLFont()
    this._font.onChange = this._fontChange.bind(this)
    this._stroke.color = '#ffffff'
    this._stroke.width = 1
  }

  dispose() {
    this._font.dispose()
    super.dispose()
  }

  get arcVisible() {
    return this._arcVisible
  }

  set arcVisible(value) {
    const newValue = !!value

    if (this._arcVisible !== newValue) {
      this._arcVisible = newValue
      this.requestUpdate()
    }
  }

  get digitCommaCount() {
    return this._digitCommaCount
  }

  set digitCommaCount(value) {
    if (this._digitCommaCount !== value) {
      this._digitCommaCount = value
      this.requestUpdate()
    }
  }

  get endValue() {
    return this._endValue
  }

  set endValue(value) {
    const newValue = +value

    if (this._endValue !== newValue) {
      this._endValue = newValue
      this.requestUpdate()
    }
  }

  get font() {
    return this._font
  }

  get startAngle() {
    return this._startAngle
  }

  set startAngle(value) {
    if (this._startAngle !== value) {
      this._startAngle = value
      this.requestUpdate()
    }
  }

  get startValue() {
    return this._startValue
  }

  set startValue(value) {
    const newValue = +value

    if (this._startValue !== newValue) {
      this._startValue = newValue
      this.requestUpdate()
    }
  }

  get stroke() {
    return this._stroke
  }

  get sweepAngle() {
    return this._sweepAngle
  }

  set sweepAngle(value) {
    let newValue = +value % 360

    if (this._sweepAngle !== newValue) {
      this._sweepAngle = newValue
      this.requestUpdate()
    }
  }

  get tickCount() {
    return this._tickCount
  }

  set tickCount(value) {
    let newValue = +value

    if (newValue < 2) {
      newValue = 2
    }

    if (this._tickCount !== newValue) {
      this._tickCount = newValue
      this.requestUpdate()
    }
  }

  get tickVisible() {
    return this._tickVisible
  }

  set tickVisible(value) {
    const newValue = !!value

    if (this._tickVisible !== newValue) {
      this._tickVisible = newValue
      this.requestUpdate()
    }
  }

  get tickTipOffset() { return this._tickTipOffset }
  set tickTipOffset(value) { this._setProp('tickTipOffset', value) }

  get tickTipVisible() {
    return this._tickTipVisible
  }

  set tickTipVisible(value) {
    const newValue = !!value

    if (this._tickTipVisible !== newValue) {
      this._tickTipVisible = newValue
      this.requestUpdate()
    }
  }

  _fontChange() {
    this.requestUpdate()
  }

  _propertyChanged(name) {
    super._propertyChanged(name)

    switch (name) {
      case 'width':
        this._domRoot.style.width = `${this._width}px`
        this.requestUpdate()
        break
      case 'height':
        this._domRoot.style.height = `${this._height}px`
        this.requestUpdate()
        break
    }
  }

  _strokeChange() {
    super._strokeChange()

    this._arc.setAttribute('stroke', this._stroke.color)
    this._tickPath.setAttribute('stroke', this._stroke.color)

    for (let tick of this._ticks) {
      var { tipText } = tick
      if (tipText) {
        tipText.setAttribute('fill', this._stroke.color)
      }
    }
  }

  initDomRoot() {
    this._domRoot = document.createElementNS(svgNS, 'svg')
    this._domRoot.setAttribute('overflow', 'visible')
    this._domRoot.classList.add('gvl-control')

    this._arc = document.createElementNS(svgNS, 'path')
    this._arc.setAttribute('fill', 'none')
    this._domRoot.appendChild(this._arc)

    this._tickPath = document.createElementNS(svgNS, 'path')
    this._tickPath.setAttribute('fill', 'none')
    this._domRoot.appendChild(this._tickPath)
  }

  render() {
    const stroke = this._stroke
    const padding = stroke.width > 0? stroke.width / 2: 0

    this._midPoint.setXY(this._width, this._height)
    this._midPoint.divide(2)

    this._arc.setAttribute('stroke', this._stroke.color)
    this._arc.setAttribute('stroke-width', this._stroke.width)

    this._arcRadius.setXY(this._midPoint.x - padding, this._midPoint.y - padding)

    let pathData = ''

    if (Math.abs(this._sweepAngle) > 1e-5) {
      pathData = createArcPathFigure(this._midPoint, this._arcRadius, this._startAngle, this._sweepAngle)
    }
    
    this._arc.setAttribute('d', pathData)
    SVGUtils.setVisible(this._arc, this._arcVisible)

    this._updateTicks()
  }

  _updateTicks() {
    for (let tick of this._ticks) {
      if (tick.tipText) {
        this._domRoot.removeChild(tick.tipText)
        tick.tipText = null
      }
    }

    if (this._ticks.length > 0) {
      this._ticks.splice(0, this._ticks.length)
    }

    const arcLength = this._arc.getTotalLength()
    const tickGap = arcLength / (this._tickCount - 1)
    const valueStep = (this._endValue - this._startValue) / (this._tickCount - 1)
    let tickPathData = ''

    let tipTexts = []
    
    for (let i = 0; i < this._tickCount; i++) {
      var tickStartLength = i * tickGap
      var tick = this._createTick(TickHeight, tickStartLength)
      this._ticks.push(tick)

      tickPathData += tick.asPathData()

      if (this._tickTipVisible) {
        var tip = this._composeTip(this._startValue + i * valueStep)

        var tipText = document.createElementNS(svgNS, 'text')
        tipText.textContent = tip
        tipText.setAttribute('font-family', this._font.family)
        tipText.setAttribute('font-size', this._font.size)
        tipText.setAttribute('fill', this._stroke.color)
        this._domRoot.appendChild(tipText)
        tipTexts.push(tipText)

        tick.tipText = tipText
      }

      if (i < this._tickCount - 1) {
        tickStartLength = (i + 0.5) * tickGap
        tick = this._createTick(TickMinorHeight, tickStartLength)
        this._ticks.push(tick)

        tickPathData += tick.asPathData()
      }
    }

    const maxTipSize = SVGUtils.getElementsMaxSize(tipTexts)
    this._alignTickTips(maxTipSize)

    this._tickPath.setAttribute('d', tickPathData)
    this._tickPath.setAttribute('stroke', this._stroke.color)
    this._tickPath.setAttribute('stroke-width', 1)

    SVGUtils.setVisible(this._tickPath, this._tickVisible)
  }

  _createTick(size, lengthAt) {
    let domStartAt = this._arc.getPointAtLength(lengthAt)
    let startAt = new PointF()
    startAt.setXY(domStartAt.x, domStartAt.y)
    let endAt = interpolatePoint(startAt, this._midPoint, size)
    const tick = new ArcScaleTick(startAt, endAt)
    
    return tick
  }

  _composeTip(value) {
    const tickValue = GVLMath.roundTo(value, this._digitCommaCount)
    const tip = formatNumber(tickValue, this._digitCommaCount)
    return tip
  }

  _alignTickTips(maxTipSize) {
    for (let tick of this._ticks) {
      var tipText = tick.tipText

      if (tipText) {
        var bbox = tipText.getBBox()
        var angle = tick.startAt.angle(this._midPoint)

        var maxSizeScalar = maxTipSize.width

        if (GVLMath.inRange(angle, PiBy4, Pi3By4) || GVLMath.inRange(angle, -Pi3By4, -PiBy4)) {
          maxSizeScalar = maxTipSize.height
        }

        var textCenter = interpolatePoint(tick.startAt, this._midPoint, TickHeight + maxSizeScalar / 2 + this._tickTipOffset)
        var tipOrigin = new PointF()
        tipOrigin.from(textCenter)
        tipOrigin.addXY(-bbox.width / 2, -bbox.height / 2)
        tipOrigin.truncate()

        tipText.setAttribute('x', tipOrigin.x)
        tipText.setAttribute('y', tipOrigin.y + bbox.height - 3.5)
      }
    }
  }
}

class ArcScaleTick {
  constructor(startAt, endAt) {
    this.startAt = startAt
    this.endAt = endAt
  }

  asPathData() {
    return `M${this.startAt.x} ${this.startAt.y} L ${this.endAt.x} ${this.endAt.y}z`
  }
}

function createArcPathFigure(center, radius, startAngle, sweepAngle) {  
  let endAngle = startAngle + sweepAngle
  let startPoint = polarToCartesian(center.x, center.y, radius.x, radius.y, startAngle)
  let endPoint = polarToCartesian(center.x, center.y, radius.x, radius.y, endAngle)

  let largeArcFlag = Math.abs(sweepAngle) <= 180 ? '0' : '1'
  let sweepFlag = sweepAngle > 0? '1': '0'

  let d = `M ${startPoint.x} ${startPoint.y} ` 
        + `A ${radius.x} ${radius.y} 0 ${largeArcFlag} ${sweepFlag} ${endPoint.x} ${endPoint.y}`

  return d
}

function polarToCartesian(centerX, centerY, radiusX, radiusY, angleInDegrees) {
  var angleInRadians = angleInDegrees * Math.PI / 180.0

  return {
    x: centerX + (radiusX * Math.cos(angleInRadians)),
    y: centerY + (radiusY * Math.sin(angleInRadians))
  }
}