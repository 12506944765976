import { GVLControl, GVLFont } from 'GVL/elements'
import { ValueType } from 'GVL/prop-types'

export class GVLTableRows extends GVLControl {

  initDomRoot() {
    this.defineRoot('table')
  }
}

export class GVLTableRow extends GVLControl {
  
  initDomRoot() {
    this._domRoot = document.createElement('tr')
  }
}

export const CellAlign = Object.freeze({
  LeftTop: { name: 'LeftTop' },
  CenterTop: { name: 'CenterTop' },
  RightTop: { name: 'RightTop' },
  LeftMiddle: { name: 'LeftMiddle' },
  CenterMiddle: { name: 'CenterMiddle' },
  RightMiddle: { name: 'RightMiddle' },
  LeftBottom: { name: 'LeftBottom' },
  CenterBottom: { name: 'CenterBottom' },
  RightBottom: { name: 'RightBottom' },
  ColumnLike: { name: 'ColumnLike' },
  name: 'CellAlign'
})

export const ColumnAlign = Object.freeze({
  LeftTop: CellAlign.LeftTop,
  CenterTop: CellAlign.CenterTop,
  RightTop: CellAlign.RightTop,
  LeftMiddle: CellAlign.LeftMiddle,
  CenterMiddle: CellAlign.CenterMiddle,
  RightMiddle: CellAlign.RightMiddle,
  LeftBottom: CellAlign.LeftBottom,
  CenterBottom: CellAlign.CenterBottom,
  RightBottom: CellAlign.RightBottom,
  name: 'ColumnAlign'
})

export const CellEditable = Object.freeze({
  Allowed: { name: 'Allowed' },
  Prohibited: { name: 'Prohibited' },
  ColumnLike: { name: 'ColumnLike' },
  name: 'CellEditable'
})



export const ColumnEditable = Object.freeze({
  Allowed: CellEditable.Allowed,
  Prohibited: CellEditable.Prohibited,
  name: 'ColumnEditable'
})

export const ColumnValueSource = Object.freeze({
  Table: { name: 'Table' },
  Column: { name: 'Column' },
  name: 'ColumnValueSource'
})

export class GVLColumn {
  constructor() {
    this._fontChange = this._fontChange.bind(this)
    this.onChange = null

    this._caption = 'Колонка'
    this._editable = ColumnEditable.Prohibited
    this._font = new GVLFont()
    this._font.size = 14
    this._font.onChange = this._fontChange
    this._fontSource = ColumnValueSource.Table
    this._textAlign = ColumnAlign.CenterMiddle
    this._textColor = '#000000'
    this._width = 80
  }

  dispose() {
    this.onChange = null
    this._font.dispose()
  }

  get caption() { return this._caption }
  set caption(value) {
    if (this._caption !== value) {
      this._caption = '' + value
      this._doChange()
    }
  }

  get editable() { return this._editable }
  set editable(value) { this._editable = value }

  get font() { return this._font }

  get fontSource() { return this._fontSource }
  set fontSource(value) {
    if (this._fontSource !== value) {
      this._fontSource = value
      this._doChange()
    }
  }

  get textAlign() { return this._textAlign }
  set textAlign(value) {
    if (this._textAlign !== value) {
      this._textAlign = value
      this._doChange()
    }
  }

  get textColor() { return this._textColor }
  set textColor(value) {
    if (this._textColor !== value) {
      this._textColor = '' + value
      this._doChange()
    }
  }

  get width() { return this._width }
  set width(value) {
    if (this._width !== value) {
      this._width = +value
      this._doChange()
    }
  }

  _fontChange() {
    this._doChange()
  }

  _doChange() {
    if (this.onChange) {
      this.onChange()
    }
  }
}
GVLColumn.gvlPropTypes = {
  editable: {
    type: ValueType.Enum,
    enumType: ColumnEditable
  },
  fontSource: {
    type: ValueType.Enum,
    enumType: ColumnValueSource
  },
  textAlign: {
    type: ValueType.Enum,
    enumType: ColumnAlign
  }
}

export function getCellAlignHorzAlign(align) {
  let result = 'left'
  
  switch (align) {
    case CellAlign.CenterTop:
    case CellAlign.CenterMiddle:
    case CellAlign.CenterBottom:
      result = 'center'
      break

    case CellAlign.RightTop:
    case CellAlign.RightMiddle:
    case CellAlign.RightBottom:
      result = 'right'
      break
  }

  return result
}

export function getCellAlignVertAlign(align) {
  let result = 'top'
  
  switch (align) {
    case CellAlign.LeftMiddle:
    case CellAlign.CenterMiddle:
    case CellAlign.RightMiddle:
      result = 'middle'
      break

    case CellAlign.LeftBottom:
    case CellAlign.CenterBottom:
    case CellAlign.RightBottom:
      result = 'bottom'
      break
  }

  return result
}