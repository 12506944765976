import { FAView } from './faView'
import { SLImageBox } from './imageBox'
import { tr } from '../utils/common'
import { GVLControl } from './elements'

export class GVLImage extends GVLControl {
  constructor() {
    super()

    this._keepDomRootByClient = true

    this._box = new SLImageBox()
    this.addControl(this._box)

    this.$spinner = new FAView()
    this.addControl(this.$spinner)
    this.$spinner.visible = false

    this.state = {
      status: 'idle',
      statusText: ''
    }

    this._fileName = ''
    this._horzAlign = 'left'
    this._pictureRotateAngle = 0
    this._stretch = 'none'
    this._vertAlign = 'top'
  }

  initDomRoot() {
    this.defineRoot('div')
  }

  get fileName() { return this._fileName }
  set fileName(value) { this._setProp('fileName', value) }

  get horzAlign() { return this._horzAlign }
  set horzAlign(value) { this._setProp('horzAlign', value) }

  get pictureRotateAngle() { return this._pictureRotateAngle }
  set pictureRotateAngle(value) { this._setProp('pictureRotateAngle', value) }

  get stretch() { return this._stretch }
  set stretch(value) { this._setProp('stretch', value) }

  get vertAlign() { return this._vertAlign }
  set vertAlign(value) { this._setProp('vertAlign', value) }

  _propertyChanged(name) {
    super._propertyChanged(name)

    switch (name) {
      case 'fileName':
        this._fetchImage()
        break
    }
  }

  async _fetchImage() {
    const b = this._box

    this.state.status = 'fetching'
    this.requestUpdate()
    try {
      const window = this.getWindow()
      const mnemoPath = window?.filePath

      const url = encodeURI(`${mnemoPath}/images/${this._fileName}`)
      const $img = await fetchDOMImage(url)

      b.img = $img
      this.state.status = 'idle'
      this.requestUpdate()
    } catch (error) {
      this.state.status = 'error'
      this.state.statusText = error.toString()
      this.requestUpdate()
    }
  }

  render() {
    const { $spinner, _box: b } = this
    b.setBounds({ left: 0, top: 0, width: this._width, height: this._height })
    b.horzAlign = this.horzAlign
    b.pictureRotateAngle = this.pictureRotateAngle
    b.vertAlign = this.vertAlign
    b.stretch = this.stretch

    const hasError = this.state.status === 'error'

    $spinner.setBounds({ left: 0, top: 0, width: this._width, height: this._height })
    $spinner.iconClass = hasError? 'exclamation-triangle': 'spinner'
    $spinner.spinning = !hasError
    $spinner.title = this.state.statusText
    $spinner.visible = this.state.status !== 'idle'
  }

}

function fetchDOMImage(url) {
  return new Promise((resolve, reject) => {
    let image = new window.Image()
    image.src = url
    image.onload = () => {
      resolve(image)
    }
    image.onerror = () => {
      const filename = url.split('/').slice(-1)[0]
      reject(new Error(tr('domImage.fetchError', { filename })))
    }
  })
}